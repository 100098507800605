window.auditForm = {
  init() {
    window.commonJS.handleInitializeDatepickerAndTimepicker()
    window.commonJS.handleInitializeSelect2()
    window.commonJS.handleCollapseLink()
    window.commonJS.clearDatePicker()
    this.handleAjaxSelect2()
    this.toggleRequired();
  },

  handleAjaxSelect2() {
    $('.ajax-select2').select2({
      ajax: {
        type: 'GET',
        url: '/admin/audits/get_audit_items',
        dataType: 'json',
        data: function (params) {
          var query = {
            search: params.term,
            page: params.page || 1
          }

          return query;
        },
        processResults: function (data, params) {
          params.page = params.page || 1;

          return {
            results: data.results,
            pagination: {
              more: (params.page * 10) < data.count_filtered
            }
          };
        }
      }
    })
  },

  toggleRequired() {
    let inboundCustomerEl = $('#audit_inbound_customer_id');
    let vendorEl = $('#audit_vendor_id');

    [
      [vendorEl, inboundCustomerEl],
      [inboundCustomerEl, vendorEl]
    ].forEach(([requiredEl, nonRequiredEL]) => {
      if (requiredEl.val() && !nonRequiredEL.val()) {
        nonRequiredEL.removeAttr('required');
      }

      requiredEl.change(e => {
        if (requiredEl.val()) {
          nonRequiredEL.removeAttr('required');
        } else {
          requiredEl.removeAttr('required');
          nonRequiredEL.prop('required', true);
        }
      });
    });
  }
}
