import {initHandsontable} from '../../../packs/handsontable';
import {newChangesOfTotal} from './calculations';
import ServiceChangesValidator from './service_changes_validator';
import HotBase from './hot_base';
import {loadNumberRenderer, monetaryRenderer, roundToThousandthsRenderer} from './hot_renderers';
import HotCrud from './hot_crud';

export default class Service extends HotBase {
  setup({
          data,
          containerId,
          currencies,
          primaryCurrency,
          loadModuleInfo,
          nonEditable,
          loadModuleId
        }) {
    primaryCurrency = primaryCurrency || currencies[0];
    let crud = new HotCrud();
    let changesValidator = new ServiceChangesValidator();

    this.hot = initHandsontable({
      containerId: containerId,
      data: data,
      additionalOptions: {
        minSpareRows: 1,
        copyPaste: false,
        fillHandle: false,
        undo: true,
        contextMenu: nonEditable ? [] : ['remove_row'],
        hiddenColumns: {
          columns: [0]
        },
        dropdownMenu: false,
        filters: false
      },
      colHeaders: [
        'ID',
        'Load',
        'Warehouse',
        'Service',
        'Description',
        'QTY',
        'Rate',
        'Total',
        'Tax',
        'Currency'
      ],
      columns: [
        {
          data: 'id',
          readOnly: true,
          className: 'htCenter',
          renderer: 'html'
        },
        {
          data: 'load_module_id',
          readOnly: true,
          className: 'htCenter',
          renderer: loadNumberRenderer(loadModuleInfo)
        },
        {
          data: 'load_warehouse_name',
          readOnly: true,
          className: 'htCenter',
          renderer: 'html'
        },
        {
          data: 'name',
          readOnly: nonEditable,
          className: 'htCenter',
          renderer: 'html'
        },
        {
          data: 'description',
          readOnly: nonEditable,
          className: 'htCenter',
          renderer: 'html'
        },
        {
          data: 'qty',
          type: 'numeric',
          editor: 'numeric',
          readOnly: nonEditable,
          allowInvalid: false,
          className: 'htCenter',
          renderer: monetaryRenderer
        },
        {
          data: 'rate',
          type: 'numeric',
          editor: 'numeric',
          readOnly: nonEditable,
          allowInvalid: false,
          className: 'htCenter',
          renderer: roundToThousandthsRenderer
        },
        {
          data: 'total',
          type: 'numeric',
          readOnly: true,
          className: 'htCenter',
          renderer: monetaryRenderer
        },
        {
          data: 'tax_included',
          readOnly: nonEditable,
          type: 'checkbox',
          className: 'htCenter'
        },
        {
          data: 'currency',
          type: 'dropdown',
          readOnly: nonEditable,
          strict: true,
          allowInvalid: false,
          source: currencies,
          className: 'htCenter'
        }
      ]
    });
    this.hot.addHook('beforeChange', (changes, source) => {
      if (source === 'loadData' || source === 'Sync.SetId') return;
      changesValidator.validate(this.hot, changes);
      prePopulateDefaultValues(changes);
      changes.push(...newChangesOfTotal(this.hot, changes));
    });

    this.hot.addHook('afterChange', (changes, source) => {
      if (source === 'loadData' || source === 'Sync.SetId') return;
      crud.create(this.hot, changes, loadModuleId, 'service');
      crud.update(this.hot, changes);
      processSubtotals(changes);
    });

    this.hot.addHook('beforeRemoveRow', (_index, _amount, physicalRows) => {
      crud.delete(this.hot, physicalRows);
    });

    this.hot.addHook('afterRemoveRow', () => {
      this.updateSubtotals();
    });

    let processSubtotals = changes => {
      let subtotalsRecalculate = changes.some(([, prop]) => {
        return prop === 'total' || prop === 'currency';
      });
      subtotalsRecalculate && this.updateSubtotals();
    };

    let prePopulateDefaultValues = changes => {
      let toPopulate = changes.filter(([row]) => {
        return !this.hot.getDataAtRowProp(row, 'id');
      }).reduce((acc, [row, prop, , newValue]) => {
        acc[row] = acc[row] || {};
        acc[row][prop] = newValue;
        return acc;
      }, {});
      Object.entries(toPopulate).forEach(([row, props]) => {
        props.hasOwnProperty('load_module_id') || changes.unshift([row, 'load_module_id', null, loadModuleId]);
        props.hasOwnProperty('load_warehouse_name') ||
          changes.unshift([row, 'load_warehouse_name', null, loadModuleInfo.find(load => load[0] === loadModuleId)[2]]);
        props.hasOwnProperty('tax_included') || changes.unshift([row, 'tax_included', null, false]);
        props.hasOwnProperty('currency') || changes.unshift([row, 'currency', null, primaryCurrency]);
      });
    };

    super.setup(currencies);
  }
}
