window.importDataIndex = {
  init() {
    window.commonJS.handleInitializeSelect2()
    window.commonJS.handleCollapseLink()
    this.handleImportTypeOnChange()
  },

  handleImportTypeOnChange() {
    $('#import_type').on('change', function (e) {
      if (e.target.value === 'Blancco') {
        $('#blancco_selects').show()
        $('#vendor, #tax , #purchased_by').select2()
      } else {
        $('#blancco_selects').hide()
      }
    })
  }
}
