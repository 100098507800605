import {toastErrorMessage} from '../../../../packs/toast';

export let initChangesValidator = hot => {
  return changes => {
    let toSplice = [];
    let errorMessages = new Set();
    let correctionChanges = [];

    changes.forEach(([row, prop, oldValue, newValue], index) => {
      if (oldValue === newValue) {
        toSplice.unshift(index);
        return;
      }
      if (['rate', 'total', 'qty'].includes(prop)) {
        if (newValue) {
          const fractionDigits = prop === 'rate' ? 3 : 2;
          newValue = parseFloat(newValue.replace(/,/g, '.'))
            .toFixed(fractionDigits);

          if (isNaN(newValue)) {
            toSplice.unshift(index);
            errorMessages.add('Invalid number format');
            return;
          }
          if (prop === 'qty' && newValue < 0) {
            toSplice.unshift(index);
            errorMessages.add('Quantity can not be less than 0');
            return;
          }
          toSplice.unshift(index);
          correctionChanges.push(
            [row, prop, oldValue, newValue]
          );
        }
      }
      if (prop === 'currency' && !newValue) {
        toSplice.unshift(index);
        errorMessages.add('Currency can not be blank');
      }
    });

    errorMessages.size > 0 && toastErrorMessage([...errorMessages].join('<br>'));

    toSplice.forEach(index => {
      changes.splice(index, 1);
    });
    correctionChanges.forEach(change => {
      changes.push(change);
    });
  }
};
