window.usersForm = {
  init() {
    window.commonJS.handleInitializeSelect2()
    this.handleUserRoleIdOnChange()
  },

  handleUserRoleIdOnChange() {
    $("#user_role_id").on('change', function () {
      var role = $("#user_role_id option:selected").text()

      if (role == "warehouse") {
        $("#warehouse-select").removeClass("hidden")
        $("#salesperson-checkbox").addClass("hidden")
      }
      else if (role == "sales" || role == "admin") {
        $("#salesperson-checkbox").removeClass("hidden")
      }
      else {
        $("#warehouse-select").addClass("hidden")
        $("#salesperson-checkbox").addClass("hidden")
      }
    })
  }
}
