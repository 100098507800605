$(document).on('turbolinks:load', () => {
  if ($('.inbound-quote-index').length === 0) return;

  window.commonJS.handleCollapseLink();
  window.commonJS.handleInitializeSelect2();
  window.commonJS.handleInitializeDatepicker();
  window.commonJS.initializeClearDatePicker();
  $('#created_on').datepicker('option', 'altField', '#q_created_on_eq');

  initInboundQuoteDatatable();
  handleFilterInboundQuoteDataTable();
});

const handleFilterInboundQuoteDataTable = () => {
  $('.submit-inbound-quote-filter').click(e => {
    e.preventDefault();

    $('#inbound-quote-dt').DataTable().ajax.reload();
  });
};

const initInboundQuoteDatatable = () => {
  $('#inbound-quote-dt').dataTable({
    searching: false,
    paging: true,
    pageLength: 20,
    info: true,
    orders: [[1, 'desc']],
    order: [],
    dom: 'Bfrtip',
    buttons: [],
    serverSide: true,
    ajax: {
      url: window.location.href + '.json'
    },
    preDrawCallback: settings => {
      const api = settings.oInstance.api();
      if (!api.context[0].ajax.data) {
        api.context[0].ajax.data = {};
      }
      api.context[0].ajax.data['q'] = $('#inbound_quote_search').serializeObject()['q'];
      api.context[0].ajax.data['q']['sorts'] = formatOrderDirection(api);
      api.context[0].ajax.data['page'] = api.page.info().page + 1;
    },
    columns: [
      {
        data: 'id',
        render: (data, _type) => {
          if (!data.id) return '';

          const link = `/inbound_quotes/${data.id}/edit`;
          return `<a href="${link}" data-turbolinks="false">${data.uid}</a>`;
        }
      },
      {data: 'user_first_name'},
      {data: 'created_on'},
      {data: 'status'},
      {data: 'inbound_customer_company_name'},
      {data: 'load_warehouse_name'},
      {
        data: 'destroy',
        className: 'text-center',
        orderable: false,
        render: (data, _type) => {
          if (!data) return '';

          return `
            <a href="/inbound_quotes/${data}" class="btn code-red btn-xs" 
              data-method='delete'
              data-confirm="Are you sure?">
              <i class='fas fa-trash-alt'></i>
            </a>`;
        }
      }
    ]
  });
};

const formatOrderDirection = datatableApi => {
  if (datatableApi.order().length === 0) return '';

  const columns = datatableApi.settings().init().columns;
  const order = datatableApi.order()[0];

  return `${columns[order[0]].data} ${datatableApi.order()[0][1]}`;
};
