window.loadItemTypeForm = {
  init() {
    this.handleAddBlanccoItemCode()
  },

  handleAddBlanccoItemCode() {
    $(".add-blancco-item-code").data("association-insertion-method", 'append').data("association-insertion-node", '.blancco-item-codes')

    $('.mbf-categories').on('cocoon:before-remove', function (e, insertedItem, originalEvent) {
      $(e.target).closest('hr').remove()
    })
  }
}
