let currentInboundUserTarget;

$(document).on('turbolinks:load', () => {
  if($('.inbound-customer-from').length > 0) {
    initializeNewUserButtonEvent();
    initializeEditButtonEvent();
    initializeSaveButtonEvent();
    initializeCocoonEvents();
    initializeCloseModalEvent();
    initPasswordShowEvents();
    initializeRemoveButtonEvent();
  }
});

let initializeCocoonEvents = () => {
  $('.inbound-users-form').on('cocoon:after-insert', (e, addedUser) => {
    $('.no-inbound-users').hide();
    initializeEditButtonEvent();

    $(addedUser).find('.remove-link').click(e => {
      userRemoveConfirmation(e)
    });
  });
}

let initializeRemoveButtonEvent = () => {
  $('.remove-link').click(e => {
    userRemoveConfirmation(e)
  });
}

let userRemoveConfirmation = userRemoveEvent => {
  userRemoveEvent.preventDefault();
  let target = $(userRemoveEvent.currentTarget);

  if (confirm('Are You Sure?')) {
    target.closest('.inbound-users-nested-form')
      .find('.cocoon-remove-link')[0].click();
  }
}

let initializeNewUserButtonEvent = () => {
  $('.new-inbound-user').click(() => {
    currentInboundUserTarget = null;

    openInboundUserForm();
  });
}

let initializeCloseModalEvent = () => {
  $('#inbound-user-form-modal').on('hide.bs.modal', () => {
    $('.inbound-user-errors').hide();
    $('#inbound-user-form-modal').find('input').val('');
    unhidePassword();
  });
}

let initializeSaveButtonEvent = () => {
  $('.save-inbound-user').click(() => {
    checkInboundUserValidity().success(data => {
      if (data.valid) {
        saveInboundUser(currentInboundUserTarget);
      } else {
        renderInboundUserErrors(data.errors)
      }
    });
  });
}

let initializeEditButtonEvent = () => {
  $('.edit-inbound-user').click(e => {
    e.preventDefault();

    currentInboundUserTarget = $(e.currentTarget).closest('.inbound-users-nested-form');
    let userData = inboundUserData(currentInboundUserTarget);
    setInboundUserDataToModal(userData);
    $('#inbound-user-form-modal').modal('show');
  })
}

let openInboundUserForm = () => {
  $('#inbound-user-form-modal').modal('show');
}

let saveInboundUser = targetForm => {
  if (!targetForm) {
    $('#add-inbound-user')[0].click();
  }

  setInboundUserData(targetForm || $('.inbound-users-nested-form').last());
  $('#inbound-user-form-modal').modal('hide');
}

let modalInboundUserData = () => {
  let data = {
    first_name: $('#inbound-user-first-name').val(),
    last_name: $('#inbound-user-last-name').val(),
    email: $('#inbound-user-email').val(),
    direct_number: $('#inbound-user-phone').val(),
    is_active: $('#inbound-user-active').is(':checked'),
    password: $('#inbound-user-password').val()
  }

  return data;
}

let setInboundUserData = targetForm => {
  let inboundUserData = modalInboundUserData();

  targetForm.find('.inbound_customer_inbound_users_first_name input').val(inboundUserData['first_name']);
  targetForm.find('.inbound_customer_inbound_users_last_name input').val(inboundUserData['last_name']);
  targetForm.find('.inbound-user-name-text').text(`${inboundUserData['first_name']} ${inboundUserData['last_name']}`);
  targetForm.find('.inbound_customer_inbound_users_email input').val(inboundUserData['email']);
  targetForm.find('.inbound-user-email-text').text(inboundUserData['email']);
  targetForm.find('.inbound_customer_inbound_users_direct_number input').val(inboundUserData['direct_number']);
  targetForm.find('.inbound-user-direct-number-text').text(inboundUserData['direct_number']);
  targetForm.find('.inbound_customer_inbound_users_is_active input').val(inboundUserData['is_active']);

  let passwordField = targetForm.find('.inbound_customer_inbound_users_password input');

  if (inboundUserData['password'].length > 0) {
    passwordField.prop('disabled', false);
    passwordField.val(inboundUserData['password']);
  } else {
    passwordField.prop('disabled', true);
  }
}

let inboundUserData = nestedUserForm => {
  let data = {
    first_name: nestedUserForm.find('.inbound_customer_inbound_users_first_name input').val(),
    last_name: nestedUserForm.find('.inbound_customer_inbound_users_last_name input').val(),
    email: nestedUserForm.find('.inbound_customer_inbound_users_email input').val(),
    direct_number: nestedUserForm.find('.inbound_customer_inbound_users_direct_number input').val(),
    is_active: nestedUserForm.find('.inbound_customer_inbound_users_is_active input').val(),
    password: nestedUserForm.find('.inbound_customer_inbound_users_password input').val()
  }

  return data;
}

let setInboundUserDataToModal = data => {
  $('#inbound-user-first-name').val(data['first_name']);
  $('#inbound-user-last-name').val(data['last_name']);
  $('#inbound-user-email').val(data['email']);
  $('#inbound-user-phone').val(data['direct_number']);
  $('#inbound-user-password').val(data['password']);
  $('#inbound-user-active').prop('checked', data['is_active'] === 'true');
}

let checkInboundUserValidity = () => {
  let userId;

  if(currentInboundUserTarget) {
    userId = currentInboundUserTarget.data('inbound-user-id');
  }

  return $.ajax({
    url: '/admin/users/check_user_validity',
    format: 'JSON',
    data: {
      id: userId,
      user: modalInboundUserData()
    }
  });
}

let renderInboundUserErrors = errors => {
  $('.inbound-user-errors').find('.error-messages').html(errors.join('<br/>'));

  $('.inbound-user-errors').show();
}

let initPasswordShowEvents = () => {
  $('.password-toggle-icon').click(() => {
    if ($('#inbound-user-password').attr('type') === 'password') {
      $('#inbound-user-password').attr('type', 'text');
      $('.password-toggle-icon .fa-eye').hide();
      $('.password-toggle-icon .fa-eye-slash').removeClass('d-none')
      $('.password-toggle-icon .fa-eye-slash').show();
    } else {
      unhidePassword();
    }
  });
}

let unhidePassword = () => {
  if ($('#inbound-user-password').attr('type') === 'text') {
    $('#inbound-user-password').attr('type', 'password');
    $('.password-toggle-icon .fa-eye').show();
    $('.password-toggle-icon .fa-eye-slash').hide();
  }
}
