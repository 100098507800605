import {toastErrorMessage} from '../../../../packs/toast';

export let onsiteServiceLinkHandler = (id) => {
  fetch(`${'/admin/load_work_orders'}/${id}`).then(response => {
    if (response.ok) {
      return response.json();
    } else {
      throw new Error('Something went wrong');
    }
  }).then(data => {
    populateOnsiteServiceModalTable([data]);
  }).catch(error => {
    toastErrorMessage(error);
  });
};

export let populateOnsiteServiceModalTable = (data) => {
  $('#onsite-service-modal-table tbody').empty();
  $('#work-order-onsite-service-modal').modal('show');

  let table = $('#onsite-service-modal-table');
  $.each(data, (_index, item) => {
    let row = $('<tr>');
    row.append(`<td>${(item.name || '')}</td>`);
    row.append(`<td>${(item.description || '')}</td>`);
    row.append(`<td>${(item.rate || '')}</td>`);
    row.append(`<td>${(item.qty || '')}</td>`);
    row.append(`<td>${(item.total || '')}</td>`);
    table.append(row);
  });
};
