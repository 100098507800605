window.citiesForm = {
  init() {
    window.commonJS.handleInitializeSelect2()
    this.handleCityCountryCodeChange()
  },

  handleCityCountryCodeChange() {
    $('#city_country_code').on('change', function () {
      var country = $(this).val()

      return $.ajax({
        type: "GET",
        url: "/admin/customers/get_states",
        data: {
          country: country,
          element_id: 'city_province_code'
        }
      })
    })
  }
}
