export let initCalculateTotals = hot => {
  return changes => {
    changes.filter(([, prop, oldValue, newValue]) => {
      return (prop === 'weight' || prop === 'rate') && oldValue !== newValue;
    }).forEach(([row, prop, , newValue]) => {
      let oldTotal = hot.getDataAtRowProp(row, 'total');
      let rate = hot.getDataAtRowProp(row, 'rate');
      let weight = hot.getDataAtRowProp(row, 'weight');
      switch (prop) {
        case 'weight':
          weight = newValue;
          break;
        case 'rate':
          rate = newValue;
          break;
      }
      let newTotal = weight * rate;
      newTotal = newTotal.toFixed(2) || 0;
      changes.push([row, 'total', oldTotal, newTotal]);
    });
  };
};
