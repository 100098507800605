window.serverForm = {
  init() {
    this.handleSaveItemButton()
    this.handleSaveButton()
  },

  handleSaveItemButton() {
    $('.save-item-btn').on('click', function(e){
      e.preventDefault()
      var p = $("#item_category_processor").val()
      var h = $("#item_category_hard_disk").val()
      var r = $("#item_category_ram").val()

      if((p != "") && (h != "") && (r != "")) {
        $('.edit_item').trigger('submit')
      } else {
        $("#item_category_processor").addClass("red-error")
        $("#item_category_hard_disk").addClass("red-error")
        $("#item_category_ram").addClass("red-error")
        return false
      }
    })
  },

  handleSaveButton() {
    $('.saving-btn').on('click', function(e){
      e.preventDefault()
      var p = $("#item_category_processor").val()
      var h = $("#item_category_hard_disk").val()
      var r = $("#item_category_ram").val()

      if((p != "") && (h != "") && (r != "")) {
        $('.new_item').trigger('submit')
      } else {
        $("#item_category_processor").addClass("red-error")
        $("#item_category_hard_disk").addClass("red-error")
        $("#item_category_ram").addClass("red-error")
        return false
      }
    })
  }
}
