window.purchaseOrderForm = {
  init() {
    this.handleInitializeDatepicker()
    window.commonJS.clearDatePicker()
    this.handleInitializeSelect2()
    this.handleOnChangeTax()
    this.handleGetVendorDetails()
    this.handleGetWarehouseDetails()
    this.handleGetStates()
    this.handleGetCities()
    this.handleCalculatePurchaseOrderTotal()
  },

  handleInitializeDatepicker() {
    $('.datepicker').datepicker({
      todayHighlight: true,
      orientation: 'auto top',
      dateFormat: 'mm-dd-yy'
    })

    $(".datepicker").datepicker("option", "dateFormat", "mm-dd-yy")
  },

  handleInitializeSelect2() {
    $('.vendor-ship-to-section .select2, .total-section .select2').select2({
      placeholder: "Select",
      allowClear: false,
      createTag: function () {
        return null;
      }
    })

    $('#vendor_province_code').select2({
      placeholder: vendorProvince
    })

    $('#vendor_city_code').select2({
      placeholder: vendorCity
    })

    $('#warehouse_province_code').select2({
      placeholder: warehouseProvinceCode
    })

    $('#warehouse_city_code').select2({
      placeholder: warehouseCity
    })

    $(".select2-create").select2({
      placeholder: "Select or Create",
      allowClear: true,
      tags: true
    })
  },

  handleOnChangeTax() {
    $("#purchase_order_tax_id").on('change', function () {
      var id = $(this).val();

      $.ajax({
        type: "GET",
        url: "/admin/purchase_orders/get_tax_details",
        dataType: "json",
        data: { tax_id: id },
        success: function (data) { $("#purchase_order_tax_percentage").val(data.percentage) }
      });
    });
  },

  handleGetVendorDetails() {
    $('#purchase_order_vendor_id').on('change', function () {
      var vendorId = $("#purchase_order_vendor_id option:selected").val()

      if (vendorId >= 1) {
        $.ajax({
          type: "GET",
          url: "/admin/purchase_orders/get_vendor_details",
          dataType: "json",
          data: { vendor_id: vendorId },
          success: function (data) {
            $("#vendor_address1").html(data.address1).prop("disabled", true)
            $("#vendor_address2").html(data.address2).prop("disabled", true)
            $('#vendor_country_code').val(data.country_code).trigger('change').prop("disabled", true)
            $("#vendor_city_code").select2({ placeholder: data.city_code }).prop("disabled", true)
            $("#vendor_province_code").select2({ placeholder: data.province_code }).prop("disabled", true)
            $("#vendor_postal_code").val(data.postal_code).prop("disabled", true)
            $("#vendor_phone_number").val(data.phone_number).prop("disabled", true)
            $("#vendor_fax_number").val(data.fax_number).prop("disabled", true)
            $("#vendor_contact_person").val(data.contact_person).prop("disabled", true)
            $("#vendor_email").val(data.email).prop("disabled", true)
          }
        })
      } else {
        $("#vendor_address1").html(null).prop("disabled", false)
        $("#vendor_address2").html(null).prop("disabled", false)
        $('#vendor_country_code').prop("disabled", false)
        $("#vendor_city_code").select2({ placeholder: 'Select Provice 1st' }).prop("disabled", false)
        $("#vendor_province_code").select2({ placeholder: 'Select Country 1st' }).prop("disabled", false)
        $("#vendor_postal_code").val(null).prop("disabled", false)
        $("#vendor_phone_number").val(null).prop("disabled", false)
        $("#vendor_fax_number").val(null).prop("disabled", false)
        $("#vendor_contact_person").val(null).prop("disabled", false)
        $("#vendor_email").val(null).prop("disabled", false)
      }
    })
  },

  handleGetWarehouseDetails() {
    $('#purchase_order_warehouse_id').on('change', function () {
      var warehouseId = $("#purchase_order_warehouse_id option:selected").val()

      if (warehouseId >= 1) {
        $.ajax({
          type: "GET",
          url: "/admin/purchase_orders/get_warehouse_details",
          dataType: "json",
          data: {
            warehouse_id: warehouseId
          },
          success: function (data) {
            $("#warehouse_address1").html(data.address1).prop("disabled", true)
            $("#warehouse_address2").html(data.address2).prop("disabled", true)
            $('#warehouse_country_code').val(data.country_code).trigger('change').prop("disabled", true)
            $("#warehouse_city_code").select2({ placeholder: data.city_code }).prop("disabled", true)
            $("#warehouse_province_code").select2({ placeholder: data.province_code }).prop("disabled", true)
            $("#warehouse_postal_code").val(data.postal_code).prop("disabled", true)
            $("#warehouse_phone_number").val(data.phone_number).prop("disabled", true)
            $("#warehouse_fax_number").val(data.fax_number).prop("disabled", true)
            $("#warehouse_contact_person").val(data.contact_person).prop("disabled", true)
            $("#warehouse_email").val(data.email).prop("disabled", true)
          }
        })
      } else {
        $("#warehouse_address1").html(null).prop("disabled", false)
        $("#warehouse_address2").html(null).prop("disabled", false)
        $('#warehouse_country_code').prop("disabled", false)
        $("#warehouse_city_code").select2({ placeholder: 'Select Provice 1st' }).prop("disabled", false)
        $("#warehouse_province_code").select2({ placeholder: 'Select Country 1st' }).prop("disabled", false)
        $("#warehouse_postal_code").val(null).prop("disabled", false)
        $("#warehouse_phone_number").val(null).prop("disabled", false)
        $("#warehouse_fax_number").val(null).prop("disabled", false)
        $("#warehouse_contact_person").val(null).prop("disabled", false)
        $("#warehouse_email").val(null).prop("disabled", false)
      }
    })
  },

  handleGetStates() {
    $('#vendor_country_code, #warehouse_country_code').on('change', function (e) {
      var modelId = e.currentTarget.id == 'vendor_country_code' ? $("#purchase_order_vendor_id option:selected").val() : $("#purchase_order_warehouse_id option:selected").val()

      if (!$('#vendor_province_code').data('firstTime')) {
        return $.ajax({
          type: "GET",
          url: "/admin/purchase_orders/get_states",
          data: {
            country: $(this).val(),
            model_id: modelId,
            element_id: elementId[$(this).attr("id")]
          }
        })
      }
    })
  },

  handleGetCities() {
    $('#vendor_province_code, #warehouse_province_code').on('change', function (e) {
      var modelId = e.currentTarget.id == 'vendor_province_code' ? $("#purchase_order_vendor_id option:selected").val() : $("#purchase_order_warehouse_id option:selected").val()

      if (!$('#vendor_province_code').data('firstTime')) {
        var state = $(this).val()
        var country = $(this).attr("id") === 'vendor_province_code' ? $('#vendor_country_code').val() : $('#warehouse_country_code').val()

        return $.ajax({
          type: "GET",
          url: "/admin/purchase_orders/get_cities",
          data: {
            country: country,
            state: state,
            element_id: elementId[$(this).attr("id")],
            model_id: modelId
          }
        })
      }
    })
  },

  handleCalculatePurchaseOrderTotal() {
    $("#purchase_order_freight").on('keyup mouseup', function (e) {
      var tax_amount = $("#purchase_order_tax_amount").val();
      var subtotal = $("#purchase_order_sub_total").val();
      var freight = $(this).val();
      var tax = $("#purchase_order_tax_percentage").val();

      if (tax == "") { tax = 0 }
      if (tax_amount == "") { tax_amount = 0 }
      if (subtotal == "") { subtotal = 0 }
      if (freight == "") { freight = 0 }

      var freight_amount = ((parseFloat(tax) / 100) * parseFloat(freight)).toFixed(2)
      var total_tax = (parseFloat(freight_amount) + parseFloat(tax_amount))

      $("#after-tax").html("+ (" + freight_amount + ")")
      $("#changable-tax").val(total_tax)
      var final_computation = (parseFloat(total_tax) + parseFloat(subtotal) + parseFloat(freight))
      $("#purchase_order_total").val(final_computation)
    })
  }
}
