export let initPrepopulate = (hot, uom, primaryCurrency) => {
  return changes => {
    let toPopulate = changes.filter(([row]) => {
      return !hot.getDataAtRowProp(row, 'id');
    }).reduce((acc, [row, prop, , newValue]) => {
      acc[row] = acc[row] || {};
      acc[row][prop] = newValue;
      return acc;
    }, {});
    Object.entries(toPopulate).forEach(([row, props]) => {
      props.hasOwnProperty('tax_included') || changes.unshift([row, 'tax_included', null, false]);
      props.hasOwnProperty('currency') || changes.unshift([row, 'currency', null, primaryCurrency]);
      props.hasOwnProperty('uom') || changes.unshift([row, 'uom', null, uom]);
      props.hasOwnProperty('weight') || changes.unshift([row, 'weight', null, 0]);
      props.hasOwnProperty('rate') || changes.unshift([row, 'rate', null, 0]);
    });
  };
};
