export let initPopulateDefaultValues = ({
    hot,
    itemDescriptions,
    validQuantities,
    primaryCurrency,
    loadModuleId,
    loadModuleInfo
  }) => {
  return changes => {
    let toPopulate = changes.filter(([row]) => {
      return !hot.getDataAtRowProp(row, 'id');
    }).reduce((acc, [row, prop, , newValue]) => {
      acc[row] = acc[row] || {};
      acc[row][prop] = newValue;
      return acc;
    }, {});
    Object.entries(toPopulate).forEach(([row, props]) => {
      props.hasOwnProperty('load_module_id') ||
        changes.unshift([row, 'load_module_id', null, loadModuleId]);

      props.hasOwnProperty('load_warehouse_name') ||
        changes.unshift([row, 'load_warehouse_name', null, loadModuleInfo.find(load => load[0] === loadModuleId)[2]]);

      if (!props.hasOwnProperty('description')) {
        populateByFullName({
          row: row,
          props: props,
          changes: changes,
          itemDescriptions: itemDescriptions,
          validQuantities: validQuantities
        });
      }

      props.hasOwnProperty('tax_included') ||
        changes.unshift([row, 'tax_included', null, false]);
      props.hasOwnProperty('currency') ||
        changes.unshift([row, 'currency', null, primaryCurrency]);
    });
  };
};

let populateByFullName = ({row, props, changes, itemDescriptions, validQuantities}) => {
  let name, fullName, description;

  if (props.hasOwnProperty('full_name')) {
    // full_name - is it custom or existing?
    let found = itemDescriptions.find(el => el[1] === props.full_name);
    // existing? - populate with name and qualified type
    if (found) {
      name = itemDescriptions.find(el => el[1] === props.full_name)[0];
      description = validQuantities[name].qualified_type;
      // custom? - populate with the same name
    } else {
      fullName = props.full_name;
      name = props.full_name;
    }
  } else {
    let itemDescription = itemDescriptions[0] || [null, null];

    if (itemDescription[0]) description = validQuantities[itemDescription[0]].qualified_type;
    fullName = itemDescription[1];
    name = itemDescription[0];
  }

  props.hasOwnProperty('full_name') ||
    changes.unshift([row, 'full_name', null, fullName]);
  changes.unshift([row, 'name', null, name]);
  changes.unshift([row, 'description', null, description]);
}
