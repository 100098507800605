$(document).on('turbolinks:load', () => {
  if ($('.transfer-index').length === 0) return;

  window.commonJS.handleInitializeSelect2();
  window.commonJS.handleCollapseLink();
  handleInitializeTransferDatatable();
  handleFilterTransferDataTable();
  window.commonJS.handleInitializeDatepicker();
  $('#transferred_on').datepicker('option', 'altField', '#q_transferred_on_eq');
  $('#eta').datepicker('option', 'altField', '#q_eta_eq');
  window.commonJS.clearDatePicker();
});

const handleFilterTransferDataTable = () => {
  $('.btn-transfer-filter').click(e => {
    e.preventDefault();
    $('#transfer-table').DataTable().ajax.reload();
  });
};

const handleInitializeTransferDatatable = () => {
  $('#transfer-table').dataTable({
    searching: false,
    ordering: false,
    paging: true,
    pageLength: 20,
    info: true,
    orders: [[1, 'desc']],
    order: [],
    dom: 'Bfrtip',
    buttons: [],
    serverSide: true,
    ajax: {
      url: '/admin/transfers.json'
    },
    preDrawCallback: settings => {
      let api = settings.oInstance.api();
      if (!api.context[0].ajax.data) {
        api.context[0].ajax.data = {};
      }
      api.context[0].ajax.data['q'] = $('#transfer_search').serializeObject()['q'];
      api.context[0].ajax.data['q']['sorts'] = formatOrderDirection(api);
      api.context[0].ajax.data['page'] = api.page.info().page + 1;
    },
    columns: [
      {
        data: 'transfer_code',
        render: (data, _type) => {
          if (data.id) {
            const link = `/admin/transfers/${data.id}`;

            return `<a href="${link}">${data.code}</a>`;
          } else {
            return '';
          }
        }
      },
      {data: 'sales'},
      {data: 'source'},
      {data: 'destination'},
      {data: 'eta'},
      {data: 'transferred_on'},
      {data: 'status'},
      {data: 'warehouse'},
      {
        data: 'destroy',
        className: 'text-center',
        orderable: false,
        render: (data, _type) => {
          if (data) {
            const link = `/admin/transfers/${data}`;

            return `
              <a href="${link}"
                class="btn code-red btn-xs"
                data-method="delete"
                data-confirm="Are you sure?">
                <i class="fas fa-trash-alt"></i>
              </a>
            `;
          } else {
            return '';
          }
        }
      }
    ]
  });
};

const formatOrderDirection = datatableApi => {
  if (datatableApi.order().length === 0) return '';

  let columns = datatableApi.settings().init().columns;
  let order = datatableApi.order()[0];

  return `${columns[order[0]].data} ${datatableApi.order()[0][1]}`;
};
