import {toastErrorMessage} from '../../../../packs/toast';

let fetchData = async (loadModuleIds, mbfCompanyId) => {
  let data = {};
  try {
    data.load_work_orders = await $.ajax({
      url: '/admin/load_work_orders',
      data: {load_module_ids: loadModuleIds},
      dataType: 'json'
    });
    data.mbf_descriptions = await $.ajax({
      url: '/admin/mbf_descriptions',
      data: {mbf_company_ids: [mbfCompanyId]},
      dataType: 'json'
    });
    data.load_mbfs = await $.ajax({
      url: '/admin/load_mbfs',
      headers: {
        Accept: 'application/json'
      },
      data: {
        mbf_description_ids: data.mbf_descriptions.map(obj => obj.id),
        load_module_ids: loadModuleIds,
        mbf_company_id: mbfCompanyId
      },
      dataType: 'json'
    });
    return data;
  } catch (error) {
    console.error('Error fetching data:', error);
    toastErrorMessage('Something went wrong. Please try again later.');
  }
};

export default fetchData;
