import LoadMbf from './load_mbf';
import {toastErrorMessage, toastSuccessMessage} from '../../packs/toast';

let mbfTabHandler = e => {
  if ($(e.target).attr('href') === '#mbf') {
    let getLoadModuleIds = () => {
      let ids = [$('#mbf-company').data('load-module-id')] || [];

      if (typeof ids === 'string') ids = [ids];
      return ids.map(id => parseInt(id, 10));
    };

    window.loadModuleGeneral.mbf.setup(
      getLoadModuleIds()
    );
  } else {
    window.loadModuleGeneral.mbf.destroy();
  }
};

window.loadModuleGeneral = {
  init() {
    this.reinit();
    this.handleMbfTab();
    this.handleDataCaptureTab();
    this.handleDisablingForm();
  },

  reinit() {
    window.commonJS.initializeTimePicker();
    window.commonJS.handleInitializeSelect2();
    window.commonJS.initializeClearDatePicker();
    window.directUpload.init();
    window.commonJS.initializeDatepicker(
      [
        'load_module_closed_date',
        'load_module_settled_date',
        'load_module_date_processed',
        'load_module_estimated_delivery_date',
        'load_module_required_date'
      ]
    );
    window.commonJS.initializeDatepicker(
      [
        'load_module_date_received'
      ],
      {
        minDate: 0
      }
    );
    this.handleTotalCalculation();
    this.handleContactPerson();
    this.handleValidateIntegerChange();
    this.initiallizeOnsiteServiceCocoon();
    setTimeout(this.handleOpenTransferAndSalesOrderModal(), 1000);
    this.handlePickupDetailRequired();
    this.handleRenderUploadFileModalForm();
    this.validateUploadFileEmpty();
    this.handleStatusChange();
    this.handleBackgroundJob();
    this.handleDestroyBackgroundJob();
    this.handleWarehouseChangeOnDraft();
    this.handleAccountRepSelect();
    this.handleDateReceived();
  },

  handleDateReceived() {
    $('#date-received-wrapper .clear-datepicker').remove();

    $('#load_module_date_received_alternate').on('change', e => {
      if (e.target.value === '') {
        $('#load_module_date_received').val('');
      }
    });
  },

  handleAccountRepSelect() {
    if (this.getPersistedLoadStatus() !== 'draft') return;

    $('#load_module_account_rep').select2({
      tags: true,
      placeholder: 'Select an option or type your own',
      allowClear: true,
      createTag: params => {
        let term = $.trim(params.term);
        if (term === '') return null;

        return {
          id: term,
          text: term,
          newTag: true
        };
      }
    })
  },

  handleWarehouseChangeOnDraft() {
    if (this.getPersistedLoadStatus() !== 'draft') return;

    let $warehouseInput = $('#load_module_load_warehouse_id');
    let $warehouseInputDup = $('#load_module_load_warehouse_id_dup');
    $warehouseInput.on('select2:select', e => {
      let selectedValue = e.params.data.id;
      $warehouseInputDup.val(selectedValue).trigger('change');
    });
    $warehouseInput.on('select2:clear', e => {
      $warehouseInputDup.val(null).trigger('change');
    });
  },

  getPersistedLoadStatus() {
    return $('#load_module_load_status_id').data('persisted');
  },

  handleDisablingForm() {
    if (!isLoadModuleEditable()) {
      $('.load-module :input').prop('disabled', true);
    }
  },

  handleDestroyBackgroundJob() {
    $('.background-job-close').click(e => {
      e.preventDefault();
      this.hideBackgroundAlerts();

      let jobId = $('#load-module-background-job').data('id');
      if (!jobId) return;

      this.updateBackgroundJobId(null);

      $.ajax({
        type: 'DELETE',
        url: `/admin/background_jobs/${jobId}`,
        success: () => {
          $('.overlay-fractional').removeClass('d-flex');
          this.updateBackgroundJobId(null);
          $('#load-receiving-table').removeClass('overlap');
          $('.add-load-receiving-button #save').removeClass('disabled');
          window.loadReceivingDetail.enableUI();
        }
      });
    });
  },

  hideBackgroundAlerts() {
    $('.alert-warning, .alert-danger, .alert-success').removeClass('d-flex');
  },

  updateBackgroundJobId(jobId) {
    $('#load-module-background-job').data('id', jobId);
  },

  checkBackgroundJob(jobId, onJobDone) {
    $('.overlay-fractional').addClass('d-flex');

    let interval;
    let checker = () => {
      $.ajax({
        type: 'GET',
        url: `/admin/background_jobs/${jobId}`,
        success: data => {
          if (data.status === 'done') {
            this.hideBackgroundAlerts();
            $('.overlay-fractional').removeClass('d-flex');

            let message = 'Load Module was successfully updated!';
            if (data.payload) {
              let processed = [];

              if (data.payload.processed_serial_no) {
                processed.push(`
                  Processed Serial No: <br>
                  ${data.payload.processed_serial_no.join('<br>')}
                `);
              }
              if (data.payload.unprocessed_serial_no) {
                processed.push(`
                  Unprocessed Serial No: <br>
                  ${data.payload.unprocessed_serial_no.join('<br>')}
                `);
              }
              message = processed.join('<br><br>');
            }
            this.updateBackgroundJobId(null);

            if (onJobDone) onJobDone();
            if ($('#receiving-dialog').hasClass('show')) {
              window.loadReceivingDetail.enableUI();
              window.loadReceivingDetail.handleUpdateLoadReceivingDetail();
            }

            $('.background-job-message-success').text(message);
            toastSuccessMessage(message);
            clearInterval(interval);
          } else if (data.status === 'failed') {
            this.hideBackgroundAlerts();
            $('.alert-danger').addClass('d-flex');

            let message = 'Failed to Update Load Module. Please try again later.';
            if (data.error_message) {
              message = `Failed to Update Load Module: ${data.error_message}`;
            }
            $('.background-job-message-danger').text(message);
            toastErrorMessage(message);
            clearInterval(interval);
          } else {
            this.hideBackgroundAlerts();
            $('.alert-warning').addClass('d-flex');
          }
        },
        error: (_jqXHR, textStatus, _errorThrown) => {
          toastErrorMessage(`Something went wrong: ${textStatus}. Please try again later.`);
        }
      });
    };
    checker();
    interval = setInterval(checker, 3000);
  },

  handleBackgroundJob() {
    let jobId = $('#load-module-background-job').data('id');
    if (jobId) {
      this.checkBackgroundJob(
        jobId,
        () => {
          this.handleGetLoadModuleGeneralInfo();
        }
      );
    }
  },

  handleGetLoadModuleGeneralInfo() {
    return $.ajax({
      type: 'GET',
      url: `/admin/load_module_generals/${$('#load_module_id').val()}.js`
    });
  },

  handleMbfTab() {
    let mbfCompanyEl = $('#mbf-company');
    let mbfCompanyId = mbfCompanyEl.data('mbf-company-id');
    let companyName = mbfCompanyEl.data('mbf-company-name');
    window.loadModuleGeneral.mbf = new LoadMbf({
      id: mbfCompanyId,
      name: companyName
    });
    $('a[data-toggle="tab"]').on(
      'shown.bs.tab',
      mbfTabHandler
    );
  },

  handleDataCaptureTab() {
    $('a[href="#tab-4"]').on(
      'shown.bs.tab',
      _e => {
        window.dataCaptureTable.ajax.reload();
        window.totalAssetsTable.ajax.reload();
    });
  },

  handleStatusChange() {
    $('#load_module_load_status_id').change(() => {
      let status = $('#load_module_load_status_id option:selected').data('name');

      if (!status) return;

      status = status.toLowerCase();
      let dateEl = $(`#load_module_${status}_date_alternate`);

      if (dateEl.val() === '') {
        dateEl.val(moment().format('MM-DD-YYYY'));
        $(`#load_module_${status}_date`).val(
          moment().format('YYYY-MM-DD')
        );
        $(`#${status}_date_clear`).removeClass('hidden');
      }
    });
  },

  handlePickupDetailRequired() {
    $('.load_module_pickup_detail_required').on('change', function (e) {
      $('.pickup-detail .select2, .pickup-detail input').prop('disabled', $(e.currentTarget).find('select').val() == 'false')
      $(e.currentTarget).find('select').prop('disabled', false)
    })

    $('.load_module_pickup_detail_required').trigger('change')
  },

  handleTotalCalculation() {
    $('.onsite-service').on('change', '.rate, .quantity', function () {
      let quantity = $(this).closest('.row').find('.quantity').val()
      let rate = $(this).closest('.row').find('.rate').val()

      $(this).closest('.row').find('.total').val((quantity * rate).toFixed(2))
    })
  },

  handleContactPerson() {
    $('.load_module_contact_person').on('select2:select', function (e) {
      let data = e.params.data

      return $.ajax({
        type: "GET",
        url: "/admin/load_modules/get_phone",
        data: {
          contact_id: data.id
        }
      })
    })

    $('.load_module_contact_person').on('select2:clearing', function (e) {
      $('.contact_phone').val('')
    })
  },

  handleValidateIntegerChange() {
    $('.contact_phone, input[type="number"][step="1"]').on('keydown', function (e) {
      window.commonJS.handleIntegerChange(e)
    })
  },

  initiallizeOnsiteServiceCocoon() {
    $(".onsite-service .add-onsite-service-button a").data("association-insertion-method", 'append').data("association-insertion-node", '.add-onsite-service-field')
  },

  handleOpenTransferAndSalesOrderModal() {
    let params = new URLSearchParams(location.search)
    let tab = params.get('tab')
    let modal = params.get('modal')

    if (tab && modal) {
      $(tab).trigger('click')
      $(modal).trigger('click')
    }
  },

  handleRenderUploadFileModalForm() {
    $('.btn-open-upload-modal').on('click', function () {
      let file_type = $(this).attr('data-type')

      $.ajax({
        type: "GET",
        url: `/admin/load_modules/${$('#load_module_id').val()}/upload_files.js`,
        data: { file_type: file_type }
      })
    })
  },

  validateUploadFileEmpty() {
    $('#uploadModal').on('click', '.btn-upload-load-attachment', function(e) {
      if ($("#uploadModal input[type='file']").get(0).files.length == 0) {
        alert("Please select at least one file.")

        $(e.currentTarget).addClass('disabled')
      }
    })

    $("#uploadModal").on('change', "input[type='file']", function() {
      if ($("#uploadModal input[type='file']").get(0).files.length > 0) { $('.btn-upload-load-attachment').removeClass('disabled') }
    })
  }
};

export const isLoadModuleEditable = () => {
  return $('#load-module-editable').data('editable');
};

export const isDateReceivedEmpty = () => {
  return $('#load_module_date_received').val() === '';
};

export let isLoadReceivingsReadOnly = () => {
  const status = $('#load_module_load_status_id')
    .data('persisted')
    .toLowerCase();

  const isReadOnlyStatus = [
    'processed',
    'settled',
    'closed'
  ].includes(status);

  return !isLoadModuleEditable() ||
    isReadOnlyStatus ||
    isDateReceivedEmpty();
};
