import {toastErrorMessage} from '../../../../../../packs/toast';

export let initRestValidation = () => {
  return (changes) => {
    let toSplice = [];
    let errorMessages = new Set();
    let propTitles = {
      tax: 'Tax',
      total_rebate: 'Total Rebate',
      rate: 'Rate',
      currency: 'Currency',
      uom: 'UOM'
    };
    changes.forEach(([row, prop, oldValue, newValue], index) => {
      if (oldValue === newValue) return toSplice.unshift(index);

      switch (prop) {
        case 'uom':
        case 'currency':
          if (!newValue) {
            toSplice.unshift(index);
            errorMessages.add(`${propTitles[prop]} can not be blank`);
          }
          break;
      }
    });
    errorMessages.size > 0 && toastErrorMessage([...errorMessages].join('<br>'));
    toSplice.forEach(index => {
      changes.splice(index, 1);
    });
  };
};
