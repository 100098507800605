// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//= require cocoon

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require.context('../images', true)
require('datatables.net-bs4')
require("chartkick")
require("chart.js")
import $ from 'jquery'
global.$ = jQuery

require( 'datatables.net-buttons' )( window, $ )
require( 'datatables.net-buttons-bs4' )( $ )
require( 'datatables.net-buttons/js/buttons.colVis')(window, $)
require( 'datatables.net-buttons/js/buttons.html5')(window, $, jszip, pdfmake)
require( 'datatables.net-buttons/js/buttons.print')(window, $)

const jszip = require('jszip')
const pdfmake = require( 'pdfmake' )

const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

window.JSZip = jszip

require("jquery-ui")
require('./jquery.doubleScroll.js')

import '../stylesheets/application'
import 'popper.js'
import './bootstrap_custom.js'
import './common.js'
import "@fortawesome/fontawesome-free/js/all"
import "@fortawesome/fontawesome-free/css/all"
import 'cocoon'
import './bootstrap-datepicker.min.js'
import 'select2'
import 'select2/dist/css/select2.css'

import './theme/inspinia/inspinia.js'
import './theme/inspinia/jquery.metisMenu.js'
import './theme/inspinia/jquery.slimscroll.min.js'
import './theme/inspinia/pace.min.js'
import './xeditor.js'
import './items.js'
import 'bootstrap-timepicker'
import 'bootstrap-timepicker/css/bootstrap-timepicker.min.css'
import 'datatables.net-bs4/css/dataTables.bootstrap4.css'

import './vfs_fonts.js'
import './daterangepicker.min.js'
import print from 'print-js'

import 'jquery-validation'
import './jquery.steps.min'
import '../pages/index'

import moment from 'moment'
window.moment = moment

window._ = require('lodash')

import 'handsontable/dist/handsontable.full.css'
import Handsontable from 'handsontable'
window.handsontable = Handsontable

import { HyperFormula } from 'hyperformula';
window.hyperformula = HyperFormula

import { registerValidator, numericValidator } from 'handsontable/validators'
registerValidator(numericValidator)
