export let toastSuccessMessage = (message, delay = 3000, redirect = '') => {
  var notif = $('.toast');
  notif.css('z-index', 99999);
  notif.removeClass('hidden');
  $('#notif-message').html(message);
  $('#redirect span').html(redirect);
  setTimeout((function() {
    notif.addClass('hidden');
  }), delay);
}

export let toastErrorMessage = (message, delay = 3000, redirect = '') => {
  var notif = $('.toast');
  notif.css('z-index', 99999);
  notif.addClass('toast-error');
  notif.removeClass('hidden');
  $('#notif-message').html(message);
  $('#redirect span').html(redirect);
  setTimeout((function() {
    notif.addClass('hidden');
    notif.removeClass('toast-error');
  }), delay);
}
