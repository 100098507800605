window.workOrderReport = {
  init() {
    window.commonJS.handleInitializeSelect2()
    window.commonJS.handleCollapseLink()
    this.handleInitializeDataTable()
    this.handleCreateAtFilter()
    this.handleDatepickerFilterTodayAndNone()
  },

  handleInitializeDataTable() {
    $('#work-order-table').DataTable({
      searching: false,
      pageLength: 25,
      paging: true,
      info: false,
      order: [[0, "desc"]],
      dom: 'frtip',
      serverSide: true,
      ajax: window.location.href,
      columns: [
        {
          data: 'show',
          render: function(data, type) {
            if (data.id) {
              let link = `/admin/load_module_generals/${data.id}`

              return `
                <a href="${link}">${data.display_id}</a>
              `
            } else {
              return ''
            }
          }
        },
        { data: 'data_capture' },
        { data: 'destruction' },
        { data: 'redeployment' },
      ]
    })
  },

  handleCreateAtFilter() {
    if ($("#processed-date-diff").length > 0 && $("#processed-date-diff").attr("data-start-date") && $("#processed-date-diff").attr("data-end-date")) {
      var processedStartDate = moment($("#processed-date-diff").attr("data-start-date"))
      var processedEndDate = moment($("#processed-date-diff").attr("data-end-date"))
    } else {
      var processedStartDate = undefined
      var processedEndDate = undefined
    }

    function cbProcessedDate(start, end) {
      if (start != null && end != null && start._isValid && end._isValid) {
        $('#processed_date_range span').html(`${start.format('MMMM D, YYYY')} - ${end.format('MMMM D, YYYY')}`)
        $("#report_processed_start_date").val(start.format('MMMM D, YYYY'))
        $("#report_processed_end_date").val(end.format('MMMM D, YYYY'))
      } else {
        $('#processed_date_range span').html('')
        $("#report_processed_start_date").val('')
        $("#report_processed_end_date").val('')
      }
    }

    $('#processed_date_range').daterangepicker({
      startDate: processedStartDate,
      endDate: processedEndDate,
      ranges: {
        'None': [moment().subtract(1, 'days'), moment().add(1, 'days')],
        'Today': [moment(), moment()],
        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
      }
    }, cbProcessedDate)

    cbProcessedDate(processedStartDate, processedEndDate)
  },

  handleDatepickerFilterTodayAndNone() {
    $('#processed_date_range').on('apply.daterangepicker', function (ev, picker) {
      if (picker.chosenLabel === 'None') {
        $('#processed_date_range span').html('')
        $("#report_processed_start_date").val('')
        $("#report_processed_end_date").val('')
      }

      if (picker.chosenLabel === 'Today') {
        $('#processed_date_range span').html(`${moment().format('MMMM D, YYYY')} - ${moment().format('MMMM D, YYYY')}`)
        $("#report_processed_start_date").val(moment().format('MMMM D, YYYY'))
        $("#report_processed_end_date").val(moment().format('MMMM D, YYYY'))
      }
    })
  }
}
