export default class Section {
  constructor(tables, sectionName) {
    this.tables = tables;
    this.subtotals = {};
    this.sectionName = sectionName;
  }

  get subtotals() {
    return this._subtotals;
  }

  set subtotals(subtotals) {
    this._subtotals = subtotals;
  }

  setup() {
    this.tables.forEach(table => {
      table.listenerOnSubtotalChange(() => {
        this.updateSubtotals();
      });
      table.updateSubtotals();
    });
    this.updateSubtotals();
  }

  onStandaloneMode() {
    this.tables.forEach(table => {
      table.onStandaloneMode();
    });
  }

  listenerOnSubtotalChange(callback) {
    this.onSubtotalChanged = callback;
  }

  resetSubtotals() {
    Object.keys(this.subtotals).forEach(currency => {
      $(`.${this.sectionName}-container`)
        .find(`.${currency}`)
        .text(0);
    });
    this.subtotals = {};
  }

  updateSubtotals() {
    this.resetSubtotals();
    this.tables.forEach(table => {
      Object.keys(table.subtotals).forEach(currency => {
        this.subtotals[currency] = this.subtotals[currency] || 0;
        this.subtotals[currency] += table.subtotals[currency];
      });
    });
    Object.keys(this.subtotals).forEach(currency => {
      $(`.${this.sectionName}-container`)
        .find(`.${currency}`)
        .text(
          this.subtotals[currency].toFixed(2)
        );
    });
    if (this.onSubtotalChanged) {
      this.onSubtotalChanged();
    }
  }

  destroy() {
    this.tables.forEach(table => {
      table.destroy();
    });
    this.tables = [];
    this.resetSubtotals();
    this.onSubtotalChanged = null;
  }
}
