window.addItemToResourceForOrder = {
  init() {
    this.handleResetModalOnShow()
    this.handleOnFinishInput()
    this.handleApplyItems()
    this.handleAddItems()
    this.handleItemMakerChange()
    this.handleItemCategoryChange()
    this.handleSelectable()
  },

  handleResetModalOnShow() {
    $('#addItemModal').on('hidden.bs.modal', function () {
      $('.result-total').text("")
    })

    $('#addItemModal').on('hidden.bs.modal shown.bs.modal', function () {
      window.addItemToResourceForOrder.handleResetSearchFormModal()

      search_param = {
        order_id: order_id,
        resource_type: $('#resource_type').val(),
        resource_id: $('#resource_id').val()
      }
    })
  },

  handleOnFinishInput() {
    $(document).on('keyup', '.item-description, .item-cpu, .item-ram', function() {
      setTimeout(() => {
        search_param[e.target.name] = e.target.value
        onSearchItems()
      }, 700)
    })
  },

  handleApplyItems() {
    $('.apply-item').on('click', function (e) {
      window.addItemToResourceForOrder.handleGetAllDataSearch()
      window.addItemToResourceForOrder.handleOnSearchItems()
    })
  },

  handleAddItems() {
    $(".add-item").on("click", function (e) {
      $(this).prop('disabled', true)

      if ($('.searched-item:checked').length === 0) {
        return alert('Please select at least 1 item')
      }

      if (['Lot', 'Order'].indexOf($('#resource_type').val()) > -1) {
        window.addItemToResourceForOrder.handleInsertItemsInPage()
      }

      $(this).prop('disabled', false)
      $('.close-modal').trigger('click')
    })
  },

  handleItemMakerChange() {
    $('#item_maker_id').on('change', function () {
      return $.ajax({
        type: "GET",
        url: "/admin/items/update_maker_model",
        data: { maker_id: $(this).val() }
      })
    })
  },

  handleItemCategoryChange() {
    $('#item_category_id').on('change', function () {
      return $.ajax({
        type: "GET",
        url: "/admin/items/update_sub_category",
        data: { category_id: $(this).val() }
      })
    })
  },

  handleSelectable() {
    $(".searched-items").selectable({
      filter: 'tr.searched-item-container',
      stop: function () {
        $(".ui-selected input", this).each(function () { this.checked = true })
      }
    })
  },

  // Private Method

  handleInsertItemsInPage() {
    var itemIds = []
    var lineId = $('#addItemModal #line_id').val()

    $('tbody .searched-item:checked').each(function (index, item) {
      itemIds.push(item.dataset.id)
    })

    $('#assigned_item_ids').val(itemIds)

    $.ajax({
      type: 'GET',
      url: `/admin/orders/${$('#order_id').val()}/assign_items.js`,
      data: { item_ids: itemIds, line_id: lineId }
    })
  },

  handleGetAllDataSearch() {
    let field = [
      '#item_category_id', '#item_sub_category_id', '#item_maker_id', '#item_maker_model_id', '.item-description', '.item-cpu', '.item-ram', '#item_audit_id', '#item_po_id', '#item_grade_id', '#item_status_id', '#item_lot_id', '#item_location_id'
    ]

    field.forEach(function (element) {
      let name = $(`${element}`).attr('name')
      search_param[name] = $(`${element}`).val()
    })
  },

  handleOnSearchItems() {
    $('.apply-item').prop('disabled', true)
    $(".add-item").prop('disabled', true)
    $('.search-item-indicator').show()

    search_param['assigned_items_ids'] = []
    $('.order-item-append .form-control.item-id').each(function (index, item) {
      search_param['assigned_items_ids'].push(item.value)
    })

    $.ajax({
      url: '/admin/orders/find_assign_items',
      type: 'POST',
      dataType: 'script',
      data: search_param,
      proccessData: false,
      success: function (data) {
        $('.apply-item').prop('disabled', false)
        $(".add-item").prop('disabled', false)
      }
    })
  },

  handleResetSearchFormModal() {
    $('#addItemModal select.select2').val('')
    $('#addItemModal select.select2').select2({
      dropdownParent: $('#addItemModal'),
      allowClear: true
    })
    $('#addItemModal input.item-description').val('')
    $('#addItemModal input.item-cpu').val('')
    $('#addItemModal input.item-ram').val('')
    $('#addItemModal .searched-items').empty()
  }
}
