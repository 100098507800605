window.inboundCustomerIndex = {
  init() {
    window.commonJS.handleInitializeSelect2();
    window.commonJS.handleCollapseLink();
    this.handleDestroyDatatableBeforeCache();
    this.handleInitializeDatatable();
  },

  handleDestroyDatatableBeforeCache() {
    document.addEventListener('turbolinks:before-cache', () => {
      if (dataTable !== null) {
        dataTable.destroy();
        dataTable = null;
      }
    })
  },

  handleInitializeDatatable() {
    dataTable = $('#inbound_customer_dt').DataTable({
      searching: true,
      pageLength: 20,
      paging: true,
      info: true,
      dom: 'Bfrtip',
      order: [[0, 'asc']],
      buttons: [],
      serverSide: true,
      ajax: window.location.href,
      columns: [
        { data: 'company_name' },
        { data: 'city' },
        { data: 'province' },
        {
          data: 'action',
          orderable: false,
          className: 'text-center',
          render: (data, type) => {
            if (data) {
              let link = `/admin/inbound_customers/${data}`;
              let editLink = `/admin/inbound_customers/${data}/edit`;

              return `
              <a class="btn btn-xs" href=${editLink}>
                <i class="far fa-edit"></i>
              </a>
              <a class="btn btn-xs" href="${link}">
                <i class="far fa-eye"></i>
              </a>
              <a href="${link}" class="btn code-red btn-xs" data-method="delete" data-confirm="Are you sure?">
                <i class="fas fa-trash-alt"></i>
              </a>
            `;
            } else {
              return '';
            }
          }
        }
      ]
    })

    $('#dataTables_filter input').off().keyup(() => {
      $('#inbound_customer_dt').DataTable().column(0).search(this.value.trim(), false, false).draw();
    });
  }
}
