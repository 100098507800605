import Section from './section';
import QualifiedSubsection from './qualified/qualified_subsection';

export default class SectionDestruction extends Section {
  constructor() {
    super(
      [],
      'qualified-general'
    );
    this.subsections = [];
  }

  setup({load_work_orders}, loadModuleIds, nonEditable) {
    let {destruction, resale, redeployment, unqualified} = load_work_orders;
    let fullNames = load_work_orders.load_item_types
      .map(pair => pair[1]);
    let subsections = {}
    let onAfterChange = () => {
      let destructionWeight = subsections.destruction.getTotalWeight() +
        subsections.redeployment.getTotalWeight() +
        subsections.unqualified.getTotalWeight();
      let resaleWeight = subsections.resale.getTotalWeight();

      let percentage;
      if (destructionWeight == 0 && resaleWeight == 0) {
        percentage = 0;
      } else {
        percentage = (resaleWeight * 100.0 /
          (resaleWeight + destructionWeight)).toFixed(2);
      }
      $('#resale-weighting-percent-value').text(`${percentage}%`);
    };

    subsections.destruction = new QualifiedSubsection(this);
    subsections.destruction.setup({
      data: destruction,
      serviceType: 'destruction',
      qualifiedTypes: ['Yes', 'No'],
      mainContainerId: 'work-order-destruction-table',
      loadWorkOrders: load_work_orders,
      loadModuleIds,
      fullNames,
      nonEditable,
      onAfterChange
    });

    subsections.redeployment = new QualifiedSubsection(this);
    subsections.redeployment.setup({
      data: redeployment,
      serviceType: 'redeployment',
      qualifiedTypes: ['Redeployment'],
      mainContainerId: 'work-order-redeployment-table',
      loadWorkOrders: load_work_orders,
      loadModuleIds,
      fullNames,
      nonEditable,
      onAfterChange
    });

    subsections.resale = new QualifiedSubsection(this);
    subsections.resale.setup({
      data: resale,
      serviceType: 'resale',
      qualifiedTypes: ['Resale'],
      mainContainerId: 'work-order-resale-table',
      loadWorkOrders: load_work_orders,
      totalEditable: true,
      loadModuleIds,
      fullNames,
      nonEditable,
      onAfterChange
    });

    if (unqualified.length === 0) $('.unqualified-container').hide();

    subsections.unqualified = new QualifiedSubsection(this);
    subsections.unqualified.setup({
      data: unqualified,
      serviceType: 'unqualified',
      qualifiedTypes: [
        'Yes',
        'No',
        'Resale',
        'Redeployment'
      ],
      mainContainerId: 'work-order-unqualified-table',
      loadWorkOrders: load_work_orders,
      loadModuleIds,
      fullNames,
      nonEditable,
      onAfterChange
    });

    super.setup();
  }

  destroy() {
    this.subsections.forEach(table => {
      table.destroy();
    });
    this.subsections = [];
    super.destroy();
  }
};
