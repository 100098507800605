import {toastErrorMessage} from '../../../../../packs/toast';

export let initWeightValidation = () => {
  return changes => {
    let toSplice = [];
    let correctionChanges = [];
    let errorMessages = new Set();

    changes.forEach(([row, prop, oldValue, newValue], index) => {
      if (prop === 'weight' &&
        newValue !== null &&
        newValue !== '') {
        newValue = newValue.toString().replace(/,/g, '.');
        newValue = parseFloat(newValue);
        toSplice.unshift(index);

        if (newValue < 0) {
          errorMessages.add('Weight can not be negative');
          return;
        }
        if (!isNaN(newValue)) {
          newValue = parseFloat(
            newValue.toFixed(2)
          );
          correctionChanges.push(
            [row, prop, oldValue, newValue]
          );
        }

      }
    });
    errorMessages.size > 0 && toastErrorMessage([...errorMessages].join('<br>'));
    toSplice.forEach(index => {
      changes.splice(index, 1);
    });
    correctionChanges.forEach(change => {
      changes.push(change);
    });
  };
};
