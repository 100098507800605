window.gradeForm = {
  init() {
    this.handleJsInput()
  },

  handleJsInput() {
    $(".js-input-upcase").bind('keyup', function (e) {
      if (e.which >= 97 && e.which <= 122) {
        var newKey = e.which - 32
        e.keyCode = newKey
        e.charCode = newKey
      }
      $(this).val($(this).val().toUpperCase())
    })
  }
}
