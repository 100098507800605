import {initHandsontable} from '../../../../../packs/handsontable';
import {
  exactValueRenderer,
  monetaryRenderer,
  roundToThousandthsRenderer
} from '../../../../load_modules/work_order/hot_renderers';
import {hotCrud} from '../hot_crud';
import {initPrepopulate} from '../common/prepopulate';
import {initCalculateTotals} from './helpers/calculate_totals';
import HotBase from '../../../../load_modules/work_order/hot_base';
import {initWeightValidation} from '../common/validate_weight';
import {initRestValidation} from './helpers/validate_rest';

export default class Blackberry extends HotBase {
  setup({
          containerId,
          data,
          nonEditable,
          uoms,
          fixedUom,
          currencies,
          primaryCurrency,
          loadNumber
        }) {
    this.hot = initHandsontable({
      containerId: containerId,
      data: data,
      additionalOptions: {
        minSpareRows: 0,
        contextMenu: [],
        copyPaste: false,
        fillHandle: false,
        undo: true,
        hiddenColumns: {
          columns: [0]
        },
        dropdownMenu: false,
        filters: false,
        rowHeaders: true
      },
      colHeaders: [
        'ID',
        'Load Module',
        'Warehouse',
        'Category',
        'Description',
        'Weight in kgs',
        'UOM',
        'NQ Rate',
        'Total Rebate/(Charge)',
        'Tax',
        'Currency'
      ],
      columns: [
        {
          data: 'id',
          readOnly: true,
          width: 0,
          className: 'htCenter',
          renderer: 'html'
        },
        {
          data: 'load_module_id',
          readOnly: true,
          className: 'htCenter',
          renderer: exactValueRenderer(loadNumber)
        },
        {
          data: 'load_warehouse_name',
          readOnly: true,
          className: 'htCenter',
          renderer: 'html'
        },
        {
          data: 'category',
          readOnly: true,
          className: 'htCenter',
          renderer: 'html'
        },
        {
          data: 'description',
          readOnly: true,
          className: 'htCenter',
          renderer: 'html'
        },
        {
          data: 'weight',
          readOnly: nonEditable,
          type: 'numeric',
          className: 'htCenter',
          renderer: monetaryRenderer
        },
        {
          data: 'uom',
          readOnly: nonEditable,
          type: 'dropdown',
          strict: true,
          allowInvalid: false,
          source: uoms,
          className: 'htCenter'
        },
        {
          data: 'rate',
          readOnly: nonEditable,
          type: 'numeric',
          className: 'htCenter',
          renderer: roundToThousandthsRenderer
        },
        {
          data: 'total_rebate',
          type: 'numeric',
          readOnly: true,
          className: 'htCenter',
          renderer: monetaryRenderer
        },
        {
          data: 'tax_included',
          readOnly: nonEditable,
          type: 'checkbox',
          className: 'htCenter'
        },
        {
          data: 'currency',
          readOnly: nonEditable,
          type: 'dropdown',
          strict: true,
          allowInvalid: false,
          source: currencies,
          className: 'htCenter'
        }
      ]
    });

    let ajax = hotCrud(this.hot);
    let prepopulate = initPrepopulate(this.hot, fixedUom, primaryCurrency);
    let validateWeight = initWeightValidation();
    let validateRest = initRestValidation();
    let calculateTotals = initCalculateTotals(this.hot);

    this.hot.addHook('beforeChange', (changes, source) => {
      if (!changes ||
        source === 'loadData' ||
        source === 'Sync.SetId') return;

      prepopulate(changes);
      validateWeight(changes);
      validateRest(changes);
      calculateTotals(changes);
    });

    this.hot.addHook('afterChange', (changes, source) => {
      if (!changes ||
        source === 'loadData' ||
        source === 'Sync.SetId') return;

      ajax.create(changes);
      ajax.update(changes);
      changes.some(([, prop]) => {
        return prop === 'total_rebate' ||
          prop === 'currency';
      }) && this.updateSubtotals();

      super.onAfterChange(changes);
    });

    super.setup(currencies);
  }

  updateSubtotals() {
    this.currencies.forEach(currency => {
      this._subtotals[currency] = 0;
    });
    for (let i = 0; i < this.hot.countSourceRows(); i++) {
      let currency = this.hot.getDataAtRowProp(i, 'currency');
      let total = this.hot.getDataAtRowProp(i, 'total_rebate');
      this._subtotals[currency] += Number(total);
    }
    this.onSubtotalChanged();
  }
};
