import {toastErrorMessage} from '../../../packs/toast';

export default class HotCrud {
  constructor() {
    this.url = '/admin/load_work_orders';
  }

  create(hot, changes, loadModuleId, serviceType) {
    // Reduce to unique rows
    let reduced = changes.reduce((acc, [row, prop, , newValue]) => {
      if (!hot.getDataAtRowProp(row, 'id')) {
        acc[row] = acc[row] || {
          load_module_id: loadModuleId,
          service_type: serviceType
        };
        acc[row][prop] = newValue;
      }
      return acc;
    }, {});
    Object.keys(reduced).forEach(row => {
      let payload = reduced[row];
      fetch(this.url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('Something went wrong');
          }
          return response.json();
        })
        .then(data => {
          hot.setDataAtRowProp([
            [row, 'id', data.id]
          ], 'Sync.SetId');
        })
        .catch(error => {
          toastErrorMessage(error);
        });
    });
  }

  update(hot, changes) {
    let toProcess = changes.map(([row, prop, , newValue]) => {
      return [hot.getDataAtRowProp(row, 'id'), row, prop, newValue];
    });
    // Reduce to unique rows
    let reduced = toProcess.filter(([id]) => id).reduce((acc, [id, row, prop, newValue]) => {
      acc[row] = acc[row] || {
        id: id
      };
      acc[row][prop] = newValue;
      return acc;
    }, {});
    Object.keys(reduced).forEach(row => {
      let payload = reduced[row];
      fetch(`${this.url}/${payload.id}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('Something went wrong');
          }
        })
        .catch(error => {
          toastErrorMessage(error);
        });
    });
  }

  delete(hot, physicalRows) {
    physicalRows.map(row => {
      return hot.getDataAtRowProp(row, 'id');
    }).filter(id => id)
      .forEach(id => {
        fetch(`${this.url}/${id}`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json'
          }
        }).then(response => {
          if (!response.ok) {
            throw new Error('Something went wrong');
          }
        }).catch(error => {
          toastErrorMessage(error);
        });
      });
  }
}
