export let newChangesOfTotal = (hot, changes) => {
  return changes.filter(([, prop, oldValue, newValue]) => {
    return (prop === 'qty' || prop === 'rate') && oldValue !== newValue;
  }).map(([row, prop, , newValue]) => {
    let oldTotal = hot.getDataAtRowProp(row, 'total');
    let rate = hot.getDataAtRowProp(row, 'rate');
    let qty = hot.getDataAtRowProp(row, 'qty');
    switch (prop) {
      case 'qty':
        qty = newValue;
        break;
      case 'rate':
        rate = newValue;
        break;
    }
    let newTotal = qty * rate;
    newTotal = newTotal.toFixed(2) || 0;
    return [row, 'total', oldTotal, newTotal];
  });
};

let parseNumeric = (value) => {
  return parseFloat(
    (value || 0).toString()
      .replace(',', '.')
  );
};

let calculateTotal = (multiplier, rate) => {
  return (parseNumeric(multiplier) * parseNumeric(rate)).toFixed(2);
};

export let calculateTotalsByRateType = (hot, changes) => {
  let toProcess = changes.filter(([, prop, oldValue, newValue]) => {
    return [
        'qty',
        'rate',
        'weight',
        'rate_type'
      ].includes(prop) &&
      oldValue !== newValue;
  });

  let newChanges = [];
  toProcess.forEach(([row, prop, , newValue]) => {
    let oldTotal = hot.getDataAtRowProp(row, 'total');
    let qty = hot.getDataAtRowProp(row, 'qty');
    let weight = hot.getDataAtRowProp(row, 'weight');
    let rate = hot.getDataAtRowProp(row, 'rate');
    let rateType = hot.getDataAtRowProp(row, 'rate_type');

    switch (prop) {
      case 'qty':
        if (rateType === 'quantity') {
          newChanges.push(
            buildChangeOnTotal(row, oldTotal, calculateTotal(newValue, rate))
          );
        }
        break;
      case 'weight':
        if (rateType === 'weight') {
          newChanges.push(
            buildChangeOnTotal(row, oldTotal, calculateTotal(newValue, rate))
          );
        }
        break;
      case 'rate':
        rate = newValue;
        if (rateType === 'quantity') {
          newChanges.push(
            buildChangeOnTotal(row, oldTotal, calculateTotal(qty, rate))
          );
        } else if (rateType === 'weight') {
          newChanges.push(
            buildChangeOnTotal(row, oldTotal, calculateTotal(weight, rate))
          );
        }
        break;
      case 'rate_type':
        if (newValue === 'quantity') {
          newChanges.push(
            buildChangeOnTotal(row, oldTotal, calculateTotal(qty, rate))
          );
        } else if (newValue === 'weight') {
          newChanges.push(
            buildChangeOnTotal(row, oldTotal, calculateTotal(weight, rate))
          );
        }
        break;
    }
  });
  return newChanges;
};

let buildChangeOnTotal = (row, oldTotal, newTotal) => {
  return [row, 'total', oldTotal, newTotal || 0, true];
};

export let resetRateByTotal = (hot, changes) => {
  let newChanges = [];
  changes.forEach(([row, prop, , , computed]) => {
    if (prop === 'total' && !computed) {
      newChanges.push([
        row,
        'rate',
        hot.getDataAtRowProp(row, 'rate'),
        null
      ]);
    }
  });
  return newChanges;
};
