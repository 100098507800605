window.shippedDateFilter = {
  init() {
    this.handleInitializeReportRange()
    this.handleDatepickerFilterTodayAndNone()
  },

  handleDatepickerFilterTodayAndNone() {
    $('#shipped-date').on('apply.daterangepicker', function (ev, picker) {
      if (picker.chosenLabel === 'None') {
        $('#shipped-date span').html('')
        $("#order_shipped_date_start").val('')
        $("#order_shipped_date_end").val('')
      }

      if (picker.chosenLabel === 'Today') {
        $('#shipped-date span').html(`${moment().format('MMMM D, YYYY')} - ${moment().format('MMMM D, YYYY')}`)
        $("#order_shipped_date_start").val(moment().format('MMMM D, YYYY'))
        $("#order_shipped_date_end").val(moment().format('MMMM D, YYYY'))
      }
    })
  },

  handleInitializeReportRange() {
    if ($("#shipped-date-diff").length > 0 && $("#shipped-date-diff").attr("data-start-date") && $("#shipped-date-diff").attr("data-end-date")) {
      var start = moment($("#shipped-date-diff").attr("data-start-date"));
      var end = moment($("#shipped-date-diff").attr("data-end-date"));
    } else {
      var start = undefined;
      var end = undefined;
    }

    function shippedDateCallback(start, end) {
      $('#shipped-date span').html(start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY'));
      $("#order_shipped_date_start").val(start.format('MMMM D, YYYY'));
      $("#order_shipped_date_end").val(end.format('MMMM D, YYYY'));
    }

    $('#shipped-date').daterangepicker({
        startDate: start,
        endDate: end,
        ranges: {
          'None': [moment().subtract(1, 'days'), moment().add(1, 'days')],
          'Today': [moment(), moment()],
          'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
          'Last 7 Days': [moment().subtract(6, 'days'), moment()],
          'Last 30 Days': [moment().subtract(29, 'days'), moment()],
          'This Month': [moment().startOf('month'), moment().endOf('month')],
          'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
        }
    }, shippedDateCallback);

    if(start && end)
      shippedDateCallback(start, end);
  }
}
