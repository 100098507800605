$(document).on('click', '#item_dt > tbody > tr > td > .edit-text', function(e) {
  e.preventDefault();

  // Remove All input open
  $(".edit-input").addClass('hidden');
  $(".edit-text").removeClass('hidden');

  var id = $(this).attr('id');
  var col = $(this).attr('data-col');
  var row_id = $(this).attr('data-id');

  $(".edit-text#"+id).addClass('hidden');
  $(this).next(".edit-input#"+id).removeClass("hidden");

  $(this).next(".edit-input#" + id).find("input, select, textarea").addClass('active');

  var categoryId = $("#" + row_id + "-category > .item_category_id").val() || $("#" + row_id + "-category > .item_category_id").attr('data-id');
  var makerId = $("#" + row_id + "-maker > .item_maker_id").val() || $("#" + row_id + "-maker > .item_maker_id").attr('data-id');

  $.ajax({
    type: "GET",
    url: "/admin/items/get_subcategories",
    dataType: "json",
    data: {
      category_id: categoryId
    },
    success: function(items) {
      $.each(items, function (i, item) {
        $("."+row_id+"-subcategory-select").append($('<option>', {
          value: item.id,
          text : item.name
        }));
      });
    }
  });

  $.ajax({
    type: "GET",
    url: "/admin/items/get_makermodels",
    dataType: "json",
    data: {
      maker_id: makerId
    },
    success: function(items) {
      $.each(items, function (i, item) {
        $("."+row_id+"-makermodel-select").append($('<option>', {
          value: item.id,
          text : item.name
        }));
      });
    }
  });
});

$(document).on('click', '.list-of-items .cancel-input', function(e) {
  var id = $(this).attr('id');
  var col = $(this).attr('data-col');
  console.log(col);
  $(".edit-text#"+id+"-"+col).removeClass('hidden');
  $(".edit-input").addClass('hidden');
  $(".edit-input").find('input').removeClass('active');
  $(".edit-input").find('select').removeClass('active');
});

$(document).on('click', '.list-of-items .save-item', function() {
  var id = $(this).attr('id');
  var col = $(this).attr('data-col');

  var id = $(".active#item_id").val();
  var key = $(".active#item_key").val();
  var value = $(".active#item_value").val();

  $.ajax({
    type: "POST",
    url: "/admin/items/edit_item",
    data: {
      id: id,
      key: key,
      value: value
    },
    success: function() {
    }
  });
});
