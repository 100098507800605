import Section from './section';
import Service from './service';

export default class SectionService extends Section {
  constructor(mbfCompany) {
    super(
      [],
      'service'
    );
    this.mbfCompany = mbfCompany;
  }

  setup({load_work_orders}, loadModuleIds, nonEditable) {
    let primaryCurrency = load_work_orders.primary_currency;
    if (this.mbfCompany) {
      primaryCurrency = load_work_orders.mbf_company_currencies
        .find(currency => currency[0] === this.mbfCompany.name)[1]
    }
    this.tableIds = loadModuleIds.map((loadModuleId, index) => {
      let tableId = `work-order-service-table-${loadModuleId}`;
      let container = $('<div></div>').attr('id', tableId);
      if (index < loadModuleIds.length - 1) {
        container.addClass('mb-3');
      }
      $(`#work-order-service-table`).append(container);

      let serviceData = load_work_orders.service.filter(item => {
        return item.load_module_id === loadModuleId;
      });
      let table = new Service();
      table.setup({
        data: serviceData,
        containerId: tableId,
        currencies: load_work_orders.currencies,
        primaryCurrency: primaryCurrency,
        loadModuleInfo: load_work_orders.load_module_info,
        nonEditable: nonEditable,
        loadModuleId: loadModuleId
      });
      this.tables.push(table);
      return tableId;
    });
    super.setup();
  }

  destroy() {
    this.tableIds.forEach(tableId => {
      $(`#${tableId}`).remove();
    });
    this.tableIds = [];
    super.destroy();
  }
}
