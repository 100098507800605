window.customReport = {
  init() {
    this.handleEnableButton()
    window.commonJS.handleCollapseLink()
  },

  handleEnableButton() {
    function enableButton() {
      $("#generate-excel-report").removeAttr('data-disable-with')
      $("#generate-excel-report").prop('disabled', false)
    }

    $("#generate-excel-report").on('click', function (e) { setTimeout(enableButton, 1000) })
  },

  handleInitializeReportRange() {
    var today = moment().format('LL')
    $("#date-result").html(today)

    if ($("#date-diff").length > 0 && $("#date-diff").attr("data-start-date") && $("#date-diff").attr("data-end-date")) {
      var start = moment($("#date-diff").attr("data-start-date"))
      var end = moment($("#date-diff").attr("data-end-date"))
    } else {
      var start = undefined
      var end = undefined
    }

    function cb(start, end) {
      $('#reportrange span').html(start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY'))
      $("#report_start_date").val(start.format('MMMM D, YYYY'))
      $("#report_end_date").val(end.format('MMMM D, YYYY'))
    }

    $('#reportrange').daterangepicker({
      startDate: start,
      endDate: end,
      ranges: {
        'Today': [moment(), moment()],
        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
      }
    }, cb)

    $('#reportrange').on('apply.daterangepicker', function (ev, picker) {
      if (picker.chosenLabel === 'None') {
        $('#reportrange span').html('')
        $("#report_start_date").val('')
        $("#report_end_date").val('')
      }
    })

    cb(start, end)
  }
}
