import {toastErrorMessage} from '../../packs/toast';

$(document).on('turbolinks:load', () => {
  if ($('#create-new-location').length === 0) return;

  [
    $('#address_detail_country_code'),
    $('#address_detail_province_code'),
    $('#address_detail_city_code')
  ].forEach($el => {
    window.commonJS.handleInitializeSelect2(
      $el,
      {
        allowClear: false,
        width: '100%'
      }
    );
  });

  handleCountryChanged();
  handleProvinceChanged();
});

const populateProvinces = ({current_province, provinces}) => {
  Object.keys(provinces).forEach(provinceCode => {
    const option =
      new Option(provinces[provinceCode],
        provinceCode,
        false,
        false);
    $('#address_detail_province_code').append(option);
  });
  $('#address_detail_province_code')
    .val(current_province)
    .trigger('change.select2');
};

const populateCities = ({cities}) => {
  Object.keys(cities).forEach(cityCode => {
    const option =
      new Option(cities[cityCode],
        cities[cityCode],
        false,
        false);
    $('#address_detail_city_code').append(option);
  });
  $('#address_detail_city_code')
    .val(cities[0])
    .trigger('change.select2');
};

const handleCountryChanged = () => {
  $('#address_detail_country_code').on('select2:select', e => {
    $('#address_detail_province_code').empty();
    $('#address_detail_city_code').empty();

    const country = e.params.data.id;
    fetchCities(country, null)
      .done(data => {
        populateProvinces(data);
        populateCities(data);
      })
      .fail(error => {
        console.error('Error handling country change:', error);
        toastErrorMessage('Something went wrong. Please try again later.');
      });
  });
};

const handleProvinceChanged = () => {
  $('#address_detail_province_code').on('select2:select', (e, _options) => {
    $('#address_detail_city_code').empty();

    const province = e.params.data.id;
    const country = $('#address_detail_country_code').val();

    fetchCities(country, province)
      .done(data => {
        populateCities(data);
      })
      .fail(error => {
        console.error('Error handling province change:', error);
        toastErrorMessage('Something went wrong. Please try again later.');
      });
  });
};

const fetchCities = (country, province) => {
  return $.ajax({
    url: '/cities/cities_by_location',
    type: 'GET',
    data: {
      country: country,
      province: province
    },
    dataType: 'json'
  });
};
