window.purchaseOrdersIndex = {
  init() {
    this.handleInitializeDatable()
    window.commonJS.handleCollapseLink()
    this.handleFilterPurchaseOrderDataTable()
  },

  handleFilterPurchaseOrderDataTable() {
    $('.purchase-order-filter-btn').on('click', function() {
      $('#purchase_order_dt').DataTable().ajax.reload()
    })
  },

  handleInitializeDatable() {
    $('#purchase_order_dt').DataTable({
      searching: false,
      paging: true,
      pageLength: 50,
      info: true,
      order: [[1, "desc"]],
      dom: 'Bfrtip',
      buttons: [
        {
          extend: 'copy',
          action: datatableCustomExportFunction
        },
        {
          extend: 'csv',
          text: 'CSV',
          titleAttr: 'CSV',
          action: datatableCustomExportFunction
        },
        {
          extend: 'excel',
          text: 'Excel',
          titleAttr: 'Excel',
          action: datatableCustomExportFunction
        },
        {
          extend: 'pdf',
          action: datatableCustomExportFunction
        },
        {
          extend: 'print',
          action: datatableCustomExportFunction
        }
      ],
      serverSide: true,
      ajax: {
        url: window.location.href,
        data: function (params) {
          params.purchase_order = window.commonJS.handleGetFormData($('#purchase-order-filter-form'), [])
        }
      },
      columns: [
        {
          data: 'show',
          render: function (data, type) {
            if (data.id) {
              let link = `/admin/purchase_orders/${data.id}`

              return `
                <a href="${link}">
                  ${data.display_id}
                </a>
              `
            } else {
              return ''
            }
          }
        },
        { data: 'status' },
        { data: 'purchased_date' },
        { data: 'no_of_items' },
        { data: 'vendor' },
        { data: 'warehouse' },
        { data: 'payment_method' },
        { data: 'tax' },
        { data: 'freight' },
        { data: 'total' },
        { data: 'sub_total' },
        { data: 'created_by' },
        {
          data: 'destroy',
          className: 'text-center',
          orderable: false,
          render: function (data, type) {
            if (data.can_destroy) {
              let link = `/admin/purchase_orders/${data.id}`

              return `
                <a href="${link}" class="btn code-red btn-xs" data-method='delete' data-confirm="Are you sure?">
                  <i class='fas fa-trash-alt'></i>
                </a>
              `
            } else {
              return ''
            }
          }
        }
      ]
    })
  }
}
