import Handsontable from 'handsontable';
import {onsiteServiceLinkHandler} from './modal_handlers/onsite_service_link_handler';
import {qualifiedLinkHandler} from './modal_handlers/qualified_link_handler';

export let loadNumberRenderer = loadNumbers => {
  return (hotInstance, td, row, column, prop, value, cellProperties) => {
    Handsontable.renderers.BaseRenderer.apply(
      null,
      [
        hotInstance,
        td,
        row,
        column,
        prop,
        value,
        cellProperties
      ]
    );
    if (value) {
      td.innerHTML = loadNumbers
        .find(load => load[0] === value)[1]
    } else {
      td.innerHTML = null;
    }
    return td;
  };
};

export let exactValueRenderer = loadNumber => {
  return (hotInstance, td, row, column, prop, value, cellProperties) => {
    Handsontable.renderers.BaseRenderer.apply(
      null,
      [
        hotInstance,
        td,
        row,
        column,
        prop,
        value,
        cellProperties
      ]
    );
    td.innerHTML = loadNumber;
    return td;
  };
};

export let monetaryRenderer = (hotInstance, td, row, column, prop, value, cellProperties) => {
  Handsontable.renderers.BaseRenderer.apply(
    null,
    [
      hotInstance,
      td,
      row,
      column,
      prop,
      value,
      cellProperties
    ]
  );
  let newValue = parseFloat(value);
  if (isNaN(newValue)) {
    td.innerHTML = value;
    return td;
  }
  td.innerHTML = newValue.toFixed(2);
  return td;
};

export let roundToThousandthsRenderer = (hotInstance, td, row, column, prop, value, cellProperties) => {
  Handsontable.renderers.BaseRenderer.apply(
    null,
    [
      hotInstance,
      td,
      row,
      column,
      prop,
      value,
      cellProperties
    ]
  );
  let newValue = parseFloat(value);
  if (isNaN(newValue)) {
    td.innerHTML = value;
    return td;
  }
  td.innerHTML = newValue.toFixed(3);
  return td;
};

export let qtyRenderer = (hotInstance, td, row, column, prop, value, cellProperties) => {
  Handsontable.renderers.BaseRenderer.apply(
    null,
    [
      hotInstance,
      td,
      row,
      column,
      prop,
      value,
      cellProperties
    ]
  );
  let newValue = parseInt(value, 10);
  if (isNaN(newValue)) {
    td.innerHTML = value;
    return td;
  }
  td.innerHTML = newValue;
  return td;
};

export let qualifiedModalLinkRenderer = (hotInstance, td, row, column, prop, value, cellProperties) => {
  Handsontable.renderers.BaseRenderer.apply(
    null,
    [
      hotInstance,
      td,
      row,
      column,
      prop,
      value,
      cellProperties
    ]
  );
  td.innerHTML = `<a href="#" class="hot-link">${value}</a>`;
  td.addEventListener('click', e => {
    e.preventDefault();
    qualifiedLinkHandler(
      hotInstance.getDataAtRowProp(row, 'id')
    );
  });
  return td;
};

export let onsiteServiceLinkRenderer = (hotInstance, td, row, column, prop, value, cellProperties) => {
  Handsontable.renderers.BaseRenderer.apply(
    null,
    [
      hotInstance,
      td,
      row,
      column,
      prop,
      value,
      cellProperties
    ]
  );
  td.innerHTML = `<a href="#" class="hot-link">${value}</a>`;
  td.addEventListener('click', e => {
    e.preventDefault();
    onsiteServiceLinkHandler(
      hotInstance.getDataAtRowProp(row, 'id')
    );
  });
  return td;
};
