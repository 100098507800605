window.taxesForm = {
  init() {
    window.commonJS.handleInitializeSelect2()
    this.handleInitializeSelect2TaxProvinceCode()
    this.handleTaxCountryCodeChange()
  },

  handleInitializeSelect2TaxProvinceCode() {
    $('#tax_province_code').select2({
      placeholder: 'Select Country First',
      allowClear: true
    })
  },

  handleTaxCountryCodeChange() {
    $('#tax_country_code').on('change', function () {
      var country = $(this).val()

      return $.ajax({
        type: "GET",
        url: "/admin/taxes/get_states",
        data: { country: country }
      })
    })
  }
}
