import {initHandsontable} from '../../packs/handsontable';
import {toastErrorMessage} from '../../packs/toast';

const ID_COLUMN_INDEX = 7;
const COMPANY_COLUMN_INDEX = 5;

export const initializeCostRecoveryHot = (itemTypes, companyName) => {
  const $dataEl = $('#cost-recovery-data');
  const existingData = $dataEl.data('existing-data') || [];

  let deletedRows = [];

  const hot = initHandsontable({
    containerId: 'cost-recovery-table',
    data: existingData,
    colHeaders: [
      'Category',
      'Hardware',
      'Quantity',
      'Price',
      'FMV / Status',
      `${companyName} %`,
      'eCycle %',
      'ID'
    ],
    columns: [
      {
        data: 'category',
        type: 'dropdown',
        source: [
          'Redeployment',
          'Resale',
          'Recycling'
        ],
        validator: (query, callback) => {
          callback(true);
        }
      },
      {
        data: 'hardware',
        type: 'dropdown',
        source: itemTypes,
        validator: (query, callback) => {
          callback(true);
        }
      },
      {
        data: 'quantity',
        type: 'text'
      },
      {
        data: 'price',
        type: 'numeric',
        allowInvalid: false,
        editor: 'numeric'
      },
      {
        data: 'fmv_status',
        type: 'text'
      },
      {
        data: 'company_percentage',
        type: 'text'
      },
      {
        data: 'ecycle_percentage',
        type: 'text'
      },
      {
        data: 'id',
        type: 'text',
        readOnly: true
      }
    ],
    additionalOptions: {
      startRows: 5,
      minSpareRows: 1,
      rowHeaders: false,
      contextMenu: [
        'row_above',
        'row_below',
        'remove_row',
        'clear_column',
        '---------',
        'undo',
        'redo',
        '---------',
        'copy',
        'cut'
      ],
      dropdownMenu: false,
      filters: false,
      hiddenColumns: {
        columns: [ID_COLUMN_INDEX],
        indicators: false
      },
    }
  });

  hot.addHook('beforeChange', (changes, source) => {
    if (source === 'loadData') return;

    const toSplice = [];
    const correctionChanges = [];
    changes.forEach(([row, prop, oldValue, newValue], index) => {
      if (prop === 'price') {
        newValue = newValue === null ? '' : newValue;
        newValue = parseFloat(newValue.toString()
          .replace(/,/g, '.'))
          .toFixed(3);
        toSplice.unshift(index);

        if (isNaN(newValue)) return;
        if (newValue < 0) {
          return toastErrorMessage('Price cannot be negative');
        }
        correctionChanges.push([row, prop, oldValue, newValue]);
      }
    });
    toSplice.forEach(index => changes.splice(index, 1));
    correctionChanges.forEach(change => changes.push(change));
  });

  hot.addHook('afterChange', (changes, source) => {
    if (source === 'loadData') return;

    serializeHotData(hot, deletedRows);
  });

  hot.addHook('beforeRemoveRow', (index, amount) => {
    for (let i = 0; i < amount; i++) {
      const deletedRow = hot.getSourceDataAtRow(index + i);
      if (deletedRow && deletedRow.id) {
        deletedRows.push(deletedRow.id);
      }
    }
  });

  hot.addHook('afterRemoveRow', () => {
    serializeHotData(hot, deletedRows);
  });

  const $inboundEl = $('#inbound_quote_inbound_customer_id');
  $inboundEl.on('select2:select', e => {
    const companyName = e.params.data.text;
    const currentHeaders = hot.getColHeader();
    currentHeaders[COMPANY_COLUMN_INDEX] = `${companyName} %`;

    hot.updateSettings({
      colHeaders: currentHeaders
    });
  });

  serializeHotData(hot, deletedRows);

  return hot;
};

const serializeHotData = (hot, deletedRows) => {
  const $hiddenFieldsContainer = $('#cost-recovery-hidden-fields');
  $hiddenFieldsContainer.empty();

  hot.getData().forEach(function (row, index) {
    if (row.some(cell => cell !== null && cell !== '')) {
      let fields = '';

      if (row[ID_COLUMN_INDEX]) {
        fields += `<input type="hidden" 
        name="inbound_quote[cost_recoveries_attributes][${index}][id]"
        value="${row[ID_COLUMN_INDEX]}">`;
      }
      [
        'category',
        'hardware',
        'quantity',
        'price',
        'fmv_status',
        'company_percentage',
        'ecycle_percentage',
      ].forEach((field, idx) => {
        fields += `<input type="hidden" 
          name="inbound_quote[cost_recoveries_attributes][${index}][${field}]" 
          value="${row[idx] || ''}">`;
      });
      $hiddenFieldsContainer.append(fields);
    }
  });

  deletedRows.forEach((id, idx) => {
    const fields = `
      <input type="hidden" name="inbound_quote[cost_recoveries_attributes][${idx}][id]" value="${id}">
      <input type="hidden" name="inbound_quote[cost_recoveries_attributes][${idx}][_destroy]" value="1">
    `;
    $hiddenFieldsContainer.append(fields);
  });
};
