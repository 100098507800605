window.itemIndex = {
  init() {
    window.commonJS.handleCollapseLink()
    this.handleDisabledNextAndPrevButton()
    this.handlePrevItemClick()
    this.handleNextItemClick()
    this.handleSearchIndex()
    this.handleListViewClick()
  },

  handleDisabledNextAndPrevButton() {
    var currentItem = $('#search_index').val()
    var allItems = parseInt($('#total-items').val())

    if(currentItem + '' == 1 + '') {
      $('.prevItem').addClass( "disabled" )
      $('.prevItemItem').addClass( "disabled" )
    } else {
      $('.prevItem').removeClass( "disabled" )
      $('.prevItemItem').removeClass( "disabled" )
    }

    if(currentItem+'' == allItems+'') {
      $('.nextItem').addClass( "disabled" )
      $('.nextItemItem').addClass( "disabled" )
    } else {
      $('.nextItem').removeClass( "disabled" )
      $('.nextItemItem').removeClass( "disabled" )
    }
  },

  handlePrevItemClick() {
    $('.prevItem').on('click', function (e) {
      var item_id = this.dataset.id

      $.ajax({
        type: "GET",
        url: "/admin/items/get_prev_next_item",
        dataType: "script",
        data: {
          target: 'prev',
          item_id: item_id,
          item_index: $('#search_index').val(),
          total_items: $('#total-items').val()
        }
      })
    })

    $('.prevItemItem').on('click', function (e) {
      var item_id = this.dataset.id
      var all_params = $("#all_params").val()

      $.ajax({
        type: "GET",
        url: "/admin/items/get_prev_next_item",
        dataType: "script",
        data: {
          target: 'prev',
          item_id: item_id,
          all_params: all_params,
          item_index: $('#search_index').val(),
          total_items: $('#total-items').val()
        }
      })
    })
  },

  handleNextItemClick() {
    $('.nextItem').on('click', function (e) {
      var item_id = this.dataset.id

      $.ajax({
        type: "GET",
        url: "/admin/items/get_prev_next_item",
        dataType: "script",
        data: {
          target: 'next',
          item_id: item_id,
          item_index: $('#search_index').val(),
          total_items: $('#total-items').val()
        }
      })
    })

    $('.nextItemItem').on('click', function (e) {
      var item_id = this.dataset.id
      var all_params = $("#all_params").val()

      $.ajax({
        type: "GET",
        url: "/admin/items/get_prev_next_item",
        dataType: "script",
        data: {
          target: 'next',
          item_id: item_id,
          all_params: all_params,
          item_index: $('#search_index').val(),
          total_items: $('#total-items').val()
        }
      })
    })
  },

  handleSearchIndex() {
    $("#search-index").on('click', function (e) {
      var data = $("#search_index").val()
      var all_p = $("#all_params").val()

      var url = location.search
      var urlprev = window.itemIndex.handleRemoveParam("prev", url)
      var urlnext = window.itemIndex.handleRemoveParam("next", urlprev)
      var final = window.itemIndex.handleRemoveParam("search", urlnext)

      if(all_p != "") {
        window.location.href = "/admin/items"+final+"&search="+data
      }else {
        window.location.href = "/admin/items?"+final+"&search="+data
      }
    })
  },

  handleListViewClick() {
    $('#listView').on('click', function (e) {
      var url = location.search
      var urlprev = window.itemIndex.handleRemoveParam("prev", url)
      var urlnext = window.itemIndex.handleRemoveParam("next", urlprev)
      window.location.href = "/admin/items/lists"+urlnext
    })
  },

  handleRemoveParam(key, sourceURL) {
    var rtn = sourceURL.split("?")[0],
        param,
        params_arr = [],
        queryString = (sourceURL.indexOf("?") !== -1) ? sourceURL.split("?")[1] : ""

    if (queryString !== "") {
      params_arr = queryString.split("&")
      for (var i = params_arr.length - 1; i >= 0; i -= 1) {
          param = params_arr[i].split("=")[0]
          if (param === key) {
              params_arr.splice(i, 1)
          }
      }
      rtn = rtn + "?" + params_arr.join("&")
    }
    return rtn
  }
}
