export default class CurrencyTotal {
  constructor(sections) {
    this.sections = sections;
  }

  setup() {
    this.sections.forEach(section => {
      section.listenerOnSubtotalChange(() => {
        this.updateSubtotals();
      });
    });
    this.updateSubtotals();
  }

  updateSubtotals() {
    let subtotals = {};
    this.sections.forEach(section => {
      Object.keys(section.subtotals).forEach(currency => {
        subtotals[currency] = subtotals[currency] || 0;
        subtotals[currency] += section.subtotals[currency];
      });
    });
    Object.keys(subtotals).forEach(currency => {
      $('.currency-total-container')
        .find(`.${currency}`)
        .text(subtotals[currency].toFixed(2));
    });
  }
}
