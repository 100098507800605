window.loadSettingIndex = {
  init() {
    this.initializeItemTypeDatatable()
    this.searchItemType()
  },

  initializeItemTypeDatatable() {
    $('#item-type-table').DataTable({
      order: [[0, 'asc']],
      searching: false,
      paging: false,
      info: false,
      serverSide: true,
      autoWidth: false,
      ajax: {
        url: '/admin/load_settings/search_item_type',
        data: function (params) {
          params.search = $('.search-item-type input').val()
        }
      },
      columns: [
        { data: 'id' },
        { data: 'name' },
        { data: 'full_name' },
        { data: 'item_group' },
        { data: 'parent_code' },
        {
          data: 'blancco_item_code',
          orderable: false
        },
        {
          width: "15%",
          data: 'action',
          render: function(data, type) {
            if (data) {
              let editLink = `/admin/load_item_types/${data}/edit`
              let link = `/admin/load_item_types/${data}`

              return `
                <a class="btn btn-xs" href="${editLink}">
                  <i class="far fa-edit"></i>
                </a>
                <a class="btn btn-xs" href="${link}">
                  <i class="far fa-eye"></i>
                </a>
                <a data-confirm="Are you sure?" class="btn code-red btn-xs" rel="nofollow" data-method="delete" href="${link}">
                  <i class="fas fa-trash-alt"></i>
                </a>
              `
            } else {
              return ''
            }
          },
          orderable: false
        }
      ]
    })
  },

  searchItemType() {
    var typingTimer;
    var doneTypingInterval = 500;

    $('.search-item-type input').keyup(function () {
      clearTimeout(typingTimer)

      if ($('.search-item-type input').val) {
        typingTimer = setTimeout(function () { $('#item-type-table').DataTable().ajax.reload() }, doneTypingInterval)
      }
    })
  }
}
