import fetchData from '../settlement_reports/report_details/mbf/fetch_data';
import SectionMbf from '../settlement_reports/report_details/mbf/section_mbf';
import CurrencyTotal from './work_order/currency_total';
import {isLoadModuleEditable} from './load_module_general';

export default class LoadMbf {
  constructor(mbfCompany) {
    this.mbfCompany = mbfCompany;
    this.sections = [];
  }

  setup(loadModuleIds) {
    let nonEditable = !isLoadModuleEditable();
    fetchData(loadModuleIds, this.mbfCompany.id).then(data => {
      this.sections = [
        new SectionMbf(this.mbfCompany),
      ];
      this.sections.forEach(section => {
        section.setup(data, loadModuleIds, nonEditable);
        section.onStandaloneMode();
      });
      this.currencyTotal = new CurrencyTotal(this.sections);
      this.currencyTotal.setup();
    });
  }

  destroy() {
    if (this.sections) {
      this.sections.forEach(section => {
        section.destroy();
      });
    }
    this.sections = null;
  }
}
