window.orderShow = {
  init() {
    this.handleDisableNextPrevButton()
    this.handleGetNextItem()
    this.handleGetPrevItem()
    this.handleDeleteOrder()
    this.handlePrintDiv()
  },

  handleGetPrevItem() {
    $('.prevItem').on('click', function (e) {
      $.ajax({
        type: "GET",
        url: "/admin/orders/get_prev_item",
        dataType: "json",
        data: { order_id: $(this).attr('id') },
        success: function (data) { window.location.href = `/admin/orders/${data.id}` }
      })
    })
  },

  handleGetNextItem() {
    $('.nextItem').on('click', function (e) {
      $.ajax({
        type: "GET",
        url: "/admin/orders/get_next_item",
        dataType: "json",
        data: { order_id: $(this).attr('id') },
        success: function (data) { window.location.href = `/admin/orders/${data.id}` }
      })
    })
  },

  handleDisableNextPrevButton() {
    var lastId = $("#lastId").val()

    if (lastId == $('#order_id').val()) {
      $('.prevItem').addClass("disabled")
    } else {
      $('.prevItem').removeClass("disabled")
    }

    var nextId = $("#nextId").val()

    if (nextId == $('#order_id').val()) {
      $('.nextItem').addClass("disabled")
    } else {
      $('.nextItem').removeClass("disabled")
    }
  },

  handlePrintDiv() {
    window.onafterprint = function () {
      window.location.reload(true);
    }

    $('.orders-show').on('click', '.print-div', function (e) {
      var divName = $(e.target).attr('data-print-div-name')
      var printContents = document.getElementById(divName).innerHTML
      var originalContents = document.body.innerHTML

      document.body.classList.add('bg-white')
      document.body.innerHTML = printContents
      window.print()
      document.body.classList.remove('bg-white')
      document.body.innerHTML = originalContents
    })
  },

  handleDeleteOrder() {
    $('.delete-order').on('click', function (e) {
      e.preventDefault()
      $('#deleteOrderModel').modal('show')
    })
  }
}
