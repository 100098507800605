window.itemEdit = {
  init() {
    this.handleInitializeSelect2()
    window.commonJS.handleCollapseLink()
  },

  handleInitializeSelect2() {
    $("#item_audit_code").select2({ dropdownParent: "#auditModal .modal-body" })

    $('#item_audit_code').on('select2:select', function (e) {
      var audit_id = e.params.data.id
      $("#item_audit_id").val(audit_id)
    })
  }
}
