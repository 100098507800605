window.printerForm = {
  init() {
    this.handleSaveItemButton()
    this.handleSaveButton()
  },

  handleSaveItemButton() {
    $('.save-item-btn').on('click', function(e){
      e.preventDefault()
      var ram = $("#item_category_ram").val()

      if(ram != "") {
        $('.edit_item').trigger('submit')
      } else {
        $("#item_category_ram").addClass("red-error")
        return false
      }
    })
  },

  handleSaveButton() {
    $('.saving-btn').on('click', function(e){
      e.preventDefault()
      var ram = $("#item_category_ram").val()

      if(ram != "") {
        $('.new_item').trigger('submit')
      } else {
        $("#item_category_ram").addClass("red-error")
        return false
      }
    })
  }
}
