import {toastErrorMessage} from '../../../packs/toast';
import {createContactInboundCustomer} from '../api_requests';

export default class ContactModal {
  constructor(onCreate, onClose) {
    this.onCreate = onCreate;
    this.onClose = onClose;

    this.modalSelector = '#create-new-contact';
    this.$modal = $(this.modalSelector);
    this.bindModalEvents();
  }

  setInboundCustomerId(id) {
    $(`${this.modalSelector} #inbound-customer-id`).val(id);
  }

  showModal() {
    this.$modal.modal({backdrop: 'static', keyboard: false});
    this.$modal.modal('show');
  }

  hideModal() {
    this.$modal.modal('hide');
  }

  bindModalEvents() {
    $('.cancel-create-contact, #create-new-contact .close').click(() => {
      this.clearFormInputs();
      this.onClose();
    });

    $('#save-contact').click(e => {
      e.preventDefault();

      this.saveContact();
    });
  }

  saveContact() {
    const $form = $('#new_contact_inbound_customer');
    const locale = window.commonJS.getLocaleFromUrl();
    const actionUrl = new URL('/contact_inbound_customers', window.location.origin);
    actionUrl.searchParams.set('locale', locale);

    createContactInboundCustomer($form.serialize())
      .done(response => {
        this.hideModal();
        this.clearFormInputs();
        this.onCreate(response.id);
      })
      .fail(response => {
        const messages = JSON.parse(response.responseText).errors;
        toastErrorMessage(messages.join(', '));
      });
  }

  clearFormInputs() {
    [
      '#contact_inbound_customer_name',
      '#contact_inbound_customer_title',
      '#contact_inbound_customer_phone',
      '#contact_inbound_customer_email'
    ].forEach(id => $(id).val(''));
  }
}
