window.rmasForm = {
  init() {
    window.commonJS.handleInitializeSelect2()
    window.commonJS.handleInitializeDatepickerAndTimepicker()
    this.handleAppendItemList()
    this.handleSelectAddRmaItems()
    this.handleAfterInsertRmaItem()
    this.handleOnSelectRma()
    this.handleSelect2Clear()
  },

  handleSelectAddRmaItems() {
    $('.js-select2-rma-items').on('select2:select', function (e) {
      var item = e.params.data
      $item_nested = $(this).parents('.rma-items-nested-fields.nested-fields')

      $.ajax({
        type: "GET",
        url: "/admin/rmas/find_item_detail",
        data: {
          item_id: item.id
        }
      }).done(function (data) {
        if (data) {
          $item_nested.find('.js-make-field').val(data['make'])
          $item_nested.find('.js-model-field').val(data['model'])
          $item_nested.find('.js-description-field').val(data['description'])
        }
      })
    })
  },

  handleAppendItemList() {
    let itemIds = []

    $.each($('.rma-items-nested-fields').find('select'), function(index, item) {
      itemIds.push(item.value)
    })

    $('select[id^=rma_rma_items_attributes_]').last().select2({
      ajax: {
        url: '/admin/rmas/find_items',
        dataType: 'json',
        data: {
          order_id: $('#rma_order_id').val(),
          quote_id: $('#rma_quote_id').val(),
          item_ids: itemIds,
        },
        processResults: function (data) {
          return { results: data.results }
        }
      },
    })
  },

  handleAfterInsertRmaItem() {
    $('#rma-item-nested-wrapper').on('cocoon:after-insert', function (e, added_item) {
      window.rmasForm.handleAppendItemList()
      window.rmasForm.handleSelectAddRmaItems()
    })
  },

  handleSelect2Clear() {
    $('#rma_order_id, #rma_quote_id, #rma_customer_id').on('select2:clear', function (e) {
      $('#rma_customer_name').val('')
      $('#rma_customer_address1').val('')
      $('#rma_customer_address2').val('')
      $('#rma_customer_city_code').val('')
      $('#rma_customer_province_code').val('')
      $('#rma_customer_postal_code').val('')
      $('#rma_customer_country_code').val('')
      $('#rma_customer_phone_number').val('')
      $('#rma_customer_fax_number').val('')
      $('#rma_customer_contact_name').val('')

      $('.remove_fields').trigger('click')
      $('#rma_user_id').val(null).trigger('change')
    });
  },

  handleOnSelectRma() {
    $('#rma_order_id, #rma_quote_id, #rma_customer_id').on('select2:select', function (e) {
      if (this.name === 'rma[quote_id]') {
        var name = 'quote_id'
      } else if (this.name === 'rma[order_id]') {
        var name = 'order_id'
      } else {
        var name = 'customer_id'
      }

      $('.remove_fields').trigger('click')

      window.rmasForm.handleAppendItemList()

      $.ajax({
        type: "GET",
        url: "/admin/rmas/find_contact",
        data: { [name]: this.value }
      }).done(function (data) {
        var customer = data.customer
        $('#rma_customer_name').val(customer.name)
        $('#rma_customer_address1').val(customer.address1)
        $('#rma_customer_address2').val(customer.address2)
        $('#rma_customer_city_code').val(customer.city_code)
        $('#rma_customer_province_code').val(customer.province_code)
        $('#rma_customer_postal_code').val(customer.postal_code)
        $('#rma_customer_country_code').val(customer.country_code)
        $('#rma_customer_phone_number').val(customer.phone_number)
        $('#rma_customer_fax_number').val(customer.fax_number)
        $('#rma_customer_contact_name').val(customer.contact_name)

        if (data.salesperson) {
          $('#rma_user_id').val(data.salesperson.id).trigger('change')

          if (name === 'quote_id') {
            $('#rma_order_id').val(data.order_id).trigger('change')
          } else {
            $('#rma_quote_id').val(data.quote_id).trigger('change')
          }
        }
      })
    })
  }
}
