window.addItemToResourceForLot = {
  init() {
    this.handleResetModalOnShow()
    this.handleOnFinishInput()
    this.handleAddItem()
    this.handleApplyItem()
    this.handleCategoryOnChange()
    this.handleSelectable()
  },

  handleResetModalOnShow() {
    $('#addItemModal').on('hidden.bs.modal', function () {
      $('.result-total').text("")
    })

    $('#addItemModal').on('hidden.bs.modal shown.bs.modal', function () {
      window.addItemToResource.handleResetSearchFormModal()

      search_param = {
        order_id: order_id,
        resource_type: $('#resource_type').val(),
        resource_id: $('#resource_id').val()
      }
    })
  },

  handleOnFinishInput() {
    $('#addItemModal').on('keyup', '.item-description, .item-cpu, .item-ram', function() {
      setTimeout(() => {
        search_param[e.target.name] = e.target.value
        window.addItemToResource.handleOnSearchItems()
      }, 700)
    })
  },

  handleApplyItem() {
    $('.apply-item').on('click', function (e) {
      window.addItemToResource.handleGetAllDataSearch()
      window.addItemToResource.handleOnSearchItems()
    })
  },

  handleAddItem() {
    $(".add-item").on("click", function (e) {
      $(this).prop('disabled', true)

      if ($('.searched-item:checked').length === 0) {
        return alert('Please select at least 1 item')
      }

      if (['Lot', 'Order'].indexOf($('#resource_type').val()) > -1) {
        insertItemsInPage()
      }

      $(this).prop('disabled', false)
      $('.close-modal').trigger('click')
    })
  },

  handleCategoryOnChange() {
    $('#item_category_id').on('change', function () {
      var id = $(this).val()

      return $.ajax({
        type: "GET",
        url: "/admin/items/update_sub_category",
        data: { category_id: id }
      })
    })
  },

  handleSelectable() {
    $(".searched-items").selectable({
      filter: 'tr.searched-item-container',
      stop: function () {
        $(".ui-selected input", this).each(function () {
          this.checked = true
        })
      }
    })
  },

  // Private Method

  handleInsertItemsInPage() {
    var fragment = document.createDocumentFragment()

    $('tbody .searched-item:checked').each(function () {
      var element = document.getElementById('item_' + this.dataset.id).cloneNode(true)
      element.classList.remove("hidden")

      fragment.appendChild(element)
      $(element).find('.item-line-id').val($('#line_id').val())
    })

    $('#last-item-partial').append(fragment)
  },

  handleGetAllDataSearch() {
    let field = [
      '#item_category_id', '#item_sub_category_id', '#item_maker_id', '#item_maker_model_id', '.item-description', '.item-cpu', '.item-ram', '#item_audit_id', '#item_po_id', '#item_grade_id', '#item_status_id', '#item_lot_id', '#item_location_id'
    ]

    field.forEach(function (element) {
      let name = $(`${element}`).attr('name')
      search_param[name] = $(`${element}`).val()
    })
  },

  handleResetSearchFormModal() {
    $('#addItemModal select.select2').val('')
    $('#addItemModal select.select2').select2({
      dropdownParent: $('#addItemModal'),
      allowClear: true
    })
    $('#addItemModal input.item-description').val('')
    $('#addItemModal input.item-cpu').val('')
    $('#addItemModal input.item-ram').val('')
    $('#addItemModal .searched-items').empty()
  },

  handleOnSearchItems() {
    $('.apply-item').prop('disabled', true)
    $(".add-item").prop('disabled', true)
    $('.search-item-indicator').show()

    $.ajax({
      url: '/admin/orders/find_assign_items',
      dataType: 'script',
      data: search_param,
      success: function (data) {
        $('.apply-item').prop('disabled', false)
        $(".add-item").prop('disabled', false)
      }
    })
  }
}
