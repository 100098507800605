window.orderIndex = {
  init() {
    window.commonJS.handleCollapseLink()
    this.handleFilterOrderDataTable()
    this.handleInitializeOrderDataTable()
  },

  handleFilterOrderDataTable() {
    $('.order-filter-btn, #view_shipped_and_cancelled_orders').on('click', function () {
      $('#order_dt').DataTable().ajax.reload()
    })
  },

  handleInitializeOrderDataTable() {
    $('#order_dt').DataTable({
      "order": [[1, 'desc']],
      "pageLength": 50,
      "paging": true,
      "info": true,
      "searching": false,
      dom: 'Blfrtip',
      buttons: [
        {
          "extend": 'csv',
          "text": 'CSV',
          "titleAttr": 'CSV',
          "action": datatableCustomExportFunction
        },
        {
          "extend": 'excel',
          "text": 'Excel',
          "titleAttr": 'Excel',
          "action": datatableCustomExportFunction
        },

      ],
      "serverSide": true,
      "ajax": {
        url: window.location.href,
        data: function(params) {
          params.order_filter = window.commonJS.handleGetFormData($('#order-form'), [])
          params.view_shipped_and_cancelled = $('#view_shipped_and_cancelled_orders').is(":checked")
        }
      },
      "columnDefs": [
        {
          "render": function (data, type, row) {
            return `<a href='/admin/orders/${row[10]}'>${row[0]}</a>`;
          },
          "targets": 0
        },
        {
          "render": function (data, type, row) {
            return `<a href='/admin/quotes/${row[11]}'>${row[1]}</a>`;
          },
          "targets": 1
        }
      ]
    })
  }
}
