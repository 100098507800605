window.loadCodInfo = {
  init() {
    this.handlePreviewImage()
    window.commonJS.handleInitializeSelect2()
  },

  handlePreviewImage() {
    $('#signature-file').on('change', function(e) {
      const validImageTypes = ['image/jpeg', 'image/png']
      const files = e.target.files
      const file = files[0]
      const fileType = file['type']

      if (!validImageTypes.includes(fileType)) {
        alert('Please upload the following file types: .jpeg, .png')
      } else {
        const fileReader = new FileReader()
        fileReader.readAsDataURL(file)

        fileReader.onload = function () {
          $('.signature-preview').html(`<img src="${fileReader.result}" alt="${file.name}" class="signature-preview-img" />`)
        }
      }
    })
  }
}
