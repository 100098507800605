import {toastErrorMessage} from '../../packs/toast';
import IdManager from '../common/id_manager';

$(document).on('turbolinks:load', () => {
  if ($('.inbound-logistics-report-index').length > 0) {
    window.commonJS.handleCollapseLink();
    window.commonJS.handleInitializeSelect2();

    window.selectedLoads = new IdManager();
    initInboundLogisticsReportDataTable();
    handleFilterInboundLogisticsReportDataTable();
    handleExportInboundLogisticsReport();
    handleDateRangePicker();
    handleResetFilter();
  }
});

let handleResetFilter = () => {
  $('#inbound-report-filter-reset').click(_e => {
    window.selectedLoads.clearIds();
  });
};

let handleDateRangePicker = () => {
  window.commonJS.initializeDateRangePicker([
    'date_received',
    'date_processed',
    'closed_date'
  ], 'load_module_q');
};

let handleFilterInboundLogisticsReportDataTable = () => {
  $('.submit-inbound-logistics-report-filter').click(e => {
    e.preventDefault();

    window.selectedLoads.clearIds();

    $('#inbound-logistics-report-table').DataTable().ajax.reload();
  });
};

let handleExportInboundLogisticsReport = () => {
  const exportUrl = '/reporting/inbound_logistics_reports/export.xlsx';

  $('#inbound-logistics-report-table').on('change', 'input[type="checkbox"]', e => {
    let checkbox = $(e.currentTarget);
    if (checkbox.is(':checked')) {
      window.selectedLoads.addId(checkbox.val());
    } else {
      window.selectedLoads.removeId(checkbox.val());
    }
  });

  $('#export-inbound-selected').on('click', e => {
    let selectedIds = window.selectedLoads.getAllIds();
    if (selectedIds.length > 0) {
      let url = `${exportUrl}?${$.param({load_module_ids: selectedIds})}`;
      $(e.currentTarget).attr('href', url);
    } else {
      toastErrorMessage('No rows selected');
      e.preventDefault();
    }
  });
  $('#export-inbound-all').on('click', e => {
    let data = $('#load_module_search').serializeObject();
    let url = `${exportUrl}?${$.param(data)}`;
    $(e.currentTarget).attr('href', url);
  });
};

let initInboundLogisticsReportDataTable = () => {
  $('#inbound-logistics-report-table').dataTable({
    searching: false,
    paging: true,
    pageLength: 20,
    info: true,
    order: [[7, 'desc']],
    dom: 'Bfrtip',
    buttons: [],
    serverSide: true,
    ajax: {
      url: '/reporting/inbound_logistics_reports.json'
    },
    preDrawCallback: settings => {
      let api = settings.oInstance.api();
      if (!api.context[0].ajax.data) {
        api.context[0].ajax.data = {};
      }
      api.context[0].ajax.data['load_module_q'] = $('#load_module_search').serializeObject()['load_module_q'];
      api.context[0].ajax.data['load_module_q']['sorts'] = formatOrderDirection(api);
      api.context[0].ajax.data['page'] = api.page.info().page + 1;
    },
    rowCallback: (row, data) => {
      if (window.selectedLoads.hasId(data.id.toString())) {
        $(row).find('.load-module-checkbox').prop('checked', true);
      }
    },
    columns: [
      {
        data: 'id',
        orderable: false,
        searchable: false,
        render: (id, _type, _row) => {
          return `
            <div class="col">
              <div class="row d-flex flex-row justify-content-center">
                <input
                  type="checkbox"
                  class="row-checkbox load-module-checkbox"
                  value="${id}">
              </div>
            </div>`;
        }
      },
      {data: 'inbound_customer_v_number'},
      {data: 'inbound_customer_company_name'},
      {
        data: 'pickup_address',
        orderable: false
      },
      {data: 'date_received'},
      {data: 'load_status_position'},
      {data: 'load_stewardship_program_name'},
      {
        data: 'load_number',
        render: (data, _type, _row) => {
          return `
            <a href="${`/admin/load_module_generals/${data.id}`}"
              target="_blank">
              ${data.load_number}
            </a>`;
        }
      },
      {
        data: 'load_warehouse_name'
      },
      {data: 'load_carrier_name'},
      {data: 'carrier_cost'},
      {data: 'date_processed'},
      {data: 'closed_date'}
    ]
  });
};

let formatOrderDirection = datatableApi => {
  if (datatableApi.order().length === 0) return '';

  let columns = datatableApi.settings().init().columns;
  let order = datatableApi.order()[0];

  return `${columns[order[0]].data}  ${datatableApi.order()[0][1]}`;
};
