import {createAddressDetail} from '../api_requests';
import {toastErrorMessage} from '../../../packs/toast';

export default class LocationModal {
  constructor(onCreate, onClose) {
    this.onCreate = onCreate;
    this.onModalClose = onClose;

    this.modalSelector = '#create-new-location';
    this.$modal = $(this.modalSelector);
    this.bindModalEvents();
  }

  setInboundCustomerId(id) {
    $(`${this.modalSelector} #inbound-customer-id`).val(id);
  }

  showModal() {
    this.$modal.modal({backdrop: 'static', keyboard: false});
    this.$modal.modal('show');
  }

  hideModal() {
    this.$modal.modal('hide');
  }

  bindModalEvents() {
    $('.cancel-create-new-location, #create-new-location .close').click(() => {
      this.clearFormInputs();
      this.onModalClose();
    });

    $('#save-location').click(e => {
      e.preventDefault();

      this.saveLocation();
    });
  }

  saveLocation() {
    const $form = $('#new_address_detail');

    createAddressDetail($form.serialize())
      .done(response => {
        this.hideModal();
        this.clearFormInputs();
        this.onCreate(response.id);
      })
      .fail(response => {
        const messages = JSON.parse(response.responseText).errors;
        toastErrorMessage(messages.join(', '));
      });
  }

  clearFormInputs() {
    [
      '#address_detail_pickup_address_id',
      '#address_detail_street',
      '#address_detail_postal_code',
      '#address_detail_zone'
    ].forEach(id => $(id).val(''));
  }
}
