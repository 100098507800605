import Huawei from './huawei/huawei';
import Nokia from './nokia/nokia';
import Telus from './telus/telus';
import Blackberry from './blackberry/blackberry';
import AppleCanada from './apple_canada/apple_canada';

let mbfHelper = {
  initMbfTable: (company) => {
    switch (company) {
      case 'Apple Canada':
        return new AppleCanada();
      case 'Blackberry':
        return new Blackberry();
      case 'Ericsson':
        return new Nokia();
      case 'Huawei':
        return new Huawei();
      case 'Nokia':
        return new Nokia();
      case 'Telus':
        return new Telus();
    }
  },

  initRowsData: (load_mbfs, mbf_descriptions, loadModuleIds, loadModuleInfo) => {
    let data = {};

    loadModuleIds.forEach(loadModule => {
      mbf_descriptions.forEach(description => {
        let load_mbf = load_mbfs.load_mbfs.find(el => {
          return el.mbf_description_id === description.id &&
            el.load_module_id === loadModule;
        }) || {};

        data[loadModule] = data[loadModule] || [];
        data[loadModule].push({
          id: load_mbf.id || null,
          load_module_id: load_mbf.load_module_id || loadModule,
          load_warehouse_name: loadModuleInfo
            .find(load => load[0] === loadModule)[2],
          mbf_company_id: description.mbf_company_id,
          mbf_description_id: description.id,
          preset_rate: description.rate,
          category: description.mbf_category_name,
          description: description.name,
          weight: load_mbf.weight || null,
          unbox_weight: load_mbf.unbox_weight || null,
          uom: load_mbf.uom || null,
          rate: load_mbf.rate || null,
          total: load_mbf.total || null,
          total_rebate: load_mbf.total_rebate || null,
          tax: load_mbf.tax || null,
          tax_included: load_mbf.tax_included || null,
          currency: load_mbf.currency || null
        });
      });
    });
    return data;
  }
};

export default mbfHelper;
