import {toastErrorMessage} from '../../../../packs/toast';

export let hotCrud = hot => {
  return {
    create: changes => {
      let reduced = changes.reduce((acc, [row, prop, , newValue]) => {
        if (!hot.getDataAtRowProp(row, 'id')) {
          acc[row] = acc[row] || {
            load_module_id: hot.getDataAtRowProp(row, 'load_module_id'),
            mbf_company_id: hot.getDataAtRowProp(row, 'mbf_company_id'),
            mbf_description_id: hot.getDataAtRowProp(row, 'mbf_description_id')
          };
          acc[row][prop] = newValue;
        }
        return acc;
      }, {});

      Object.keys(reduced).forEach(row => {
        let payload = {};
        payload.load_mbf = reduced[row];
        fetch('/admin/load_mbfs', {
          method: 'POST',
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify(payload)
        })
          .then(response => {
            if (!response.ok) {
              throw new Error('Something went wrong');
            }
            return response.json();
          })
          .then(data => {
            hot.setDataAtRowProp([
              [row, 'id', data.id]
            ], 'Sync.SetId');
          })
          .catch(error => {
            toastErrorMessage(error);
          });
      });
    },
    update: changes => {
      let toProcess = changes.map(([row, prop, , newValue]) => {
        return [hot.getDataAtRowProp(row, 'id'), row, prop, newValue];
      });
      // Reduce to unique rows
      let reduced = toProcess.filter(([id]) => id).reduce((acc, [id, row, prop, newValue]) => {
        acc[row] = acc[row] || {
          id: id
        };
        acc[row][prop] = newValue;
        return acc;
      }, {});
      Object.keys(reduced).forEach(row => {
        let payload = {};
        payload.load_mbf = reduced[row];
        fetch(`/admin/load_mbfs/${payload.load_mbf.id}`, {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(payload)
        })
          .then(response => {
            if (!response.ok) {
              throw new Error('Something went wrong');
            }
          })
          .catch(error => {
            toastErrorMessage(error);
          });
      });
    }
  };
};
