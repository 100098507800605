$(document).on('turbolinks:load', () => {
  if ($('.edit_mbf_company').length > 0) {
    initMbfDescriptionRateInput();
  }
});

let initMbfDescriptionRateInput = () => {
  $('.edit_mbf_company').on('input', '.mbf-description-rate', e => {
    $(e.currentTarget).val(
      $(e.currentTarget).val().replace(/,/g, '.')
    );
  });
};
