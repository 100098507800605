import {toastErrorMessage} from '../../packs/toast';

$(document).on('turbolinks:load', () => {
  if ($('.arma-report-index').length > 0) {
    handleExportArmaReport();
    handleActiveTab();
  }
});

let handleExportArmaReport = () => {
  const exportUrl = '/reporting/arma_reports/export.xlsx';

  // ARMA Export
  $('#export-arma-selected').on('click', e => {
    let selectedTransfers = window.selectedTransfers.getAllIds();
    let selectedLoads = window.selectedLoads.getAllIds();

    if (selectedTransfers.length > 0 || selectedLoads.length > 0) {
      let params = $.param({
        transfer_ids: selectedTransfers,
        load_module_ids: selectedLoads,
        export_selection: 'selection'
      });
      let url = `${exportUrl}?${params}`;
      $(e.currentTarget).attr('href', url);
    } else {
      toastErrorMessage('No rows selected');
      e.preventDefault();
    }
  });

  $('#export-arma-all').on('click', e => {
    let transfer_data = $('#transfer_search').serializeObject();
    let load_module_data = $('#load_module_search').serializeObject();
    let data = Object.assign({}, transfer_data, load_module_data);
    let url = `${exportUrl}?${$.param(data)}`;
    $(e.currentTarget).attr('href', url);
  });

  // Loads Export
  $('#export-loads-selected').on('click', e => {
    let selectedIds = window.selectedLoads.getAllIds();
    if (selectedIds.length > 0) {
      let data = Object.assign(
        {
          export_type: 'loads',
          export_selection: 'selection'
        },
        {load_module_ids: selectedIds}
      );
      let url = `${exportUrl}?${$.param(data)}`;
      $(e.currentTarget).attr('href', url);
    } else {
      toastErrorMessage('No rows selected');
      e.preventDefault();
    }
  });
  $('#export-loads-all').on('click', e => {
    let loadModuleData = $('#load_module_search').serializeObject();
    let data = Object.assign(
      {export_type: 'loads'},
      loadModuleData
    );
    let url = `${exportUrl}?${$.param(data)}`;
    $(e.currentTarget).attr('href', url);
  });

  // Deliveries Export
  $('#export-deliveries-selected').on('click', e => {
    let selectedIds = window.selectedTransfers.getAllIds();

    if (selectedIds.length > 0) {
      let data = Object.assign(
        {
          export_type: 'deliveries',
          export_selection: 'selection'
        },
        {transfer_ids: selectedIds}
      );
      let url = `${exportUrl}?${$.param(data)}`;
      $(e.currentTarget).attr('href', url);
    } else {
      toastErrorMessage('No rows selected');
      e.preventDefault();
    }
  });
  $('#export-deliveries-all').on('click', e => {
    let transferData = $('#transfer_search').serializeObject();
    let data = Object.assign(
      {export_type: 'deliveries'},
      transferData
    );
    let url = `${exportUrl}?${$.param(data)}`;
    $(e.currentTarget).attr('href', url);
  });
};

let handleActiveTab = () => {
  let saveSelectedTab = tabId => {
    localStorage.setItem('arma_active_tab', tabId);
  };
  let restoreSelectedTab = () => {
    let selectedTab = localStorage.getItem('arma_active_tab');
    if (selectedTab) {
      $('#arma-tabs a[href="#' + selectedTab + '"]').tab('show');
    }
  };
  let resetSelectedTab = () => {
    localStorage.removeItem('arma_active_tab');
  };

  $('.reset-search-form').on('click', e => {
    let activeTab = $('#arma-tabs .nav-link.active')
      .attr('href')
      .substring(1);
    saveSelectedTab(activeTab);
  });

  restoreSelectedTab();
  resetSelectedTab();
};
