import {toastErrorMessage} from '../../../packs/toast';
import {kilograms_to_pounds, pounds_to_kilograms} from '../../common/measurement_converter';

export default class QualifiedChangesValidator {
  validate(hot, changes) {
    let toSplice = [];
    let errorMessages = new Set();
    let correctionChanges = [];
    changes.forEach(([row, prop, oldValue, newValue], index) => {
      if (oldValue === newValue) return toSplice.unshift(index);

      switch (prop) {
        case 'full_name':
          if (!newValue) {
            toSplice.unshift(index);
            errorMessages.add('Item Description can not be blank');
          }
          break;
        case 'name':
          break;
        case 'description':
          if (!newValue) {
            toSplice.unshift(index);
            errorMessages.add('Qualified Type can not be blank');
            break;
          }
          break;
        case 'qty':
          if (newValue === null || newValue === '') {
          } else if (newValue < 0) {
            toSplice.unshift(index);
            errorMessages.add('Quantity can not be negative');
          } else {
            newValue = parseInt(newValue, 10);

            toSplice.unshift(index);
            correctionChanges.push(
              [row, 'qty', oldValue, newValue]
            );
          }
          break;
        case 'rate_type':
          if (!newValue) {
            toSplice.unshift(index);
            errorMessages.add('Rate Type can not be blank');
          }
          break;
        case 'rate':
          if (newValue) {
            newValue = parseFloat(newValue.replace(/,/g, '.')).toFixed(3);

            toSplice.unshift(index);
            correctionChanges.push(
              [row, 'rate', oldValue, newValue]
            );
          }
          break;
        case 'weight':
          if (newValue === null || newValue === '') {
            toSplice.unshift(index);
            errorMessages.add('Weight can not be blank');
          } else if (newValue < 0) {
            toSplice.unshift(index);
            errorMessages.add('Weight can not be negative');
          } else {
            newValue = parseFloat(
              newValue.replace(/,/g, '.')
            ).toFixed(2);

            toSplice.unshift(index);
            correctionChanges.push(
              [row, 'weight', oldValue, newValue]
            );
          }
          break;
        case 'total':
          if (newValue) {
            newValue = parseFloat(newValue.replace(/,/g, '.')).toFixed(2);

            toSplice.unshift(index);
            correctionChanges.push(
              [row, 'total', oldValue, newValue]
            );
          }
          break;
        case 'tax_included':
          break;
        case 'currency':
          if (!newValue) {
            toSplice.unshift(index);
            errorMessages.add('Currency can not be blank');
          }
          break;
        case 'uom':
          if (!newValue) {
            toSplice.unshift(index);
            errorMessages.add('UOM can not be blank');
          }
          break;
      }
    });
    errorMessages.size > 0 && toastErrorMessage([...errorMessages].join('<br>'));

    // Remove changes that are not valid
    toSplice.forEach(index => {
      changes.splice(index, 1);
    });

    // Add correction changes
    correctionChanges.forEach(change => {
      changes.push(change);
    });
  }
};
