import $ from "jquery";

$( document ).on('turbolinks:load', function() {
  gatherMakerModelsSelect2();

  $('.js-select-makers-field').change(function() {
    $('.js-select-models-field').val('');
    gatherMakerModelsSelect2();
  });
});

function gatherMakerModelsSelect2() {
  $('.js-select-models-field').select2({
    width: '100%',
    multiple: true,
    placeholder: 'Select',
    allowClear: true,
    ajax: {
      url: '/admin/items/get_makermodels',
      dataType: 'json',
      data: function(params) {
        var selectedMakerId = $('.item-filter').find('.js-select-makers-field').val();
        return { search_content: params.term, maker_id: selectedMakerId }
      },
      processResults: function(data) {
        data.push({id: "NONE", maker_id: 0, name: "NONE"})
        return { results: data };
      }
    },
    templateResult: formatResult,
    templateSelection: formatSelection,
  }).trigger('change');
}

function formatResult(item) {
  return item.name;
}

function formatSelection(item) {
  return item.text || item.name;
}
