class IdManager {
  constructor() {
    this.idSet = new Set();
  }

  addId(id) {
    this.idSet.add(id);
  }

  removeId(id) {
    this.idSet.delete(id);
  }

  clearIds() {
    this.idSet.clear();
  }

  hasId(id) {
    return this.idSet.has(id);
  }

  getAllIds() {
    return Array.from(this.idSet);
  }
}

export default IdManager;
