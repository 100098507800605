import {initHandsontable} from '../../packs/handsontable';

export const initializeValueAddedServicesHot = onsiteServices => {
  const $dataEl = $('#value-added-services-data');
  const existingData = $dataEl.data('existing-data') || [];

  let deletedRows = [];

  const hot = initHandsontable({
    containerId: 'value-added-services-table',
    data: existingData,
    colHeaders: [
      'Value Added Services',
      'Rate',
      'Hours/Unit',
      'Resources',
      'Cost',
      'Comments',
      'ID'
    ],
    columns: [
      {
        data: 'service',
        type: 'dropdown',
        strict: false,
        source: onsiteServices,
        validator: (query, callback) => {
          callback(true);
        }
      },
      {
        data: 'rate',
        type: 'numeric',
        allowInvalid: false,
        editor: 'numeric'
      },
      {
        data: 'hours_unit',
        type: 'numeric'
      },
      {
        data: 'resources',
        type: 'numeric'
      },
      {
        data: 'cost',
        type: 'numeric'
      },
      {
        data: 'comments',
        type: 'text'
      },
      {
        data: 'id',
        type: 'text',
        readOnly: true
      }
    ],
    additionalOptions: {
      startRows: 1,
      minSpareRows: 1,
      rowHeaders: false,
      contextMenu: [
        'row_above',
        'row_below',
        'remove_row',
        'clear_column',
        '---------',
        'undo',
        'redo',
        '---------',
        'copy',
        'cut'
      ],
      fillHandle: false,
      dropdownMenu: false,
      filters: false,
      hiddenColumns: {
        columns: [6],
        indicators: false
      }
    }
  });

  hot.addHook('beforeChange', (changes, source) => {
    if (source === 'loadData') return;

    const toSplice = [];
    const correctionChanges = [];
    changes.forEach(([row, prop, oldValue, newValue], index) => {
      if (['rate', 'hours_unit', 'resources', 'cost'].includes(prop)) {
        if (prop !== 'cost' &&
          oldValue !== newValue &&
          (newValue === null || newValue === '')) {
          const oldCost = hot.getDataAtRowProp(row, 'cost');
          correctionChanges.push([row, 'cost', oldCost, null]);

          return;
        }

        const fractionDigits = prop === 'cost' ? 2 : 3;
        newValue = newValue === null ? '' : newValue;
        newValue = parseFloat(newValue.toString()
          .replace(/,/g, '.'))
          .toFixed(fractionDigits);
        toSplice.unshift(index);

        if (!isNaN(newValue)) {
          correctionChanges.push([row, prop, oldValue, newValue]);
        }
        if (prop === 'cost') return;
        let rate = parseFloat(hot.getDataAtRowProp(row, 'rate') || 0);
        let hoursUnit = parseFloat(hot.getDataAtRowProp(row, 'hours_unit') || 0);
        let resources = parseFloat(hot.getDataAtRowProp(row, 'resources') || 0);
        rate = prop === 'rate' ? newValue : rate;
        hoursUnit = prop === 'hours_unit' ? newValue : hoursUnit;
        resources = prop === 'resources' ? newValue : resources;
        const newCost = parseFloat(calculateCost(rate, hoursUnit, resources)).toFixed(2);
        const oldCost = hot.getDataAtRowProp(row, 'cost');
        correctionChanges.push([row, 'cost', oldCost, newCost]);
      }
    });
    toSplice.forEach(index => changes.splice(index, 1));
    correctionChanges.forEach(change => changes.push(change));
  });

  hot.addHook('afterChange', (changes, source) => {
    if (source === 'loadData') return;

    serializeHotData(hot, deletedRows);
    updateCostTotalRow(hot);
  });

  hot.addHook('beforeRemoveRow', (index, amount) => {
    for (let i = 0; i < amount; i++) {
      const deletedRow = hot.getSourceDataAtRow(index + i);
      if (deletedRow && deletedRow.id) {
        deletedRows.push(deletedRow.id);
      }
    }
  });

  hot.addHook('afterRemoveRow', () => {
    serializeHotData(hot, deletedRows);
    updateCostTotalRow(hot);
  });

  serializeHotData(hot, deletedRows);
  updateCostTotalRow(hot);
};

const calculateCost = (rate, hoursUnit, resources) => {
  return (rate * hoursUnit) * resources;
};

const updateCostTotalRow = hot => {
  const totalCost = hot.getData().reduce((sum, row) => {
    const cost = parseFloat(row[4]) || 0;
    return sum + cost;
  }, 0);

  const $el = $('#value-added-cost-total');
  $el.text(totalCost.toFixed(2));
};

const serializeHotData = (hot, deletedRows) => {
  const $hiddenFieldsContainer = $('#value-added-services-hidden-fields');
  $hiddenFieldsContainer.empty();

  hot.getData().forEach((row, index) => {
    if (row.some(cell => cell !== null && cell !== '')) {
      let fields = '';

      if (row[6]) fields += `<input type="hidden" name="inbound_quote[value_added_services_attributes][${index}][id]" value="${row[6]}">`;
      fields += `<input type="hidden" name="inbound_quote[value_added_services_attributes][${index}][service]" value="${row[0] || ''}">`;
      fields += `<input type="hidden" name="inbound_quote[value_added_services_attributes][${index}][rate]" value="${row[1] || ''}">`;
      fields += `<input type="hidden" name="inbound_quote[value_added_services_attributes][${index}][hours_unit]" value="${row[2] || ''}">`;
      fields += `<input type="hidden" name="inbound_quote[value_added_services_attributes][${index}][resources]" value="${row[3] || ''}">`;
      fields += `<input type="hidden" name="inbound_quote[value_added_services_attributes][${index}][cost]" value="${row[4] || ''}">`;
      fields += `<input type="hidden" name="inbound_quote[value_added_services_attributes][${index}][comments]" value="${row[5] || ''}">`;

      $hiddenFieldsContainer.append(fields);
    }
  });

  deletedRows.forEach((id, idx) => {
    const fields = `
      <input type="hidden" name="inbound_quote[value_added_services_attributes][${idx}][id]" value="${id}">
      <input type="hidden" name="inbound_quote[value_added_services_attributes][${idx}][_destroy]" value="1">
    `;
    $hiddenFieldsContainer.append(fields);
  });
};
