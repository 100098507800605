window.commissionReport = {
  init() {
    this.handleExportCommissionReport()
    window.commonJS.handleCollapseLink()
    window.commonJS.handleInitializeSelect2()
    this.handleInitializeReportRangeDatePicker()
    this.handleInitializeBuyRangeDatePicker()
    this.handleInitializeSellRangeDatePicker()
    this.handleInitializeDatatable()
    this.handleFilterQuoteDataTable()
  },

  enableButton() {
    $("#generate-excel-report").removeAttr('data-disable-with');
    $("#generate-excel-report").prop('disabled', false);
  },

  handleExportCommissionReport() {
    $("#generate-excel-report").on('click', function(e) {
      setTimeout(window.commissionReport.enableButton(), 1000);
      $('#overlay').addClass('d-flex')

      $.ajax({
        type: 'GET',
        url: '/admin/reports/commission.json',
        data: {
          'report[audit_id]': $('#report_audit_id').val(),
          'report[buy_start_date]': $('#buy_start_date').val(),
          'report[buy_end_date]': $('#buy_end_date').val(),
          'report[sell_start_date]': $('#sell_start_date').val(),
          'report[sell_end_date]': $('#sell_end_date').val(),
          commit: 'Export'
        },
        success: function(data) {
          if (data.background_job_id) {
            window.commissionReport.checkReportStatus = setInterval(window.commissionReport.checkExportStatus, 2000, data.background_job_id);
          }
        }
      })
    });
  },

  checkExportStatus(background_job_id) {
    $.ajax({
      type: 'GET',
      url: `/admin/background_jobs/${background_job_id}/status.json`,
      success: function(data) {
        if (data.status === 'done' || data.status === 'failed') {
          clearInterval(window.commissionReport.checkReportStatus)
          $('#overlay').removeClass('d-flex')

          if (data.status === 'done') {
            window.location = `/admin/background_jobs/${background_job_id}/download_file`
          } else if (data.status === 'failed') {
            error_message(data.error_message)
          }
        }
      }
    })
  },

  handleInitializeReportRangeDatePicker() {
    function cb(start, end) {
      $('#reportrange span').html(`${start.format('MMMM D, YYYY')} - ${end.format('MMMM D, YYYY')}`)
      $("#start_date").val(start.format('MMMM D, YYYY'))
      $("#end_date").val(end.format('MMMM D, YYYY'))
    }

    $('#reportrange').daterangepicker({
      startDate: start,
      endDate: end,
      ranges: {
        'Today': [moment(), moment()],
        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
      }
    }, cb)

    cb(start, end)
  },

  handleInitializeBuyRangeDatePicker() {
    function cb_buy_date(start, end) {
      if (start != null && end != null && start._isValid && end._isValid) {
        $('#buy_date_range span').html(`${start.format('MMMM D, YYYY')} - ${end.format('MMMM D, YYYY')}`);
        $("#buy_start_date").val(start.format('MMMM D, YYYY'));
        $("#buy_end_date").val(end.format('MMMM D, YYYY'));
      } else {
        $('#buy_date_range span').html('');
        $("#buy_start_date").val('');
        $("#buy_end_date").val('');
      }
    }

    $('#buy_date_range').daterangepicker({
      autoUpdateInput: false,
      ranges: {
        'None': [moment().subtract(1, 'days'), moment().add(1, 'days')],
        'Today': [moment(), moment()],
        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
      }
    }, cb_buy_date);

    $('#buy_date_range').on('apply.daterangepicker', function (ev, picker) {
      if (picker.chosenLabel === 'None') {
        $('#buy_date_range span').html('');
        $("#buy_start_date").val('');
        $("#buy_end_date").val('');
      }

      if (picker.chosenLabel === 'Today') {
        $('#buy_date_range span').html(`${moment().format('MMMM D, YYYY')} - ${moment().format('MMMM D, YYYY')}`);
        $("#buy_start_date").val(moment());
        $("#buy_end_date").val(moment());
      }
    });

    cb_buy_date(buy_start_date, buy_end_date)
  },

  handleInitializeSellRangeDatePicker() {
    function cb_sell_date(start, end) {
      if (start != null && end != null && start._isValid && end._isValid) {
        $('#sell_date_range span').html(`${start.format('MMMM D, YYYY')} - ${end.format('MMMM D, YYYY')}`);
        $("#sell_start_date").val(start.format('MMMM D, YYYY'));
        $("#sell_end_date").val(end.format('MMMM D, YYYY'));
      } else {
        $('#sell_date_range span').html('');
        $("#sell_start_date").val('');
        $("#sell_end_date").val('');
      }
    }

    $('#sell_date_range').daterangepicker({
      autoUpdateInput: false,
      ranges: {
        'None': [moment().subtract(1, 'days'), moment().add(1, 'days')],
        'Today': [moment(), moment()],
        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
      }
    }, cb_sell_date);

    cb_sell_date(sell_start_date, sell_end_date)

    $('#sell_date_range').on('apply.daterangepicker', function (ev, picker) {
      if (picker.chosenLabel === 'None') {
        $('#sell_date_range span').html('');
        $("#sell_start_date").val('');
        $("#sell_end_date").val('');
      }

      if (picker.chosenLabel === 'Today') {
        $('#sell_date_range span').html(`${moment().format('MMMM D, YYYY')} - ${moment().format('MMMM D, YYYY')}`);
        $("#sell_start_date").val(moment().format('MMMM D, YYYY'));
        $("#sell_end_date").val(moment().format('MMMM D, YYYY'));
      }
    });
  },

  handleFilterQuoteDataTable() {
    $('#generate-report').on('click', function () {
      $('#commission-table').DataTable().ajax.reload()
    })
  },

  handleInitializeDatatable() {
    $('#commission-table').DataTable({
      searching: false,
      pageLength: 50,
      paging: true,
      info: true,
      dom: 'Bfrtip',
      order: [[1, "desc"]],
      buttons: [],
      serverSide: true,
      ajax: {
        url: window.location.href,
        data: function (params) {
          params.report = window.commonJS.handleGetFormData($('#reports-form'), [])
        }
      },
      columns: [
        { data: 'id' },
        { data: 'serial' },
        { data: 'audit_code' },
        { data: 'order_code' },
        { data: 'inbound_sale_rep' },
        { data: 'full_name' },
        { data: 'category_name' },
        { data: 'make' },
        { data: 'model' },
        { data: 'purchase_price' },
        { data: 'cost' },
        { data: 'selling_price' },
        { data: 'profit' },
        { data: 'audited_date' },
      ]
    })
  }
}
