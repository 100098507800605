window.loadReport = {
  init() {
    window.commonJS.handleInitializeSelect2()
    window.commonJS.handleCollapseLink()
    this.handleDatepickerFilterTodayAndNone()
    this.handleCreateAtFilter()
    this.handleEnableExportButton()
    this.handleSubmitExportFullReport()
    this.handleLoadModuleIds()
    this.handleInitializeAdditionalFileModal()
  },

  enableButton() {
    $("input[type='submit']").removeAttr('data-disable-with')
    $("input[type='submit']").prop('disabled', false)
  },

  handleEnableExportButton() {
    $("#generate-excel-report").on('click', function(e) {
      setTimeout(window.loadReport.enableButton, 1000)
    })
  },

  handleCreateAtFilter() {
    if ($("#processed-date-diff").length > 0 && $("#processed-date-diff").attr("data-start-date") && $("#processed-date-diff").attr("data-end-date")) {
      var processedStartDate = moment($("#processed-date-diff").attr("data-start-date"))
      var processedEndDate = moment($("#processed-date-diff").attr("data-end-date"))
    } else {
      var processedStartDate = undefined
      var processedEndDate = undefined
    }

    function cbProcessedDate(start, end) {
      if (start != null && end != null && start._isValid && end._isValid) {
        $(' #processed_date_range span').html(`${start.format('MMMM D, YYYY')} - ${end.format('MMMM D, YYYY')}`)
        $("#report_processed_start_date").val(start.format('MMMM D, YYYY'))
        $("#report_processed_end_date").val(end.format('MMMM D, YYYY'))
      } else {
        $(' #processed_date_range span').html('')
        $("#report_processed_start_date").val('')
        $("#report_processed_end_date").val('')
      }
    }

    $('#processed_date_range').daterangepicker({
      startDate: processedStartDate,
      endDate: processedEndDate,
      ranges: {
        'None': [moment().subtract(1, 'days'), moment().add(1, 'days')],
        'Today': [moment(), moment()],
        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
      }
    }, cbProcessedDate)

    cbProcessedDate(processedStartDate, processedEndDate)
  },

  handleDatepickerFilterTodayAndNone() {
    $(' #processed_date_range').on('apply.daterangepicker', function (ev, picker) {
      if (picker.chosenLabel === 'None') {
        $(' #processed_date_range span').html('')
        $("#report_processed_start_date").val('')
        $("#report_processed_end_date").val('')
      }

      if (picker.chosenLabel === 'Today') {
        $(' #processed_date_range span').html(`${moment().format('MMMM D, YYYY')} - ${moment().format('MMMM D, YYYY')}`)
        $("#report_processed_start_date").val(moment().format('MMMM D, YYYY'))
        $("#report_processed_end_date").val(moment().format('MMMM D, YYYY'))
      }
    })
  },

  handleSubmitExportFullReport() {
    $('#export-full-report-btn').on('click', function() {
      $('#additional-export-file').modal('hide')
      $('#overlay').addClass('d-flex')

      var exportOptions = []

      $.each($('.export-options input[type="checkbox"]:checked'), function (index, item) {
        exportOptions.push(item.value)
      })

      $.ajax({
        type: 'GET',
        url: '/admin/reports/load_report',
        data: {
          'load_report[load_module_ids]': $('#load_module_ids').val(),
          'export_options': exportOptions,
          'commit': 'export_full'
        },
        success: function(data) {
          if (data.background_job_id) {
            window.loadReport.checkReportStatus = setInterval(window.loadReport.handleCheckReportStatus, 5000, data.background_job_id)
          }
        }
      })
    })
  },

  handleCheckReportStatus(background_job_id) {
    $.ajax({
      type: 'GET',
      url: `/admin/background_jobs/${background_job_id}/status.json`,
      success: function (data) {
        if (data.status === 'done' || data.status === 'failed') {
          $('#overlay').removeClass('d-flex')
          clearInterval(window.loadReport.checkReportStatus)

          if (data.status === 'done') {
            window.location = `/admin/background_jobs/${background_job_id}/download_file`
          } else if (data.status === 'failed') {
            error_message(data.error_message)
          }
        }
      }
    })
  },

  handleLoadModuleIds() {
    $("#load-report-table").on('change', "input[type='checkbox']", function (e) {
      let load_module_ids = $('#load_module_ids').val().length > 0 ? $('#load_module_ids').val().split(',') : []

      if (e.target.checked) {
        $.each($('#load-report-table input:checked'), function (index, item) { load_module_ids.push(item.value) })
      } else {
        load_module_ids = $.grep(load_module_ids, function (value) { return value != $(e.target).val() })
      }

      $('#load_module_ids').val([...new Set(load_module_ids)])

      $.each($('.pagination a'), function (index, element) {
        var url = new URL(element.href)
        var pathName = url.pathname

        var params = new URLSearchParams(url.search)
        params.set('load_module_ids', [...new Set(load_module_ids)])

        $(element).attr('href', `${pathName}?${params.toString()}`)
      })
    })
  },

  handleInitializeAdditionalFileModal() {
    $('.choose-additional-file').on('click', function () {
      $('.export-options input[type="checkbox"]').prop('checked', false)
    })
  },
}
