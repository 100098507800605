export let initUpdateCells = ({
    hot,
    itemDescriptions,
    validQuantities
  }) => {
  return changes => {
    let toProcess = changes.filter(([row]) => {
      return hot.getDataAtRowProp(row, 'id');
    }).reduce((acc, [row, prop, , newValue]) => {
      acc[row] = acc[row] || {};
      acc[row][prop] = newValue;
      return acc;
    }, {});
    Object.entries(toProcess).forEach(([row, props]) => {
      if (!props.hasOwnProperty('full_name')) return;
      let itemNo = props.full_name;
      let found = itemDescriptions.find(el => el[1] === props.full_name);

      if (found) {
        itemNo = found[0];
        changes.unshift([
          row,
          'description',
          hot.getDataAtRowProp(row, 'description'),
          validQuantities[itemNo].qualified_type
        ]);
      }
      changes.unshift([row, 'name', null, itemNo]);
    });
  };
};
