import Section from './section';
import OnsiteService from './onsite_service';

export default class SectionOnsiteService extends Section {
  constructor() {
    super(
      [],
      'onsite-service'
    );
  }

  setup({load_work_orders}, loadModuleIds, nonEditable) {
    let {onsite_service} = load_work_orders;

    if (onsite_service.length) {
      $('#onsite-service-content').show();
      $('#onsite-service-no-items').hide();
    } else {
      $('#onsite-service-content').hide();
      $('#onsite-service-no-items').show();
    }

    let table = new OnsiteService();
    table.setup({
      data: onsite_service,
      containerId: 'work-order-onsite-service-table',
      currencies: load_work_orders.currencies,
      primaryCurrency: load_work_orders.primary_currency,
      loadModuleInfo: load_work_orders.load_module_info,
      nonEditable: nonEditable
    });
    this.tables.push(table);

    super.setup();
  }
}
