$(document).on('turbolinks:load', () => {
  if ($('.settlement-report-index').length > 0) {
    window.commonJS.handleCollapseLink();
    window.commonJS.handleInitializeSelect2();
    window.commonJS.handleInitializeDatepicker();
    $('.datepicker').datepicker('option', 'altField', '#q_created_date_eq');
    window.commonJS.clearDatePicker();
    initSettlementReportDataTable();
    handleFilterSettlementReportDataTable();
  }
});

let handleFilterSettlementReportDataTable = () => {
  $('.submit-settlement-report-filter').click(e => {
    e.preventDefault();

    $('#settlement-report-table').DataTable().ajax.reload();
  });
};

let initSettlementReportDataTable = () => {
  let thCount = $('#settlement-report-table thead tr th').length;
  let columns = [
    {
      data: 'settlement_number',
      render: (data, _type, _row) => {
        let link = `/admin/settlement_reports/${data.id}`;

        return `<a href="${link}">${data.settlement_number}</a>`;
      }
    },
    {data: 'inbound_customer_company_name'},
    {data: 'created_at'},
    {data: 'status'},
    {
      data: 'destroy',
      className: 'text-center',
      orderable: false,
      render: (data, _type) => {
        if (!data) {
          return '';
        }

        let link = `/admin/settlement_reports/${data}`;

        return `
            <a href="${link}" class="btn code-red btn-xs" 
              data-method="delete" 
              data-confirm="Are you sure?">
              <i class="fas fa-trash-alt"></i>
            </a>`;
      }
    }
  ];
  if (thCount < columns.length) columns.pop();

  $('#settlement-report-table').dataTable({
    searching: false,
    paging: true,
    pageLength: 20,
    info: true,
    orders: [[1, 'desc']],
    order: [],
    dom: 'Bfrtip',
    buttons: [],
    serverSide: true,
    ajax: {
      url: window.location.href + '.json'
    },
    preDrawCallback: settings => {
      let api = settings.oInstance.api();
      if (!api.context[0].ajax.data) {
        api.context[0].ajax.data = {};
      }
      api.context[0].ajax.data['q'] = $('#settlement_report_search').serializeObject()['q'];
      api.context[0].ajax.data['q']['sorts'] = formatOrderDirection(api);
      api.context[0].ajax.data['page'] = api.page.info().page + 1;
    },
    columns: columns
  });
};

let formatOrderDirection = (datatableApi) => {
  if (datatableApi.order().length === 0) return '';

  let columns = datatableApi.settings().init().columns;
  let order = datatableApi.order()[0];

  return `${columns[order[0]].data}  ${datatableApi.order()[0][1]}`;
};
