window.transferExport = {
  init() {
    this.handleFormExportType();
    this.handleOpenAndCloseExportTransfer();
  },

  handleFormExportType() {
    $('#export-transfer-modal form').attr(
      'action',
      `/admin/transfers/export.${ $('.export-type input:checked').val() }`
    );

    $('.export-type input').change(e => {
      const action = `/admin/transfers/export.${e.target.value}`;
      $('#export-transfer-modal form').attr('action', action);
    })
  },

  handleOpenAndCloseExportTransfer() {
    $('#export-transfer-modal form').submit(e => {
      if ($('#export-transfer-modal select').length > 0 && $('#export-transfer-modal select').val()) {
        $('#export-transfer-modal').modal('hide');
      } else {
        e.preventDefault();
        alert('Please select at least 1 transfer');
        $('.export-transfers').attr('disabled', true);
      }
    });

    $('#export-transfer-modal select').change(e => {
      if ($('#export-transfer-modal select').length > 0 && $('#export-transfer-modal select').val()) {
        $('.export-transfers').attr('disabled', false);
      }
    });

    $('.open-transfer-export-modal').click(() => {
      $('.export-transfers').attr('disabled', false);
      $('#export-transfer-modal form')[0].reset();
      $('#export-transfer-modal #code').val([]).trigger('change');

      let transferCode = $('#transfer_code').val();
      if (transferCode.length > 0) {
        $('#code').val(transferCode).trigger('change');
      }
    });
  },
};
