window.reCyclingWeightReport = {
  init() {
    window.commonJS.handleInitializeSelect2()
    window.commonJS.handleCollapseLink()
    this.handleInitializeDataTable()
  },

  handleInitializeDataTable() {
    $('#recycling-weight-table').DataTable({
      searching: false,
      paging: false,
      info: false,
      order: [[0, "desc"]],
      dom: 'frtip',
      serverSide: true,
      ajax: window.location.href,
      columns: [
        { data: 'category' },
        { data: 'quantity_net_weight' },
        { data: 'qty' },
        { data: 'gross' },
        { data: 'tare' },
        { data: 'qualified_weight' },
        { data: 'unqualified_weight' }
      ]
    })
  },
}
