window.indexReport = {
  init() {
    window.commonJS.handleCollapseLink()
    window.commonJS.handleInitializeSelect2()
    this.handleGenerateReport()
    this.handleInitializeReportDateRangePicker()
    this.handleTotalCountOnClick()
    this.handleSubmitInventoryReportForm()
  },

  handleGenerateReport() {
    function enableButton() {
      $("#generate-excel-report").removeAttr('data-disable-with')
      $("#generate-excel-report").prop('disabled', false)
    }

    $("#generate-excel-report").on('click', function (e) { setTimeout(enableButton, 1000) })
  },

  handleInitializeReportDateRangePicker() {
    var today = moment().format('LL')
    $("#date-result").html(today)

    if ($("#date-diff").length > 0 && $("#date-diff").attr("data-start-date") && $("#date-diff").attr("data-end-date")) {
      var start = moment($("#date-diff").attr("data-start-date"))
      var end = moment($("#date-diff").attr("data-end-date"))
    } else {
      var start = moment().startOf('month')
      var end = moment()
    }

    if ($('#start').val() && $('#end').val() && $('#start').val().length > 0 && $('#end').val().length > 0) {
      start = moment($('#start').val())
      end = moment($('#end').val())
    }

    function cb(start, end) {
      $('#reportrange span').html(start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY'))

      $('#start').val(start.format('MMMM D, YYYY'))
      $('#end').val(end.format('MMMM D, YYYY'))
      $("#report_start_date").val(start.format('MMMM D, YYYY'))
      $("#report_end_date").val(end.format('MMMM D, YYYY'))
    }

    $('#reportrange').daterangepicker({
      startDate: start,
      endDate: end,
      autoUpdateInput: false,
      locale: { cancelLabel: 'Clear' },
      ranges: {
        'Today': [moment(), moment()],
        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
      }
    }, cb)

    $('#reportrange').on('cancel.daterangepicker', function (ev, picker) {
      $('#reportrange span').html('')
      $('#start').val('')
      $('#end').val('')
    })

    cb(start, end)
  },

  handleTotalCountOnClick() {
    $("#total-count").on("click", function (e) {
      $("#inventory_report_name").val('')
      $("#inventory-report-form-submit").trigger('click')
      return true
    })
  },

  handleSubmitInventoryReportForm() {
    $("#inventory-report-form-submit").on("click", function (e) {
      $("#inventory_report_user_id").val($("#report_user_id").val())
      $("#inventory_report_start_date").val($("#report_start_date").val())
      $("#inventory_report_end_date").val($("#report_end_date").val())
    })
  }
}
