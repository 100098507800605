window.portableForm = {
  init() {
    this.handleSavingItemButton()
    this.handleSavingButton()
  },

  handleSavingButton() {
    $('.save-item-btn').on('click', function (e) {
      e.preventDefault()
      var p = $("#item_category_processor").val()
      var h = $("#item_category_screen_size").val()
      var r = $("#item_category_ram").val()
      var m = $("#item_category_memory_slot").val()

      if ((p != "") && (h != "") && (r != "") && (m != "")) {
        $('.edit_item').trigger('submit')
      } else {
        $("#item_category_processor").addClass("red-error")
        $("#item_category_screen_size").addClass("red-error")
        $("#item_category_ram").addClass("red-error")
        $("#item_category_memory_slot").addClass("red-error")
        return false
      }
    })
  },

  handleSavingButton() {
    $('.saving-btn').on('click', function (e) {
      e.preventDefault()
      var p = $("#item_category_processor").val()
      var h = $("#item_category_screen_size").val()
      var r = $("#item_category_ram").val()
      var m = $("#item_category_memory_slot").val()

      if ((p != "") && (h != "") && (r != "") && (m != "")) {
        $('.new_item').trigger('submit')
      } else {
        $("#item_category_processor").addClass("red-error")
        $("#item_category_screen_size").addClass("red-error")
        $("#item_category_ram").addClass("red-error")
        $("#item_category_memory_slot").addClass("red-error")
        return false
      }
    })
  }
}
