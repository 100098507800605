export const fetchLoadWorkOrders = loadModuleIds => {
  return $.ajax({
    url: '/admin/load_work_orders',
    data: {
      load_module_ids: loadModuleIds
    },
    dataType: 'json',
    contentType: 'application/json'
  });
};

export const fetchInboundCustomer = customerId => {
  return $.ajax({
    url: `/admin/inbound_customers/${customerId}`,
    type: 'GET',
    dataType: 'json',
    contentType: 'application/json'
  });
};

export const createAddressDetail = data => {
  const locale = window.commonJS.getLocaleFromUrl();
  const actionUrl = new URL('/address_details', window.location.origin);
  actionUrl.searchParams.set('locale', locale);

  return $.ajax({
    url: actionUrl.toString(),
    method: 'POST',
    data: data
  });
};

export const createContactInboundCustomer = data => {
  return $.ajax({
    url: '/contact_inbound_customers',
    method: 'POST',
    data: data
  });
};

export const fetchLoadItemTypes = () => {
  return $.ajax({
    url: '/admin/load_item_types',
    type: 'GET',
    dataType: 'json',
    contentType: 'application/json'
  });
};
