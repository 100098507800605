export default class WeightTotal {
  constructor(mbf_section) {
    this.mbf_section = mbf_section;
    this.elements = [];
  }

  setup(fixedUom) {
    this.fixedUom = fixedUom;
    this.mbf_section.tables.forEach(table => {
      table.listenerOnWeightChange(() => {
        this.updateTotalWeight();
      });
    });
    this.updateTotalWeight();
  }

  calculateSubtotals() {
    let subtotals = [];

    this.mbf_section.tables.forEach(table => {
      let subtotal = {};
      subtotals.push(subtotal);

      for (let i = 0; i < table.hot.countSourceRows(); i++) {
        let weight = table.hot.getDataAtRowProp(i, 'weight');
        let uom = table.hot.getDataAtRowProp(i, 'uom');

        if (uom && weight && !isNaN(weight)) {
          subtotal[uom] = subtotal[uom] || 0;
          subtotal[uom] += parseFloat(weight);
        }
      }
    });

    return subtotals;
  }

  showSubtotals(subtotals) {
    let tables = this.mbf_section.tables;

    for (let i = 0; i < tables.length; i++) {
      let table = tables[i];
      let subtotal = subtotals[i];
      let tableId = $(table.hot.rootElement).attr('id');
      let container = $(`#weight-total-${tableId}`);
      let value = subtotal[this.fixedUom] || 0;

      if (container.length > 0) {
        $(`#weight-value-${tableId}`).text(
          value.toFixed(2)
        );
      } else {
        container = $(`
          <div class="d-flex flex-column align-items-end mb-3">
            <table class="weight-total-table">
              <tbody>
                <tr>
                  <td id="weight-total-${tableId}">
                    Subtotal ${this.fixedUom}:
                  </td>
                  <td id="weight-value-${tableId}">
                    ${value.toFixed(2)}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        `);
        $(`#${tableId}`).after(container);
      }
      this.elements.push(container);
    }
  }

  updateTotalWeight() {
    let subtotals = this.calculateSubtotals();

    if (this.mbf_section.tables.length > 1) {
      this.showSubtotals(subtotals);
    }
    this.updateMainTotal(subtotals);
  }

  updateMainTotal(subtotals) {
    let total = 0;
    subtotals.forEach(subtotal => {
      total += (subtotal[this.fixedUom] || 0);
    });
    $('#main-weight-total-uom').text(`Total ${this.fixedUom}:`);
    $('#main-weight-total-value').text(total.toFixed(2));
  }

  destroy() {
    this.fixedUom = null;
    this.elements.forEach(el => {
      $(el).remove();
    });
    this.elements = [];
  }
}
