window.shippedOrdersReport = {
  init() {
    window.commonJS.handleCollapseLink()
    this.enableButton()
    this.handleInitializeReportRangeDatePicker()
    // this.handleFilterShippedOrderDataTable()
    // this.handleInitializeDatatable()
  },

  enableButton() {
    $("#generate-excel-report, #generate-report").removeAttr('data-disable-with')
    $("#generate-excel-report, #generate-report").prop('disabled', false)
    $("#generate-excel-report, #generate-report").on('click', function (e) {
      setTimeout(enableButton, 1000)
    })
  },

  handleInitializeReportRangeDatePicker() {
    $('#report-range').on('apply.daterangepicker', function (ev, picker) {
      if (picker.chosenLabel === 'None') {
        $("#report-range span").html('')
        $("#report_start_date").val('')
        $("#report_end_date").val('')
      }

      if (picker.chosenLabel === 'Today') {
        $("#report-range span").html(`${moment().format('MMMM D, YYYY')} - ${moment().format('MMMM D, YYYY')}`)
        $("#report_start_date").val(moment().format('MMMM D, YYYY'))
        $("#report_end_date").val(moment().format('MMMM D, YYYY'))
      }
    })

    if ($("#date-diff").length > 0 && $("#date-diff").attr("data-start-date") && $("#date-diff").attr("data-end-date")) {
      var receivedStartDate = moment($("#date-diff").attr("data-start-date"))
      var receivedEndDate = moment($("#date-diff").attr("data-end-date"))
    } else {
      var receivedStartDate = undefined
      var receivedEndDate = undefined
    }

    function cbReceivedDate(start, end) {
      if (start != null && end != null && start._isValid && end._isValid) {
        $('#report-range span').html(`${start.format('MMMM D, YYYY')} - ${end.format('MMMM D, YYYY')}`)
        $("#report_start_date").val(start.format('MMMM D, YYYY'))
        $("#report_end_date").val(end.format('MMMM D, YYYY'))
      } else {
        $('#report-range span').html('')
        $("#report_start_date").val('')
        $("#report_end_date").val('')
      }
    }

    $('#report-range').daterangepicker({
      startDate: receivedStartDate,
      endDate: receivedEndDate,
      ranges: {
        'None': [moment().subtract(1, 'days'), moment().add(1, 'days')],
        'Today': [moment(), moment()],
        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
      }
    }, cbReceivedDate)

    cbReceivedDate(receivedStartDate, receivedEndDate)
  },

  handleFilterShippedOrderDataTable() {
    $('.shipped-order-filter-btn').on('click', function () {
      $('#shipped-order-dt').DataTable().ajax.reload()
    })
  },

  handleInitializeDatatable() {
    $('#shipped-order-dt').DataTable({
      searching: false,
      pageLength: 20,
      paging: true,
      info: true,
      dom: 'Bfrtip',
      order: [[1, "desc"]],
      buttons: [],
      serverSide: true,
      ajax: window.location.href,
      columns: [
        { data: 'audit_code' },
        { data: 'vendor' },
        { data: 'maker' },
        { data: 'category' },
        { data: 'sub_category' },
        { data: 'serial' },
        {
          data: 'order',
          render: function (data, type) {
            if (data.id) {
              let link = `/admin/audits/${data.id}`

              return `
                <a href="${link}">${data.display_id}</a>
              `
            } else {
              return ''
            }
          }
        },
        { data: 'customer_name' },
        { data: 'customer_province_code' },
        { data: 'shipped_date' }
      ]
    });
  }
}
