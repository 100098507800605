import {toastErrorMessage, toastSuccessMessage} from '../../packs/toast';
import {initializeScopeHot} from './scope_hot';
import {initializeCostRecoveryHot} from './cost_recovery_hot';
import {initializeValueAddedServicesHot} from './value_added_services_hot';
import LocationModal from '../common/modals/add_new_location';
import ContactModal from '../common/modals/add_new_contact';
import {fetchInboundCustomer, fetchLoadItemTypes} from '../common/api_requests';

$(document).on('turbolinks:load', () => {
  if ($('.inbound-quote-form').length === 0) return;

  window.commonJS.initializeDatepicker(
    [
      'inbound_quote_targeted_on'
    ]
  );
  window.commonJS.handleInitializeSelect2();
  window.commonJS.handleInitializeSelect2(
    $('#inbound_quote_status'),
    {
      allowClear: false
    }
  );
  handleInboundCustomerChanged();
  handleInboundQuoteOnSave();
  initializeScopeHot();
  handleCostRecoveryHot();
  handleValueAddedServicesHot();
  handleInboundQuoteNewLocation();
  handleInboundQuoteNewContact();
});

const handleValueAddedServicesHot = () => {
  const onsiteServices = $('#load-onsite-services').data('items');
  initializeValueAddedServicesHot(onsiteServices);
};

const handleCostRecoveryHot = () => {
  const companyName = $('#inbound_quote_inbound_customer_id option:selected').text();
  fetchLoadItemTypes()
    .done(data => {
      const itemTypes = data.map(item => item.full_name);
      initializeCostRecoveryHot(itemTypes, companyName);
    }).fail(error => {
    console.error('Error:', error);
    toastErrorMessage('Something went wrong. Please try again later.');
  });
};

const selectedInboundCustomer = () => {
  return $('#inbound_quote_inbound_customer_id').val();
};

const handleInboundQuoteOnSave = () => {
  $('#save-inbound-quote').click(e => {
    e.preventDefault();

    saveInboundQuote();
  });
};

const saveInboundQuote = () => {
  const $form = $('.inbound-quote-form');
  const formData = $form.serializeArray();
  const actualMethod = $form.find('input[name="_method"]').val() ||
    $form.attr('method');

  $.ajax({
    url: $form.attr('action'),
    type: actualMethod,
    dataType: 'json',
    data: formData,
    headers: {
      'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
    },
    success: response => {
      window.location.href = `/inbound_quotes/${response.id}/edit`;
      toastSuccessMessage('Inbound Quote has been saved successfully');
    },
    error: response => {
      const messages = JSON.parse(response.responseText).errors;
      toastErrorMessage(messages.join(', '));
    }
  });
};

const handleInboundCustomerChanged = () => {
  const $inboundEl = $('#inbound_quote_inbound_customer_id');
  $inboundEl.on('select2:select', e => {
    const selectedOption = e.params.data;
    fetchInboundCustomer(selectedOption.id)
      .done(data => {
        updateLocationOptions(data.address_details);
        updateContactNameOptions(
          $('#inbound_quote_contact_inbound_customer_id'),
          data.contact_inbound_customers
        );
        $('#inbound_quote_user_id')
          .val(data.user.id)
          .trigger('change.select2');
      })
      .fail(error => {
        console.error('Error:', error);
        toastErrorMessage('Something went wrong. Please try again later.');
      });
  });
  $inboundEl.on('select2:clear', _e => {
    [
      $('#inbound_quote_address_detail_id'),
      $('#inbound_quote_contact_inbound_customer_id')
    ].forEach(input => {
      input.empty();
      input.trigger('change.select2');
    });
  });
};

const handleInboundQuoteNewLocation = () => {
  const $el = $('#inbound_quote_address_detail_id');
  const locationModal = new LocationModal(
    addressDetailId => refreshLocationOptions(addressDetailId),
    () => resetLocationSelected()
  );

  $el.on('select2:select', e => {
    const selectedOption = e.params.data;

    if (selectedOption.id !== 'createNewLocation') return;

    locationModal.setInboundCustomerId(selectedInboundCustomer());
    locationModal.showModal();
  });
};

const refreshLocationOptions = addressDetailId => {
  const customerId = selectedInboundCustomer();
  fetchInboundCustomer(customerId)
    .done(data => {
      updateLocationOptions(
        data.address_details,
        addressDetailId
      );
    })
    .fail(error => {
      console.error('Error:', error);
      toastErrorMessage('Something went wrong. Please try again later.');
    });
};

const updateLocationOptions = (addressDetails, selectedId = null) => {
  const selectElement = $('#inbound_quote_address_detail_id');
  const title = selectElement.data('title');
  const emptyOption = new Option(
    '',
    '',
    true,
    true);
  const lastOption = new Option(
    title,
    'createNewLocation',
    false,
    false);
  selectElement.empty();
  selectElement.append(emptyOption);

  addressDetails.forEach(address => {
    const option =
      new Option(address.full_address_with_name,
        address.id,
        false,
        false);
    selectElement.append(option);
  });
  selectElement.append(lastOption);
  selectElement.val(selectedId);
  selectElement.trigger('change.select2');
};

const resetLocationSelected = () => {
  $('#inbound_quote_address_detail_id')
    .val('')
    .trigger('change.select2');
};

const handleInboundQuoteNewContact = () => {
  const $el = $('#inbound_quote_contact_inbound_customer_id');
  const contactModal = new ContactModal(
    createdContactId => refreshContactOptions(createdContactId),
    () => resetContactSelected()
  );

  $el.on('select2:select', e => {
    const selectedOption = e.params.data;

    if (selectedOption.id !== 'createNewContact') return;

    contactModal.setInboundCustomerId(selectedInboundCustomer());
    contactModal.showModal();
  });
};

const resetContactSelected = () => {
  $('#inbound_quote_contact_inbound_customer_id')
    .val('')
    .trigger('change.select2');
};

const refreshContactOptions = (createdContactId) => {
  const customerId = selectedInboundCustomer();
  const $el = $('#inbound_quote_contact_inbound_customer_id');

  fetchInboundCustomer(customerId)
    .done(data => {
      updateContactNameOptions($el, data.contact_inbound_customers);
      $el.val(createdContactId).trigger('change.select2');
    })
    .fail(error => {
      console.error('Error:', error);
      toastErrorMessage('Something went wrong. Please try again later.');
    });
};

const updateContactNameOptions = ($inputEl, contactNames) => {
  const title = $inputEl.data('title');
  const emptyOption = new Option(
    '',
    '',
    true,
    false);
  const lastOption = new Option(
    title,
    'createNewContact',
    false,
    false);
  $inputEl.empty();
  $inputEl.append(emptyOption);

  contactNames.forEach(contact => {
    const displayName = contact.name;
    const option =
      new Option(
        displayName,
        contact.id,
        false,
        false);
    $inputEl.append(option);
  });
  $inputEl.append(lastOption);
  $inputEl.trigger('change.select2');
};
