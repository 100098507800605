window.orderEdit = {
  init() {
    this.handleDisableReasonInputWhenStatusChange()
    this.handleSubmitOrder()
    this.handleItemLineIdChange()
    this.handleItemRemove()
  },

  handleDisableReasonInputWhenStatusChange() {
    $("#order_order_status_id").on('change', function () {
      if ($("#order_order_status_id option:selected").text() === "Cancelled") {
        $("#cancelletion-reason").removeClass('hidden')
        $(".cancelletion-reason-input").prop('required', true)
      }
      else {
        $("#cancelletion-reason").addClass('hidden')
        $(".cancelletion-reason-input").prop('required', false)
      }
    })
  },

  handleItemLineIdChange() {
    $('#add-items-partial').on('change', '.item-line-id', function() {
      var isError = false

      $('.item-line-id').each(function (idx, item) {
        if ($(item).val() == null) {
          return isError = true
        }
      })

      $('.btn-submit').prop('disabled', isError)
    })
  },

  handleSubmitOrder() {
    $('.btn-submit').on('click', function (e) {
      e.preventDefault();
      $(this).prop('disabled', true)
      let isError = false;

      $('.item-line-id').each(function (idx, item) {
        $(item).removeClass('red-error')
        let value = $(item).val();
        $(item).parent().find('.text-danger').addClass('error-line-id')

        if (value === undefined || !value) {
          $(item).addClass('red-error')
          $(item).parent().find('.text-danger').removeClass('error-line-id')
          isError = true;
        }
      })

      if (isError) {
        $(this).prop('disabled', true)
        return false
      }

      $('.quote-line').each(function (idx, item) {
        let qty = $(item).find('.qty').text();
        let quote_line_id = $(item).find('.quote-line-id').text();
        let total = 0;

        $('.item-line-id').each(function (idx, item) {
          if (!item.classList.contains('destroyed') && $(item).val() === quote_line_id) {
            total++
          }
        })

        currentStatus = $('.order_status_id').select2('data')[0].text.toLowerCase()

        if (currentStatus === 'shipped') {
          if (total > qty) {
            $(this).prop('disabled', true)
            alert(`You assigned more items than the quote, please try again!`)
            isError = true
          }
        }
      })

      if (!isError) { $('.order-form').trigger('submit') }
    })
  },

  handleItemRemove() {
    $('#add-items-partial').on('click', '.remove_fields', function (e) {
      $(e.target).parents('.box-nested').find('.item-line-id').addClass('destroyed')
      $('.btn-submit').prop('disabled', false)
    })
  }
}
