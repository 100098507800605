window.itemEditable = {
  init() {
    window.commonJS.handleCollapseLink()
    this.handleInitializeDataTable()
    this.handleSelectAllItem()
    this.handleDisabledEditable()
    this.handleDetailView()
    this.handleEditAll()
    this.handleDataEditItemColumn()
    this.handlePrintBarcode()
    this.handleExportItems()
    this.handleItemIds()
  },

  handleExportItems() {
    $('.export-button').on('click', function (e) {
      $('#overlay').addClass('d-flex')

      var searchParams = new URLSearchParams(location.search)
      var itemParams = {}

      for (const key of searchParams.keys()) {
        if (key == 'item[status_id][]') {
          itemParams[`${key}`] = searchParams.getAll(key)
        } else {
          itemParams[`${key}`] = searchParams.get(key)
        }
      }

      itemParams['export_format'] = $(this).attr('data-export-format');

      $.ajax({
        type: 'GET',
        url: `/admin/items/export_editable.json`,
        data: itemParams,
        success: data => {
          if (data.background_job_id) {
            this.checkReportStatus = setInterval(
              this.handleCheckExportStatus,
              5000,
              data.background_job_id
            )
          } else {
            $('#overlay').removeClass('d-flex');
            error_message(data.error_message);
          }
        }
      });
    })
  },

  handleCheckExportStatus(background_job_id) {
    $.ajax({
      type: 'GET',
      url: `/admin/background_jobs/${background_job_id}/status.json`,
      success: data => {
        if (data.status === 'done' || data.status === 'failed') {
          $('#overlay').removeClass('d-flex');
          clearInterval(this.checkReportStatus);

          if (data.status === 'done') {
            window.location = `/admin/background_jobs/${background_job_id}/download_file`;
          } else if (data.status === 'failed') {
            error_message(data.error_message);
          }
        }
      }
    });
  },

  handleInitializeDataTable() {
    $('#select_all').on('click', function () {
      $('.item_checkbox').prop('checked', $(this).prop("checked"))
    })

    var page = parseInt($('#search_index').val()) || 1

    inventoryTable = $('#item_dt').DataTable({
      searching: false,
      displayStart: (page - 1) * 50,
      pageLength: 50,
      paging: true,
      info: true,
      processing: true,
      serverSide: true,
      dom: "<'row'<'col-sm-12'f>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'row'<'col-sm-12'i><'col-sm-12'p>>",
      ajax: {
        url: window.location.href,
        dataSrc: function (json) {
          inventoryTableData = json;
          return json.data;
        }
      },
      drawCallback: function () {
        $.getScript(`${inventoryTable.ajax.url()}?${$.param(inventoryTable.ajax.params())}`);
      },
      createdRow: function (row, data, dataIndex) {
        $(row).addClass(`js-editable-item-row-${data.show}`);
      },
      columns: [
        {
          data: 'check',
          orderable: false,
          render: function (data, type) {
            if (data) {
              return `
                <input
                  type="checkbox"
                  name="item"
                  id="${data}"
                  value="1"
                  class="item_checkbox"
                >
              `
            } else {
              return ''
            }
          }
        },
        {
          data: 'destroy',
          render: function (data, type) {
            if (data.id) {
              let link = `/admin/items/${data.id}`

              return `
                <a data-confirm="Are you sure?" class="btn code-red btn-xs" rel="nofollow" data-method="delete" href="${link}">
                  <i class="fas fa-trash-alt"></i>
                </a>
              `
            } else {
              return ''
            }
          }
        },
        {
          data: 'show',
          render: function (data, type) {
            if (data) {
              let link = `/admin/items/${data}`

              return `
                <a href="${link}">${data}</a>
              `
            } else {
              return ''
            }
          }
        },
        {
          data: 'serial',
          render: function (data, type) {
            if (data.code) {
              return `
                <div class="edit-text" data-col="serial" data-id="${data.id}" id="${data.id}-serial">
                  <p>${data.code}</p>
                </div>
                <div class="edit-input hidden" id="${data.id}-serial">
                  <input type="hidden" name="item[id]" id="item_id" value="${data.id}">
                  <input type="hidden" name="item[key]" id="item_key" value="serial">
                  <input type="text" name="item[value]" id="item_value" value="${data.code}" class="upper-case upper" autocomplete="off">
                  <div class="btn-group float-right">
                    <button class="btn btn-xs btn-warning cancel-input" data-col="serial" id="${data.id}">
                      <i class="fas fa-times"></i>
                    </button>
                    <button class="btn btn-xs btn-primary save-item" data-col="serial" id="${data.id}">
                      <i class="fas fa-check"></i>
                    </button>
                  </div>
                </div>
              `
            } else {
              return ''
            }

          }
        },
        {
          data: 'category',
          render: function (data, type) {
            let selectOptions = window.itemEditable.renderSelectOption(inventoryTableData.categories, data.category)

            if (data.category) {
              return `
                <div class="edit-text" data-col="category" data-id="${data.id}" id="${data.id}-category">
                  <p class="item_category_id" data-id="${data.category_id}">${data.category}</p>
                </div>
                <div class="edit-input hidden" id="${data.id}-category">
                  <input type="hidden" name="item[id]" id="item_id" value="${data.id}">
                  <input type="hidden" name="item[key]" id="item_key" value="category_id">
                  <select name="item[value]" id="item_value">
                    ${selectOptions}
                  </select>
                  <div class="btn-group float-right">
                    <button class="btn btn-xs btn-warning cancel-input" data-col="category" id="${data.id}">
                      <i class="fas fa-times"></i>
                    </button>
                    <button class="btn btn-xs btn-primary save-item" data-col="category" id="${data.id}">
                      <i class="fas fa-check"></i>
                    </button>
                  </div>
                </div>
              `
            } else {
              return ''
            }
          }
        },
        {
          data: 'sub_category',
          render: function (data, type) {
            return `
              <div class="edit-text" data-col="sub-category" data-id="${data.id}" id="${data.id}-sub-category">
                <p>${data.sub_category.length > 0 ? data.sub_category : ''}</p>
              </div>
              <div class="edit-input hidden" id="${data.id}-sub-category">
                <input type="hidden" name="item[id]" id="item_id" value="${data.id}">
                <input type="hidden" name="item[key]" id="item_key" value="sub_category_id">
                <select name="item[value]" id="item_value" class="${data.id}-subcategory-select hidden-subcategory-select"></select>
                <div class="btn-group float-right">
                  <button class="btn btn-xs btn-warning cancel-input" data-col="sub-category" id="${data.id}">
                    <i class="fas fa-times"></i>
                  </button>
                  <button class="btn btn-xs btn-primary save-item" data-col="sub-category" id="${data.id}">
                    <i class="fas fa-check"></i>
                  </button>
                </div>
              </div>
            `
          }
        },
        {
          data: 'make',
          render: function (data, type) {
            return `
              <div class="edit-text" data-col="maker" data-id="${data.id}" id="${data.id}-maker">
                <p class="item_maker_id" data-id="${data.maker_id}">${data.maker.length > 0 ? data.maker : ''}</p>
              </div>
              <div class="edit-input hidden" id="${data.id}-maker">
                <input type="hidden" name="item[id]" id="item_id" value="${data.id}">
                <input type="hidden" name="item[key]" id="item_key" value="maker_id">
                <select name="item[value]" id="item_value">
                  ${window.itemEditable.renderSelectOption(inventoryTableData.makers, data.maker)}
                </select>
                <div class="btn-group float-right">
                  <button class="btn btn-xs btn-warning cancel-input" data-col="maker" id="${data.id}">
                    <i class="fas fa-times"></i>
                  </button>
                  <button class="btn btn-xs btn-primary save-item" data-col="maker" id="${data.id}">
                    <i class="fas fa-check"></i>
                  </button>
                </div>
              </div>
            `
          }
        },
        {
          data: 'maker_model',
          render: function (data, type) {
            return `
              <div class="edit-text" data-col="maker-model" data-id="${data.id}" id="${data.id}-maker-model">
                <p id="item_value">${data.maker_model}</p>
              </div>
              <div class="edit-input hidden" id="${data.id}-maker-model">
                <input type="hidden" name="item[id]" id="item_id" value="${data.id}">
                <input type="hidden" name="item[key]" id="item_key" value="maker_model_id">
                <select name="item[value]" id="item_value" class="${data.id}-makermodel-select"></select>
                <div class="btn-group float-right">
                  <button class="btn btn-xs btn-warning cancel-input" data-col="maker-model" id="${data.id}">
                    <i class="fas fa-times"></i>
                  </button>
                  <button class="btn btn-xs btn-primary save-item" data-col="maker-model" id="${data.id}">
                    <i class="fas fa-check"></i>
                  </button>
                </div>
              </div>
            `
          }
        },
        {
          data: 'condition',
          render: function (data, type) {
            return window.itemEditable.renderSelectEditableTemplate(data, 'condition', inventoryTableData.condition_types)
          }
        },
        {
          data: 'status',
          render: function (data, type) {
            return window.itemEditable.renderSelectEditableTemplate(data, 'status', inventoryTableData.statuses)
          }
        },
        {
          data: 'grade',
          render: function (data, type) {
            return window.itemEditable.renderSelectEditableTemplate(data, 'grade', inventoryTableData.grades)
          }
        },
        {
          data: 'description',
          render: function (data, type) {
            return `
              <div class="edit-text" data-col="description" data-id="${data.id}" id="${data.id}-description">
                <p>${data.description}</p>
              </div>
              <div class="edit-input hidden" id="${data.id}-description">
                <input type="hidden" name="item[id]" id="item_id" value="${data.id}">
                <input type="hidden" name="item[key]" id="item_key" value="description">
                <textarea name="item[value]" id="item_value">${data.description}</textarea>
                <div class="btn-group float-right">
                  <button class="btn btn-xs btn-warning cancel-input" data-col="description" id="${data.id}">
                    <i class="fas fa-times"></i>
                  </button>
                  <button class="btn btn-xs btn-primary save-item" data-col="description" id="${data.id}">
                    <i class="fas fa-check"></i>
                  </button>
                </div>
              </div>
            `
          }
        },
        { data: 'audit' },
        { data: 'audited_date' },
        {
          data: 'quote_no',
          render: function (data, type) {
            return window.itemEditable.renderSelectEditableTemplate(data, 'quote', inventoryTableData.quotes)
          }
        },
        {
          data: 'order',
          render: function (data, type) {
            return window.itemEditable.renderSelectEditableTemplate(data, 'order', inventoryTableData.orders)
          }
        },
        {
          data: 'purchase_no',
          render: function (data, type) {
            return window.itemEditable.renderSelectEditableTemplate(data, 'purchase-order', inventoryTableData.purchase_orders)
          }
        },
        {
          data: 'location',
          render: function (data, type) {
            return window.itemEditable.renderSelectEditableTemplate(data, 'warehouse', inventoryTableData.warehouses)
          }
        },
        {
          data: 'cost',
          render: function (data, type) {
            return window.itemEditable.renderInputEditableTemplate(data, 'cost')
          }
        },
        {
          data: 'hard_disk',
          render: function (data, type) {
            return window.itemEditable.renderInputEditableTemplate(data, 'hdd')
          }
        },
        {
          data: 'ram',
          render: function (data, type) {
            return window.itemEditable.renderInputEditableTemplate(data, 'ram')
          }
        },
        {
          data: 'notes',
          render: function (data, type) {
            return window.itemEditable.renderInputEditableTemplate(data, 'notes')
          }
        }
      ]
    })
  },

  renderSelectOption(collection, selected) {
    let selectOptions = ''

    for (const item of collection) {
      selectOptions += `<option value="${item.id}" ${item.name == selected ? 'selected' : ''}>${item.name || item.id}</option>`
    }

    return selectOptions
  },

  renderSelectEditableTemplate(data, model) {
    return `
      <div class="edit-text" data-col="${model}" data-id="${data.id}" id="${data.id}-${model}">
        <p>${data.item}</p>
      </div>
      <div class="edit-input hidden" id="${data.id}-${model}">
        <input type="hidden" name="item[id]" id="item_id" value="${data.id}" class="">
        <input type="hidden" name="item[key]" id="item_key" value="${model}_id" class="">
        <select name="item[value]" id="item_value" class="">
          ${window.itemEditable.renderSelectOption(data.items, data.item)}
        </select>
        <div class="btn-group float-right">
          <button class="btn btn-xs btn-warning cancel-input" data-col="${model}" id="${data.id}">
            <i class="fas fa-times"></i>
          </button>
          <button class="btn btn-xs btn-primary save-item" data-col="${model}" id="${data.id}">
            <i class="fas fa-check"></i>
          </button>
        </div>
      </div>
    `
  },

  renderInputEditableTemplate(data, model) {
      return `
      <div class="edit-text" data-col="${model}" data-id="${data.id}" id="${data.id}-${model}">
        <input class="text-value" value="${data.item}">
      </div>
      <div class="edit-input hidden" id="${data.id}-${model}">
        <input type="hidden" name="item[id]" id="item_id" value="${data.id}">
        <input type="hidden" name="item[key]" id="item_key" value="${model}">
        <input type="text" name="item[value]" id="item_value" value="${data.item}" autocomplete="off">
        <div class="btn-group float-right">
          <button class="btn btn-xs btn-warning cancel-input" data-col="${model}" id="${data.id}">
            <i class="fas fa-times"></i>
          </button>
          <button class="btn btn-xs btn-primary save-item" data-col="${model}" id="${data.id}">
            <i class="fas fa-check"></i>
          </button>
        </div>
      </div>
    `
  },

  handleSelectAllItem() {
    $('#select_all').on('click', function() {
      $('.item_checkbox').prop('checked', $(this).prop("checked"))
      $('.item_checkbox').trigger('change')
    })
  },

  handleDisabledEditable() {
    $('#disabled-editable').on('click', function (e) {
      var url = location.search
      var urlprev = window.itemEditable.handleRemoveParam("prev", url)
      var urlnext = window.itemEditable.handleRemoveParam("next", urlprev)
      window.location.href = `/admin/items/lists${urlnext}`
    })
  },

  handleDetailView() {
    $('#detailedView').on('click', function (e) {
      window.location.href = `/admin/items${location.search}`
    })
  },

  handleEditAll() {
    $(document).on('keydown', function ( e ) {
      if ((e.metaKey || e.ctrlKey) && ( String.fromCharCode(e.which).toLowerCase() === 'm') ) {
        $('#editAll').modal('show')

        var checkedIds = $('#item-ids').val()

        if ($('input:checkbox:checked').length > 0) { $("#available_item_ids").val(checkedIds) }
      }
    })
  },

  handleDataEditItemColumn() {
    $("#data_edit_item_column").on('change', function() {
      var val = $(this).val()

      if( val == "hard_disk" ) {
        $(".hard_disk_val_id").removeClass("hidden")
        $(".hard_disk_val_id").addClass("active")

        $(".ram_val_id").addClass("hidden")
        $(".status_val_id").addClass("hidden")
        $(".condition_val_id").addClass("hidden")
        $(".grade_val_id").addClass("hidden")
        $(".maker_val_id").addClass("hidden")
        $(".category_val_id").addClass("hidden")
        $(".partner_number_val").addClass("hidden")
        $(".maker_model_val_id").addClass("hidden")
        $(".sub_category_val_id").addClass("hidden")
        $(".warehouse_val_id").addClass("hidden")
        $(".vendor_val_id").addClass("hidden")
        $(".lot_val_id").addClass("hidden")
        $(".cost_val").addClass("hidden")

        $(".ram_val_id").removeClass("active")
        $(".status_val_id").removeClass("active")
        $(".condition_val_id").removeClass("active")
        $(".grade_val_id").removeClass("active")
        $(".maker_val_id").removeClass("active")
        $(".category_val_id").removeClass("active")
        $(".partner_number_val").removeClass("active")
        $(".maker_model_val_id").removeClass("active")
        $(".sub_category_val_id").removeClass("active")
        $(".warehouse_val_id").removeClass("active")
        $(".vendor_val_id").removeClass("active")
        $(".lot_val_id").removeClass("active")
        $(".cost_val").removeClass("active")
      }

      else if( val == "ram" ) {
        $(".ram_val_id").removeClass("hidden")
        $(".ram_val_id").addClass("active")

        $(".hard_disk_val_id").addClass("hidden")
        $(".status_val_id").addClass("hidden")
        $(".condition_val_id").addClass("hidden")
        $(".grade_val_id").addClass("hidden")
        $(".maker_val_id").addClass("hidden")
        $(".category_val_id").addClass("hidden")
        $(".partner_number_val").addClass("hidden")
        $(".maker_model_val_id").addClass("hidden")
        $(".sub_category_val_id").addClass("hidden")
        $(".warehouse_val_id").addClass("hidden")
        $(".vendor_val_id").addClass("hidden")
        $(".lot_val_id").addClass("hidden")
        $(".cost_val").addClass("hidden")

        $(".hard_disk_val_id").removeClass("active")
        $(".status_val_id").removeClass("active")
        $(".condition_val_id").removeClass("active")
        $(".grade_val_id").removeClass("active")
        $(".maker_val_id").removeClass("active")
        $(".category_val_id").removeClass("active")
        $(".partner_number_val").removeClass("active")
        $(".maker_model_val_id").removeClass("active")
        $(".sub_category_val_id").removeClass("active")
        $(".warehouse_val_id").removeClass("active")
        $(".vendor_val_id").removeClass("active")
        $(".lot_val_id").removeClass("active")
        $(".cost_val").removeClass("active")
      }

      else if( val == "status_id" ) {
        $(".status_val_id").removeClass("hidden")
        $(".status_val_id").addClass("active")

        $(".hard_disk_val_id").addClass("hidden")
        $(".ram_val_id").addClass("hidden")
        $(".condition_val_id").addClass("hidden")
        $(".grade_val_id").addClass("hidden")
        $(".maker_val_id").addClass("hidden")
        $(".category_val_id").addClass("hidden")
        $(".partner_number_val").addClass("hidden")
        $(".maker_model_val_id").addClass("hidden")
        $(".sub_category_val_id").addClass("hidden")
        $(".warehouse_val_id").addClass("hidden")
        $(".vendor_val_id").addClass("hidden")
        $(".lot_val_id").addClass("hidden")

        $(".hard_disk_val_id").removeClass("active")
        $(".ram_val_id").removeClass("active")
        $(".condition_val_id").removeClass("active")
        $(".grade_val_id").removeClass("active")
        $(".maker_val_id").removeClass("active")
        $(".category_val_id").removeClass("active")
        $(".partner_number_val").removeClass("active")
        $(".maker_model_val_id").removeClass("active")
        $(".sub_category_val_id").removeClass("active")
        $(".warehouse_val_id").removeClass("active")
        $(".vendor_val_id").removeClass("active")
        $(".lot_val_id").removeClass("active")
        $(".cost_val").addClass("hidden")
        $(".cost_val").removeClass("active")

      } else if (val == "condition_type_id") {
        $(".condition_val_id").removeClass("hidden")
        $(".condition_val_id").addClass("active")

        $(".hard_disk_val_id").addClass("hidden")
        $(".ram_val_id").addClass("hidden")
        $(".status_val_id").addClass("hidden")
        $(".grade_val_id").addClass("hidden")
        $(".maker_val_id").addClass("hidden")
        $(".category_val_id").addClass("hidden")
        $(".partner_number_val").addClass("hidden")
        $(".maker_model_val_id").addClass("hidden")
        $(".sub_category_val_id").addClass("hidden")
        $(".warehouse_val_id").addClass("hidden")
        $(".vendor_val_id").addClass("hidden")
        $(".lot_val_id").addClass("hidden")

        $(".hard_disk_val_id").removeClass("active")
        $(".ram_val_id").removeClass("active")
        $(".status_val_id").removeClass("active")
        $(".grade_val_id").removeClass("active")
        $(".maker_val_id").removeClass("active")
        $(".category_val_id").removeClass("active")
        $(".partner_number_val").removeClass("active")
        $(".maker_model_val_id").removeClass("active")
        $(".sub_category_val_id").removeClass("active")
        $(".warehouse_val_id").removeClass("active")
        $(".vendor_val_id").removeClass("active")
        $(".lot_val_id").removeClass("active")
        $(".cost_val").addClass("hidden")
        $(".cost_val").removeClass("active")

      } else if (val == "grade_id") {
        $(".grade_val_id").removeClass("hidden")
        $(".grade_val_id").addClass("active")

        $(".hard_disk_val_id").addClass("hidden")
        $(".ram_val_id").addClass("hidden")
        $(".status_val_id").addClass("hidden")
        $(".condition_val_id").addClass("hidden")
        $(".maker_val_id").addClass("hidden")
        $(".category_val_id").addClass("hidden")
        $(".partner_number_val").addClass("hidden")
        $(".maker_model_val_id").addClass("hidden")
        $(".sub_category_val_id").addClass("hidden")
        $(".warehouse_val_id").addClass("hidden")
        $(".vendor_val_id").addClass("hidden")
        $(".lot_val_id").addClass("hidden")

        $(".hard_disk_val_id").removeClass("active")
        $(".ram_val_id").removeClass("active")
        $(".status_val_id").removeClass("active")
        $(".condition_val_id").removeClass("active")
        $(".maker_val_id").removeClass("active")
        $(".category_val_id").removeClass("active")
        $(".partner_number_val").removeClass("active")
        $(".maker_model_val_id").removeClass("active")
        $(".sub_category_val_id").removeClass("active")
        $(".warehouse_val_id").removeClass("active")
        $(".vendor_val_id").removeClass("active")
        $(".lot_val_id").removeClass("active")
        $(".cost_val").addClass("hidden")
        $(".cost_val").removeClass("active")

      } else if (val == "maker_id") {
        $(".maker_val_id").removeClass("hidden")
        $(".maker_val_id").addClass("active")

        $(".hard_disk_val_id").addClass("hidden")
        $(".ram_val_id").addClass("hidden")
        $(".status_val_id").addClass("hidden")
        $(".condition_val_id").addClass("hidden")
        $(".grade_val_id").addClass("hidden")
        $(".category_val_id").addClass("hidden")
        $(".partner_number_val").addClass("hidden")
        $(".maker_model_val_id").addClass("hidden")
        $(".sub_category_val_id").addClass("hidden")
        $(".warehouse_val_id").addClass("hidden")
        $(".vendor_val_id").addClass("hidden")
        $(".lot_val_id").addClass("hidden")

        $(".hard_disk_val_id").removeClass("active")
        $(".ram_val_id").removeClass("active")
        $(".status_val_id").removeClass("active")
        $(".condition_val_id").removeClass("active")
        $(".grade_val_id").removeClass("active")
        $(".category_val_id").removeClass("active")
        $(".maker_model_val_id").removeClass("active")
        $(".partner_number_val").removeClass("active")
        $(".sub_category_val_id").removeClass("active")
        $(".warehouse_val_id").removeClass("active")
        $(".vendor_val_id").removeClass("active")
        $(".lot_val_id").removeClass("active")
        $(".cost_val").addClass("hidden")
        $(".cost_val").removeClass("active")

      } else if (val == "category_id") {
        $(".category_val_id").removeClass("hidden")
        $(".category_val_id").addClass("active")

        $(".hard_disk_val_id").addClass("hidden")
        $(".ram_val_id").addClass("hidden")
        $(".status_val_id").addClass("hidden")
        $(".condition_val_id").addClass("hidden")
        $(".grade_val_id").addClass("hidden")
        $(".maker_val_id").addClass("hidden")
        $(".maker_model_val_id").addClass("hidden")
        $(".partner_number_val").addClass("hidden")
        $(".sub_category_val_id").addClass("hidden")
        $(".warehouse_val_id").addClass("hidden")
        $(".vendor_val_id").addClass("hidden")
        $(".lot_val_id").addClass("hidden")

        $(".hard_disk_val_id").removeClass("active")
        $(".ram_val_id").removeClass("active")
        $(".status_val_id").removeClass("active")
        $(".condition_val_id").removeClass("active")
        $(".grade_val_id").removeClass("active")
        $(".maker_val_id").removeClass("active")
        $(".partner_number_val").removeClass("active")
        $(".maker_model_val_id").removeClass("active")
        $(".sub_category_val_id").removeClass("active")
        $(".warehouse_val_id").removeClass("active")
        $(".vendor_val_id").removeClass("active")
        $(".lot_val_id").removeClass("active")
        $(".cost_val").addClass("hidden")
        $(".cost_val").removeClass("active")

      } else if (val == "part_number" ) {
        $(".partner_number_val").removeClass("hidden")
        $(".partner_number_val").addClass("active")

        $(".hard_disk_val_id").addClass("hidden")
        $(".ram_val_id").addClass("hidden")
        $(".status_val_id").addClass("hidden")
        $(".condition_val_id").addClass("hidden")
        $(".grade_val_id").addClass("hidden")
        $(".maker_val_id").addClass("hidden")
        $(".category_val_id").addClass("hidden")
        $(".maker_model_val_id").addClass("hidden")
        $(".sub_category_val_id").addClass("hidden")
        $(".warehouse_val_id").addClass("hidden")
        $(".vendor_val_id").addClass("hidden")
        $(".lot_val_id").addClass("hidden")

        $(".hard_disk_val_id").removeClass("active")
        $(".ram_val_id").removeClass("active")
        $(".status_val_id").removeClass("active")
        $(".condition_val_id").removeClass("active")
        $(".grade_val_id").removeClass("active")
        $(".maker_val_id").removeClass("active")
        $(".category_val_id").removeClass("active")
        $(".maker_model_val_id").removeClass("active")
        $(".sub_category_val_id").removeClass("active")
        $(".warehouse_val_id").removeClass("active")
        $(".vendor_val_id").removeClass("active")
        $(".lot_val_id").removeClass("active")
        $(".cost_val").addClass("hidden")
        $(".cost_val").removeClass("active")

      } else if (val == "maker_model_id" ) {
        $(".maker_model_val_id").removeClass("hidden")
        $(".maker_model_val_id").addClass("active")

        $(".hard_disk_val_id").addClass("hidden")
        $(".ram_val_id").addClass("hidden")
        $(".status_val_id").addClass("hidden")
        $(".condition_val_id").addClass("hidden")
        $(".grade_val_id").addClass("hidden")
        $(".maker_val_id").addClass("hidden")
        $(".category_val_id").addClass("hidden")
        $(".partner_number_val").addClass("hidden")
        $(".sub_category_val_id").addClass("hidden")
        $(".warehouse_val_id").addClass("hidden")
        $(".vendor_val_id").addClass("hidden")
        $(".lot_val_id").addClass("hidden")

        $(".hard_disk_val_id").removeClass("active")
        $(".ram_val_id").removeClass("active")
        $(".status_val_id").removeClass("active")
        $(".condition_val_id").removeClass("active")
        $(".grade_val_id").removeClass("active")
        $(".maker_val_id").removeClass("active")
        $(".category_val_id").removeClass("active")
        $(".partner_number_val").removeClass("active")
        $(".sub_category_val_id").removeClass("active")
        $(".warehouse_val_id").removeClass("active")
        $(".vendor_val_id").removeClass("active")
        $(".lot_val_id").removeClass("active")
        $(".cost_val").addClass("hidden")
        $(".cost_val").removeClass("active")

      } else if (val == "sub_category_id" ) {
        $(".sub_category_val_id").removeClass("hidden")
        $(".sub_category_val_id").addClass("active")

        $(".hard_disk_val_id").addClass("hidden")
        $(".ram_val_id").addClass("hidden")
        $(".status_val_id").addClass("hidden")
        $(".condition_val_id").addClass("hidden")
        $(".grade_val_id").addClass("hidden")
        $(".maker_val_id").addClass("hidden")
        $(".category_val_id").addClass("hidden")
        $(".partner_number_val").addClass("hidden")
        $(".maker_model_val_id").addClass("hidden")
        $(".warehouse_val_id").addClass("hidden")
        $(".vendor_val_id").addClass("hidden")
        $(".lot_val_id").addClass("hidden")

        $(".hard_disk_val_id").removeClass("active")
        $(".ram_val_id").removeClass("active")
        $(".status_val_id").removeClass("active")
        $(".condition_val_id").removeClass("active")
        $(".grade_val_id").removeClass("active")
        $(".maker_val_id").removeClass("active")
        $(".category_val_id").removeClass("active")
        $(".partner_number_val").removeClass("active")
        $(".maker_model_val_id").removeClass("active")
        $(".warehouse_val_id").removeClass("active")
        $(".vendor_val_id").removeClass("active")
        $(".lot_val_id").removeClass("active")
        $(".cost_val").addClass("hidden")
        $(".cost_val").removeClass("active")

      } else if (val == "warehouse_id" ) {
        console.log(val)
        $(".warehouse_val_id").removeClass("hidden")
        $(".warehouse_val_id").addClass("active")

        $(".hard_disk_val_id").addClass("hidden")
        $(".ram_val_id").addClass("hidden")
        $(".status_val_id").addClass("hidden")
        $(".condition_val_id").addClass("hidden")
        $(".grade_val_id").addClass("hidden")
        $(".maker_val_id").addClass("hidden")
        $(".category_val_id").addClass("hidden")
        $(".partner_number_val").addClass("hidden")
        $(".maker_model_val_id").addClass("hidden")
        $(".sub_category_val_id").addClass("hidden")

        $(".hard_disk_val_id").removeClass("active")
        $(".ram_val_id").removeClass("active")
        $(".status_val_id").removeClass("active")
        $(".condition_val_id").removeClass("active")
        $(".grade_val_id").removeClass("active")
        $(".maker_val_id").removeClass("active")
        $(".category_val_id").removeClass("active")
        $(".partner_number_val").removeClass("active")
        $(".maker_model_val_id").removeClass("active")
        $(".sub_category_val_id").removeClass("active")
        $(".vendor_val_id").addClass("hidden")
        $(".lot_val_id").addClass("hidden")
        $(".vendor_val_id").removeClass("active")
        $(".lot_val_id").removeClass("active")
        $(".cost_val").addClass("hidden")
        $(".cost_val").removeClass("active")

      } else if (val == "vendor_id" ) {
        $(".vendor_val_id").removeClass("hidden")
        $(".vendor_val_id").addClass("active")

        $(".hard_disk_val_id").addClass("hidden")
        $(".ram_val_id").addClass("hidden")
        $(".status_val_id").addClass("hidden")
        $(".condition_val_id").addClass("hidden")
        $(".grade_val_id").addClass("hidden")
        $(".maker_val_id").addClass("hidden")
        $(".category_val_id").addClass("hidden")
        $(".partner_number_val").addClass("hidden")
        $(".maker_model_val_id").addClass("hidden")
        $(".sub_category_val_id").addClass("hidden")
        $(".warehouse_val_id").addClass("hidden")

        $(".hard_disk_val_id").removeClass("active")
        $(".ram_val_id").removeClass("active")
        $(".status_val_id").removeClass("active")
        $(".condition_val_id").removeClass("active")
        $(".grade_val_id").removeClass("active")
        $(".maker_val_id").removeClass("active")
        $(".category_val_id").removeClass("active")
        $(".partner_number_val").removeClass("active")
        $(".maker_model_val_id").removeClass("active")
        $(".sub_category_val_id").removeClass("active")
        $(".warehouse_val_id").removeClass("active")
        $(".cost_val").addClass("hidden")
        $(".cost_val").removeClass("active")

      }else if (val == "lot_id" ) {
        $(".lot_val_id").removeClass("hidden")
        $(".lot_val_id").addClass("active")

        $(".hard_disk_val_id").addClass("hidden")
        $(".ram_val_id").addClass("hidden")
        $(".status_val_id").addClass("hidden")
        $(".condition_val_id").addClass("hidden")
        $(".grade_val_id").addClass("hidden")
        $(".maker_val_id").addClass("hidden")
        $(".category_val_id").addClass("hidden")
        $(".partner_number_val").addClass("hidden")
        $(".maker_model_val_id").addClass("hidden")
        $(".sub_category_val_id").addClass("hidden")
        $(".warehouse_val_id").addClass("hidden")
        $(".vendor_val_id").addClass("hidden")
        $(".vendor_val_id").removeClass("active")

        $(".hard_disk_val_id").removeClass("active")
        $(".ram_val_id").removeClass("active")
        $(".status_val_id").removeClass("active")
        $(".condition_val_id").removeClass("active")
        $(".grade_val_id").removeClass("active")
        $(".maker_val_id").removeClass("active")
        $(".category_val_id").removeClass("active")
        $(".partner_number_val").removeClass("active")
        $(".maker_model_val_id").removeClass("active")
        $(".sub_category_val_id").removeClass("active")
        $(".warehouse_val_id").removeClass("active")
        $(".cost_val").addClass("hidden")
        $(".cost_val").removeClass("active")

      } else if (val == "cost" ) {
        $(".cost_val").removeClass("hidden")
        $(".cost_val").addClass("active")

        $(".hard_disk_val_id").addClass("hidden")
        $(".ram_val_id").addClass("hidden")
        $(".status_val_id").addClass("hidden")
        $(".condition_val_id").addClass("hidden")
        $(".grade_val_id").addClass("hidden")
        $(".maker_val_id").addClass("hidden")
        $(".category_val_id").addClass("hidden")
        $(".partner_number_val").addClass("hidden")
        $(".maker_model_val_id").addClass("hidden")
        $(".sub_category_val_id").addClass("hidden")
        $(".warehouse_val_id").addClass("hidden")

        $(".hard_disk_val_id").removeClass("active")
        $(".ram_val_id").removeClass("active")
        $(".status_val_id").removeClass("active")
        $(".condition_val_id").removeClass("active")
        $(".grade_val_id").removeClass("active")
        $(".maker_val_id").removeClass("active")
        $(".category_val_id").removeClass("active")
        $(".partner_number_val").removeClass("active")
        $(".maker_model_val_id").removeClass("active")
        $(".sub_category_val_id").removeClass("active")
        $(".warehouse_val_id").removeClass("active")
        $(".vendor_val_id").addClass("hidden")
        $(".lot_val_id").addClass("hidden")
        $(".vendor_val_id").removeClass("active")
        $(".lot_val_id").removeClass("active")
      }

      else {
        ""
      }
    })
  },

  handleRemoveParam(key, sourceURL) {
    var rtn = sourceURL.split("?")[0],
        param,
        params_arr = [],
        queryString = (sourceURL.indexOf("?") !== -1) ? sourceURL.split("?")[1] : "";

    if (queryString !== "") {
        params_arr = queryString.split("&");
        for (var i = params_arr.length - 1; i >= 0; i -= 1) {
            param = params_arr[i].split("=")[0];
            if (param === key) {
                params_arr.splice(i, 1);
            }
        }
        rtn = rtn + "?" + params_arr.join("&");
    }
    return rtn;
  },

  handlePrintBarcode() {
    $('.print-barcode').on('click', function() {
      printJS({
        printable: "multi-barcodes",
        type: "html",
        style: "@page { margin: 0; } #multi-barcodes {margin-top: 10px; text-align: center; max-height: 3cm !important; font-size: 12px;} .generated {margin: 15px 0 10px 0; display: block; page-break-before: always;} .barcode-image {display: block; margin-left: auto; margin-right: auto;} img { width: 150px;}"
      });
    })
  },

  handleItemIds() {
    $('#editable-table table').on('change', ':checkbox', function(e) {
      let item_ids = $('#item-ids').val().length > 0 ? $('#item-ids').val().split(',') : []

      if (e.target.checked) {
        $.each($('#editable-table table tbody input:checked'), function (index, item) { item_ids.push(item.id) })
      } else {
        item_ids = $.grep(item_ids, function (value) { return value != e.target.id })
      }

      $('#item-ids').val([...new Set(item_ids)])

      $.each($('#editable-table .pagination a'), function (index, element) {
        var url = new URL(element.href)
        var pathName = url.pathname

        var params = new URLSearchParams(url.search)
        params.set('item_ids', [...new Set(item_ids)])

        $(element).attr('href', `${pathName}?${params.toString()}`)
      })
    })
  }
}
