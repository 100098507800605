import './common/common'
import './common/direct_upload'

import './audits/filter'
import './audits/form'
import './audits/index'
import './audits/show'

import './cities/form'

import './companies/index'

import './customers/form'

import './dashboard/index'

import './grades/form'

import './import_data/index'

import './items/desktop_form'
import './items/edit'
import './items/editable'
import './items/filter'
import './items/filter_editable'
import './items/filter_form'
import './items/form'
import './items/index'
import './items/laptop_form'
import './items/monitor_form'
import './items/notebook_form'
import './items/portable_form'
import './items/printer_form'
import './items/server_form'
import './items/system_form'

import './load_settings/load_cod_info'
import './load_settings/mbf_company_setting'
import './load_settings/index'
import './load_settings/load_item_type/form'

import './load_modules/load_data_capture'
import './load_modules/load_module_general'
import './load_modules/load_module_new'
import './load_modules/load_module'
import './load_modules/load_receiving_detail'
import './load_modules/load_receiving'

import './lots/form'
import './lots/add_item_to_resource'

import './mbf_companies/form'

import './orders/edit'
import './orders/index'
import './orders/show'
import './orders/filter'
import './orders/add_item_to_resource'
import './orders/form'

import './purchase_orders/filter'
import './purchase_orders/form'
import './purchase_orders/index'
import './purchase_orders/new'
import './purchase_orders/purchase_order_item_fields'
import './purchase_orders/show'

import './quotes/edit'
import './quotes/filter'
import './quotes/index'
import './quotes/list'
import './quotes/new_form'
import './quotes/new_quote_item_fields'
import './quotes/new'
import './quotes/quote_item'
import './quotes/show'

import './reports/commission_report'
import './reports/custom_report_filters'
import './reports/custom_report'
import './reports/index'
import './reports/load_inventory_report'
import './reports/load_report'
import './reports/recycling_weight_report'
import './reports/shipped_orders_report'
import './reports/work_order_report'

import './resale_assessments/resale_assessment_items_table'
import './resale_assessments/form'
import './resale_assessments/index'
import './resale_assessments/show'

import './rmas/filter'
import './rmas/form'
import './rmas/index'
import './rmas/rmas_list'

import './settlement_reports/form'
import './settlement_reports/index'
import './settlement_reports/show'
import './settlement_reports/new'

import './shared/shipped_date_filter'

import './taxes/form'

import './transfers/filter'
import './transfers/index'
import './transfers/form'
import './transfers/export'
import './transfers/show'

import './users/form'

import './vendors/form'

import './warehouses/form'

import './inbound_customer/edit'
import './inbound_customer/index'
import './inbound_customer/form'

import './inbound_logistics_reports/index'

import './outbound_logistics_reports/index'

import './arma_reports/index'

import './pickup_requests/index'
import './pickup_requests/form'
import './pickup_requests/location'

import './inbound_quotes/index'
import './inbound_quotes/form'
