window.loadModule = {
  init() {
    window.commonJS.handleInitializeSelect2();
    window.commonJS.handleCollapseLink();

    this.initializeDataTables();
    this.handleInitializeLoadStatusSelect2();
    this.handleInitializeEtaDatePicker();
    this.handleInitializeRcvdDatePicker();
    this.handleInitializeProcessedDatePicker();
    this.handleUpdateMultipleLoadStatus();
    this.handleSelectAll();
    this.handleLoadModuleExport();
  },

  initializeDataTables() {
    [
      '#create-load-table',
      '#received-load-table',
      '#in-progress-load-table',
      '#processed-load-table',
      '#settled-load-table',
      '#closed-load-table',
      '#draft-load-table',
    ].forEach(this.initializeDataTable);
  },

  handleSelectAll() {
    $('#check_all').on('click', function (event) {
      if (this.checked) {
        $(':checkbox').each(function () { this.checked = true })
      } else {
        $(':checkbox').each(function () { this.checked = false })
      }
    })
  },

  initializeDataTable(selector) {
    let colCount = [
      '#create-load-table',
      '#draft-load-table',
    ].includes(selector) ? 9 : 13
    $(selector).DataTable({
      searching: false,
      paging: false,
      order: [],
      columnDefs: [
        {
          targets: colCount,
          orderable: false
        }
      ]
    })
  },

  handleInitializeLoadStatusSelect2() {
    $('.load-status-select2').select2({
      placeholder: "Select",
      width: "100%"
    })
  },

  handleInitializeEtaDatePicker() {
    if ($("#eta-date-diff").length > 0 && $("#eta-date-diff").attr("data-start-date") && $("#eta-date-diff").attr("data-end-date")) {
      var eta_start_date = moment($("#eta-date-diff").attr("data-start-date"))
      var eta_end_date = moment($("#eta-date-diff").attr("data-end-date"))
    }
    else {
      var eta_start_date = undefined
      var eta_end_date = undefined
    }

    function cb_eta_date(start, end, datepicker) {
      if (start != null && end != null && start._isValid && end._isValid) {
        $('#eta-date span').html(`${start.format('MMMM D, YYYY')} - ${end.format('MMMM D, YYYY')}`)
        $("#load_eta_start_date").val(start.format('MMMM D, YYYY'))
        $("#load_eta_end_date").val(end.format('MMMM D, YYYY'))
      } else {
        $('#eta-date span').html('')
        $("#load_eta_start_date").val('')
        $("#load_eta_end_date").val('')
      }
    }

    $('#eta-date').on('apply.daterangepicker', function (ev, picker) {
      if (picker.chosenLabel === 'Today') {
        $('#eta-date span').html(`${moment().format('MMMM D, YYYY')} - ${moment().format('MMMM D, YYYY')}`)
        $("#load_eta_start_date").val(moment())
        $("#load_eta_end_date").val(moment())
      }

      if (picker.chosenLabel === 'None') {
        $('#eta-date span').html('')
        $("#load_eta_start_date").val('')
        $("#load_eta_end_date").val('')
      }
    })

    $('#eta-date').daterangepicker({
      startDate: eta_start_date,
      endDate: eta_end_date,
      ranges: {
        'None': [moment().subtract(1, 'days'), moment().add(1, 'days')],
        'Today': [moment(), moment()],
        'This Week': [moment().startOf('isoWeek'), moment().endOf('isoWeek')],
        'Next Week': [moment().add(1, 'weeks').startOf('isoWeek'), moment().add(1, 'weeks').endOf('isoWeek')],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Next Month': [moment().add(1, 'month').startOf('month'), moment().add(1, 'month').endOf('month')],
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
      }
    }, cb_eta_date)

    cb_eta_date(eta_start_date, eta_end_date)
  },

  handleInitializeRcvdDatePicker() {
    if ($("#rcvd-date-diff").length > 0 && $("#rcvd-date-diff").attr("data-start-date") && $("#rcvd-date-diff").attr("data-end-date")) {
      var rcvd_start_date = moment($("#rcvd-date-diff").attr("data-start-date"))
      var rcvd_end_date = moment($("#rcvd-date-diff").attr("data-end-date"))
    }
    else {
      var rcvd_start_date = undefined
      var rcvd_end_date = undefined
    }

    function cb_rcvd_date(start, end, datepicker) {
      if (start != null && end != null && start._isValid && end._isValid) {
        $('#rcvd-date span').html(`${start.format('MMMM D, YYYY')} - ${end.format('MMMM D, YYYY')}`)
        $("#load_rcvd_start_date").val(start.format('MMMM D, YYYY'))
        $("#load_rcvd_end_date").val(end.format('MMMM D, YYYY'))
      } else {
        $('#rcvd-date span').html('')
        $("#load_rcvd_start_date").val('')
        $("#load_rcvd_end_date").val('')
      }
    }

    $('#rcvd-date').on('apply.daterangepicker', function (ev, picker) {
      if (picker.chosenLabel === 'Today') {
        $('#rcvd-date span').html(`${moment().format('MMMM D, YYYY')} - ${moment().format('MMMM D, YYYY')}`)
        $("#load_rcvd_start_date").val(moment())
        $("#load_rcvd_end_date").val(moment())
      }

      if (picker.chosenLabel === 'None') {
        $('#rcvd-date span').html('')
        $("#load_rcvd_start_date").val('')
        $("#load_rcvd_end_date").val('')
      }
    })

    $('#rcvd-date').daterangepicker({
      startDate: rcvd_start_date,
      endDate: rcvd_end_date,
      ranges: {
        'None': [moment().subtract(1, 'days'), moment().add(1, 'days')],
        'Today': [moment(), moment()],
        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last Week': [moment().subtract(1, 'weeks').startOf('isoWeek'), moment().subtract(1, 'weeks').endOf('isoWeek')],
        'This Month to Date': [moment().startOf('month'), moment()]
      }
    }, cb_rcvd_date)

    cb_rcvd_date(rcvd_start_date, rcvd_end_date)
  },

  handleInitializeProcessedDatePicker() {
    let processed_start_date, processed_end_date;
    if ($('#processed-date-diff').length > 0 &&
      $('#processed-date-diff').attr('data-start-date') &&
      $('#processed-date-diff').attr('data-end-date')) {
      processed_start_date = moment($('#processed-date-diff').attr('data-start-date'));
      processed_end_date = moment($('#processed-date-diff').attr('data-end-date'));
    }

    let cb_processed_date = (start, end) => {
      if (start != null && end != null && start._isValid && end._isValid) {
        $('#processed-date span').html(`${start.format('MMMM D, YYYY')} - ${end.format('MMMM D, YYYY')}`);
        $('#load_processed_start_date').val(start.format('MMMM D, YYYY'));
        $('#load_processed_end_date').val(end.format('MMMM D, YYYY'));
      } else {
        $('#processed-date span').html('');
        $('#load_processed_start_date').val('');
        $('#load_processed_end_date').val('');
      }
    }

    $('#processed-date').on('apply.daterangepicker', (ev, picker) => {
      if (picker.chosenLabel === 'Today') {
        $('#processed-date span').html(`${moment().format('MMMM D, YYYY')} - ${moment().format('MMMM D, YYYY')}`);
        $('#load_processed_start_date').val(moment());
        $('#load_processed_end_date').val(moment());
      }

      if (picker.chosenLabel === 'None') {
        $('#processed-date span').html('');
        $('#load_processed_start_date').val('');
        $('#load_processed_end_date').val('');
      }
    })

    $('#processed-date').daterangepicker({
      startDate: processed_start_date,
      endDate: processed_end_date,
      ranges: {
        'None': [moment().subtract(1, 'days'), moment().add(1, 'days')],
        'Today': [moment(), moment()],
        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last Week': [moment().subtract(1, 'weeks').startOf('isoWeek'), moment().subtract(1, 'weeks').endOf('isoWeek')],
        'This Month to Date': [moment().startOf('month'), moment()]
      }
    }, cb_processed_date);

    cb_processed_date(processed_start_date, processed_end_date);
  },

  handleUpdateMultipleLoadStatus() {
    $('.btn-change-status-modal').on('click', function (e) {
      if ($('.load-module-result input[type="checkbox"]:checked').length > 0) {
        $('#change-load-module-status-modal').modal('show')

        let loadModules = []

        $('.load-module-result tbody input[type="checkbox"]:checked').each(function (index, element) {
          loadModules.push(element.value)
        })

        $('#change-load-module-status-modal #load_module_ids').val(loadModules)
      } else {
        alert('Please select at least 1 load module to change status')
      }
    })
  },

  handleLoadModuleExport() {
    $('#btn-export').on('click', e => {
      e.preventDefault();

      const ids = [];

      $('.load-module-result tbody input[type="checkbox"]:checked').each((index, element) => {
        ids.push(element.value);
      });

      if (ids.length > 0) {
        $('#export-form input[name="load_module_ids[]"]').remove();

        ids.forEach(id => {
          $('<input>').attr({
            type: 'hidden',
            name: 'load_module_ids[]',
            value: id
          }).appendTo('#export-form');
        });

        $('#export-form').submit();
      } else {
        alert('Please select at least 1 load module to export');
      }
    });
  }
}

export let getLoadModule = loadId => {
  return $.ajax({
    url: `/admin/load_modules/${loadId}`,
    contentType: 'application/json; charset=utf-8',
    dataType: 'json',
  });
}
