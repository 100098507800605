import {toastErrorMessage, toastSuccessMessage} from '../../packs/toast';
import {createContactInboundCustomer, fetchInboundCustomer} from '../common/api_requests';
import LocationModal from '../common/modals/add_new_location';
import ContactModal from '../common/modals/add_new_contact';

$(document).on('turbolinks:load', () => {
  if ($('.pickup-request-form').length > 0) {
    window.commonJS.initializeDatepicker(
      [
        'pickup_request_created_on'
      ]
    );
    window.commonJS.initializeDatepicker(
      [
        'pickup_request_pickup_on'
      ],
      {
        minDate: 0
      }
    );
    window.commonJS.handleInitializeSelect2();
    window.commonJS.handleInitializeSelect2(
      $('#pickup_request_status'),
      {allowClear: false}
    );
    initializeTimePicker();
    handlePickupRequestTypeChanged();
    handleInboundCustomerChanged();
    handlePickupRequestStatusChanged();
    disablePickupRequestInputs();
    handlePickupRequestSaveButtons();
    handlePickupRequestOnSave();
    handlePickupRequestOnSubmit();
    handlePickupRequestNewLocation();
    handlePickupRequestNewContact();

    $('[data-toggle="tooltip"]').tooltip();
  }
});

const selectedInboundCustomer = () => {
  return $('#pickup_request_inbound_customer_id').val();
};

const initializeTimePicker = () => {
  $('.timepicker').timepicker({
    minuteStep: 5,
    showInputs: true,
    defaultTime: '10:00 AM',
    showMeridian: true,
    showSeconds: false,
    disableMousewheel: false
  });
};

const savePickupRequest = (pickupRequestProps = {}) => {
  const $form = $('.pickup-request-form');
  const formData = $form.serializeArray();
  const jsonData = {pickup_request: {}};
  formData.forEach(({name, value}) => {
    const keyParts = name.split('[').map(part => part.replace(']', ''));
    if (keyParts.length === 2) {
      jsonData.pickup_request[keyParts[1]] = value;
    } else {
      jsonData[keyParts[0]] = value;
    }
  });
  Object.keys(pickupRequestProps).forEach((key) => {
    jsonData.pickup_request[key] = pickupRequestProps[key];
  });
  const actualMethod = $form.find('input[name="_method"]').val() ||
    $form.attr('method');

  const locale = window.commonJS.getLocaleFromUrl();
  const actionUrl = new URL($form.attr('action'), window.location.origin);
  actionUrl.searchParams.set('locale', locale);

  $.ajax({
    url: actionUrl.toString(),
    type: actualMethod,
    dataType: 'json',
    contentType: 'application/json; charset=utf-8',
    data: JSON.stringify(jsonData),
    headers: {
      'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
    },
    success: response => {
      toastSuccessMessage('Pickup Request has been saved successfully');
      window.location.href = `/pickup_requests/${response.id}/edit`;
    },
    error: response => {
      const messages = JSON.parse(response.responseText).errors;
      toastErrorMessage(messages.join(', '));
    }
  });
};

const handlePickupRequestOnSave = () => {
  $('#save-pickup-request').click(e => {
    e.preventDefault();

    savePickupRequest();
  });
};

const handlePickupRequestOnSubmit = () => {
  const $submitBtn = $('#submit-pickup-request');
  $submitBtn.click(e => {
    e.preventDefault();

    const message = $submitBtn.data('confirm-message');
    if (!confirm(message)) return;

    savePickupRequest({status: 'submitted'});
  });
};

const handlePickupRequestSaveButtons = () => {
  const $saveBtn = $('#save-pickup-request');
  const $submitBtn = $('#submit-pickup-request');
  const $state = $('#pickup-request-state');
  const persistedStatus = $state.data('status');
  const role = $state.data('role');

  switch (role) {
    case 'admin':
      $saveBtn.removeAttr('hidden');
      break;
    case 'inbound':
      if (persistedStatus === 'new') {
        $saveBtn.removeAttr('hidden');
        $submitBtn.removeAttr('hidden');
      }
      break;
  }
};

const handlePickupRequestNewLocation = () => {
  const $el = $('#pickup_request_address_detail_id');
  const locationModal = new LocationModal(
    addressDetailId => refreshLocationOptions(addressDetailId),
    () => resetLocationSelected()
  );

  $el.on('select2:select', e => {
    const selectedOption = e.params.data;

    if (selectedOption.id !== 'createNewLocation') return;

    locationModal.setInboundCustomerId(selectedInboundCustomer());
    locationModal.showModal();
  });
};

const resetContactSelected = $inputEl => {
  $inputEl.val('').trigger('change.select2');
};

let handlePickupRequestNewContact = () => {
  let $primary = $('#pickup_request_primary_contact_id');
  let $secondary = $('#pickup_request_secondary_contact_id');
  let $currentEl, selectedPrimaryId, selectedSecondaryId;
  let contactModal = new ContactModal(
    createdContactId => {
      let selectedPrimaryId = $currentEl.is($primary) ? createdContactId : $primary.val();
      let selectedSecondaryId = $currentEl.is($secondary) ? createdContactId : $secondary.val();
      refreshContactOptions(selectedPrimaryId, selectedSecondaryId);
    },
    () => resetContactSelected($currentEl.is($primary) ? $primary : $secondary)
  );

  [
    $primary,
    $secondary
  ].forEach($el => {
    $el.on('select2:select', e => {
      let selectedOption = e.params.data;

      if (selectedOption.id !== 'createNewContact') return;

      selectedPrimaryId = $primary.val();
      selectedSecondaryId = $secondary.val();
      $currentEl = $el;

      contactModal.setInboundCustomerId(selectedInboundCustomer());
      contactModal.showModal();
    });
  });
};

let refreshContactOptions = (primarySelectedId, secondarySelectedId) => {
  let customerId = selectedInboundCustomer();
  let $primary = $('#pickup_request_primary_contact_id');
  let $secondary = $('#pickup_request_secondary_contact_id');

  fetchInboundCustomer(customerId)
    .done(data => {
      [
        [$primary, primarySelectedId],
        [$secondary, secondarySelectedId]
      ].forEach(elWithSelected => {
        let $el = elWithSelected[0];
        let id = elWithSelected[1];
        updateContactNameOptions($el, data.contact_inbound_customers);
        $el.val(id).trigger('change.select2');
      });
    })
    .fail(error => {
      console.error('Error:', error);
      toastErrorMessage('Something went wrong. Please try again later.');
    });
};

const handlePickupRequestTypeChanged = () => {
  pickupRequestTypeFormSwitch();

  $('.pickup-request-type').change(() => {
    pickupRequestTypeFormSwitch();
  });
};

const handlePickupRequestStatusChanged = () => {
  const $state = $('#pickup-request-state');
  const persistedStatus = $state.data('status');
  if (persistedStatus !== 'closed') return;

  const $statusEl = $('#pickup_request_status');
  const previousStatus = $statusEl.val();

  $statusEl.on('select2:select', e => {
    const selectedOption = e.params.data;
    if (selectedOption.id === 'closed') return;

    const message = 'Changing the status will delete the related Load Module record once you save the form.' +
      ' This action cannot be undone. Do you want to proceed?';

    if (!confirm(message)) setPickupRequestStatus(previousStatus);
  });
};

const setPickupRequestStatus = status => {
  $('#pickup_request_status')
    .val(status)
    .trigger('change.select2');
};

let updateContactNameOptions = ($inputEl, contactNames) => {
  let title = $inputEl.data('title');
  let emptyOption = new Option(
    '',
    '',
    true,
    false);
  let lastOption = new Option(
    title,
    'createNewContact',
    false,
    false);
  $inputEl.empty();
  $inputEl.append(emptyOption);

  contactNames.forEach(contact => {
    let displayName = contact.name;
    let option =
      new Option(
        displayName,
        contact.id,
        false,
        false);
    $inputEl.append(option);
  });
  $inputEl.append(lastOption);
  $inputEl.trigger('change.select2');
};

let updateLocationOptions = (addressDetails, selectedId = null) => {
  let selectElement = $('#pickup_request_address_detail_id');
  let title = selectElement.data('title');
  let emptyOption = new Option(
    '',
    '',
    true,
    true);
  let lastOption = new Option(
    title,
    'createNewLocation',
    false,
    false);
  selectElement.empty();
  selectElement.append(emptyOption);

  addressDetails.forEach(address => {
    let option =
      new Option(address.full_address_with_name,
        address.id,
        false,
        false);
    selectElement.append(option);
  });
  selectElement.append(lastOption);
  selectElement.val(selectedId);
  selectElement.trigger('change.select2');
};

const resetLocationSelected = () => {
  $('#pickup_request_address_detail_id')
    .val('')
    .trigger('change.select2');
};

const refreshLocationOptions = addressDetailId => {
  const customerId = selectedInboundCustomer();
  fetchInboundCustomer(customerId)
    .done(data => {
      updateLocationOptions(
        data.address_details,
        addressDetailId
      );
    })
    .fail(error => {
      console.error('Error:', error);
      toastErrorMessage('Something went wrong. Please try again later.');
    });
};

const handleInboundCustomerChanged = () => {
  const $inboundEl = $('#pickup_request_inbound_customer_id');
  $inboundEl.on('select2:select', e => {
    const selectedOption = e.params.data;
    fetchInboundCustomer(selectedOption.id)
      .done(data => {
        updateLocationOptions(data.address_details);
        [
          $('#pickup_request_primary_contact_id'),
          $('#pickup_request_secondary_contact_id')
        ].forEach($el => {
          updateContactNameOptions($el, data.contact_inbound_customers);
        });
      })
      .fail(error => {
        console.error('Error:', error);
        toastErrorMessage('Something went wrong. Please try again later.');
      });
  });
  $inboundEl.on('select2:clear', _e => {
    [
      $('#pickup_request_address_detail_id'),
      $('#pickup_request_primary_contact_id'),
      $('#pickup_request_secondary_contact_id')
    ].forEach(input => {
      input.empty();
      input.trigger('change.select2');
    });
  });
};

const disableAllInputs = () => {
  $('.pickup-request-form :input')
    .not('[name="_method"]')
    .prop('disabled', true);
  $('.timepicker').addClass('disabled');
};

const disablePickupRequestInputs = () => {
  const $el = $('#pickup-request-state');

  switch ($el.data('status')) {
    case 'closed': {
      disableAllInputs();
      break;
    }
    case 'submitted': {
      if ($el.data('role') === 'inbound') {
        disableAllInputs();
      }
      break;
    }
  }
  $('#pickup_request_status, #save-pickup-request').prop('disabled', false);
};

const pickupRequestTypeFormSwitch = () => {
  switch ($('input[name="pickup_request[request_type]"]:checked').val()) {
    case 'standard':
      showPickupRequestFormBlock($('.standard-form-block'));
      hidePickupRequestFormBlock($('.white-glove-form-block'));
      break;
    case 'white_glove':
      hidePickupRequestFormBlock($('.standard-form-block'));
      showPickupRequestFormBlock($('.white-glove-form-block'));
      break;
    default:
      hidePickupRequestFormBlock($('.standard-form-block'));
      hidePickupRequestFormBlock($('.white-glove-form-block'));
      break;
  }
};

const hidePickupRequestFormBlock = block => {
  block.hide();
  block.find('input').prop('readonly', true);
  block.find('textarea').prop('readonly', true);
};

const showPickupRequestFormBlock = block => {
  block.show();
  block.find('input').prop('readonly', false);
  block.find('textarea').prop('readonly', false);
};
