window.orderForm = {
  init() {
    this.handleInitializeSelect2()
    this.handleClickItemId()
    this.handleItemMakerChange()
    this.handleItemMakerModelChange()
    this.handleAssignItems()
    this.handleInitializeDatepicker()
    this.handleQuoteIdChange()
    this.handleSearchInput()
    this.handlePreventSubmitFormByEnter()
  },

  handleInitializeDatepicker() {
    $('.datepicker').datepicker({
      todayHighlight: true,
      orientation: 'auto top'
    })

    $(".datepicker").datepicker(
      "option", "dateFormat", "mm-dd-yy"
    )

    let shippedDate = $('.datepicker').attr('value')
    $('.datepicker').val(shippedDate)
  },

  handleInitializeSelect2() {
    $('.select2, .select2-create').select2({
      placeholder: "Select",
      allowClear: true
    })

    $('#order_order_status_id').on('select2:select', function (e) {
      var data = e.params.data
      if (data.text === 'Shipped') {
        $('#order_shipped_date').datepicker('setDate', new Date())
      }
    })
  },

  handleClickItemId() {
    var handleClick = debounce(function (e) {
      var name = e.target.className.indexOf('item-serial') > -1 ? 'serial' : 'item_id'
      var value = e.target.value
      $.ajax({
        type: "GET",
        url: "/admin/orders/fill_item_detail?assigned_item=true",
        dataType: "json",
        data: {
          [name]: value
        },
        success: function (data) {
          var itemForm = $(e.target).parent().parent().parent()

          if (data.item) {
            window.orderForm.insertAssignedItemData(itemForm, data.item)
          } else {
            window.orderForm.clearAssignedItemData(itemForm)
          }
        }
      })
    }, 700)

    $(document).on('keyup', '.item-id', handleClick)
    $(document).on('keyup', '.item-serial', handleClick)
  },

  handleItemMakerChange() {
    $('#item_maker_id').on('change', function () {
      var id = $(this).val()
      return $.ajax({
        type: "GET",
        url: "/admin/orders/update_maker_model",
        data: { maker_id: id }
      })
    })
  },

  handleItemMakerModelChange() {
    $("#item_maker_model_id").change(function () {
      var maker_model_id = $(this).val()
      var maker_id = $('#item_maker_id').val()
      return $.ajax({
        type: "GET",
        url: "/admin/orders/update_items",
        data: {
          maker_id: maker_id,
          maker_model_id: maker_model_id
        }
      })
    })
  },

  handleAssignItems() {
    $('.js-select2-assign-items').select2({
      allowClear: true,
      width: '100%',
      ajax: {
        url: '/admin/orders/find_assign_items',
        dataType: 'json',
        data: function (params) {
          var selectedItemId = $(this).val()
          return {
            q: params.term,
            category_id: $('.js-assign-items-filter').find('#item_category_id').val(),
            sub_category_id: $('.js-assign-items-filter').find('#item_sub_category_id').val(),
            maker_id: $('.js-assign-items-filter').find('#item_maker_id').val(),
            maker_model_id: $('.js-assign-items-filter').find('#item_maker_model_id').val(),
            audit_id: $('.js-assign-items-filter').find('#item_audit_id').val()
          }
        },
        processResults: function (data) {
          return { results: data.results }
        }
      },
      placeholder: {
        id: "",
        item: {},
        placeholder: "Leave blank to ..."
      },
      templateResult: window.orderForm.formatResult,
      templateSelection: window.orderForm.formatSelection,
    }).trigger('change')

    $('.js-select2-assign-items').on('select2:clearing', function (e) {
      window.selectedItem = null
      $(".selected-item").html('')
    })

    $('.js-select2-assign-items').on('select2:select', function (e) {
      var item = e.params.data

      $.ajax({
        type: "GET",
        url: "/admin/orders/fill_item_detail",
        dataType: "script",
        data: {
          item_id: item.id
        }
      })
    })
  },

  formatResult(station) {
    if (station.placeholder) return station.placeholder
    return station.serial
  },

  formatSelection(station) {
    if (station.placeholder) return station.placeholder
    return station.serial
  },

  handleQuoteIdChange() {
    $('#order_quote_id').on('change', function () {
      var id = $(this).val()

      $.ajax({
        type: "GET",
        url: "/admin/orders/get_quote",
        dataType: "json",
        data: { quote_id: id },
        success: function (data) {
          console.log(data)
          $("#quote-summary").removeClass("hidden")
          $("#date-required-order").html(data.date_required)
          $("#status-order").html(data.status)
          $("#total-order").html(data.total)
          $("#total-subtotal").html(data.subtotal)
        }
      })
    })

    window.commonJS.clearDatePicker()
  },

  handleSearchInput() {
    $(".search-input").on('keyup', function (e) {
      if (e.which >= 97 && e.which <= 122) {
        var newKey = e.which - 32
        e.keyCode = newKey
        e.charCode = newKey
      }
      $(this).val($(this).val().toUpperCase())
      var serial = $(this).val()

      $.ajax({
        type: "GET",
        url: "/admin/orders/autocomplete",
        dataType: "json",
        data: { serial: serial },
        success: function (item) {
          if ((item != undefined) || (item != null)) {
            $(".result_serial").val(item.serial.toUpperCase())
            $(".result_id").val(item.id)
          }
        }
      })
    })
  },

  clearAssignedItemData(itemForm) {
    itemForm.find(".item-description").val('')
    itemForm.find(".item-serial").val('')
    itemForm.find(".item-maker-model").val('')
    itemForm.find(".item-maker-model").trigger('change')
    itemForm.find(".item-maker").val('')
    itemForm.find(".item-maker").trigger('change')
  },

  insertAssignedItemData(itemForm, selectedItem) {
    itemForm.find(".item-serial").val(selectedItem.serial)
    itemForm.find(".item-id").val(selectedItem.id)

    if (selectedItem.grade_id != undefined) {
      itemForm.find(".item-grade").val(selectedItem.grade_id)
      itemForm.find(".item-grade").select2().trigger('change')
    }

    itemForm.find(".item-qty").val(1)
    itemForm.find(".item-description").val(selectedItem.description)

    if (selectedItem.maker_id != undefined) {
      itemForm.find(".item-maker").val(selectedItem.maker_id)
      itemForm.find(".item-maker").select2().trigger('change')
    }

    if (selectedItem.maker_model_id != undefined) {
      itemForm.find(".item-maker-model").val(selectedItem.maker_model_id)
      itemForm.find(".item-maker-model").select2().trigger('change')
      itemForm.find(".item-model").data('updated-value', selectedItem.maker_model_id)
    }
  },

  handlePreventSubmitFormByEnter() {
    $(window).on('keydown', function (event) {
      if (event.keyCode == 13) {
        event.preventDefault()
        return false
      }
    })
  }
}
