window.companiesIndex = {
  init() {
    this.handleInitializeSelect2()
    this.handleCompanyCountryCodeOnChange()
    this.handleCompanyProvinceCodeOnChange()
  },

  handleInitializeSelect2() {
    $('.select2').select2({
      placeholder: "Select",
      allowClear: true
    })

    $('#company_province_code').select2({
      placeholder: "Select Country First",
      allowClear: true
    })

    $('#company_city_code').select2({
      placeholder: "Select Province First",
      allowClear: true
    })
  },

  handleCompanyCountryCodeOnChange() {
    $('#company_country_code').on('change', function () {
      return $.ajax({
        type: "GET",
        url: "/admin/customers/get_states",
        data: {
          country: $(this).val(),
          element_id: 'company_province_code'
        }
      })
    })
  },

  handleCompanyProvinceCodeOnChange() {
    $('#company_province_code').on('change', function () {
      return $.ajax({
        type: "GET",
        url: "/admin/companies/get_cities",
        data: {
          country: $('#company_country_code').val(),
          state: $(this).val()
        }
      })
    })
  }
}
