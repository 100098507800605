import {toastErrorMessage} from '../../../../../../packs/toast';

export let initRestValidation = () => {
  return (changes) => {
    let toSplice = [];
    let correctionChanges = [];
    let errorMessages = new Set();
    let propTitles = {
      tax: 'Tax',
      total_rebate: 'Total Rebate',
      rate: 'Rate',
      currency: 'Currency',
      uom: 'UOM',
      unbox_weight: 'Unbox Weight'
    };
    changes.forEach(([row, prop, oldValue, newValue], index) => {
      if (oldValue === newValue) return toSplice.unshift(index);

      switch (prop) {
        case 'unbox_weight':
        case 'tax':
        case 'rate':
          if (newValue) {
            newValue = newValue.toString().replace(/,/g, '.');
            newValue = parseFloat(newValue);
            toSplice.unshift(index);

            if (!isNaN(newValue)) {
              correctionChanges.push(
                [row, prop, oldValue, newValue.toFixed(3)]
              );
            }
          }
          break;
        case 'uom':
        case 'currency':
          if (!newValue) {
            toSplice.unshift(index);
            errorMessages.add(`${propTitles[prop]} can not be blank`);
          }
          break;
      }
    });
    errorMessages.size > 0 && toastErrorMessage([...errorMessages].join('<br>'));
    toSplice.forEach(index => {
      changes.splice(index, 1);
    });
    correctionChanges.forEach(change => {
      changes.push(change);
    });
  };
};

