import {toastErrorMessage, toastSuccessMessage} from '../../packs/toast';

window.loadModuleNew = {
  init() {
    window.commonJS.handleInitializeSelect2()
    window.commonJS.initializeTimePicker();
    window.commonJS.initializeDatepicker(
      [
        'load_module_required_date',
        'load_module_estimated_delivery_date'
      ]
    );
    this.handleInitializePickupDetailSelect2()
    this.handlePickupDetailRequired()
    this.handleGetContact()
    this.handleGetZone()
    this.handleSelectWarehouse()
    this.handleAddCreateOptionToContactSelect2()
    this.handleAddCreateOptionToCarrierSelect2()
    this.handleAddCreateOptionToPickupAddressSelect2()
    this.handleAddressDetailCountryChange()
    this.handleAddressDetailProvinceChange()
    this.handleAccountRepSelect();
    this.handleSelect2Customization();
    this.handleStatusChanged();
    this.handleSubmitLoadModuleForm();
  },

  handleSubmitLoadModuleForm() {
    $('#submit-load-module-btn').click(e => {
      e.preventDefault();

      const $form = $('#new_load_module');
      const formData = $form.serializeArray();
      const jsonData = {load_module: {}};
      formData.forEach(({name, value}) => {
        const keyParts = name.split('[').map(part => part.replace(']', ''));
        if (keyParts.length === 2) {
          jsonData.load_module[keyParts[1]] = value;
        } else {
          jsonData[keyParts[0]] = value;
        }
      });
      const actualMethod = $form.find('input[name="_method"]').val() ||
        $form.attr('method');

      $.ajax({
        url: $form.attr('action'),
        type: actualMethod,
        dataType: 'json',
        contentType: 'application/json; charset=utf-8',
        data: JSON.stringify(jsonData),
        headers: {
          'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
        },
        success: response => {
          window.location.href = `/admin/load_module_generals/${response.id}`;
          toastSuccessMessage('Load Module has been saved successfully');
        },
        error: response => {
          const messages = JSON.parse(response.responseText).errors;
          toastErrorMessage(messages.join(', '));
        }
      });
    });
  },

  handleStatusChanged() {
    $('#load_module_load_status_id').on('select2:select', e => {
      let selectedOption = e.params.data;
      let required = selectedOption.text.toLowerCase() !== 'draft';

      $('#load_module_load_stewardship_program_id').prop('required', required);
      $('#load_module_load_warehouse_id').prop('required', required);
    });
  },

  handleSelect2Customization() {
    let classes = [
      'country-detail',
      'province-detail',
      'city-detail',
    ];

    classes.forEach(cls => {
      $.each($(`.${cls}`), (_i, el) => {
        window.commonJS.initializeSelect2Customization(el, {width: '100%'});
      });
    });
  },

  handleAccountRepSelect() {
    $('#load_module_account_rep').select2({
      tags: true,
      placeholder: 'Select an option or type your own',
      allowClear: true,
      createTag: params => {
        let term = $.trim(params.term);
        if (term === '') return null;

        return {
          id: term,
          text: term,
          newTag: true
        };
      }
    })
  },

  handleAddressDetailCountryChange() {
    $('#create-new-pickup-address').on('select2:select', '.country-detail', function () {
      let elementId = $(this).parents('#create-new-pickup-address').find('.province-detail').attr('id')

      return $.ajax({
        type: "GET",
        url: "/admin/inbound_customers/get_states",
        data: {
          country: $(this).val(),
          element_id: elementId
        }
      })
    })
  },

  handleAddressDetailProvinceChange() {
    $('#create-new-pickup-address').on('change', '.province-detail', function () {
      let elementId = $(this).parents('#create-new-pickup-address').find('.city-detail').attr('id')
      let state = $(this).val()
      let country = $(this).parents('#create-new-pickup-address').find('.country-detail').val()

      return $.ajax({
        type: "GET",
        url: "/admin/inbound_customers/get_cities",
        data: {
          state: state,
          country: country,
          element_id: elementId,
          id: $('#create-new-contact #inbound-customer-id').val()
        }
      })
    })
  },

  handleAddCreateOptionToContactSelect2() {
    $('.contact-select2').select2().on('change', (e) => {
      if ( e.target.value == 'createNewContact') {
        $('#create-new-contact').modal({ backdrop: 'static', keyboard: false })
        $('#create-new-contact #inbound-customer-id').val($('#load_module_inbound_customer_id').val())
        $('#create-new-contact').modal('show')
      }
    })

    $('.cancel-create-contact, #create-new-contact .close').on('click', () => {
      $('#load_module_contact').val('').trigger('change')
    })
  },

  handleAddCreateOptionToCarrierSelect2() {
    $('.carrier-select2').select2().on('change', (e) => {
      if (e.target.value == 'createNewCarrier') {
        $('#create-new-carrier').modal({ backdrop: 'static', keyboard: false })
        $('#create-new-carrier').modal('show')
      }
    })

    $('.cancel-create-carrier, #create-new-carrier .close').on('click', () => {
      $('#load_module_load_carrier_id').val('').trigger('change')
    })
  },

  handleAddCreateOptionToPickupAddressSelect2() {
    $('.pickup-address-select2').select2().on('change', (e) => {
      if (e.target.value == 'createNewPickupAddress') {
        $('#create-new-pickup-address').modal({ backdrop: 'static', keyboard: false })
        $('#create-new-pickup-address #inbound-customer-id').val($('#load_module_inbound_customer_id').val())
        $('#create-new-pickup-address').modal('show')
      }
    })

    $('.cancel-create-pickup-address, #create-new-pickup-address .close').on('click', () => {
      $('#load_module_pickup_address_id').val('').trigger('change')
    })
  },

  handlePickupDetailRequired() {
    $('.load_module_pickup_detail_required').on('change', function (e) {
      $('.pickup-detail .toggle').prop('disabled', $(e.currentTarget).find('select').val() == 'false')
      $(e.currentTarget).find('select').prop('disabled', false)
    })

    $('.load_module_pickup_detail_required').trigger('change')
  },

  handleInitializePickupDetailSelect2() {
    $('.pickup-detail-required').select2({
      placeholder: 'Select',
      width: '100%'
    })
  },

  handleGetContact() {
    $('.load_module_customer_name, .load_module_inbound_customer_id').on('select2:select', function (e) {
      let data = e.params.data;

      if (e.target.id == 'load_module_customer_name') {
        $('#load_module_inbound_customer_id').val(data.id).trigger('change')
      } else if (e.target.id == 'load_module_inbound_customer_id') {
        $('#load_module_customer_name').val(data.id).trigger('change')
      }

      return $.ajax({
        type: "GET",
        url: "/admin/load_modules/get_contact",
        data: { user_id: data.id }
      })
    })
  },

  handleGetZone() {
    $('.load_module_load_stewardship_program_id').on('select2:select', function (e) {
      let data = e.params.data;

      return $.ajax({
        type: "GET",
        url: "/admin/load_modules/zone",
        data: {
          stewardship_id: data.id
        }
      });
    });
  },

  handleSelectWarehouse() {
    var searchParams = new URLSearchParams(window.location.search)
    var loadWarehouseId = searchParams.get('load_warehouse_id')
    var $warehouseSelect2 = $('#load_module_load_warehouse_id')

    if (loadWarehouseId) {
      $warehouseSelect2.prop('disabled', true)
      $warehouseSelect2.val(loadWarehouseId).trigger('change')
    }
  }
}
