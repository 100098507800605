window.transferFilter = {
  init() {
    window.commonJS.handleInitializeSelect2();
    window.commonJS.handleInitializeDatepicker();
    $('#transferred_on_alternate').datepicker(
      'option',
      'altField',
      '#transferred_on'
    );
    $('#eta_alternate').datepicker(
      'option',
      'altField',
      '#eta'
    );
    window.commonJS.clearDatePicker();
  }
};
