window.rmasList = {
  init() {
    this.handleInitializeDataTable()
    this.handleFilterDataTableRma()
  },

  handleFilterDataTableRma() {
    $('.btn-filter-rma, #view_closed_rmas_check').on('click', function() {
      $('#rma_dt').DataTable().ajax.reload()
    })
  },

  handleInitializeDataTable() {
    $('#rma_dt').DataTable({
      searching: false,
      pageLength: 25,
      paging: true,
      info: true,
      order: [[0, "desc"]],
      serverSide: true,
      ajax: {
        url: window.location.href,
        data: function(params) {
          params.rma = window.commonJS.handleGetFormData($('#rmas-form'))
          params.view_closed_rmas = $("#view_closed_rmas_check").is(":checked")
        }
      },
      columns: [
        {
          data: 'code',
          searchable: true
        },
        { data: 'name' },
        { data: 'quote_display_id' },
        { data: 'order_display_id' },
        { data: 'status' },
        { data: 'status_updated_at' },
        {
          data: 'edit',
          className: 'text-center',
          orderable: false,
          render: function (data, type) {
            if (data.can_update) {
              let link = `/admin/rmas/${data.id}/edit`

              return `
                <a href="${link}">
                  <i class="far fa-edit"></i>
                </a>
              `
            } else {
              return ''
            }
          }
        },
        {
          data: 'destroy',
          className: 'text-center',
          orderable: false,
          render: function (data, type) {
            if (data.can_destroy) {
              let link = `/admin/rmas/${data.id}`

              return `
                <a href="${link}" class="btn code-red btn-xs" data-method='delete' data-confirm="Are you sure?">
                  <i class='fas fa-trash-alt'></i>
                </a>
              `
            } else {
              return ''
            }
          }
        }
      ]
    })
  }
}
