import { toastErrorMessage } from './toast';

export let initHandsontable = ({
    containerId = '',
    columns = [],
    data = [],
    colHeaders = [],
    additionalOptions = {}
   }
  ) => {

  let container = document.getElementById(containerId);

  let options = {
    data: data,
    height: 'auto',
    stretchH: 'all',
    startRows: 10,
    startCols: 29,
    renderAllRows: true,
    columnSorting: true,
    licenseKey: 'non-commercial-and-evaluation',
    preventOverflow: 'horizontal',
    colHeaders: colHeaders,
    columns: columns,
    ...additionalOptions
  }

  if (data.length < 1000) {
    options = {
      columnSorting: true,
      dropdownMenu: true,
      filters: true,
      ...options
    }
  } else {
    toastErrorMessage(
      'More than 1000 lines found. Please use filters.'
    )
  }

  return handsontable(container, options);
}

export let updateHandsontableData = (table, newData) => {
  table.updateData($.merge(formatTableRowsToObject(table), newData));
}

export let formatTableRowsToObject = table => {
  let currentData = [];

  $.each(table.getData(), (index, _) => {
    if (!table.isEmptyRow(index)) {
      currentData.push(formatTableRowToObject(table, index));
    }
  });

  return currentData;
}

let formatTableRowToObject = (table, row) => {
  let obj = {};

  for (var i = 0; i < table.countCols(); i++) {
    obj[table.colToProp(i)] = table.getDataAtCell(row, i);
  }
  return obj;
}
