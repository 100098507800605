import { uneditableResaleAssessmentItemsTable } from './resale_assessment_items_table';

$(document).on('turbolinks:load', () => {
  if($('#resale-assessments-items-table').length > 0) {
    let resaleAssessmentsItems = $('#resale-assessments-items-table').data('items').data;
    if (resaleAssessmentsItems.length > 0) {
      uneditableResaleAssessmentItemsTable(
        resaleAssessmentsItems,
        'resale-assessments-items-table'
      );
    } else {
      $('#resale-assessments-items-table').html('<p>No Items To Display</p>');
    }
  }
});
