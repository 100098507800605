import {toastErrorMessage} from '../../../../packs/toast';

export let initChangesValidator = hot => {
  return changes => {
    let toSplice = [];
    let errorMessages = new Set();
    let correctionChanges = [];

    changes.forEach(([row, prop, oldValue, newValue], index) => {
      if (oldValue === newValue) return toSplice.unshift(index);

      switch (prop) {
        case 'full_name':
          if (!newValue) {
            toSplice.unshift(index);
            errorMessages.add('Item Description can not be blank');
          }
          break;
        case 'description':
          if (!newValue) {
            toSplice.unshift(index);
            errorMessages.add('Qualified Type can not be blank');
          }
          break;
        case 'qty':
          if (newValue === null || newValue === '') break;

          newValue = parseInt(newValue, 10);
          if (newValue < 0) {
            errorMessages.add('Quantity can not be negative');
          } else if (!isNaN(newValue)) {
            correctionChanges.push(
              [row, 'qty', oldValue, newValue]
            );
          }
          toSplice.unshift(index);
          break;
        case 'rate':
          if (newValue) {
            newValue = parseFloat(newValue.replace(/,/g, '.')).toFixed(3);

            toSplice.unshift(index);
            correctionChanges.push(
              [row, 'rate', oldValue, newValue]
            );
          }
          break;
        case 'currency':
          if (!newValue) {
            toSplice.unshift(index);
            errorMessages.add('Currency can not be blank');
          }
          break;
      }
    });

    let toProcess = changes.reduce((acc, [row, prop, , newValue]) => {
      acc[row] = acc[row] || {};
      acc[row][prop] = newValue;
      return acc;
    }, {});
    Object.entries(toProcess).forEach(([row, props]) => {
      if ((!props.hasOwnProperty('full_name') &&
        hot.getDataAtRowProp(row, 'id') &&
        !hot.getDataAtRowProp(row, 'full_name')) ||
          (props.hasOwnProperty('description') &&
          !hot.getDataAtRowProp(row, 'id'))) {
        errorMessages.add('Item Description can not be blank');
      }
    });

    errorMessages.size > 0 && toastErrorMessage([...errorMessages].join('<br>'));

    toSplice.forEach(index => {
      changes.splice(index, 1);
    });
    correctionChanges.forEach(change => {
      changes.push(change);
    });
  }
};
