window.mbfCompanySetting = {
  init() {
    window.commonJS.handleInitializeSelect2()
    this.handleAddCategory()
    this.handleAddUom()
  },

  handleAddCategory() {
    $("#add-category-button").data("association-insertion-method", 'append').data("association-insertion-node", '.mbf-categories')

    $('.mbf-categories').on('cocoon:before-remove', function (e, insertedItem, originalEvent) {
      $(e.target).closest('hr').remove()
    })
  },

  handleAddUom() {
    $(".add-mbf-uom-button").data("association-insertion-method", 'append').data("association-insertion-node", '.mbf-uoms')
  }
}
