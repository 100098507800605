window.lotForm = {
  init() {
    window.commonJS.handleInitializeSelect2()
    this.handleAssignedItem()
  },

  handleAssignedItem() {
    $('.lot-form').on('keyup', '.item-id', window.lotForm.handleClick());
    $('.lot-form').on('keyup', '.item-serial', window.lotForm.handleClick());
  },

  handleClick() {
    debounce(function (e) {
      var name = e.target.className.indexOf('item-serial') > -1 ? 'serial' : 'item_id'
      var value = e.target.value

      $.ajax({
        type: "GET",
        url: "/admin/orders/fill_item_detail?assigned_item=true",
        dataType: "json",
        data: {
          [name]: value
        },
        success: function (data) {
          var itemForm = $(e.target).parent().parent().parent()
          if (data.item) {
            insertAssignedItemData(itemForm, data.item)
          } else {
            clearAssignedItemData(itemForm)
          }
        }
      });
    }, 700);
  }
}
