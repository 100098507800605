window.orderFilter = {
  init() {
    window.commonJS.handleInitializeSelect2()
    this.handleDatePicker()
  },

  handleDatePicker() {
    if ($("#approved-date-diff").length > 0 && $("#approved-date-diff").attr("data-start-date") && $("#approved-date-diff").attr("data-end-date")) {
      var start = moment($("#approved-date-diff").attr("data-start-date"));
      var end = moment($("#approved-date-diff").attr("data-end-date"));
    }
    else {
      var start = undefined;
      var end = undefined;
    }

    function cb(start, end) {
      $('#approved-date span').html(start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY'));
      $("#created_at_start").val(start.format('MMMM D, YYYY'));
      $("#created_at_end").val(end.format('MMMM D, YYYY'));
    }

    $('#approved-date').on('apply.daterangepicker', function (ev, picker) {
      if (picker.chosenLabel === 'Today') {
        $('#approved-date span').html(`${moment().format('MMMM D, YYYY')} - ${moment().format('MMMM D, YYYY')}`)
        $("#created_at_start").val(moment())
        $("#created_at_end").val(moment())
      }

      if (picker.chosenLabel === 'None') {
        $('#approved-date span').html('')
        $("#created_at_start").val('')
        $("#created_at_end").val('')
      }
    })

    $('#approved-date').daterangepicker({
      autoUpdateInput: false,
      ranges: {
        'None': [moment().subtract(1, 'days'), moment().add(1, 'days')],
        'Today': [moment(), moment()],
        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
      }
    }, cb);

    if (start && end)
      cb(start, end);

    if ($("#shipped-date-diff").length > 0 && $("#shipped-date-diff").attr("data-start-date") && $("#shipped-date-diff").attr("data-end-date")) {
      var start = moment($("#shipped-date-diff").attr("data-start-date"));
      var end = moment($("#shipped-date-diff").attr("data-end-date"));
    }
    else {
      var start = undefined;
      var end = undefined;
    }


    function cb1(start, end) {
      $('#shipped-date span').html(start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY'));
      $("#shipped_date_start").val(start.format('MMMM D, YYYY'));
      $("#shipped_date_end").val(end.format('MMMM D, YYYY'));
    }

    $('#shipped-date').on('apply.daterangepicker', function (ev, picker) {
      if (picker.chosenLabel === 'Today') {
        $('#shipped-date span').html(`${moment().format('MMMM D, YYYY')} - ${moment().format('MMMM D, YYYY')}`)
        $("#shipped_date_start").val(moment())
        $("#shipped_date_end").val(moment())
      }

      if (picker.chosenLabel === 'None') {
        $('#shipped-date span').html('')
        $("#shipped_date_start").val('')
        $("#shipped_date_end").val('')
      }
    })

    $('#shipped-date').daterangepicker({
      startDate: start,
      endDate: end,
      ranges: {
        'None': [moment().subtract(1, 'days'), moment().add(1, 'days')],
        'Today': [moment(), moment()],
        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
      }
    }, cb1);

    if (start && end)
      cb1(start, end);
  }
}
