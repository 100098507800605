window.purchaseOrderItemFields = {
  init() {
    this.handleChangeCategoryItem()
    this.handleInitializeCategorySelect2()
    this.handleInitializeSubCategorySelect2()
    this.handleInitializeMakersSelect2()
    this.handleInitializeModelsSelect2()
    this.handleSetPurchaseOrderTotalValue()
    this.handleCalculatePurchaseOrderPrice()
    this.handleCalculatePurchaseOrderQuantity()
  },

  handleInitializeCategorySelect2() {
    $('.category-item').select2({
      placeholder: "Category",
      allowClear: true
    })
  },

  handleInitializeSubCategorySelect2() {
    $('.subcategory-item').select2({
      placeholder: "Subcategory",
      allowClear: true
    })
  },

  handleInitializeMakersSelect2() {
    $('.js-select-makers-field').select2({
      placeholder: "Maker",
      allowClear: true
    });
  },

  handleInitializeModelsSelect2() {
    $('.js-select-models-field').select2({
      width: '100%',
      placeholder: 'Model',
      allowClear: true,
      templateResult: window.purchaseOrderItemFields.formatResult,
      templateSelection: window.purchaseOrderItemFields.formatSelection,
      ajax: {
        url: '/admin/items/get_makermodels',
        dataType: 'json',
        data: function (params) {
          let id = this[0].id.match(/\d/g).join("")
          let select_id = $('#purchase_order_purchase_order_items_attributes_' + id + '_maker_id')
          let selectedMakerId = $('.nested-fields').find(select_id).val()

          return { search_content: params.term, maker_id: selectedMakerId }
        },
        processResults: function (data) { return { results: data } }
      },
    }).trigger('change')
  },

  handleInitializeCreateSelect2() {
    $(".select2-create").select2({ tags: true })
  },

  handleChangeCategoryItem() {
    $('.category-item').on('change', function () {
      return $.ajax({
        type: "GET",
        url: "/admin/items/update_sub_category",
        data: {
          category_id: $(this).val(),
          dom_id: $(this).attr('id').replace("category_id", "sub_category_id")
        }
      })
    })
  },

  handleSetPurchaseOrderTotalValue() {
    var purcharse_total = $('#purchase_order_total').val()

    if (purcharse_total != undefined && purcharse_total != null) {
      $('#purchase_order_total').val(parseFloat(purcharse_total).toFixed(2))
    }
  },

  handleCalculatePurchaseOrderPrice() {
    $(".poi-price").on('keyup mouseup', function (e) {
      var selectId = $(this).attr("id")
      var numb = selectId.match(/\d/g)
      numb = numb.join("")

      var qty = numb + "_qty"
      var rate = numb + "_rate"
      var total = numb + "_amount"

      var qty_val = $("#purchase_order_purchase_order_items_attributes_" + qty).val()
      var rate_val = $("#purchase_order_purchase_order_items_attributes_" + rate).val()

      var compute = parseFloat(qty_val) * parseFloat(rate_val)

      $("#purchase_order_purchase_order_items_attributes_" + total).val(compute.toFixed(2))

      var arr = $(".item-subtotal")
      var tot = 0
      for (var i = 0; i < arr.length; i++) {
        if (parseFloat(arr[i].value))
          tot += parseFloat(arr[i].value)
      }

      var tax = $("#purchase_order_tax_percentage").val()
      if (tax == "") {
        tax = 0
      }

      var tax_amount = ((parseFloat(tax) / 100) * parseFloat(tot)).toFixed(2)
      $("#purchase_order_tax_amount").val(tax_amount)
      $("#purchase_order_sub_total").val(parseFloat(tot).toFixed(2))

      var freight = $("#purchase_order_freight").val()
      if (freight == "") {
        freight = 0
      }

      var final_computation = (parseFloat(tot) + parseFloat(tax_amount) + parseFloat(freight))
      $("#purchase_order_total").val(final_computation.toFixed(2))

    })
  },

  handleCalculatePurchaseOrderQuantity() {
    $(".poi-qty").on('keyup mouseup', function (e) {
      var selectId = $(this).attr("id")
      var numb = selectId.match(/\d/g)
      numb = numb.join("")

      var qty = numb + "_qty"
      var rate = numb + "_rate"
      var total = numb + "_amount"

      var qty_val = $("#purchase_order_purchase_order_items_attributes_" + qty).val()
      var rate_val = $("#purchase_order_purchase_order_items_attributes_" + rate).val()

      var compute = parseFloat(qty_val) * parseFloat(rate_val)

      $("#purchase_order_purchase_order_items_attributes_" + total).val(compute.toFixed(2))

      var arr = $(".item-subtotal")
      var tot = 0

      for (var i = 0; i<arr.length; i++) {
        if (parseFloat(arr[i].value))
          tot += parseFloat(arr[i].value)
      }

      var tax = $("#purchase_order_tax_percentage").val()
      if (tax == "") {
        tax = 0
      }

      var tax_amount = ((parseFloat(tax) / 100) * parseFloat(tot)).toFixed(2)
      $("#purchase_order_tax_amount").val(tax_amount)
      $("#purchase_order_sub_total").val(parseFloat(tot).toFixed(2))

      var freight = $("#purchase_order_freight").val()
      if (freight == "") {
        freight = 0
      }

      var final_computation = (parseFloat(tot) + parseFloat(tax_amount) + parseFloat(freight))
      $("#purchase_order_total").val(final_computation.toFixed(2))
    })
  },

  formatResult(item) { return item.name },

  formatSelection(item) { return item.text || item.name }
}
