window.purchaseOrdersShow = {
  init() {
    window.commonJS.handleCollapseLink()
    this.handleInitializeDatable()
    this.handleSendEmail()
    this.handleDuplicateAllRecord()
    this.handleDuplicateRecordOnly()
    this.handleAddToInventory()
    this.handleMoveToOrder()
    this.handleNextItem()
    this.handlePrevItem()
    this.handleDisableNextPrevButton()
    this.handlePrintDiv()
  },

  handleInitializeDatable() {
    $('#po_item_dt').DataTable()
  },

  handleSendEmail() {
    $('.sendmail').on('click', function (e) {
      $('#sendTo').modal({
        backdrop: 'static',
        keyboard: false
      })

      $('.send-purchase-order').on('click', function () {
        $.ajax({
          type: 'GET',
          url: '/admin/purchase_orders/send_email',
          dataType: 'json',
          data: {
            customer_email: $('#customer_email').val(),
            po_id: $('#purchase_order_id').val(),
            emails: $('#salesperson_email').val(),
            user_email: $('#current_salesperson').val(),
            cc_myself: $('#cc_myself').val()
          },
          success: function (data) {
            success_message('Successfully Sent.')

            setTimeout((function () {
              success_message('Purchase Order sent')
            }), 3000)
          }
        })
      })
    })
  },

  handleDuplicateAllRecord() {
    $('.duplicate-record-all').on('click', function (e) {
      $.ajax({
        type: 'POST',
        url: '/admin/purchase_orders/clone_with_item',
        dataType: 'json',
        data: { id: $('#purchase_order_id').val() },
        success: function (data) {
          if (data.success) {
            success_message('Successfully Duplicated with Items.')

            setTimeout((function () {
              success_message('Redirecting to Cloned Purchase Order')
              window.location = `/admin/purchase_orders/${data.id}`
            }), 3000)
          } else {
            error_message(data.message)
          }
        }
      })
    })
  },

  handleDuplicateRecordOnly() {
    $('.duplicate-record-only').on('click', function (e) {
      $.ajax({
        type: 'POST',
        url: '/admin/purchase_orders/clone',
        dataType: 'json',
        data: { id: $('#purchase_order_id').val() },
        success: function (data) {
          if (data.success) {
            success_message('Successfully Duplicated.')

            setTimeout((function () {
              success_message('Redirecting to Cloned Purchase Order')
              window.location = `/admin/purchase_orders/${data.id}`
            }), 3000)
          } else {
            error_message(data.message)
          }
        }
      })
    })
  },

  handleAddToInventory() {
    $('.add-to-inventory').on('click', function (e) {
      $.ajax({
        type: 'POST',
        url: '/admin/purchase_orders/add_to_inventory',
        dataType: 'json',
        data: { id: $('#purchase_order_id').val() },
        success: function (data) {
          if (data.success) {
            success_message('Successfully Added to Inventory.')

            setTimeout((function () {
              window.location.href = `/admin/items?item[purchase_order_id]=${data.id}`
            }), 3000)
          } else {
            error_message(data.message)
          }
        }
      })
    })
  },

  handleMoveToOrder() {
    $('.move_to_ordered').on('click', function (e) {
      $.ajax({
        type: 'POST',
        url: '/admin/purchase_orders/move_to_ordered',
        dataType: 'json',
        data: { purchase_order_id: $('#purchase_order_id').val() },
        success: function (data) { success_message('Successfully Moved to Ordered.') }
      })
    })
  },

  handleNextItem() {
    $('.nextItem').on('click', function (e) {
      $.ajax({
        type: 'GET',
        url: '/admin/purchase_orders/get_next_item',
        dataType: 'json',
        data: {
          purchase_order_id: $('#purchase_order_id').val()
        },
        success: function (data) {
          window.location.href = '/admin/purchase_orders/' + data.id
        }
      })
    })
  },

  handlePrevItem() {
    $('.prevItem').on('click', function (e) {
      $.ajax({
        type: 'GET',
        url: '/admin/purchase_orders/get_prev_item',
        dataType: 'json',
        data: {
          purchase_order_id: $('#purchase_order_id').val()
        },
        success: function (data) {
          window.location.href = '/admin/purchase_orders/' + data.id
        }
      })
    })
  },

  handlePrintDiv() {
    window.onafterprint = function () {
      window.location.reload(true)
    }

    $('.print-div').on('click', function (e) {
      var divName = $(e.target).attr('data-print-div-name')
      var printContents = document.getElementById(divName).innerHTML
      var originalContents = document.body.innerHTML

      document.body.innerHTML = printContents
      window.print()
      document.body.innerHTML = originalContents
    })
  },

  handleDisableNextPrevButton() {
    var lastId = $('#lastId').val()

    if (lastId == $('#purchase_order_id').val()) {
      $('.prevItem').addClass('disabled')
    } else {
      $('.prevItem').removeClass('disabled')
    }

    var nextId = $('#nextId').val()

    if (nextId == $('#purchase_order_id').val()) {
      $('.nextItem').addClass('disabled')
    } else {
      $('.nextItem').removeClass('disabled')
    }
  }
}
