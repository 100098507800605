import 'form-serializer';
import { getLoadModule } from '../load_modules/load_module';
import { initHandsontable, updateHandsontableData, formatTableRowsToObject } from '../../packs/handsontable';
import { toastErrorMessage } from '../../packs/toast';
import {
  editableResaleAssessmentItemsTable,
  uneditableResaleAssessmentItemsTable
} from './resale_assessment_items_table';

let searchItemsDataHandsontable;
let assessmentsItemsData;
let resaleAssessmentsItemFormTable;
let dropdownItemOptions;
let itemsForDestroy = [];

$(document).on('turbolinks:load', () => {
  if ($('.resale-assessment-form').length > 0) {

    window.commonJS.handleInitializeSelect2();
    window.commonJS.handleCollapseLink();
    initCustomerAutocomplete();
    searchAssessmentItemDataEvents();
    addItemEvents();
    submitResaleAssessmentForm();
    setExistingAssessmentItems();
    autoChoseLoadIdInModal();
  }
});

// Customer Autocomplete

let disableCustomerSelect = () => {
  if ($('#resale_assessment_load_module_id').val()) {
    $('#resale_assessment_inbound_customer_id').attr('readonly', 'readonly');
  } else {
    $('#resale_assessment_inbound_customer_id').removeAttr('readonly');
  }
}

let selectCustomerAccordingToLoad = (loadId = $('#resale_assessment_load_module_id').val()) => {
  if (loadId) {
    getLoadModule(loadId).then(responce => {
      $('#resale_assessment_inbound_customer_id').val(responce.inbound_customer.id)
        .trigger('change');
    }).fail(() =>
      toastErrorMessage('Something went wrong. Please select another Load.')
    );
  }
}

let initCustomerAutocomplete = () => {
  disableCustomerSelect();

  $('#resale_assessment_load_module_id').change(e => {
    selectCustomerAccordingToLoad();
    disableCustomerSelect();
  });
}

// Resale Assessment Items Creation

let autoChoseLoadIdInModal = () => {
  $('#add-resale-assessment-items-modal').on('shown.bs.modal', () => {
   if ($('#resale_assessment_load_module_id').val()) {
      $('.js-search-by-load-module-id').val(
        $('#resale_assessment_load_module_id').val()
      ).trigger('change');
   }
  });
}

let getAssessmentItemData = (refresh = true) => {
  let itemsSearchForm = $('.assessment-items-data-search-form');
  let searchData = itemsSearchForm.serializeObject();

  if (resaleAssessmentsItemFormTable) {
    searchData['q']['id_not_in'] = resaleAssessmentsItemFormTable.getDataAtCol(7)
      .filter(Number);
  }

  if (!refresh) {
    searchData['page'] = itemsSearchForm.attr('page') || 1;
  }

  return $.ajax({
    url: itemsSearchForm.attr('action'),
    type: 'POST',
    data: searchData
  });
}

let searchAssessmentItemDataEvents = () => {
  $('.js-submit-assessments-item-search').click(e => {
    e.preventDefault();

    getAssessmentItemData().then(responce => {
      setMoreButton(responce.pagination);
      $('#result-section').html('');
      assessmentsItemsData = responce.data;
      dropdownItemOptions = responce.dropdown_options;
      initSearchItemsDataHandsontable(responce.data);
    });
  });

  $('.js-more-assessment-items-button').click(e => {
    e.preventDefault();

    getAssessmentItemData(false).then(responce => {
      setMoreButton(responce.pagination);
      assessmentsItemsData = [...assessmentsItemsData, ...responce.data];
      updateHandsontableData(searchItemsDataHandsontable, responce.data);
    });
  });
}

let setMoreButton = paginationData => {
  if (paginationData.current_page != paginationData.last_page) {
    $('.js-more-assessment-items-button').show();
    $('.assessment-items-data-search-form').attr('page', paginationData.next_page);
  } else {
    $('.js-more-assessment-items-button').hide();
  }
}

let initSearchItemsDataHandsontable = data => {
  searchItemsDataHandsontable = initHandsontable({
    containerId: 'result-section',
    data: data,
    colHeaders: [
      '',
      'ID',
      'Type',
      'Weight',
      'UOM',
      'Serial No',
      'Load ID',
      'Qualified Type',
      'Customer'
    ],
    columns: [
      {
        data: 'selected',
        type: 'checkbox',
        className: 'htCenter'
      },
      {
        data: 'load_inventory_code',
        editor: false,
        skipColumnOnPaste: true,
        className: 'htCenter',
        renderer: 'html'
      },
      {
        data: 'assessments_type',
        editor: false,
        skipColumnOnPaste: true,
        className: 'htCenter',
        renderer: 'html'
      },
      {
        data: 'weight',
        editor: false,
        skipColumnOnPaste: true,
        className: 'htCenter',
        renderer: 'html'
      },
      {
        data: 'uom',
        editor: false,
        skipColumnOnPaste: true,
        className: 'htCenter',
        renderer: 'html'
      },
      {
        data: 'serial_no',
        editor: false,
        skipColumnOnPaste: true,
        className: 'htCenter',
        renderer: 'html'
      },
      {
        data: 'load_code',
        editor: false,
        skipColumnOnPaste: true,
        className: 'htCenter',
        renderer: 'html'
      },
      {
        data: 'status',
        editor: false,
        skipColumnOnPaste: true,
        className: 'htCenter',
        renderer: 'html'
      },
      {
        data: 'acquired_from',
        editor: false,
        skipColumnOnPaste: true,
        className: 'htCenter',
        renderer: 'html'
      }
    ],
    additionalOptions: {
      filters: true
    }
  });
}

let addItemEvents = () => {
  let itemIds;

  $('.js-assessment-add-visible').click(e => {
    e.preventDefault();
    itemIds = searchItemsDataHandsontable.getDataAtCol(1).filter(Number);
    if (itemIds.length > 0) {
      addItemsToAssessmentFormTable(findItemByIds(itemIds));
      $('#add-resale-assessment-items-modal').modal('toggle');
      clearModalData();
    } else {
      toastErrorMessage('Nothing To Select');
    }
  })

  $('.js-assessment-add-selected').click(e => {
    e.preventDefault();

    const filters = searchItemsDataHandsontable.getPlugin('filters');

    filters.addCondition(0, 'eq', [true]);
    filters.filter();

    itemIds = searchItemsDataHandsontable.getDataAtCol(1).filter(Number);
    if (itemIds.length > 0) {
      addItemsToAssessmentFormTable(findItemByIds(itemIds));
      $('#add-resale-assessment-items-modal').modal('toggle');
      clearModalData();
    } else {
      filters.clearConditions();
      filters.filter();
      toastErrorMessage('Nothing Selected');
    }
  })
}

let clearModalData = () => {
  $('#result-section').html('');
  $('.assessment-items-data-search-form').trigger('reset');
  $('.assessment-items-data-search-form .select2').trigger('change');
}

let findItemByIds = selectedIds => {
  return assessmentsItemsData.filter(item => selectedIds.includes(item.load_inventory_code));
}

// Resale Assessments Items Form

let addItemsToAssessmentFormTable = data => {
  if (resaleAssessmentsItemFormTable) {
    updateHandsontableData(resaleAssessmentsItemFormTable, data);
  } else {
    initResaleAssessmentsItemsFormTable(data, dropdownItemOptions);
  }
}

let initResaleAssessmentsItemsFormTable = (data, dropdownOptions = {}) => {
  resaleAssessmentsItemFormTable = editableResaleAssessmentItemsTable(
    data,
    'resale-assessments-items-form-table',
    dropdownOptions
  );

  resaleAssessmentsItemFormTable.addHook('beforeRemoveRow', (index, amount, physicalRows) => {
    $.each(physicalRows, (_, row) => {
      let id = resaleAssessmentsItemFormTable.getDataAtCell(row, 0);
      if (id) {
        itemsForDestroy.push({ id: id, _destroy: true });
      }
    });
  });
}

let submitResaleAssessmentForm = () => {
  $('.js-save-resale-assessment').click(e => {
    e.preventDefault();

    let formData = $('.resale-assessment-form').serializeObject();

    if (resaleAssessmentsItemFormTable) {
      let items_attributes = formatTableRowsToObject(
        resaleAssessmentsItemFormTable
      );

      formData.resale_assessment['resale_assessment_items_attributes'] = $.merge(
        items_attributes,
        itemsForDestroy
      );
    }

    $.ajax({
      url: $('.resale-assessment-form').attr('action') + '.json',
      method: $('.resale-assessment-form').attr('method'),
      data: formData,
      success: response => {
        if (response.errors && response.errors.length > 0) {
          toastErrorMessage(response.errors.join('<br/>'));
        } else {
          window.location.href = `admin/resale_assessments/${data.id}`;
        }
      },
      error: (_jqXHR, textStatus, _errorThrown) => {
        toastErrorMessage(`Request failed: ${textStatus}`);
      }
    });
  });
}

let setExistingAssessmentItems = () => {
  let assessmentItems = $('.resale-assessment-form').data('assessment-items');

  if (assessmentItems.data.length == 0) return;

  if($('#resale-assessments-items-form-table').data('editable')) {
    initResaleAssessmentsItemsFormTable(
      assessmentItems.data,
      assessmentItems.dropdown_options
    );
  } else {
    uneditableResaleAssessmentItemsTable(
      assessmentItems.data,
      'resale-assessments-items-form-table'
    );
  }
}
