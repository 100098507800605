import {initHandsontable} from '../../packs/handsontable';

export let initTransferItemsFormTable = (data, containerId, editable, hiddenColumns) => {
  if (editable) {
    return editableTransferItemsFormTable(data, containerId, hiddenColumns);
  }
  return nonEditableTransferItemsFormTable(data, containerId, hiddenColumns);
};

let nonEditableTransferItemsFormTable = (data, containerId, hiddenColumns) => {
  return initHandsontable({
    containerId: containerId,
    data: data,
    additionalOptions: {
      hiddenColumns: {
        columns: hiddenColumns ? hiddenColumns.map(col => {
          Number(col);
        }) : [],
        indicators: true
      },
      contextMenu: [
        'hidden_columns_hide',
        'hidden_columns_show'
      ],
      afterHideColumns: (currentHideConfig, destinationHideConfig, _actionPossible, _stateChanged) => {
        $('#hidden-columns').val(destinationHideConfig);
      },
      afterUnhideColumns: (currentHideConfig, destinationHideConfig, _actionPossible, _stateChanged) => {
        $('#hidden-columns').val(destinationHideConfig);
      }
    },
    colHeaders: [
      'ID',
      'Serial No',
      'Qualified Type',
      'Type',
      'UOM',
      'Customer ID',
      'Load ID',
      'Load Inventory ID',
      'Pallet ID',
      'Weight KG',
      'Acquired From',
      'Description/Form Factor',
      'Make',
      'Model',
      'Wholesale FMV',
      'Cost',
      'Purchase Price',
      'Screen Size Inches',
      'CPU Type',
      'Original Hard Drive GB',
      'Original Memory GB',
      'Battery',
      'HD Serial No',
      'Overall Grade',
      'Comment',
      'Asset Tag',
      'Item Type',
      'Blancco Wiped',
      'Blancco Hardware Test',
      'Adapter'
    ],
    columns: [
      {
        data: 'id',
        editor: false,
        className: 'htCenter transfer-item-id',
        renderer: 'html'
      },
      {
        data: 'serial_no',
        editor: false,
        className: 'htCenter',
        renderer: 'html'
      },
      {
        data: 'status',
        editor: false,
        className: 'htCenter',
        renderer: 'html'
      },
      {
        data: 'types',
        editor: false,
        className: 'htCenter',
        renderer: 'html'
      },
      {
        data: 'uom',
        editor: false,
        className: 'htCenter',
        renderer: 'html'
      },
      {
        data: 'customer_code',
        editor: false,
        className: 'htCenter',
        renderer: 'html'
      },
      {
        data: 'load_code',
        editor: false,
        className: 'htCenter',
        renderer: 'html'
      },
      {
        data: 'load_inventory_code',
        editor: false,
        className: 'htCenter',
        renderer: 'html'
      },
      {
        data: 'pallet_id',
        editor: false,
        className: 'htCenter',
        renderer: 'html'
      },
      {
        data: 'weight',
        editor: false,
        className: 'htCenter',
        renderer: 'html'
      },
      {
        data: 'acquired_from',
        editor: false,
        className: 'htCenter',
        renderer: 'html'
      },
      {
        data: 'description',
        editor: false,
        className: 'htCenter',
        renderer: 'html'
      },
      {
        data: 'make',
        editor: false,
        className: 'htCenter',
        renderer: 'html'
      },
      {
        data: 'model',
        editor: false,
        className: 'htCenter',
        renderer: 'html'
      },
      {
        data: 'wholesale_fmv',
        editor: false,
        className: 'htCenter',
        renderer: 'html',
        type: 'numeric'
      },
      {
        data: 'buy_price',
        editor: false,
        className: 'htCenter',
        renderer: 'html',
        type: 'numeric'
      },
      {
        data: 'freight',
        editor: false,
        className: 'htCenter',
        renderer: 'html',
        type: 'numeric'
      },
      {
        data: 'screen_size',
        editor: false,
        className: 'htCenter',
        renderer: 'html'
      },
      {
        data: 'cpu_type',
        editor: false,
        className: 'htCenter',
        renderer: 'html'
      },
      {
        data: 'optional_hard_drive',
        editor: false,
        className: 'htCenter',
        renderer: 'html'
      },
      {
        data: 'optional_memory',
        editor: false,
        className: 'htCenter',
        renderer: 'html'
      },
      {
        data: 'battery',
        editor: false,
        className: 'htCenter',
        renderer: 'html'
      },
      {
        data: 'hd_serial_no',
        editor: false,
        className: 'htCenter',
        renderer: 'html'
      },
      {
        data: 'overall_grade',
        editor: false,
        className: 'htCenter',
        renderer: 'html'
      },
      {
        data: 'comment',
        editor: false,
        className: 'htCenter',
        renderer: 'html'
      },
      {
        data: 'asset_tag',
        editor: false,
        className: 'htCenter',
        renderer: 'html'
      },
      {
        data: 'scrape_or_resale',
        editor: false,
        className: 'htCenter',
        renderer: 'html'
      },
      {
        data: 'blancco_wiped',
        editor: false,
        className: 'htCenter',
        renderer: 'html'
      },
      {
        data: 'blancco_hardware_test',
        editor: false,
        className: 'htCenter',
        renderer: 'html'
      },
      {
        data: 'adapter',
        editor: false,
        className: 'htCenter',
        renderer: 'html'
      }
    ]
  });
};

export let editableTransferItemsFormTable = (data, containerId, hiddenColumns = [], dropdownOptions = {}) => {
  return initHandsontable({
    containerId: containerId,
    data: data,
    additionalOptions: {
      dropdownMenu: true,
      filters: true,
      hiddenColumns: {
        columns: hiddenColumns ? hiddenColumns.map(col => {
          Number(col);
        }) : [],
        indicators: true
      },
      contextMenu: [
        'row_above',
        'row_below',
        'remove_row',
        'hidden_columns_hide',
        'hidden_columns_show'
      ],
      beforeRemoveRow: (index, amount, physicalRows) => {
        let destroyTransferItemEl = $('#destroy_transfer_item_arr');
        let oldDestroyArr = [destroyTransferItemEl.val()].flat();
        let newDestroyArr = physicalRows.map(e => {
          return $(`#transfer-items-table tr:nth-child(${e + 1}) td.transfer-item-id`).text();
        }) || [];
        let destroyArr = oldDestroyArr
          .concat(newDestroyArr)
          .filter(word => word.length > 0);
        destroyTransferItemEl.val(destroyArr);
      },
      afterHideColumns: (currentHideConfig, destinationHideConfig, _actionPossible, _stateChanged) => {
        $('#hidden-columns').val(destinationHideConfig);
      },
      afterUnhideColumns: (currentHideConfig, destinationHideConfig, _actionPossible, _stateChanged) => {
        $('#hidden-columns').val(destinationHideConfig);
      }
    },
    colHeaders: [
      'ID',
      'Serial No',
      'Qualified Type',
      'Type',
      'UOM',
      'Customer ID',
      'Load ID',
      'Load Inventory ID',
      'Pallet ID',
      'Weight KG',
      'Acquired From',
      'Description/Form Factor',
      'Make',
      'Model',
      'Wholesale FMV',
      'Cost',
      'Purchase Price',
      'Screen Size Inches',
      'CPU Type',
      'Original Hard Drive GB',
      'Original Memory GB',
      'Battery',
      'HD Serial No',
      'Overall Grade',
      'Comment',
      'Asset Tag',
      'Item Type',
      'Blancco Wiped',
      'Blancco Hardware Test',
      'Adapter'
    ],
    columns: [
      {
        data: 'id',
        editor: false,
        skipColumnOnPaste: true,
        className: 'htCenter transfer-item-id',
        renderer: 'html'
      },
      {
        data: 'serial_no',
        editor: false,
        skipColumnOnPaste: true,
        className: 'htCenter',
        renderer: 'html'
      },
      {
        data: 'status',
        editor: false,
        skipColumnOnPaste: true,
        className: 'htCenter',
        renderer: 'html'
      },
      {
        data: 'types',
        editor: false,
        skipColumnOnPaste: true,
        className: 'htCenter',
        renderer: 'html'
      },
      {
        data: 'uom',
        editor: false,
        skipColumnOnPaste: true,
        className: 'htCenter',
        renderer: 'html'
      },
      {
        data: 'customer_code',
        editor: false,
        skipColumnOnPaste: true,
        className: 'htCenter',
        renderer: 'html'
      },
      {
        data: 'load_code',
        editor: false,
        skipColumnOnPaste: true,
        className: 'htCenter',
        renderer: 'html'
      },
      {
        data: 'load_inventory_code',
        editor: false,
        skipColumnOnPaste: true,
        className: 'htCenter',
        renderer: 'html'
      },
      {
        data: 'pallet_id',
        editor: false,
        skipColumnOnPaste: true,
        className: 'htCenter',
        renderer: 'html'
      },
      {
        data: 'weight',
        editor: false,
        skipColumnOnPaste: true,
        className: 'htCenter',
        renderer: 'html'
      },
      {
        data: 'acquired_from',
        editor: false,
        skipColumnOnPaste: true,
        className: 'htCenter',
        renderer: 'html'
      },
      {
        data: 'description',
        editor: false,
        skipColumnOnPaste: true,
        className: 'htCenter',
        renderer: 'html'
      },
      {
        data: 'make',
        editor: false,
        skipColumnOnPaste: true,
        className: 'htCenter',
        renderer: 'html'
      },
      {
        data: 'model',
        editor: false,
        skipColumnOnPaste: true,
        className: 'htCenter',
        renderer: 'html'
      },
      {
        data: 'wholesale_fmv',
        className: 'htCenter',
        renderer: 'html',
        type: 'numeric',
        allowInvalid: false
      },
      {
        data: 'buy_price',
        className: 'htCenter',
        renderer: 'html',
        type: 'numeric',
        allowInvalid: false
      },
      {
        data: 'freight',
        className: 'htCenter',
        renderer: 'html',
        type: 'numeric',
        allowInvalid: false
      },
      {
        data: 'screen_size',
        editor: false,
        skipColumnOnPaste: true,
        className: 'htCenter',
        renderer: 'html'
      },
      {
        data: 'cpu_type',
        editor: false,
        skipColumnOnPaste: true,
        className: 'htCenter',
        renderer: 'html'
      },
      {
        data: 'optional_hard_drive',
        skipColumnOnPaste: true,
        className: 'htCenter',
        renderer: 'html'
      },
      {
        data: 'optional_memory',
        skipColumnOnPaste: true,
        className: 'htCenter',
        renderer: 'html'
      },
      {
        data: 'battery',
        editor: false,
        skipColumnOnPaste: true,
        className: 'htCenter',
        renderer: 'html'
      },
      {
        data: 'hd_serial_no',
        editor: false,
        skipColumnOnPaste: true,
        className: 'htCenter',
        renderer: 'html'
      },
      {
        data: 'overall_grade',
        editor: false,
        skipColumnOnPaste: true,
        className: 'htCenter',
        renderer: 'html'
      },
      {
        data: 'comment',
        editor: false,
        skipColumnOnPaste: true,
        className: 'htCenter',
        renderer: 'html'
      },
      {
        data: 'asset_tag',
        editor: false,
        skipColumnOnPaste: true,
        className: 'htCenter',
        renderer: 'html'
      },
      {
        data: 'scrape_or_resale',
        editor: false,
        skipColumnOnPaste: true,
        className: 'htCenter',
        renderer: 'html'
      },
      {
        data: 'blancco_wiped',
        editor: false,
        skipColumnOnPaste: true,
        className: 'htCenter',
        renderer: 'html'
      },
      {
        data: 'blancco_hardware_test',
        editor: false,
        skipColumnOnPaste: true,
        className: 'htCenter',
        renderer: 'html'
      },
      {
        data: 'adapter',
        editor: false,
        skipColumnOnPaste: true,
        className: 'htCenter',
        renderer: 'html'
      }
    ]
  });
};
