import {qtyRenderer} from './work_order/hot_renderers';
import {isDateReceivedEmpty, isLoadReceivingsReadOnly} from './load_module_general';

window.loadReceiving = {
  qualifiedWeightCell: 8,
  unqualifiedWeightCell: 9,
  qualifiedTypeCell: 7,
  init() {
    this.handleShowReceivingDetail()
    this.handleSaveLoadReceiving()
    this.handleRenderLoadReceivingTable()
    this.handleRenderLoadReceivingDetailHot()
    this.handleRenderSaleOrders()
    this.handleLoadReceivingPopup()
    this.handleDestroySalesOrder()
    this.handleSaveSaleOrdersBeforeExport()
    this.handleImportSaleOrderFromBlancco()
    this.handleOpenLoadReceiving()
    this.handleExportFullReport()
  },

  handleExportFullReport() {
    $('#export-full-report-btn').on('click', e => {
      $('#additional-export-file').modal('hide');
      $('#overlay').addClass('d-flex');

      let loadModuleIds = [$('#load_module_id').val()];
      let checkedOptions = $('.export-options input[type="checkbox"]:checked');
      let exportOptions = $.map(checkedOptions, (item, _index) => item.value);

      $.ajax({
        type: 'GET',
        url: '/admin/reports/load_report',
        data: {
          'load_report[load_module_ids]':loadModuleIds,
          'export_options': exportOptions,
          'commit': 'export_full'
        },
        success: data => {
          if (data.background_job_id) {
            window.loadReceiving.checkReportStatus = setInterval(
              window.loadReceiving.handleCheckReportStatus,
              5000,
              data.background_job_id
            )
          }
        }
      })
    })
  },

  handleCheckReportStatus(background_job_id) {
    $.ajax({
      type: 'GET',
      url: `/admin/background_jobs/${background_job_id}/status.json`,
      success: data => {
        if (data.status === 'done' || data.status === 'failed') {
          $('#overlay').removeClass('d-flex');
          clearInterval(window.loadReceiving.checkReportStatus);

          if (data.status === 'done') {
            window.location = `/admin/background_jobs/${background_job_id}/download_file`
          } else if (data.status === 'failed') {
            error_message(data.error_message)
          }
        }
      }
    })
  },

  handleOpenLoadReceiving() {
    if (new URLSearchParams(location.search).get('open_load_receiving')) {
      window.loadReceiving.handleInitializeLoadReceivingHandsontable();
      window.loadReceiving.toggleLoadReceivingWarning();
    }
  },

  handleShowReceivingDetail() {
    $('#load-receiving-table').on('click', '.show-detail', function () {
      var qty = parseInt($(this).closest('tr').find('.qty').text())
      $('.receiving-detail-form #qty').val(qty)
      $('#receiving-dialog #receiving-detail-table').html('')
      $('#receiving-dialog #receiving-id').val($(this).text())
      $('#receiving-dialog .spinner-border').removeClass('hidden')
      $('#import-file-receiving-detail').attr('action', `/admin/load_module_receivings/${$(this).text()}/import_file`)
      $('#receiving-dialog .total-items span').text('')
      $('#receiving-dialog').modal({ backdrop: 'static', keyboard: false })
      $('#receiving-dialog').modal('show');
    });
  },

  handleRenderLoadReceivingDetailHot() {
    $('#receiving-dialog').on('shown.bs.modal', function () {
      $('#receiving-dialog .spinner-border').addClass('hidden')
      window.loadReceivingDetail.handleInitializeLoadReceivingDetailHandsontable(1, $('#receiving-dialog #receiving-id').val())
    })
  },

  handleInitializeLoadReceivingHandsontable(page = 1) {
    let readOnly = isLoadReceivingsReadOnly();

    this.handleGetLoadReceivingData(page).then(function(result) {
      let container = document.getElementById('table-data');
      let options = {
        data: result.data,
        readOnly: readOnly,
        width: '100%',
        height: 'auto',
        stretchH: 'all',
        startRows: 10,
        startCols: 15,
        colWidths: [25, 75, 75, 25, 25, 25, 25, 75, 50],
        minSpareRows: 10,
        renderAllRows: true,
        columnSorting: true,
        licenseKey: 'non-commercial-and-evaluation',
        contextMenu: readOnly ? [] : [
          'row_above',
          'row_below',
          'remove_row'
        ],
        colHeaders: [
          'ID', 'Item No', 'Quantity(Net Weight)', 'UOM', 'QTY', 'Gross', 'Tare', 'Qualified Type',
          'Qualified Weight', 'Unqualified Weight', 'Production Run',
          'Pallet ID', 'GRPO Doc Entry', 'Receiving Doc Entry'
        ],
        columns: [
          {
            data: 'id',
            readOnly: true,
            className: 'htCenter load-receiving-id',
            renderer: 'html'
          },
          {
            data: 'item_no',
            className: 'item-no',
            type: 'dropdown',
            strict: true,
            allowInvalid: false,
            source: result.load_item_types
          },
          {
            data: 'quantity',
            readOnly: true,
            className: 'quantity-net-weight',
            type: 'numeric',
            numericFormat: { pattern: '0.00' }
          },
          {
            data: 'uom',
            type: 'dropdown',
            strict: true,
            allowInvalid: false,
            source: result.load_uoms
          },
          {
            data: 'qty',
            className: 'qty',
            type: 'numeric',
            editor: 'numeric',
            validator: 'numeric',
            allowInvalid: false,
            renderer: qtyRenderer
          },
          {
            data: 'gross',
            className: 'gross',
            type: 'numeric',
            validator: 'numeric',
            allowInvalid: false,
            numericFormat: { pattern: '0.00' }
          },
          {
            data: 'tare',
            className: 'tare',
            type: 'numeric',
            validator: 'numeric',
            allowInvalid: false
          },
          {
            data: 'qualified_type',
            type: 'dropdown',
            strict: true,
            allowInvalid: false,
            source: result.load_qualified_types
          },
          {
            data: 'qualified_weight',
            validator: 'numeric',
            type: 'numeric',
            allowInvalid: false,
            numericFormat: { pattern: '0.00' }
          },
          {
            data: 'unqualified_weight',
            validator: 'numeric',
            allowInvalid: false,
            type: 'numeric',
            numericFormat: { pattern: '0.00' }
          },
          {
            data: 'production_run',
            validator: 'numeric',
            allowInvalid: false
          },
          {
            data: 'pallet_id',
            readOnly: true
          },
          {
            data: 'grop_doc_entry',
            type: 'text'
          },
          {
            data: 'receiving_doc_entry',
            type: 'text'
          },
        ],
        beforeChange: (changes, source) => {
          if (source === 'updateData' || source === 'loadData') return;

          let correctionChanges = [];
          for (let i = changes.length - 1; i >= 0; i--) {
            let [row, prop, oldValue, newValue] = changes[i];

            if (prop === 'qty' &&
              newValue !== null &&
              newValue !== ''
            ) {
              changes.splice(i, 1);
              newValue = parseInt(newValue, 10);
              correctionChanges.push(
                [row, 'qty', oldValue, newValue]
              );
            }
          }
          correctionChanges.forEach(change => {
            changes.push(change);
          });
        },
        cells: function (row, col, prop) {
          if (prop == 'qty') {
            var loadReceivingId = $(`#table-data tbody tr:nth-child(${row + 1}) td.load-receiving-id`).text()

            return { className: `qty-${loadReceivingId}` }
          }
        },
        afterSetDataAtCell: function (changes, source) {
          if (changes[0][1] === 'uom') {
            return false;
          }

          if (loadReceivingHot.getDataAtCell(changes[0][0], 3) === null) {
            setTimeout(() => { loadReceivingHot.setDataAtCell(changes[0][0], 3, 'KGS') }, 0)
          }

          $.each(changes, function (index, value) {
            if (value) {
              if (value[1] === 'tare' || value[1] === 'gross') {
                setTimeout(function () { window.loadReceiving.handleNetWeightCalculation(value) }, 0)
              }

              if (value[1] === 'qualified_type') { window.loadReceiving.handleQualifiedType(value) }
            }
          })
        },
        beforeRemoveRow: function (index, amount, physicalRows) {
          let oldDestroyArr = [$('#destroy_load_receiving_arr').val()].flat()
          let newDestroyArr = physicalRows.map(e => $(`#load-receiving-table tr:nth-child(${e + 1}) td.load-receiving-id`).text()) || []
          let destroyArr = (oldDestroyArr.concat(newDestroyArr)).filter(word => word.length > 0)
          $('#destroy_load_receiving_arr').val(destroyArr)
        },
        afterOnCellMouseDown: function (event, coords, TD) {
          if ($(TD).hasClass('selector')) {
            loadReceivingHot.selectCell(coords.row, coords.col)
            loadReceivingHot.getActiveEditor().beginEditing()
          }
        },
        afterChange: (changes) => {
          if (changes != null) {
            changes.forEach(([row, prop, oldValue, newValue]) => {
              $('#receiving_change').val(oldValue != newValue)
            })
          }
        }, afterCreateRow: function() {
          if (loadReceivingHot) {
            loadReceivingHot.render()
          }
        }, afterInit: function() {
          $('#load-receiving-table').removeClass('overlap')
          $('.add-load-receiving-button #save').removeClass('disabled')
        }
      }

      $('.ht_master.handsontable:first').remove()
      loadReceivingHot = handsontable(container, options)
    })
  },

  toggleLoadReceivingWarning() {
    if (isDateReceivedEmpty()) {
      $('#load-receiving-warning').removeClass('d-none');
    } else {
      $('#load-receiving-warning').addClass('d-none');
    }
  },

  async handleGetLoadReceivingData(page = 1) {
    $('#load-receiving-table').addClass('overlap');

    return $.ajax({
      type: 'GET',
      url: '/admin/load_module_receivings',
      dataType: 'json',
      data: {
        id: $('#load_module_id').val(),
        page: page
      },
      success: () => {
        $('#load-receiving-table').removeClass('overlap');
      }
    });
  },

  handleNetWeightCalculation(changes) {
    var column_name = changes[1]
    var row = changes[0]
    var tare = parseFloat(column_name == 'tare' ? changes[3] : $(`#table-data tr:nth-child(${row + 1}) .tare`).text()) || 0
    var gross = parseFloat(column_name == 'gross' ? changes[3] : $(`#table-data tr:nth-child(${row + 1}) .gross`).text()) || 0

    var quantityNetWeight = gross && gross > tare  ? gross - tare : 0
    loadReceivingHot.setDataAtCell(row, 2, quantityNetWeight)
  },

  handleQualifiedType(changes) {
    var changeFrom = changes[2]
    var changeTo = changes[3]
    var row = changes[0]
    var quantityNetWeight = loadReceivingHot.getDataAtCell(row, 2)
    var qualifiedType = ['Yes', 'Qualified', 'Redeployment', 'Resale']
    var nonQualifiedType = ['No']

    if (qualifiedType.includes(changeFrom)) {
      loadReceivingHot.setDataAtCell(row, window.loadReceiving.qualifiedWeightCell, null)
    } else if (nonQualifiedType.includes(changeFrom)) {
      loadReceivingHot.setDataAtCell(row, window.loadReceiving.unqualifiedWeightCell, null)
    }

    if (qualifiedType.includes(changeTo)) {
      loadReceivingHot.setDataAtCell(row, window.loadReceiving.qualifiedWeightCell, quantityNetWeight)
    } else if (nonQualifiedType.includes(changeTo)) {
      loadReceivingHot.setDataAtCell(row, window.loadReceiving.unqualifiedWeightCell, quantityNetWeight)
    }
  },

  handleSaveLoadReceiving() {
    $('.add-load-receiving-button #save').on('click', function(e) {
      $('#load-receiving-table').addClass('overlap')
      $(this).addClass('disabled')

      loadReceivingHot.deselectCell()
      setTimeout(() => { window.loadReceiving.handleSendAjaxSaveLoadReceiving() }, 800)
    })
  },

  handleSendAjaxSaveLoadReceiving(exportAction = null, showToastMessage = true) {
    $.ajax({
      type: 'PATCH',
      url: `/admin/load_module_receivings/${$('#load_module_id').val()}`,
      data: {
        load_receiving_data: loadReceivingHot.getData(),
        id: $('#load_module_id').val(),
        destroy_array: $('#destroy_load_receiving_arr').val(),
        load_modules: {
          load_status_id: $('#load_module_load_status_id').val(),
          date_received: $('#load_module_date_received').val(),
          trailer_no: $('#load_module_trailer_no').val(),
          seal_no: $('#load_module_seal_no').val(),
          bol_no: $('#load_module_bol_no').val(),
          client_no: $('#load_module_client_no').val()
        },
        export_action: exportAction,
        show_toast_message: showToastMessage
      },
      success: response => {
        window.loadModuleGeneral.updateBackgroundJobId(
          response.background_job_id);
        window.loadModuleGeneral.checkBackgroundJob(
          response.background_job_id, () => {
            $('#receiving_change').val(false);
            window.loadModuleGeneral.handleGetLoadModuleGeneralInfo();
          });
      }
    })
  },

  handleRenderLoadReceivingTable() {
    $('.receiving-tab').on('click', e => {
      if ($(e.currentTarget).hasClass('active')) return;

      window.loadReceiving
        .handleInitializeLoadReceivingHandsontable(
          this.getCurrentPage()
        );
      window.loadReceiving.toggleLoadReceivingWarning();
    });
  },

  handleLoadReceivingPopup() {
    $('.load-module .nav a').on('click', function(e) {
      if (!e.target.classList.contains('receiving-tab') && $('.load-module .nav a.active')[0].classList.contains('receiving-tab') && $('#receiving_change').val() == 'true') {
        if (!confirm("Are you sure? Your data might not be saved.")) {
          e.preventDefault();
          return false;
        } else {
          $('#receiving_change').val(false)
        }
      }
    })
  },

  // Sales Order Section

  handleRenderSaleOrders() {
    $.ajax({
      type: 'GET',
      url: `/admin/load_modules/${$('#load_module_id').val()}/sale_orders.js`
    })
  },

  handleDestroySalesOrder() {
    $('.sale-orders-form').on('click', '.btn-destroy-sales-order', function() {
      $(this).parent('td').find('input[type="hidden"]').val(true)
      $(this).closest('tr').hide()
    })
  },

  handleSaveSaleOrdersBeforeExport() {
    $('.sale-orders-form').on('click', '.export-order-sales', function() {
      $('.sale-orders-form').addClass('overlap')
      $('.sale-orders-form form').append('<input type="hidden" name="export_pdf" id="export_pdf" value="true">')
      $('.sale-orders-form input[type="submit"]').trigger('click')
    })
  },

  handleImportSaleOrderFromBlancco() {
    $('.sale-orders-form').on('click', '.import-from-blancco', function() {
      $.ajax({
        type: "POST",
        url: `/admin/sales_order_items/import_from_blancco.js`,
        data: { serial_no_list: $('.serial-no').map(function () { return $(this).val() }).get() }
      })
    })
  },

  updateByLoadStatus() {
    if (!loadReceivingHot) return;

    let readOnly = isLoadReceivingsReadOnly();

    loadReceivingHot.updateSettings({
      readOnly: readOnly,
      contextMenu: readOnly ? [] : [
        'row_above',
        'row_below',
        'remove_row'
      ]
    });
    loadReceivingHot.render();
  },

  onLoadModuleUpdated() {
    this.updateByLoadStatus();
    this.toggleLoadReceivingWarning();
  },

  getCurrentPage() {
    return $('.pagination .active .page-link.current').text().trim() || 1;
  },
}
