import {toastErrorMessage} from '../../../../packs/toast';

export let qualifiedLinkHandler = (id) => {
  fetch(`${'/admin/load_work_orders'}/${id}`).then(response => {
    if (response.ok) {
      return response.json();
    } else {
      throw new Error('Something went wrong');
    }
  }).then(data => {
    populateQualifiedModalTable(data.load_receivings);
  }).catch(error => {
    toastErrorMessage(error);
  });
};

export let populateQualifiedModalTable = (data) => {
  $('#qualified-modal-table tbody').empty();
  $('#work-order-qualified-modal').modal('show');

  let table = $('#qualified-modal-table');
  $.each(data, (_index, item) => {
    let row = $('<tr>');
    row.append(`<td>${(item.id || '')}</td>`);
    row.append(`<td>${(item.item_no || '')}</td>`);
    row.append(`<td>${(item.quantity || '')}</td>`);
    row.append(`<td>${(item.qty || '')}</td>`);
    row.append(`<td>${(item.gross || '')}</td>`);
    row.append(`<td>${(item.tare || '')}</td>`);
    row.append(`<td>${(item.qualified_type || '')}</td>`);
    row.append(`<td>${(item.qualified_weight || '')}</td>`);
    row.append(`<td>${(item.unqualified_weight || '')}</td>`);
    row.append(`<td>${(item.pallet_status || '')}</td>`);
    row.append(`<td>${(item.production_run || '')}</td>`);
    row.append(`<td>${(item.pallet_id || '')}</td>`);
    row.append(`<td>${(item.grop_doc_entry || '')}</td>`);
    row.append(`<td>${(item.receiving_doc_entry || '')}</td>`);
    table.append(row);
  });
};
