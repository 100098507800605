window.warehousesForm = {
  init() {
    this.handleInitializeSelect2()
    this.handleWareHouseCountryCodeChange()
    this.handleWareHouseProvinceCodeChange()
  },

  handleInitializeSelect2() {
    $('#warehouse_country_code, #warehouse_province_code, #warehouse_city_code').select2({
      placeholder: 'Select Country First',
      allowClear: true
    })
  },

  handleWareHouseCountryCodeChange() {
    $('#warehouse_country_code').on('change', function () {
      if (!$('#warehouse_province_code').data('firstTime')) {
        var country = $(this).val()

        return $.ajax({
          type: "GET",
          url: "/admin/customers/get_states",
          data: {
            country: country,
            element_id: 'warehouse_province_code'
          }
        })
      }
    })
  },

  handleWareHouseProvinceCodeChange() {
    $('#warehouse_province_code').on('change', function () {
      if (!$('#warehouse_province_code').data('firstTime')) {
        var state = $(this).val()
        var country = $('#warehouse_country_code').val();

        return $.ajax({
          type: "GET",
          url: "/admin/customers/get_cities",
          data: {
            country: country,
            state: state,
            element_id: 'warehouse_city_code'
          }
        });
      }
    });
  }
}
