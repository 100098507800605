window.quotesShow = {
  init() {
    this.handleCloneQuote()
    this.handleCloneQuoteWithItem()
    this.handleApproveQuote()
    this.handleGetNextItem()
    this.handleGetPrevItem()
    this.handleSendEmail()
    this.handleDisableNextPrevButton()
    this.handlePrintDiv()
  },

  handleCloneQuote() {
    $('.clone-quote').on('click', function (e) {
      $.ajax({
        type: "POST",
        url: "/admin/quotes/clone",
        dataType: "json",
        data: { id: $('#quote_id').val() },
        success: function (data) {
          if (data.success) {
            success_message('Successfully Duplicated.')
            success_message('Redirecting to Cloned Quote')

            setTimeout((function () { window.location = "/admin/quotes/" + data.quote.id }), 1000)
          } else {
            error_message(data.message)
          }
        }
      })
    })
  },

  handleCloneQuoteWithItem() {
    $('.clone-quote-with-items').on('click', function (e) {
      $.ajax({
        type: "POST",
        url: "/admin/quotes/clone_with_items",
        dataType: "json",
        data: { id: $('#quote_id').val() },
        success: function (data) {
          if (data.success) {
            success_message('Successfully Duplicated with Items.')
            success_message('Redirecting to Cloned Quote')

            setTimeout((function () { window.location = "/admin/quotes/" + data.quote.id }), 1000)
          } else {
            error_message(data.message)
          }
        }
      })
    })
  },

  handleApproveQuote() {
    $(".approvenow").on("click", function (e) {
      var quote_id = $(this).attr("id")

      $.ajax({
        type: "GET",
        url: "/admin/quotes/approve_quote",
        dataType: "json",
        data: {
          quote_id: quote_id
        },
        success: function (data) {
          $(".approvenow").prop("disabled", false)
          success_message('Successfully Approved.')

          setTimeout((function () {
            success_message('Quote status is approved')

            location.reload()
          }), 4000)
        }
      })
    })
  },

  handleGetPrevItem() {
    $('.prevItem').on('click', function (e) {
      var quote_id = $(this).attr('id')

      $.ajax({
        type: "GET",
        url: "/admin/quotes/get_prev_item",
        dataType: "json",
        data: {
          quote_id: quote_id
        },
        success: function (data) {
          window.location.href = "/admin/quotes/" + data.id
        }
      })
    })
  },

  handleGetNextItem() {
    $('.nextItem').on('click', function (e) {
      var quote_id = $(this).attr('id')

      $.ajax({
        type: "GET",
        url: "/admin/quotes/get_next_item",
        dataType: "json",
        data: {
          quote_id: quote_id
        },
        success: function (data) {
          window.location.href = "/admin/quotes/" + data.id
        }
      })
    })
  },

  handleSendEmail() {
    $(".sendmail").on("click", function (e) {
      var customer_id = $(this).attr('id')
      var quote_id = $(this).attr('data-quote')

      $('#sendTo').modal({
        backdrop: 'static',
        keyboard: false
      })

      $(".send-quote").on("click", function (e) {
        var customer_email = $("#customer_email").val()
        var emails = $("#salesperson_email").val()
        var user_email = $("#current_salesperson").val()
        var cc_myself = $("#cc_myself").val()

        $.ajax({
          type: "GET",
          url: "/admin/quotes/send_email",
          dataType: "json",
          data: {
            customer_email: customer_email,
            quote_id: quote_id,
            emails: emails,
            user_email: user_email,
            cc_myself: cc_myself
          },
          success: function (data) {
            success_message('Successfully Sent.')
            setTimeout((function () {
              success_message("Quote sent")
            }), 2000);
          }
        })
      })
    })
  },

  handleDisableNextPrevButton() {
    var lastId = $('#lastId').val()

    if (lastId == $('#quote_id').val()) {
      $('.prevItem').addClass('disabled')
    } else {
      $('.prevItem').removeClass('disabled')
    }

    var nextId = $('#nextId').val()

    if (nextId == $('#quote_id').val()) {
      $('.nextItem').addClass('disabled')
    } else {
      $('.nextItem').removeClass('disabled')
    }
  },

  handlePrintDiv() {
    window.onafterprint = function () {
      window.location.reload(true);
    }

    $('.project-manager').on('click', '.print-div', function(e) {
      var divName = $(e.target).attr('data-print-div-name')
      var printContents = document.getElementById(divName).innerHTML
      var originalContents = document.body.innerHTML

      document.body.innerHTML = printContents
      window.print()
      document.body.innerHTML = originalContents
    })
  }
}
