import { initHandsontable } from "../../packs/handsontable";

export let uneditableResaleAssessmentItemsTable  = (data, containerId) => {
  return initHandsontable({
    containerId: containerId,
    data: data,
    additionalOptions: {
      contextMenu: [
        'hidden_columns_show',
        'hidden_columns_hide'
      ],
      hiddenColumns: {
        columns: [],
        indicators: true,
      },
    },
    colHeaders: [
      'ID',
      'Serial No',
      'Qualified Type',
      'Type',
      'UOM',
      'Customer ID',
      'Load ID',
      'Load Inventory ID',
      'Pallet ID',
      'Weight KG',
      'Acquired From',
      'Description/Form Factor',
      'Maker',
      'Model',
      'Wholesale FMV',
      'Cost',
      'Purchase Price',
      'Screen Size Inches',
      'CPU Type',
      'Original Hard Drive GB',
      'Original Memory GB',
      'Battery',
      'HD Serial No',
      'Overall Grade',
      'Comment',
      'Asset Tag',
      'Item Type',
      'Blancco Wiped',
      'Blancco Hardware Test',
      'Adapter'
    ],
    columns: [
      {
        data: 'id',
        editor: false,
        className: 'htCenter',
        renderer: 'html'
      },
      {
        data: 'serial_no',
        editor: false,
        className: 'htCenter',
        renderer: 'html'
      },
      {
        data: 'status',
        editor: false,
        className: 'htCenter',
        renderer: 'html'
      },
      {
        data: 'assessments_type',
        editor: false,
        className: 'htCenter',
        renderer: 'html'
      },
      {
        data: 'uom',
        editor: false,
        className: 'htCenter',
        renderer: 'html'
      },
      {
        data: 'customer_code',
        editor: false,
        className: 'htCenter',
        renderer: 'html'
      },
      {
        data: 'load_code',
        editor: false,
        className: 'htCenter',
        renderer: 'html'
      },
      {
        data: 'load_inventory_code',
        editor: false,
        className: 'htCenter',
        renderer: 'html'
      },
      {
        data: 'pallet_id',
        editor: false,
        className: 'htCenter',
        renderer: 'html'
      },
      {
        data: 'weight',
        editor: false,
        className: 'htCenter',
        renderer: 'html'
      },
      {
        data: 'acquired_from',
        editor: false,
        className: 'htCenter',
        renderer: 'html'
      },
      {
        data: 'description',
        editor: false,
        className: 'htCenter',
        renderer: 'html'
      },
      {
        data: 'maker',
        editor: false,
        className: 'htCenter',
        renderer: 'html'
      },
      {
        data: 'model',
        editor: false,
        className: 'htCenter',
        renderer: 'html'
      },
      {
        data: 'wholesale_fmv',
        editor: false,
        className: 'htCenter',
        renderer: 'html'
      },
      {
        data: 'buy_price',
        editor: false,
        className: 'htCenter',
        renderer: 'html'
      },
      {
        data: 'freight',
        editor: false,
        className: 'htCenter',
        renderer: 'html'
      },
      {
        data: 'screen_size',
        editor: false,
        className: 'htCenter',
        renderer: 'html'
      },
      {
        data: 'cpu_type',
        editor: false,
        className: 'htCenter',
        renderer: 'html'
      },
      {
        data: 'optional_hard_drive',
        editor: false,
        className: 'htCenter',
        renderer: 'html'
      },
      {
        data: 'optional_memory',
        editor: false,
        className: 'htCenter',
        renderer: 'html'
      },
      {
        data: 'battery',
        editor: false,
        className: 'htCenter',
        renderer: 'html'
      },
      {
        data: 'hd_serial_no',
        editor: false,
        className: 'htCenter',
        renderer: 'html'
      },
      {
        data: 'overall_grade',
        editor: false,
        className: 'htCenter',
        renderer: 'html'
      },
      {
        data: 'comment',
        editor: false,
        className: 'htCenter',
        renderer: 'html'
      },
      {
        data: 'asset_tag',
        editor: false,
        className: 'htCenter',
        renderer: 'html'
      },
      {
        data: 'processing_type',
        editor: false,
        className: 'htCenter',
        renderer: 'html'
      },
      {
        data: 'blancco_wiped',
        editor: false,
        className: 'htCenter',
        renderer: 'html'
      },
      {
        data: 'blancco_hardware_test',
        editor: false,
        className: 'htCenter',
        renderer: 'html'
      },
      {
        data: 'adapter',
        editor: false,
        className: 'htCenter',
        renderer: 'html'
      }
    ]
  });
}

export let editableResaleAssessmentItemsTable = (data, containerId,dropdownOptions = {}) => {
  return initHandsontable({
    containerId: containerId,
    data: data,
    additionalOptions: {
      contextMenu: [
        'remove_row',
        'hidden_columns_show',
        'hidden_columns_hide'
      ],
      hiddenColumns: {
        columns: [],
        indicators: true,
      },
    },
    colHeaders: [
      'ID',
      'Serial No',
      'Qualified Type',
      'Type',
      'UOM',
      'Customer ID',
      'Load ID',
      'Load Inventory ID',
      'Pallet ID',
      'Weight KG',
      'Acquired From',
      'Description/Form Factor',
      'Maker',
      'Model',
      'Wholesale FMV',
      'Cost',
      'Purchase Price',
      'Screen Size Inches',
      'CPU Type',
      'Original Hard Drive GB',
      'Original Memory GB',
      'Battery',
      'HD Serial No',
      'Overall Grade',
      'Comment',
      'Asset Tag',
      'Item Type',
      'Blancco Wiped',
      'Blancco Hardware Test',
      'Adapter'
    ],
    columns: [
      {
        data: 'id',
        readOnly: true,
        skipColumnOnPaste: true,
        className: 'htCenter',
        renderer: 'html'
      },
      {
        data: 'serial_no',
        readOnly: true,
        className: 'htCenter',
        renderer: 'html'
      },
      {
        data: 'status',
        type: 'dropdown',
        strict: true,
        allowInvalid: false,
        source: dropdownOptions.load_qualified_types
      },
      {
        data: 'assessments_type',
        type: 'dropdown',
        strict: true,
        allowInvalid: false,
        source: dropdownOptions.load_item_types
      },
      {
        data: 'uom',
        type: 'dropdown',
        strict: true,
        allowInvalid: false,
        source: dropdownOptions.load_uoms
      },
      {
        data: 'customer_code',
        readOnly: true,
        className: 'htCenter',
        renderer: 'html'
      },
      {
        data: 'load_code',
        readOnly: true,
        className: 'htCenter',
        renderer: 'html'
      },
      {
        data: 'load_inventory_code',
        readOnly: true,
        className: 'htCenter',
        renderer: 'html'
      },
      {
        data: 'pallet_id',
        readOnly: true,
        className: 'htCenter',
        renderer: 'html'
      },
      {
        data: 'weight',
        validator: 'numeric',
        type: 'numeric',
        allowInvalid: false,
        numericFormat: { pattern: '0.00' }
      },
      {
        data: 'acquired_from',
        type: 'text'
      },
      {
        data: 'description',
        type: 'text'
      },
      {
        data: 'maker',
        type: 'text'
      },
      {
        data: 'model',
        type: 'text'
      },
      {
        data: 'wholesale_fmv',
        validator: 'numeric',
        type: 'numeric',
        allowInvalid: false,
        numericFormat: { pattern: '0.00' }
      },
      {
        data: 'buy_price',
        validator: 'numeric',
        type: 'numeric',
        allowInvalid: false,
        numericFormat: { pattern: '0.00' }
      },
      {
        data: 'freight',
        validator: 'numeric',
        type: 'numeric',
        allowInvalid: false,
        numericFormat: { pattern: '0.00' }
      },
      {
        data: 'screen_size',
        type: 'text'
      },
      {
        data: 'cpu_type',
        type: 'text'
      },
      {
        data: 'optional_hard_drive',
        type: 'text',
        className: 'htCenter',
        renderer: 'html'
      },
      {
        data: 'optional_memory',
        type: 'text',
        className: 'htCenter',
        renderer: 'html'
      },
      {
        data: 'battery',
        validator: 'numeric',
        type: 'numeric',
        allowInvalid: false,
        numericFormat: { pattern: '0' }
      },
      {
        data: 'hd_serial_no',
        type: 'text'
      },
      {
        data: 'overall_grade',
        type: 'text'
      },
      {
        data: 'comment',
        type: 'text'
      },
      {
        data: 'asset_tag',
        type: 'text'
      },
      {
        data: 'processing_type',
        type: 'dropdown',
        strict: true,
        allowInvalid: false,
        source: dropdownOptions.assessment_processing_type
      },
      {
        data: 'blancco_wiped',
        type: 'text'
      },
      {
        data: 'blancco_hardware_test',
        type: 'text'
      },
      {
        data: 'adapter',
        type: 'text'
      }
    ]
  });
}
