import {toastErrorMessage, toastSuccessMessage} from '../../packs/toast';
import {getLoadModuleIds} from './show';

$(document).on('turbolinks:load', () => {
  if ($('.settlement-report-form').length > 0) {
    window.commonJS.handleCollapseLink();
    window.commonJS.handleInitializeSelect2();
    onSaveSettlement();
    onMoveSettlement();
  }
});

let onSaveSettlement = () => {
  $('.js-save-settlement-report').click(e => {
    e.preventDefault();

    saveSettlement({
      load_module_ids: getLoadModuleIds(),
      status: $('#settlement_report_status').val()
    });
  });
};

let onMoveSettlement = () => {
  $('.js-move-settlement-report').click(e => {
    e.preventDefault();

    $('#settlement_report_status')
      .val('settled')
      .trigger('change');

    saveSettlement({
      load_module_ids: getLoadModuleIds(),
      status: 'settled'
    });
  });
};

let saveSettlement = settlement => {
  let form = $('.settlement-report-form');
  form.trigger(new $.Event('settlement-report-form:updating'));

  fetch(form.attr('action') + '.json', {
    method: 'PATCH',
    body: JSON.stringify({
      settlement_report: settlement
    }),
    headers: {
      'Content-Type': 'application/json'
    }
  }).then(response => {
    if (response.ok) {
      toastSuccessMessage('Settlement Report has been saved successfully');
      form.trigger(new $.Event('settlement-report-form:updated'));
    } else {
      response.json().then(json => {
        let errorMessage = 'Something went wrong. Please try again later.';
        if (json.errors.length > 0) {
          errorMessage = json.errors.join('<br/>');
        }
        toastErrorMessage(errorMessage);
      });
    }
  });
};
