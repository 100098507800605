import {initHandsontable} from '../../../packs/handsontable';
import {
  loadNumberRenderer,
  monetaryRenderer,
  qtyRenderer,
  qualifiedModalLinkRenderer, roundToThousandthsRenderer
} from './hot_renderers';
import HotBase from './hot_base';
import HotCrud from './hot_crud';
import {initOnBeforeChangesHook} from './qualified/hooks/before_changes';

export default class Qualified extends HotBase {
  setup({
      data,
      containerId,
      serviceType,
      currencies,
      primaryCurrency,
      itemNos,
      itemDescriptions,
      fullNames,
      loadModuleInfo,
      loadModuleId,
      uoms,
      rateTypes,
      nonEditable,
      qualifiedTypes,
      totalEditable = false,
      onAfterChange
    }) {
    this.hot = initHandsontable({
      containerId: containerId,
      data: data,
      additionalOptions: {
        minSpareRows: 1,
        contextMenu: nonEditable ? [] : ['remove_row'],
        copyPaste: false,
        fillHandle: false,
        undo: true,
        hiddenColumns: {
          columns: [0, 13, 14, 15, 16]
        },
        dropdownMenu: false,
        filters: false,
        columnSorting: false
      },
      colHeaders: [
        'ID',                 // 0
        'Load',               // 1
        'Warehouse',          // 2
        'Item Description',   // 3
        'Qualified Type',     // 4
        'QTY',                // 5
        'Weight',             // 6
        'UOM',                // 7
        'Rate Type',          // 8
        'Rate',               // 9
        'Total',              // 10
        'Tax',                // 11
        'Currency',           // 12
        'Weight KGS',         // 13
        'Weight LBS'          // 14
      ],
      columns: [
        {
          data: 'id',
          readOnly: true,
          className: 'htCenter',
          renderer: 'html'
        },
        {
          data: 'load_module_id',
          readOnly: true,
          className: 'htCenter',
          renderer: loadNumberRenderer(loadModuleInfo)
        },
        {
          data: 'load_warehouse_name',
          readOnly: true,
          className: 'htCenter',
          renderer: 'html'
        },
        {
          data: 'full_name',
          type: 'dropdown',
          strict: false,
          readOnly: nonEditable,
          className: 'htCenter',
          source: fullNames,
          validator: (query, callback) => {
            callback(true);
          }
        },
        {
          data: 'description',
          type: 'dropdown',
          strict: true,
          allowInvalid: false,
          readOnly: true,
          className: 'htCenter',
          source: qualifiedTypes
        },
        {
          data: 'qty',
          type: 'numeric',
          editor: 'numeric',
          readOnly: nonEditable,
          allowInvalid: false,
          className: 'htCenter',
          renderer: qtyRenderer
        },
        {
          data: 'weight',
          type: 'numeric',
          readOnly: true,
          allowInvalid: false,
          className: 'htCenter',
          renderer: monetaryRenderer
        },
        {
          data: 'uom',
          type: 'dropdown',
          readOnly: nonEditable,
          strict: true,
          allowInvalid: false,
          source: uoms,
          className: 'htCenter'
        },
        {
          data: 'rate_type',
          type: 'dropdown',
          readOnly: nonEditable,
          strict: true,
          allowInvalid: false,
          className: 'htCenter',
          source: rateTypes
        },
        {
          data: 'rate',
          type: 'numeric',
          editor: 'numeric',
          readOnly: nonEditable,
          allowInvalid: false,
          className: 'htCenter',
          renderer: roundToThousandthsRenderer
        },
        {
          data: 'total',
          type: 'numeric',
          editor: 'numeric',
          readOnly: nonEditable ? nonEditable : !totalEditable,
          allowInvalid: false,
          className: 'htCenter',
          renderer: monetaryRenderer
        },
        {
          data: 'tax_included',
          readOnly: nonEditable,
          type: 'checkbox',
          className: 'htCenter'
        },
        {
          data: 'currency',
          type: 'dropdown',
          readOnly: nonEditable,
          strict: true,
          allowInvalid: false,
          source: currencies,
          className: 'htCenter'
        },
        {
          data: 'weight_kgs',
          readOnly: nonEditable,
          className: 'htCenter'
        },
        {
          data: 'weight_lbs',
          readOnly: nonEditable,
          className: 'htCenter'
        },
        {
          data: 'load_receiving_id',
          readOnly: true
        },
        {
          data: 'name',
          readOnly: true
        }
      ]
    });

    let crud = new HotCrud();
    let onBeforeChanges = initOnBeforeChangesHook({
      hot: this.hot,
      serviceType,
      itemDescriptions,
      primaryCurrency,
      loadModuleInfo,
      loadModuleId,
      uoms,
      qualifiedTypes,
      rateTypes
    });

    this.hot.updateSettings({
      cells: (row, col) => {
        let cellProperties = {};
        let receivingId = this.hot.getDataAtRowProp(row, 'load_receiving_id');

        if (receivingId && col === 3) {
          cellProperties.renderer = qualifiedModalLinkRenderer;
        }
        if (nonEditable) return cellProperties;
        if ([0, 1, 2, 5, 7, 8, 9, 10, 11, 12, 13, 14].includes(col)) return cellProperties;

        cellProperties.readOnly = !!receivingId;
        return cellProperties;
      }
    });
    let silentSources = [
      'loadData',
      'Weight.View',
      'Sync.SetId'
    ];
    this.hot.addHook('beforeChange', (changes, source) => {
      if (silentSources.includes(source)) return;
      onBeforeChanges(changes);
    });
    this.hot.addHook('afterChange', (changes, source) => {
      if (silentSources.includes(source)) return;

      crud.create(
        this.hot,
        changes,
        loadModuleId,
        'destruction'
      );
      crud.update(this.hot, changes);
      this.updateSubtotals();
      onAfterChange();
    });
    this.hot.addHook('beforeRemoveRow', (_index, _amount, physicalRows) => {
      crud.delete(this.hot, physicalRows);
    });
    this.hot.render();

    super.setup(currencies);
  }
};
