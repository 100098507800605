window.newQuoteItemFields = {
  init() {
    this.handleRemoveFields()
    this.handleItemQtyChange()
    this.handleItemSellingChange()
    this.handleMakerChange()
  },

  handleRemoveFields() {
    $('.remove_fields').on('click', function () {
      var selectId = $(this).parent().siblings().children(".item-qty").attr("id")
      var numb = selectId.match(/\d/g)
      numb = numb.join("")

      var qty = numb + "_qty"
      var rate = numb + "_each_price"
      var total = numb + "_item_subtotal"

      var qty_val = $("#quote_quote_items_attributes_" + qty).val()
      var rate_val = $("#quote_quote_items_attributes_" + rate).val()

      var compute = parseFloat(qty_val || 0) * parseFloat(rate_val || 0)

      $("#quote_quote_items_attributes_" + total).val(compute)

      var arr = $(".item-subtotal")
      var tot = 0
      for (var i = 0; i<arr.length; i++) {
        if (parseFloat(arr[i].value))
          tot += parseFloat(arr[i].value)
      }

      tot = tot - compute

      var tax = $("#quote_tax_percentage").val()
      if (tax == "") {
        tax = 0
      }
      var tax_amount = ((parseFloat(tax) / 100) * parseFloat(tot)).toFixed(2)

      $("#quote_tax_amount").val(tax_amount)

      $("#quote_subtotal").val(parseFloat(tot).toFixed(2))

      var freight = $("#quote_freight").val()
      if (freight == "") {
        freight = 0
      }
      var final_computation = (parseFloat(tot) + parseFloat(tax_amount) + parseFloat(freight))

      $("#quote_total").val(final_computation.toFixed(2))
    })
  },

  handleItemQtyChange() {
    $('.item-qty').on('keyup mouseup', function () {
      var selectId = $(this).attr("id")
      var numb = selectId.match(/\d/g)
      numb = numb.join("")

      var qty = numb + "_qty"
      var rate = numb + "_each_price"
      var total = numb + "_item_subtotal"

      var qty_val = $("#quote_quote_items_attributes_" + qty).val()
      var rate_val = $("#quote_quote_items_attributes_" + rate).val()

      var compute = parseFloat(qty_val || 0) * parseFloat(rate_val || 0)

      $("#quote_quote_items_attributes_" + total).val(compute)

      var arr = $(".item-subtotal")
      var tot = 0
      for (var i = 0; i < arr.length;i++) {
        if (parseFloat(arr[i].value))
          tot += parseFloat(arr[i].value)
      }

      var tax = $("#quote_tax_percentage").val()
      if (tax == "") {
        tax = 0
      }
      var tax_amount = ((parseFloat(tax) / 100) * parseFloat(tot)).toFixed(2)

      $("#quote_tax_amount").val(tax_amount)

      $("#quote_subtotal").val(parseFloat(tot).toFixed(2))

      var freight = $("#quote_freight").val()
      if (freight == "") {
        freight = 0
      }
      var final_computation = (parseFloat(tot) + parseFloat(tax_amount) + parseFloat(freight))

      $("#quote_total").val(final_computation.toFixed(2))
    })
  },

  handleItemSellingChange() {
    $('.item-selling').on('keyup mouseup', function () {
      var selectId = $(this).attr("id")
      var numb = selectId.match(/\d/g)
      numb = numb.join("")

      var qty = numb + "_qty"
      var rate = numb + "_each_price"
      var total = numb + "_item_subtotal"

      var qty_val = $("#quote_quote_items_attributes_" + qty).val()
      var rate_val = $("#quote_quote_items_attributes_" + rate).val()

      var compute = parseFloat(qty_val || 0) * parseFloat(rate_val || 0)

      $("#quote_quote_items_attributes_" + total).val(compute)

      var arr = $(".item-subtotal")
      var tot = 0
      for (var i = 0; i < arr.length; i++) {
        if (parseFloat(arr[i].value))
          tot += parseFloat(arr[i].value)
      }

      var tax = $("#quote_tax_percentage").val()
      if (tax == "") {
        tax = 0
      }
      var tax_amount = ((parseFloat(tax) / 100) * parseFloat(tot)).toFixed(2)

      var freight = $("#quote_freight").val()
      if (freight == "") {
        freight = 0
      }
      var freight_tax = (parseFloat(freight) * (parseFloat(tax) / 100)).toFixed(2)

      $("#quote_tax_amount").val(parseFloat(tax_amount) + parseFloat(freight_tax))

      $("#quote_subtotal").val(parseFloat(tot).toFixed(2))

      var final_computation = (parseFloat(tot) + parseFloat(tax_amount) + parseFloat(freight))

      $("#quote_total").val(final_computation)

    })
  },

  handleMakerChange() {
    $('.maker-select').on('change', function () {
      var id = $(this).val()
      var selectId = $(this).attr('id')
      var numb = selectId.match(/\d/g)
      numb = numb.join("")
      var modelId = numb + "_maker_model_id"
      var select = $("#quote_quote_items_attributes_" + modelId)

      select.empty()

      $.ajax({
        type: "GET",
        url: "/admin/quotes/update_models",
        dataType: "json",
        data: {
          make_id: id
        },
        success: function (models) {
          select.prepend("<option value='' selected='selected'></option>")
          for (var j = 0; j < models.length; j++) {
            select.append($("<option></option>").attr("value", models[j].id).text(models[j].name))
          }
          if (select.data('updated-value') != undefined) {
            select.val(select.data('updated-value'))
            select.removeData('updated-value')
          }
        }
      })
    })
  }
}
