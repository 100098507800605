window.itemForm = {
  init() {
    this.handleAutitCodeOnChange()
    this.handleAutitCodeOnChange()
    this.handleItemMakerIdOnChange()
    this.handleItemCategoryIdOnChange()
    this.handleGenerateSku()
    this.handleInitializeSelect2()
  },

  handleInitializeSelect2() {
    $('.select2').select2({
      placeholder: "Select",
      allowClear: true
    })

    $(".select2-create").select2({
      tags: true
    })
  },

  handleAutitCodeOnChange() {
    $("#audit_code").on('change', function () {
      $("#item_audit_id").val($("#audit_code").val())
    })
  },

  handleItemMakerIdOnChange() {
    $('#item_maker_id').on('change', function () {
      return $.ajax({
        type: "GET",
        url: "/admin/items/update_maker_model",
        data: { maker_id: $(this).val() }
      })
    })
  },

  handleItemCategoryIdOnChange() {
    $('#item_category_id').on('change', function () {
      var id = $(this).val()
      var device = $("#item_category_id option:selected").text()
      var item_id = $("#item_id").val()
      var setId = device.toLowerCase() + "-form"
      $(".deviceForm")[0].setAttribute("id", setId)
      $('#item-device-form').removeClass('hidden')
      $('#device-chosen').html(device)

      $.ajax({
        type: "GET",
        url: "/admin/items/set_device",
        data: {
          name: device.toLowerCase(),
          category_id: id,
          item_id: item_id
        }
      })

      return $.ajax({
        type: "GET",
        url: "/admin/items/update_sub_category",
        data: { category_id: id }
      })
    })
  },

  handleGenerateSku() {
    $("#generate-sku").on('click', function (e) {
      var cat = $("#item_category_id option:selected").text()
      var make = $("#item_maker_id option:selected").text()
      var model = $("#item_maker_model_id option:selected").text()
      var processor = $("#item_category_processor").val()
      var hd = $("#item_category_hard_disk").val()
      var ram = $("#item_category_ram").val()
      var size = $("#item_category_size").val()
      var color = $("#item_category_color").val()
      var screen_size = $("#item_category_screen_size").val()
      var memory_slot = $("#item_category_memory_slot").val()

      if ((cat == "Desktop") || (cat == "Server") || (cat == "Laptop")) {
        if (cat && make && model && processor && hd && ram) {
          var sku_1 = cat.substring(0, 2).toUpperCase()
          var sku_2 = make.substring(0, 2).toUpperCase()
          var sku_3 = model.toUpperCase().replace(/ /g, '')
          var sku_4 = processor.replace(/ /g, '')
          var sku_5 = hd.replace(/[^\d.]/g, '')
          var sku_6 = ram.replace(/[^\d.]/g, '')

          var item_sku = sku_1 + "-" + sku_2 + "-" + sku_3 + sku_4 + "-" + sku_5 + sku_6
          $("#item_sku").val(item_sku)

        } else {
          $('#alertModal').modal('show')
        }
      } else if (cat == "Portable") {
        if (cat && make && model && ram && screen_size && memory_slot) {
          var sku_1 = cat.substring(0, 2).toUpperCase()
          var sku_2 = make.substring(0, 2).toUpperCase()
          var sku_3 = model.toUpperCase().replace(/ /g, '')
          var sku_4 = ram.replace(/ /g, '')
          var sku_5 = screen_size.replace(/ /g, '')
          var sku_6 = memory_slot.replace(/ /g, '')

          var item_sku = sku_1 + "-" + sku_2 + "-" + sku_3 + sku_4 + sku_5 + sku_6
          $("#item_sku").val(item_sku)

        } else {
          $('#alertModal').modal('show')
        }
      } else if (cat == "Printer") {
        if (cat && make && model && ram) {
          var sku_1 = cat.substring(0, 2).toUpperCase()
          var sku_2 = make.substring(0, 2).toUpperCase()
          var sku_3 = model.toUpperCase().replace(/ /g, '')
          var sku_4 = ram.replace(/ /g, '')

          var item_sku = sku_1 + "-" + sku_2 + "-" + sku_3 + sku_4
          $("#item_sku").val(item_sku)

        } else {
          $('#alertModal').modal('show')
        }
      } else if (cat == "Monitor") {
        if (cat && make && model && size && color) {
          var sku_1 = cat.substring(0, 2).toUpperCase()
          var sku_2 = make.substring(0, 2).toUpperCase()
          var sku_3 = model.toUpperCase().replace(/ /g, '')
          var sku_4 = size.replace(/ /g, '')
          var sku_5 = color.replace(/ /g, '').toUpperCase()

          var item_sku = sku_1 + "-" + sku_2 + "-" + sku_3 + "-" + sku_4 + "-" + sku_5
          $("#item_sku").val(item_sku)

        } else {
          $('#alertModal').modal('show')
        }
      } else {
        $('#alertModal').modal('show')
      }
    })
  }
}
