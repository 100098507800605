import {populateDataCaptureModalTable} from './modal_handlers/populate_data_capture_modal';
import {populateQualifiedModalTable} from './modal_handlers/qualified_link_handler';
import {populateOnsiteServiceModalTable} from './modal_handlers/onsite_service_link_handler';

export default class Summary {
  setup(summary) {
    this.summary = summary;
    this.handleSummaryClick();

    if (summary.serial_no.length > 0) {
      $('#serial-no-value').text(summary.serial_no.length);
      $('#serial-no-row').show();
    } else {
      $('#serial-no-row').hide();
    }
    if (summary.make_model.length > 0) {
      $('#make-model-value').text(summary.make_model.length);
      $('#make-model-row').show();
    } else {
      $('#make-model-row').hide();
    }
    if (summary.serial_number_and_make_model.length > 0) {
      $('#serial-number-and-make-model-value').text(
        summary.serial_number_and_make_model.length
      );
      $('#serial-number-and-make-model-row').show();
    } else {
      $('#serial-number-and-make-model-row').hide();
    }
    if (summary.hdd.length > 0) {
      $('#hdd-value').text(summary.hdd.length);
      $('#hdd-row').show();
    } else {
      $('#hdd-row').hide();
    }
    if (summary.asset_tag.length > 0) {
      $('#asset-tag-value').text(summary.asset_tag.length);
      $('#asset-tag-row').show();
    } else {
      $('#asset-tag-row').hide();
    }
    $('#resale-weighting-percent-value').text(
      `${summary.resale_weighting_percentage}%`
    );
    this.onsite_service_grouped = summary.onsite_service.reduce((result, item) => {
      let key = item.name;
      result[key] = result[key] || [];
      result[key].push(item);
      return result;
    }, {});
    $.each(this.onsite_service_grouped, (name, loadWorkOrders) => {
      let sum = loadWorkOrders.reduce((sum, loadWorkOrder) => {
        return sum + (parseFloat(loadWorkOrder.total) || 0);
      }, 0);
      if (sum != 0) {
        let row = $('.summary');
        row.append(
          `<div class="col-12 summary-row"
            data-type="onsite_service"
            data-service="${name}">
            <p>${name}: ${Number.parseFloat(sum).toFixed(2)}</p>
          </div>`
        );
        row.show();
      }
    });
  }

  destroy() {
    $('div[data-type="onsite_service"]').remove();
    $('.summary-row').off('click');
  }

  handleSummaryClick() {
    $('.summary').on('click', '.summary-row', e => {
      e.preventDefault();

      let type = $(e.currentTarget).data('type');
      let data = this.summary[type];

      switch (type) {
        case 'serial_no':
          populateDataCaptureModalTable(data);
          break;
        case 'make_model':
          populateDataCaptureModalTable(data);
          break;
        case 'serial_number_and_make_model':
          populateDataCaptureModalTable(data);
          break;
        case 'hdd':
          populateDataCaptureModalTable(data);
          break;
        case 'asset_tag':
          populateDataCaptureModalTable(data);
          break;
        case 'qualified_resale':
          populateQualifiedModalTable(data);
          break;
        case 'onsite_service':
          populateOnsiteServiceModalTable(
            this.onsite_service_grouped[
              $(e.currentTarget).data('service')
              ]
          );
          break;
      }
    });
  }
}
