window.itemMonitorForm = {
  init() {
    window.commonJS.handleInitializeDatepickerAndTimepicker()
    this.handleSaveItemButton()
    this.handleSaveButton()
  },

  handleSaveItemButton() {
    $('.save-item-btn').on('click', function (e) {
      e.preventDefault()
      var size = $("#item_category_size").val()
      var color = $("#item_category_color").val()

      if ((color != "") && (size != "")) {
        $('.edit_item').trigger('submit')
      } else {
        $("#item_category_color").addClass("red-error")
        $("#item_category_size").addClass("red-error")
        return false
      }
    })
  },

  handleSaveButton() {
    $('.saving-btn').on('click', function (e) {
      e.preventDefault()
      var size = $("#item_category_size").val()
      var color = $("#item_category_color").val()

      if ((color != "") && (size != "")) {
        $('.new_item').trigger('submit')
      } else {
        $("#item_category_color").addClass("red-error")
        $("#item_category_size").addClass("red-error")
        return false
      }
    })
  }
}
