import 'form-serializer';

let initResaleAssessmentsDataTable = () => {
  $('#resale-assessments-table').dataTable({
    searching: false,
    paging: true,
    pageLength: 20,
    info: true,
    orders: [[1, 'desc']],
    order: [],
    dom: 'Bfrtip',
    buttons: [],
    serverSide: true,
    ajax: {
      url: window.location.href + '.json'
    },
    preDrawCallback: function( settings ) {
      let api = this.api();
      if (!api.context[0].ajax.data) {
        api.context[0].ajax.data = {};
      }

      api.context[0].ajax.data['q'] = $('#resale_assessment_search').serializeObject()['q'];
      api.context[0].ajax.data['q']['sorts'] = formatOrderDirection(api);
      api.context[0].ajax.data['page'] = api.page.info().page + 1;
    },
    columns: [
      {
        data: 'uid',
        render: function (data, type) {
          if (!data.id) {
            return '';
          }

          let link = `/admin/resale_assessments/${data.id}`;
          return `<a href="${link}">${data.uid}</a>`;
        }
      },
      { data: 'load_module_load_number',
        render: function (data, type) {
          if (!data.id) {
            return '';
          }

          let link = `/admin/load_module_generals/${data.id}`;
          return `<a href="${link}">${data.load_number}</a>`;
        }
       },
      { data: 'inbound_customer_company_name' },
      { data: 'status' },
      { data: 'created_at' },
      {
        data: 'destroy',
        className: 'text-center',
        orderable: false,
        render: function (data, type) {
          if (!data) {
            return '';
          }

          let link = `/admin/resale_assessments/${data}`;

          return `
            <a href="${link}" class="btn code-red btn-xs" 
              data-method='delete' 
              data-confirm="Are you sure?">
              <i class='fas fa-trash-alt'></i>
            </a>`;
        }
      }
    ]
  });
}

let formatOrderDirection = (datatableApi) => {
  if (datatableApi.order().length == 0) {
    return '';
  }

  let columns = datatableApi.settings().init().columns;
  let order = datatableApi.order()[0];

  return `${columns[order[0]].data}  ${datatableApi.order()[0][1]}`;
}

let handleFilterResaleAssessmentsDataTable = () => {
  $('.submit-resale-assessments-filter').click(e => {
    e.preventDefault();

    $('#resale-assessments-table').DataTable().ajax.reload()
  })
}

$(document).on('turbolinks:load', () => {
  if ($('.resale-assessments-index').length > 0) {
    window.commonJS.handleCollapseLink();
    window.commonJS.handleInitializeSelect2();
    window.commonJS.handleInitializeDatepicker();
    $(".datepicker").datepicker('option', 'altField', '#q_created_date_eq');
    window.commonJS.clearDatePicker()
    initResaleAssessmentsDataTable();
    handleFilterResaleAssessmentsDataTable();
  }
});
