import Section from '../../../load_modules/work_order/section';
import mbfHelper from './mbf_helper';
import WeightTotal from './weight_total';

export default class SectionMbf extends Section {
  constructor(mbfCompany) {
    super(
      [],
      'mbf'
    );
    this.mbfCompany = mbfCompany;
    this.tableIds = [];
    this.weighTotal = new WeightTotal(this);
  }

  setup(data, loadModuleIds, nonEditable) {
    let {load_mbfs, mbf_descriptions} = data;
    let rows = mbfHelper.initRowsData(
      load_mbfs,
      mbf_descriptions,
      loadModuleIds,
      load_mbfs.load_module_info
    );

    loadModuleIds.forEach((loadModuleId, index) => {
      let tableId = `mbf-table-${loadModuleId}`;
      let container = $('<div></div>').attr('id', tableId);
      $(`#mbf-table`).append(container);
      this.tableIds.push(tableId);

      let table = mbfHelper.initMbfTable(this.mbfCompany.name);
      table.setup({
        containerId: tableId,
        data: rows[loadModuleId],
        nonEditable: nonEditable,
        loadNumber: load_mbfs.load_module_info
          .find(load => load[0] === loadModuleId)[1],
        uoms: load_mbfs.uoms,
        fixedUom: load_mbfs.fixed_uom,
        currencies: load_mbfs.currencies,
        primaryCurrency: load_mbfs.mbf_company_currencies
          .find(([company, currency]) => company === this.mbfCompany.name)[1]
      });
      this.tables.push(table);
    });

    $('.mbf-rate-btn').click(() => {
      this.tables.forEach(table => {
        let changes = [];
        for (let row = 0; row < table.hot.countSourceRows(); row++) {
          let presetRate = table.hot.getDataAtRowProp(row, 'preset_rate');
          changes.push([row, 'rate', presetRate]);
        }
        table.hot.setDataAtRowProp(changes, 'PresetRate.SetRate');
      });
    });

    this.weighTotal.setup(load_mbfs.fixed_uom);

    super.setup();
  }

  destroy() {
    this.weighTotal.destroy();
    this.tableIds.forEach(tableId => {
      $(`#${tableId}`).remove();
    });
    this.tableIds = [];
    super.destroy();
  }
}
