window.quoteItem = {
  init() {
    window.commonJS.handleInitializeSelect2()
    this.handleCalculateTaxExempt()
    this.handleCitySelection()
    this.handleStateSelection()
    this.handleCustomerCountryChange()
    this.handleCustomerProvinceChange()
    this.handleCustomerCityCodeChange()
    this.handleShipmentProvinceCodeChange()
    this.handleDisableFormControlOfApprovedQuote()
    this.handleCustomerIdChange()
    this.handleInitializeSelect2WhenAddQuoteItem()
    this.handleQuoteFreightChange()
    this.handleQuoteSubtotalChange()
    this.handleCopyAddress()
    this.handleToggleWhenAddItem()
    this.handleMakerSelectOnChange()
    this.handleCalculateWhenRemoveFields()
    this.handleCalculateWhenChangingQty()
    this.handleCalculateWhenChangingSelling()
    //---------------- Quote Search Item ----------------//
    this.handleAddItemFromSearchModal()
    this.handleApplyItem()
    this.initializeSelectable()
    this.handleUpdateSubCategory()
    this.handleUpdateMakerModel()
    this.handleResetOnShownAndCloseSearchModal()
    this.handleResetSearchModalOnClose()
    this.handleSearchItemInfo()
  },

  handleCalculateTaxExempt() {
    $(".tax-exempt").on('change', function () {
      if ($(this).is(':checked')) {
        $("#quote_total").val($("#quote_total").val() - $("#quote_tax_amount").val());
        $("#quote_tax_percentage").val(0);
        $("#quote_tax_amount").val(0);
      }
    });
  },

  handleStateSelection() {
    $('#shipment_country_code').on('change', function () {
      var country = $(this).val();
      return $.ajax({
        type: "GET",
        url: "/admin/customers/get_states.js",
        data: {
          country: country,
          element_id: 'shipment_province_code'
        },
        success: function() {
          var province = $("#customer_province_code").val();
          if (province === "") {
            province = $("#customer_province_code").next().find('.select2-selection__placeholder').text();
          }
          $("#shipment_province_code").select2().val(province).trigger("change");
        }
      });
    });
  },

  handleCitySelection() {
    $('#shipment_province_code').on('change', function () {
      var state = $(this).val();
      var country = $('#shipment_country_code').val();
      return $.ajax({
        type: "GET",
        url: "/admin/customers/get_cities.js",
        data: {
          country: country,
          state: state,
          element_id: 'shipment_city_code'
        },
        success: function() {
          var city = $("#customer_city_code").val();
          if (city === "") {
            city = $("#customer_city_code").next().find('.select2-selection__placeholder').text();
          }
          $("#shipment_city_code").select2().val(city).trigger("change");
        }
      });
    });
  },

  handleCustomerCountryChange() {
    $('#customer_country_code').on('change', function () {
      var country = $(this).val();
      localStorage.setItem("customer_country_code", country);
      return $.ajax({
        type: "GET",
        url: "/admin/quotes/get_states_customer",
        data: {
          country: country
        }
      });
    });
  },

  handleCustomerProvinceChange() {
    $('#customer_province_code').on('change', function () {
      var state = $(this).val();
      localStorage.setItem("customer_province_code", state);
      var country = $('#customer_country_code').val();

      return $.ajax({
        type: "GET",
        url: "/admin/quotes/get_cities_customer",
        data: {
          country: country,
          state: state
        }
      });
    });
  },

  handleCustomerCityCodeChange() {
    $('#customer_city_code').on('change', function () {
      localStorage.setItem("customer_city_code", $(this).val());
    });
  },

  handleShipmentProvinceCodeChange() {
    $("#shipment_province_code").on('change', function () {
      var province_code = $(this).val();
      $.ajax({
        type: "GET",
        url: "/admin/quotes/get_tax_details",
        dataType: "json",
        data: {
          province_code: province_code
        },
        success: function (data) {
          if (data) {
            $(this).val(province_code);
            $("#quote_tax_id").val(data.id).trigger('change');
            $("#quote_tax_percentage").val(data.percentage);
          }
        }
      });
    });
  },

  handleDisableFormControlOfApprovedQuote() {
    if ($("#quote_status").val() == "approved") {
      $(".form-control").prop('disabled', true);
    }
  },

  handleCustomerIdChange() {
    $('#quote_customer_id').on('change', function () {
      var customerId = $("#quote_customer_id option:selected").val();

      if (customerId >= 1) {
        $.ajax({
          type: "GET",
          url: "/admin/quotes/get_customer_details",
          dataType: "json",
          data: {
            customer_id: customerId
          },
          success: function (data) {
            console.log(data);
            $("#customer_address1").html(data.address1)
            $("#customer_address2").html(data.address2)

            $("#customer_country_code").select2({ placeholder: data.country_code })
            $("#customer_city_code").select2({ placeholder: data.city_code })
            $("#customer_province_code").select2({ placeholder: data.province_code })

            $("#customer_postal_code").val(data.postal_code)
            $("#customer_phone_number").val(data.phone_number)
            $("#customer_fax_number").val(data.fax_number)
            $("#customer_contact_name").val(data.contact_name)
            $("#customer_email").val(data.email)

            if (data.tax_id != null) {
              $("#quote_tax_id").val(data.tax_id).trigger('change');
              $("#quote_tax_percentage").val(data.percentage);
            } else {
              $("#quote_tax_id").val('').trigger('change');
              $("#quote_tax_percentage").val(0);
            }
          }
        });
      } else {
        $("#customer_address1").html(null)
        $("#customer_address2").html(null)
        $('#customer_country_code')
        $("#customer_city_code").select2({ placeholder: 'Select Provice 1st' })
        $("#customer_province_code").select2({ placeholder: 'Select Country 1st' })
        $("#customer_postal_code").val(null)
        $("#customer_phone_number").val(null)
        $("#customer_fax_number").val(null)
        $("#customer_contact_name").val(null)
        $("#customer_email").val(null)
      }
    });
  },

  handleQuoteFreightChange() {
    $("#quote_freight").on('keyup mouseup', function (e) {
      window.quoteItem.handleTaxChange();
    });
  },

  handleQuoteSubtotalChange() {
    $("#quote_subtotal").on('change', function () {
      window.quoteItem.handleTaxChange();
    });
  },

  handleTaxChange() {
    var subtotal = $("#quote_subtotal").val();
    var freight = $("#quote_freight").val();
    var tax = Math.abs(parseFloat($("#quote_tax_percentage").val()));

    if (tax == "") { tax = 0; }
    if (subtotal == "") { subtotal = 0; }
    if (freight == "") { freight = 0; }
    var subtotal_tax = (parseFloat(subtotal) * (parseFloat(tax) / 100)).toFixed(2);
    var freight_tax = (parseFloat(freight) * (parseFloat(tax) / 100)).toFixed(2);
    console.log(parseFloat(freight_tax) + parseFloat(subtotal_tax))
    $("#quote_tax_amount").val(parseFloat(freight_tax) + parseFloat(subtotal_tax));
    var total_tax = $("#quote_tax_amount").val();

    // $("#after-tax").html("+ ("+freight_amount+")");
    $("#changable-tax").val(total_tax);
    //$("#quote_tax_amount").val(total_tax);

    var final_computation = (parseFloat(total_tax) + parseFloat(subtotal) + parseFloat(freight));
    $("#quote_total").val(final_computation);
  },

  handleCopyAddress() {
    $('#copy-address').on('click', function () {
      var address_name = $("#quote_customer_id option:selected").text();
      var address1 = $("#customer_address1").html();
      var address2 = $("#customer_address2").html();
      var country = $("#customer_country_code").val();

      if (country === "") {
        country = $("#customer_country_code").next().find('.select2-selection__placeholder').text();
      }

      var postal = $("#customer_postal_code").val();
      var contact = $("#customer_contact_name").val();
      var email = $("#customer_email").val();

      $("#shipment_name").val(address_name);
      $("#shipment_address1").val(address1);
      $("#shipment_address2").val(address2);
      $("#shipment_country_code").select2().val(country).trigger("change");
      $("#shipment_postal_code").val(postal);
      $("#shipment_contact_name").val(contact);
      $("#shipment_email").val(email);
    });
  },

  handleInitializeSelect2WhenAddQuoteItem() {
    $('#add-quote-item-select2').on('click', function () {
      setTimeout(function () {
        $('.quote-select2').select2({
          placeholder: "Select",
          allowClear: true
        });
      }, 0)
    })
  },

  handleToggleWhenAddItem() {
    $('.add-quote-item').on('click', function() {
      $('#sub_categories .search-item-indicator').removeClass('hidden')
    })
  },

  handleMakerSelectOnChange() {
    $('#sub_categories').on('change', '.maker-select', function (e) {
      var id = $(this).val();
      var select = $(e.currentTarget).parents('.nested-fields').find('.maker-model-select')

      select.empty();

      $.ajax({
        type: "GET",
        url: "/admin/quotes/update_models",
        dataType: "json",
        data: {
          make_id: id
        },
        success: function (models) {
          select.prepend("<option value='' selected='selected'></option>");
          for (var j = 0; j < models.length; j++) {
            select.append($("<option></option>").attr("value", models[j].id).text(models[j].name));
          };
          if (select.data('updated-value') != undefined) {
            select.val(select.data('updated-value'));
            select.removeData('updated-value');
          }
        }
      });
    });
  },

  handleCalculateWhenRemoveFields() {
    $('#sub_categories').on('click', '.remove_fields', function (e) {
      var $quoteItemField = $(e.currentTarget).parents('.nested-fields')
      var qty_val = $quoteItemField.find('.item-qty').val() || 0
      var rate_val = $quoteItemField.find('.item-selling').val() || 0
      var compute = parseFloat(qty_val) * parseFloat(rate_val)

      $quoteItemField.find('.item-subtotal').val(compute.toFixed(2))

      var tot = 0;
      $('.item-subtotal').each(function(index, element) { if (element.value) { tot += element.value }})

      //remove total
      tot = tot - compute

      var tax = $("#quote_tax_percentage").val()
      if (tax == "") { tax = 0 }
      var taxAmount = ((parseFloat(tax) / 100) * parseFloat(tot)).toFixed(2)
      $("#quote_tax_amount").val(taxAmount)

      $("#quote_subtotal").val(parseFloat(tot).toFixed(2))

      var freight = $("#quote_freight").val()
      if (freight == "") { freight = 0 }

      var finalComputation = (parseFloat(tot) + parseFloat(taxAmount) + parseFloat(freight))

      $("#quote_total").val(finalComputation.toFixed(2))
    });
  },

  handleCalculateWhenChangingQty() {
    $('#sub_categories').on('keyup mouseup', '.item-qty', function(e) {
      var $quoteItemField = $(e.currentTarget).parents('.nested-fields')
      var qty_val = $quoteItemField.find('.item-qty').val() || 0
      var rate_val = $quoteItemField.find('.item-selling').val() || 0
      var compute = parseFloat(qty_val) * parseFloat(rate_val)

      $quoteItemField.find('.item-subtotal').val(compute.toFixed(2))

      var tot = 0
      $('.item-subtotal').each(function (index, element) { if (element.value) { tot += element.value } })

      var tax = $("#quote_tax_percentage").val()
      if (tax == "") { tax = 0 }

      var tax_amount = ((parseFloat(tax) / 100) * parseFloat(tot)).toFixed(2)

      $("#quote_tax_amount").val(tax_amount)

      $("#quote_subtotal").val(parseFloat(tot).toFixed(2))

      var freight = $("#quote_freight").val()
      if (freight == "") { freight = 0 }

      var finalComputation = (parseFloat(tot) + parseFloat(tax_amount) + parseFloat(freight))
      $("#quote_total").val(finalComputation.toFixed(2))
    })
  },

  handleCalculateWhenChangingSelling() {
    $('#sub_categories').on('keyup mouseup', '.item-selling', function (e) {
      var $quoteItemField = $(e.currentTarget).parents('.nested-fields')
      var qty_val = $quoteItemField.find('.item-qty').val() || 0
      var rate_val = $quoteItemField.find('.item-selling').val() || 0
      var compute = parseFloat(qty_val) * parseFloat(rate_val)

      $quoteItemField.find('.item-subtotal').val(compute.toFixed(2))

      var tot = 0
      $('.item-subtotal').each(function (index, element) { if (element.value) { tot += parseFloat(element.value) } })

      var tax = $("#quote_tax_percentage").val()
      if (tax == "") { tax = 0 }

      var tax_amount = ((parseFloat(tax) / 100) * parseFloat(tot)).toFixed(2)

      var freight = $("#quote_freight").val()
      if (freight == "") { freight = 0 }
      var freight_tax = (parseFloat(freight) * (parseFloat(tax) / 100)).toFixed(2)

      $("#quote_tax_amount").val(parseFloat(tax_amount) + parseFloat(freight_tax))
      $("#quote_subtotal").val(parseFloat(tot).toFixed(2))

      var finalComputation = (parseFloat(tot) + parseFloat(tax_amount) + parseFloat(freight))

      $("#quote_total").val(finalComputation.toFixed(2))
    });
  },
  //---------------- Quote Search Item ----------------//

  handleAddItemFromSearchModal() {
    $(".add-item").on("click", function (e) {
      var selectedItems = $('.searched-item:checked')
      if (selectedItems.length === 0) return alert('Please select at least 1 item')

      if (['Lot', 'Order'].indexOf($('#resource_type').val()) > -1) {
        window.quoteItem.insertItemsInPage();
      } else {
        let quotes = {}

        selectedItems.each(function (index, item) {
          quotes[index] = window.quoteItem.insertAssignedItemData(item.dataset)
        })

        $('#sub_categories .search-item-indicator').removeClass('hidden')

        $.ajax({
          type: 'POST',
          url: `/admin/quotes/${$('#quote_id').val()}/quote_items`,
          data: {
            quote: {
              quote_items_attributes: quotes
            }
          }
        })
      }

      $('.close-modal').trigger('click');
    });
  },

  insertItemsInPage() {
    var fragment = document.createDocumentFragment();

    $('tbody .searched-item:checked').each(function () {
      var element = document.getElementById('item_' + this.dataset.id).cloneNode(true);
      element.classList.remove("hidden");

      fragment.appendChild(element);
      $(element).find('.item-line-id').val($('#line_id').val())
    });

    document.getElementById('quote-item-list').append(fragment);
  },

  insertAssignedItemData(selectedItem) {
    let data = {}

    data.item_id = selectedItem.id
    data.description = selectedItem.description
    if (selectedItem.gradeId != undefined) { data.grade_id = selectedItem.gradeId }
    if (selectedItem.makerId != undefined) { data.maker_id = selectedItem.makerId }
    if (selectedItem.makerModelId != undefined) { data.maker_model_id = selectedItem.makerModelId }

    return data
  },

  handleApplyItem() {
    _this = this

    $('.apply-item').on('click', function (e) {
      _this.getAllDataSearch()
      _this.onSearchItems()
    })
  },

  getAllDataSearch() {
    let fields = [
      '#item_category_id', '#item_sub_category_id', '#item_maker_id', '#item_maker_model_id', '.item-description', '.item-cpu',
      '.item-ram', '#item_audit_id', '#item_po_id', '#item_grade_id', '#item_status_id', '#item_lot_id', '#item_location_id'
    ]

    fields.forEach(function (element) { search_param[$(element).attr('name')] = $(element).val() })
  },

  onSearchItems() {
    $('.apply-item').addClass('disabled')
    $('.searched-items, .result-total').addClass('hidden')
    $('#addItemModal .search-item-indicator').show()

    $.ajax({
      url: '/admin/quotes/find_assign_items',
      type: 'GET',
      dataType: 'script',
      data: search_param,
      success: function (data) {
        $('.apply-item').removeClass('disabled')
        $('#addItemModal .searched-items, .result-total').removeClass('hidden')
      }
    })
  },

  initializeSelectable() {
    $(".searched-items").selectable({
      filter: 'tr.searched-item-container',
      stop: function () {
        $(".ui-selected input", this).each(function () {
          this.checked = true;
        });
      }
    });
  },

  handleUpdateSubCategory() {
    $('#item_category_id').on('change', function () {
      var id = $(this).val();
      return $.ajax({
        type: "GET",
        url: "/admin/items/update_sub_category",
        data: {
          category_id: id
        }
      });
    });
  },

  handleUpdateMakerModel() {
    $('#item_maker_id').on('change', function () {
      var id = $(this).val();
      return $.ajax({
        type: "GET",
        url: "/admin/items/update_maker_model",
        data: {
          maker_id: id
        }
      });
    });
  },

  handleResetOnShownAndCloseSearchModal() {
    $('#addItemModal').on('hidden.bs.modal shown.bs.modal', function () {
      window.quoteItem.handleResetSearchFormModal();

      search_param = {
        order_id: order_id,
        resource_type: $('#resource_type').val(),
        resource_id: $('#resource_id').val()
      }
    });
  },

  handleResetSearchModalOnClose() {
    $('#addItemModal').on('hidden.bs.modal', function () {
      $('.result-total').text("")
    })
  },

  handleResetSearchFormModal() {
    $('#addItemModal select.select2').val('');
    $('#addItemModal select.select2').select2({
      dropdownParent: $('#addItemModal'),
      allowClear: true
    });
    $('#addItemModal input.item-description').val('');
    $('#addItemModal input.item-cpu').val('');
    $('#addItemModal input.item-ram').val('');
    $('#addItemModal .searched-items').empty();
  },

  handleSearchItemInfo() {
    let onFinishInput = debounce(function (e) {
      search_param[e.target.name] = e.target.value
      window.quoteItem.onSearchItems()
    }, 700)

    $(document).on('keyup', '.item-description, .item-cpu, .item-ram', onFinishInput);
  }
}
