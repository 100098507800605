export let populateDataCaptureModalTable = (data) => {
  $('#data-capture-modal-table tbody').empty();
  $('#work-order-data-capture-modal').modal('show');

  let table = $('#data-capture-modal-table');
  $.each(data, (_index, item) => {
    let row = $('<tr>');
    row.append(`<td>${(item.id || '')}</td>`);
    row.append(`<td>${(item.item_no || '')}</td>`);
    row.append(`<td>${(item.maker_name || '')}</td>`);
    row.append(`<td>${(item.maker_model_name || '')}</td>`);
    row.append(`<td>${(item.serial_no || '')}</td>`);
    row.append(`<td>${(item.asset_tag || '')}</td>`);
    row.append(`<td>${(item.port_no || '')}</td>`);
    row.append(`<td>${(item.added_value || '')}</td>`);
    table.append(row);
  });
};
