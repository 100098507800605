window.customReportFilters = {
  init() {
    window.commonJS.handleInitializeSelect2()
    this.handleItemCategoryIdOnChange()
    this.handleDatepickerFilterTodayAndNone()
    this.handleCreateAtFilter()
  },

  handleItemCategoryIdOnChange() {
    $('#item_category_id').on('change', function() {
      return $.ajax({
        type: "GET",
        url: "/admin/items/update_sub_category",
        data: { category_id: $(this).val() }
      })
    })
  },

  handleDatepickerFilterTodayAndNone() {
    $('#report-range').on('apply.daterangepicker', function (ev, picker) {
      if (picker.chosenLabel === 'None') {
        $('#report-range span').html('')
        $("#report_start_date").val('')
        $("#report_end_date").val('')
      }

      if (picker.chosenLabel === 'Today') {
        $('#report-range span').html(`${moment().format('MMMM D, YYYY')} - ${moment().format('MMMM D, YYYY')}`)
        $("#report_start_date").val(moment().format('MMMM D, YYYY'))
        $("#report_end_date").val(moment().format('MMMM D, YYYY'))
      }
    })
  },

  handleCreateAtFilter() {
    if ($("#date-diff").length > 0 && $("#date-diff").attr("data-start-date") && $("#date-diff").attr("data-end-date")) {
      var startDate = moment($("#date-diff").attr("data-start-date"))
      var endDate = moment($("#date-diff").attr("data-end-date"))
    } else {
      var startDate = undefined
      var endDate = undefined
    }

    function cbDate(start, end) {
      if (start != null && end != null && start._isValid && end._isValid) {
        $('#report-range span').html(`${start.format('MMMM D, YYYY')} - ${end.format('MMMM D, YYYY')}`)
        $("#report_start_date").val(start.format('MMMM D, YYYY'))
        $("#report_end_date").val(end.format('MMMM D, YYYY'))
      } else {
        $('#report-range span').html('')
        $("#report_start_date").val('')
        $("#report_end_date").val('')
      }
    }

    $('#report-range').daterangepicker({
      startDate: startDate,
      endDate: endDate,
      ranges: {
        'None': [moment().subtract(1, 'days'), moment().add(1, 'days')],
        'Today': [moment(), moment()],
        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
      }
    }, cbDate)

    cbDate(startDate, endDate)
  },
}
