loadInventoryReport = {
  init() {
    window.commonJS.handleInitializeSelect2()
    window.commonJS.handleCollapseLink()
    this.handleInitializeDataTable()
    this.handleFilterLoadInventoryDataTable()
  },

  handleFilterLoadInventoryDataTable() {
    $('.load-inventory-filter-btn').on('click', function() {
      $('#load-inventory-table').DataTable().ajax.reload()
    })
  },

  handleInitializeDataTable() {
    $('#load-inventory-table').DataTable({
      searching: false,
      pageLength: 25,
      paging: true,
      info: true,
      order: [[0, "desc"]],
      dom: 'Bfrtip',
      buttons: [
        {
          extend: 'copy',
          action: datatableCustomExportFunction
        },
        {
          extend: 'csv',
          text: 'CSV',
          titleAttr: 'CSV',
          action: datatableCustomExportFunction
        },
        {
          extend: 'excel',
          text: 'Excel',
          titleAttr: 'Excel',
          action: datatableCustomExportFunction
        },
        {
          extend: 'pdf',
          action: datatableCustomExportFunction
        },
        {
          extend: 'print',
          action: datatableCustomExportFunction
        }
      ],
      serverSide: true,
      ajax: {
        url: window.location.href,
        data: function(params) {
          params.load_inventory = window.commonJS.handleGetFormData($('#reports-form'), ['load_module_id'])
          params.load_module_id = $('#load_module_id').val()
        }
      },
      columns: [
        {
          data: 'load_receiving_detail',
          render: function (data, type) {
            if (data.id) {
              let link = `/admin/load_module_generals/${data.load_module_id}?open_load_receiving=${data.load_receiving_id}`

              return `<a href="${link}">${data.id}</a>`
            } else {
              return ''
            }
          }
        },
        { data: 'types' },
        { data: 'weight' },
        { data: 'uom' },
        { data: 'serial_no' },
        {
          data: 'load_module',
          render: function (data, type) {
            if (data) {
              let link = `/admin/load_module_generals/${data.id}`

              return `<a href="${link}">${data.number}</a>`
            } else {
              return ''
            }
          }
        },
        { data: 'qualified_type' },
        { data: 'status' },
        { data: 'customer' }
      ]
    })
  }
}
