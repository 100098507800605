window.filterForm = {
  init() {
    window.commonJS.handleInitializeSelect2()
    this.handleCategoryIdOnChange()
  },

  handleCategoryIdOnChange() {
    $('#item_category_id').on('change', function () {
      return $.ajax({
        type: "GET",
        url: "/admin/items/update_sub_category",
        data: { category_id: $(this).val() }
      });
    });
  }
}
