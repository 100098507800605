window.newQuoteForm = {
  init() {
    window.commonJS.clearDatePicker()
    this.handleInitializeSelect2()
    this.handleChangeTaxExempt()
    this.handleQuoteStatus()
    this.handleOnChangeShipmentCountryCode()
    this.handleOnChangeShipmentProvinceCode()
    this.handleChangeCustomerCountryCode()
    this.handleChangeCustomerProvinceCode()
    this.handleChangeCityCode()
    this.handleGetTaxDetails()
    this.handleInitializeSelect2ForQuoteItem()
    this.handleCopyAddress()
    this.handleQuoteCustomerIdOnChange()
    this.handleCalculateTaxWhenQuoteFreightChange()
    this.handleCalculateTaxWhenQuoteSubtotalChange()
  },

  handleInitializeSelect2() {
    $(".select2").select2({
      placeholder: "Select or Create",
      allowClear: true,
      tags: true
    })

    $("#quote_customer_id").select2({
      placeholder: "Select or Create",
      allowClear: true,
      tags: true
    })

    $('.datepicker').datepicker({
      todayHighlight: true,
      orientation: 'auto top',
      dateFormat: 'dd-mm-yy'
    })
  },

  handleChangeTaxExempt() {
    $(".tax-exempt").on('change', function () {
      if ($(this).is(':checked')) {
        $("#quote_total").val($("#quote_total").val() - $("#quote_tax_amount").val())
        $("#quote_tax_percentage").val(0)
        $("#quote_tax_amount").val(0)
      }
    })
  },

  handleQuoteStatus() {
    var quote_status = $("#quote_status").val();

    if (quote_status == "approved") {
      $(".form-control").prop('disabled', true);
    }
  },

  handleStateSelection() {
    var province = $("#customer_province_code").val()

    if (province === "") {
      province = $("#customer_province_code").next().find('.select2-selection__placeholder').text()
    }

    $("#shipment_province_code").select2().val(province).trigger("change")
  },

  handleOnChangeShipmentCountryCode() {
    $('#shipment_country_code').on('change', function () {
      return $.ajax({
        type: "GET",
        url: "/admin/customers/get_states.js",
        data: {
          country: $(this).val(),
          element_id: 'shipment_province_code'
        },
        success: window.newQuoteForm.handleStateSelection()
      })
    })
  },

  handleCitySelection() {
    var city = $("#customer_city_code").val()

    if (city === "") {
      city = $("#customer_city_code").next().find('.select2-selection__placeholder').text()
    }

    $("#shipment_city_code").select2().val(city).trigger("change")
  },

  handleOnChangeShipmentProvinceCode() {
    $('#shipment_province_code').on('change', function () {
      return $.ajax({
        type: "GET",
        url: "/admin/customers/get_cities.js",
        data: {
          country: $('#shipment_country_code').val(),
          state: $(this).val(),
          element_id: 'shipment_city_code'
        },
        success: window.newQuoteForm.handleCitySelection
      })
    })
  },

  handleChangeCustomerCountryCode() {
    $('#customer_country_code').on('change', function () {
      var country = $(this).val()
      localStorage.setItem("customer_country_code", country)

      return $.ajax({
        type: "GET",
        url: "/admin/quotes/get_states_customer",
        data: { country: country }
      })
    })
  },

  handleChangeCustomerProvinceCode() {
    $('#customer_province_code').on('change', function () {
      var state = $(this).val()
      localStorage.setItem("customer_province_code", state)
      var country = $('#customer_country_code').val()

      return $.ajax({
        type: "GET",
        url: "/admin/quotes/get_cities_customer",
        data: {
          country: country,
          state: state
        }
      })
    })
  },

  handleChangeCityCode() {
    $('#customer_city_code').on('change', function () {
      localStorage.setItem("customer_city_code", $(this).val())
    })
  },

  handleGetTaxDetails() {
    $("#shipment_province_code").on('change', function () {
      var province_code = $(this).val()

      $.ajax({
        type: "GET",
        url: "/admin/quotes/get_tax_details",
        dataType: "json",
        data: { province_code: province_code },
        success: function (data) {
          if (data) {
            $(this).val(province_code)
            $("#quote_tax_id").val(data.id).trigger('change')
            $("#quote_tax_percentage").val(data.percentage)
          }
        }
      })
    })
  },

  handleInitializeSelect2ForQuoteItem() {
    $('#add-quote-item-select2').on('click', function () {
      setTimeout(function () {
        $('.quote-select2').select2({
          placeholder: "Select",
          allowClear: true
        })
      }, 0)
    })
  },

  handleCopyAddress() {
    $('#copy-address').on('click', function () {
      var address_name = $("#quote_customer_id option:selected").text()
      var address1 = $("#customer_address1").html()
      var address2 = $("#customer_address2").html()
      var country = $("#customer_country_code").val()

      if (country === "") {
        country = $("#customer_country_code").next().find('.select2-selection__placeholder').text()
      }

      var postal = $("#customer_postal_code").val()
      var contact = $("#customer_contact_name").val()
      var email = $("#customer_email").val()

      $("#shipment_name").val(address_name)
      $("#shipment_address1").val(address1)
      $("#shipment_address2").val(address2)

      $("#shipment_country_code").select2().val(country).trigger("change")

      $("#shipment_postal_code").val(postal)
      $("#shipment_contact_name").val(contact)
      $("#shipment_email").val(email)
    })
  },

  handleQuoteCustomerIdOnChange() {
    $('#quote_customer_id').on('change', function () {
      var customerId = $("#quote_customer_id option:selected").val()

      if (customerId >= 1) {
        $.ajax({
          type: "GET",
          url: "/admin/quotes/get_customer_details",
          dataType: "json",
          data: { customer_id: customerId },
          success: function (data) {
            console.log(data)
            $("#customer_address1").html(data.address1)
            $("#customer_address2").html(data.address2)

            $("#customer_country_code").select2({ placeholder: data.country_code })
            $("#customer_city_code").select2({ placeholder: data.city_code })
            $("#customer_province_code").select2({ placeholder: data.province_code })

            $("#customer_postal_code").val(data.postal_code)
            $("#customer_phone_number").val(data.phone_number)
            $("#customer_fax_number").val(data.fax_number)
            $("#customer_contact_name").val(data.contact_name)
            $("#customer_email").val(data.email)

            if (data.tax_id != null) {
              $("#quote_tax_id").val(data.tax_id).trigger('change')
              $("#quote_tax_percentage").val(data.percentage)
            } else {
              $("#quote_tax_id").val('').trigger('change')
              $("#quote_tax_percentage").val(0)
            }
          }
        })
      } else {
        $("#customer_address1").html(null)
        $("#customer_address2").html(null)
        $('#customer_country_code')
        $("#customer_city_code").select2({ placeholder: 'Select Provice 1st' })
        $("#customer_province_code").select2({ placeholder: 'Select Country 1st' })
        $("#customer_postal_code").val(null)
        $("#customer_phone_number").val(null)
        $("#customer_fax_number").val(null)
        $("#customer_contact_name").val(null)
        $("#customer_email").val(null)
      }
    })
  },

  handleCalculateTaxWhenQuoteFreightChange() {
    $("#quote_freight").on('keyup mouseup', function (e) { window.newQuoteForm.taxChange() })
  },

  handleCalculateTaxWhenQuoteSubtotalChange() {
    $("#quote_subtotal").on('change', function () { window.newQuoteForm.taxChange() })
  },

  taxChange() {
    var subtotal = $("#quote_subtotal").val()
    var freight = $("#quote_freight").val()
    var tax = Math.abs(parseFloat($("#quote_tax_percentage").val()))

    if (tax == "") { tax = 0 }
    if (subtotal == "") { subtotal = 0 }
    if (freight == "") { freight = 0 }

    var subtotal_tax = (parseFloat(subtotal) * (parseFloat(tax) / 100)).toFixed(2)
    var freight_tax = (parseFloat(freight) * (parseFloat(tax) / 100)).toFixed(2)
    var total_tax = $("#quote_tax_amount").val()
    var final_computation = (parseFloat(total_tax) + parseFloat(subtotal) + parseFloat(freight)).toFixed(2)

    $("#quote_tax_amount").val((parseFloat(freight_tax) + parseFloat(subtotal_tax)).toFixed(2))
    $("#changable-tax").val(total_tax)
    $("#quote_total").val(final_computation)
  }
}
