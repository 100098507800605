import Section from './section';
import DataCapture from './data_capture';

export default class SectionDataCapture extends Section {
  constructor(mbfCompany) {
    super(
      [],
      'data-capture'
    );
    this.tableIds = [];
    this.mbfCompany = mbfCompany;
  }

  setup({load_work_orders}, loadModuleIds, nonEditable) {
    let primaryCurrency = load_work_orders.primary_currency;
    if (this.mbfCompany) {
      primaryCurrency = load_work_orders.mbf_company_currencies
        .find(currency => currency[0] === this.mbfCompany.name)[1]
    }
    this.tableIds = loadModuleIds.map((loadModuleId, index) => {
      let tableId = `work-order-data-capture-table-${loadModuleId}`;
      let container = $('<div></div>').attr('id', tableId);
      if (index < loadModuleIds.length - 1) {
        container.addClass('mb-3');
      }
      $(`#work-order-data-capture-table`).append(container);

      let dataCapture = load_work_orders.data_capture.filter(item => {
        return item.load_module_id === loadModuleId;
      });
      let itemNos = Object.keys(
        load_work_orders.load_receiving_item_no_count[loadModuleId] || {}
      );
      let itemDescriptions = load_work_orders.load_item_types
          .filter(pair => itemNos.includes(pair[0]));

      let table = new DataCapture();
      table.setup({
        data: dataCapture,
        containerId: tableId,
        currencies: load_work_orders.currencies,
        primaryCurrency: primaryCurrency ||
          load_work_orders.currencies[0],
        itemNos: itemNos,
        validQuantities: load_work_orders.load_receiving_item_no_count[loadModuleId],
        loadModuleInfo: load_work_orders.load_module_info,
        loadModuleId: loadModuleId,
        itemDescriptions: itemDescriptions,
        nonEditable: nonEditable,
        qualifiedTypes: load_work_orders.qualified_types
      });
      this.tables.push(table);
      return tableId;
    });
    super.setup();
  }

  destroy() {
    this.tableIds.forEach(tableId => {
      $(`#${tableId}`).remove();
    });
    this.tableIds = [];
    super.destroy();
  }
}
