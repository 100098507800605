import {initHandsontable} from '../../packs/handsontable';
import {toastErrorMessage} from '../../packs/toast';

let searchResultHot = null;
let data = null;

$(document).on('turbolinks:load', () => {
  if ($('.settlement-report-new').length > 0) {
    window.commonJS.handleInitializeSelect2();
    window.commonJS.handleCollapseLink();
    window.commonJS.handleInitializeDatepicker();
    window.commonJS.clearDatePicker();
    $('#date_processed_gteq').datepicker(
      'option',
      'altField',
      '#q_date_processed_gteq'
    );
    $('#date_processed_lteq').datepicker(
      'option',
      'altField',
      '#q_date_processed_lteq'
    );

    handleSearch();
    handleSubmit();
  }
});

let handleSearch = () => {
  $('.js-submit-load-modules-search').click(e => {
    e.preventDefault();

    let searchForm = $('.settlement-report-load-modules-search-form');
    let searchData = searchForm.serializeObject();

    $.ajax({
      url: searchForm.attr('action'),
      data: searchData,
      success: response => {
        if (searchResultHot) searchResultHot.destroy();
        data = response.data;
        searchResultHot = initSearchResultHot(data);
      },
      error: error => {
        toastErrorMessage('Something went wrong. Please try again later.');
        console.error(error);
      }
    });
  });
};

let sendRequest = (loadModuleIds, uniqueCustomerId) => {
  $.ajax({
    url: '/admin/settlement_reports',
    method: 'POST',
    data: {
      settlement_report: {
        load_module_ids: loadModuleIds,
        inbound_customer_id: uniqueCustomerId
      }
    },
    success: response => {
      window.location.href = `/admin/settlement_reports/${response.id}`;
    },
    error: error => {
      enableSubmitButtons();
      toastErrorMessage('Something went wrong. Please try again later.');
      console.error('Failed to create resource:', error);
    }
  });
};

let disableSubmitButtons = () => {
  $('.js-submit-all-settlement-report').prop('disabled', true);
  $('.js-submit-selected-settlement-report').prop('disabled', true);
};

let enableSubmitButtons = () => {
  $('.js-submit-all-settlement-report').prop('disabled', false);
  $('.js-submit-selected-settlement-report').prop('disabled', false);
};

let countCompanyOccurrences = loadModuleData => {
  let counts = {};
  let companiesSelected = loadModuleData.map(row => row.company_name);
  companiesSelected.reduce((acc, company) => {
    if (company in counts) {
      counts[company]++;
    } else {
      counts[company] = 1;
    }
    return acc;
  }, {});
  return counts;
};

let handleSubmit = () => {
  $('.js-submit-all-settlement-report, .js-submit-selected-settlement-report').click(event => {
    disableSubmitButtons();

    let classList = event.target.classList;

    if (classList.contains('js-submit-all-settlement-report')) {
      if (!searchResultHot || data.length === 0) {
        enableSubmitButtons();
        toastErrorMessage('Nothing to Select');
        return;
      }

      let changes = [];
      let rows = searchResultHot.getData();
      for (let i = 0; i < rows.length; i++) {
        changes.push([i, 'selected', true])
      }
      searchResultHot.setDataAtRowProp(changes, 'user.selectAll');
    }

    if (!searchResultHot || data.length === 0) {
      enableSubmitButtons();
      toastErrorMessage('Nothing Selected');
      return;
    }
    let loadModuleIds = [];
    for (let i = 0; i < searchResultHot.countRows(); i++) {
      let selected = searchResultHot.getDataAtRowProp(i, 'selected');
      if (selected === true) {
        loadModuleIds.push(
          searchResultHot.getDataAtRowProp(i, 'id')
        );
      }
    }

    if (loadModuleIds.length === 0) {
      enableSubmitButtons();
      toastErrorMessage('Nothing Selected');
      return;
    }

    let loadModuleData = data.filter(row => loadModuleIds.includes(row.id));
    let uniqueCustomerIds = [...new Set(
      loadModuleData.map(row => row.inbound_customer_id)
    )];
    if (uniqueCustomerIds.length > 1) {
      enableSubmitButtons();
      toastErrorMessage('Only loads with the same customer are allowed.');
      return;
    }

    let occurrences = countCompanyOccurrences(loadModuleData);
    let found = findMbfCompanyOccurrence(occurrences);
    if (found.company) {
      enableSubmitButtons();
      toastErrorMessage(`For ${found.company}, only one load selection is allowed at a time.`);
      return;
    }

    sendRequest(loadModuleIds, uniqueCustomerIds[0]);
  });
};

let findMbfCompanyOccurrence = occurrences => {
  let companies = mbfSingleLoadCompanies();
  for (let i = 0; i < companies.length; i++) {
    let count = occurrences[companies[i]];
    if ((count || 0) > 1) {
      return {
        company: [companies[i]],
        count: count
      };
    }
  }
  return {};
};

let mbfSingleLoadCompanies = () => {
  return $('#mbf-single-load-companies')
    .data('mbf-single-load-companies')
    .split(',');
};

let initSearchResultHot = data => {
  return initHandsontable({
    containerId: 'load-modules-search-result-table',
    data: data,
    additionalOptions: {
      copyPaste: false,
      undo: true,
      hiddenColumns: {
        columns: [0]
      },
      dropdownMenu: false
    },
    colHeaders: [
      'ID',
      '',
      'Load Number',
      'Customer',
      'Load Status',
      'Warehouse',
      'Date Processed'
    ],
    columns: [
      {
        data: 'id',
        type: 'checkbox',
        className: 'htCenter'
      },
      {
        data: 'selected',
        type: 'checkbox',
        className: 'htCenter'
      },
      {
        data: 'load_number',
        editor: false,
        className: 'htCenter'
      },
      {
        data: 'company_name',
        editor: false,
        className: 'htCenter'
      },
      {
        data: 'load_status',
        editor: false,
        className: 'htCenter'
      },
      {
        data: 'load_warehouse',
        editor: false,
        className: 'htCenter'
      },
      {
        data: 'date_processed',
        editor: false,
        className: 'htCenter'
      }
    ]
  });
};
