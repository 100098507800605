window.vendorForm = {
  init() {
    this.handleInitializeSelect2()
    this.handleVendorCountryCodeChange()
    this.handleVendorProvinceCodeChange()
  },

  handleInitializeSelect2() {
    $('.select2').select2({
      placeholder: "Select",
      allowClear: true
    })

    $('#vendor_province_code').select2({
      placeholder: 'Select Country First',
      allowClear: true
    })

    $('#vendor_city_code').select2({
      placeholder: 'Select Province First',
      allowClear: true
    })
  },

  handleVendorCountryCodeChange() {
    $('#vendor_country_code').on('change', function () {
      if (!$('#vendor_province_code').data('firstTime')) {
        var country = $('#vendor_country_code').val() ? $('#vendor_country_code').val() : localStorage.getItem("vendor_country_code")

        return $.ajax({
          type: "GET",
          url: "/admin/customers/get_states.js",
          data: {
            country: country,
            element_id: 'vendor_province_code'
          }
        })
      }
    })
  },

  handleVendorProvinceCodeChange() {
    $('#vendor_province_code').on('change', function () {
      if (!$('#vendor_province_code').data('firstTime')) {
        var state = $("#vendor_province_code").val() ? $("#vendor_province_code").val() : localStorage.getItem("vendor_province_code")
        var country = $('#vendor_country_code').val()

        return $.ajax({
          type: "GET",
          url: "/admin/customers/get_cities.js",
          data: {
            country: country,
            state: state,
            element_id: 'vendor_city_code'
          }
        })
      }
    })
  }
}
