import {calculateTotalsByRateType} from '../../calculations';
import QualifiedChangesValidator from '../../qualified_changes_validator';

export let initOnBeforeChangesHook = ({
    hot,
    serviceType,
    itemDescriptions,
    primaryCurrency,
    loadModuleId,
    loadModuleInfo,
    uoms,
    qualifiedTypes,
    rateTypes
  }) => {
  let changesValidator = new QualifiedChangesValidator();
  let populate = initPopulateDefaults({
    hot,
    serviceType,
    itemDescriptions,
    primaryCurrency,
    loadModuleInfo,
    loadModuleId,
    uoms,
    qualifiedTypes,
    rateTypes
  });
  return changes => {
    populate(changes);
    changesValidator.validate(hot, changes);
    changes.push(...calculateWeight(hot, changes));
    changes.push(...setWeightCellByUom(hot, changes));
    changes.push(...calculateTotalsByRateType(hot, changes));
    changes.push(...resetRateByTotal(hot, changes));
  };
};

let setWeightCellByUom = (hot, changes) => {
  let newChanges = [];
  changes.forEach(([row, prop, _oldValue, newValue]) => {
    if (prop !== 'uom') return;

    let id = hot.getDataAtRowProp(row, 'id');

    if (!id) return;

    let value = hot.getDataAtRowProp(row, 'weight_lbs');

    if (newValue === 'KGS') {
      value = hot.getDataAtRowProp(row, 'weight_kgs');
    }
    newChanges.push([
      row,
      'weight',
      null,
      parseFloat(value).toFixed(2)
    ]);
  });
  return newChanges;
};

let calculateWeight = (hot, changes) => {
  let newChanges = [];
  changes.forEach(([row, prop, _oldValue, newValue]) => {
    if (prop !== 'weight') return;

    let uom = hot.getDataAtRowProp(row, 'uom');
    let id = hot.getDataAtRowProp(row, 'id');
    if (!id) uom = 'KGS';

    let weight_lbs = newValue;
    let weight_kgs = newValue;

    switch (uom) {
      case 'KGS':
        weight_lbs = newValue * 2.20462;
        break;
      case 'LBS':
        weight_kgs = newValue * 0.453592;
        break;
    }
    newChanges.push(
      [row, 'weight_lbs', null,
        parseFloat(weight_lbs).toFixed(2)],
      [row, 'weight_kgs', null,
        parseFloat(weight_kgs).toFixed(2)],
    );
  });
  return newChanges;
};

let resetRateByTotal = (hot, changes) => {
  let newChanges = [];
  changes.forEach(([row, prop, , , computed]) => {
    if (prop === 'total' && !computed) {
      newChanges.push([
        row,
        'rate',
        hot.getDataAtRowProp(row, 'rate'),
        null
      ]);
    }
  });
  return newChanges;
};

let initPopulateDefaults = ({
    hot,
    serviceType,
    itemDescriptions,
    primaryCurrency,
    loadModuleId,
    loadModuleInfo,
    uoms,
    qualifiedTypes,
    rateTypes
  }) => {
  return changes => {
    let toPopulate = changes.filter(([row]) => {
      return !hot.getDataAtRowProp(row, 'id');
    }).reduce((acc, [row, prop, , newValue]) => {
      acc[row] = acc[row] || {};
      acc[row][prop] = newValue;
      return acc;
    }, {});

    Object.entries(toPopulate).forEach(([row, props]) => {
      props.hasOwnProperty('load_module_id') ||
        changes.unshift([row, 'load_module_id', null, loadModuleId]);
      props.hasOwnProperty('load_warehouse_name') ||
        changes.unshift([
          row,
          'load_warehouse_name',
          null,
          loadModuleInfo.find(load => load[0] === loadModuleId)[2]]
        );

      if (props.hasOwnProperty('full_name')) {
        // is full_name custom or not?
        let found = itemDescriptions.find(el => el[1] === props.full_name);
        if (!found) found = [props.full_name, props.full_name];
        changes.unshift([row, 'name', null, found[0]]);
        changes.unshift([row, 'full_name', null, found[1]]);
      } else {
        changes.unshift([row, 'name', null, itemDescriptions[0][0]]);
        changes.unshift([row, 'full_name', null, itemDescriptions[0][1]]);
      }
      if (!props.hasOwnProperty('rate_type')) {
        let defaultRateType = rateTypes[0];
        switch (serviceType) {
          case 'destruction':
            defaultRateType = 'weight'
            break;
          case 'resale':
          case 'redeployment':
            defaultRateType = 'quantity'
            break;
        }
        changes.unshift([row, 'rate_type', null, defaultRateType]);
      }

      props.hasOwnProperty('description') ||
        changes.unshift([row, 'description', null, qualifiedTypes[0]]);
      props.hasOwnProperty('qty') ||
        changes.unshift([row, 'qty', null, 0]);
      props.hasOwnProperty('weight') ||
        changes.unshift([row, 'weight', null, '0']);
      props.hasOwnProperty('uom') ||
        changes.unshift([row, 'uom', null, uoms[0]]);
      props.hasOwnProperty('rate') ||
        changes.unshift([row, 'rate', null, 0]);
      props.hasOwnProperty('tax_included') ||
        changes.unshift([row, 'tax_included', null, false]);
      props.hasOwnProperty('currency') ||
        changes.unshift([row, 'currency', null, primaryCurrency]);
    });
  };
};
