import Qualified from '../qualified';

export default class QualifiedSubsection {
  constructor(parent) {
    this.parent = parent;
    this.tables = [];
  }

  setup({
    data,
    serviceType,
    loadModuleIds,
    loadWorkOrders,
    nonEditable,
    qualifiedTypes,
    mainContainerId,
    fullNames,
    totalEditable = false,
    onAfterChange
    }) {
    let groupedByLoadModuleId = data.reduce((acc, item) => {
      let key = item.load_module_id;
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(item);
      return acc;
    }, {});

    loadModuleIds.forEach(loadModuleId => {
      let tableId = `${mainContainerId}-${loadModuleId}`;
      let container = $('<div></div>').attr('id', tableId);
      container.addClass('mb-2');
      $(`#${mainContainerId}`).append(container);

      let items = groupedByLoadModuleId[loadModuleId] || [];
      items.sort((a, b) => {
        return b.load_receiving_id - a.load_receiving_id;
      });

      let table = new Qualified();
      table.setup({
        data: items,
        containerId: tableId,
        loadModuleId: parseInt(loadModuleId),
        currencies: loadWorkOrders.currencies,
        itemDescriptions: loadWorkOrders.load_item_types,
        primaryCurrency: loadWorkOrders.primary_currency,
        loadModuleInfo: loadWorkOrders.load_module_info,
        rateTypes: loadWorkOrders.rate_types,
        uoms: loadWorkOrders.uoms,
        serviceType,
        totalEditable,
        qualifiedTypes,
        nonEditable,
        fullNames,
        onAfterChange
      });
      this.parent.tables.push(table);
      this.tables.push(table);
    });
  }

  getTotalWeight() {
    let sum = 0;
    this.tables.forEach(table => {
      for (let row = 0; row < table.hot.countRows(); row++) {
        sum += parseFloat(
          table.hot.getDataAtRowProp(row, 'weight_kgs')) || 0;
      }
    });
    return sum;
  }

  destroy() {
    this.tables = [];
  }
};
