import {initHandsontable} from '../../../../../packs/handsontable';
import {
  exactValueRenderer,
  monetaryRenderer,
  roundToThousandthsRenderer
} from '../../../../load_modules/work_order/hot_renderers';
import HotBase from '../../../../load_modules/work_order/hot_base';
import {hotCrud} from '../hot_crud';
import {initPrepopulate} from './helpers/prepopulate';
import {initCalculateTotals} from '../common/calculate_totals';
import {initWeightValidation} from '../common/validate_weight';
import {initRestValidation} from './helpers/validate_rest';

export default class Telus extends HotBase {
  setup({
          containerId,
          data,
          nonEditable,
          uoms,
          fixedUom,
          currencies,
          primaryCurrency,
          loadNumber
        }) {
    this.hot = initHandsontable({
      containerId: containerId,
      data: data,
      additionalOptions: {
        minSpareRows: 0,
        contextMenu: [],
        copyPaste: false,
        fillHandle: false,
        undo: true,
        hiddenColumns: {
          columns: [0]
        },
        dropdownMenu: false,
        filters: false,
        rowHeaders: true
      },
      colHeaders: [
        'ID',
        'Load Module',
        'Warehouse',
        'Category',
        'Description',
        'Unbox Weight',
        'Weight',
        'UOM',
        'Rate',
        'Total',
        'Tax',
        'Currency'
      ],
      columns: [
        {
          data: 'id',
          readOnly: true,
          className: 'htCenter',
          renderer: 'html'
        },
        {
          data: 'load_module_id',
          readOnly: true,
          className: 'htCenter',
          renderer: exactValueRenderer(loadNumber)
        },
        {
          data: 'load_warehouse_name',
          readOnly: true,
          className: 'htCenter',
          renderer: 'html'
        },
        {
          data: 'category',
          readOnly: true,
          className: 'htCenter',
          renderer: 'html'
        },
        {
          data: 'description',
          readOnly: true,
          className: 'htCenter',
          renderer: 'html'
        },
        {
          data: 'unbox_weight',
          readOnly: nonEditable,
          type: 'numeric',
          className: 'htCenter',
          renderer: monetaryRenderer
        },
        {
          data: 'weight',
          readOnly: nonEditable,
          type: 'numeric',
          className: 'htCenter',
          renderer: monetaryRenderer
        },
        {
          data: 'uom',
          readOnly: nonEditable,
          type: 'dropdown',
          strict: true,
          allowInvalid: false,
          source: uoms,
          className: 'htCenter'
        },
        {
          data: 'rate',
          readOnly: nonEditable,
          type: 'numeric',
          className: 'htCenter',
          renderer: roundToThousandthsRenderer
        },
        {
          data: 'total',
          type: 'numeric',
          readOnly: true,
          className: 'htCenter',
          renderer: monetaryRenderer
        },
        {
          data: 'tax_included',
          readOnly: nonEditable,
          type: 'checkbox',
          className: 'htCenter'
        },
        {
          data: 'currency',
          readOnly: nonEditable,
          type: 'dropdown',
          strict: true,
          allowInvalid: false,
          source: currencies,
          className: 'htCenter'
        }
      ]
    });

    let ajax = hotCrud(this.hot);
    let prepopulate = initPrepopulate(this.hot, fixedUom, primaryCurrency);
    let validateWeight = initWeightValidation();
    let validateRest = initRestValidation();
    let calculateTotals = initCalculateTotals(this.hot);

    this.hot.addHook('beforeChange', (changes, source) => {
      if (!changes ||
        source === 'loadData' ||
        source === 'Sync.SetId') return;

      prepopulate(changes);
      validateWeight(changes);
      validateRest(changes);
      calculateTotals(changes);
    });

    this.hot.addHook('afterChange', (changes, source) => {
      if (!changes ||
        source === 'loadData' ||
        source === 'Sync.SetId') return;

      ajax.create(changes);
      ajax.update(changes);
      changes.some(([, prop]) => {
        return prop === 'total' ||
          prop === 'currency';
      }) && this.updateSubtotals();

      super.onAfterChange(changes);
    });

    super.setup(currencies);
  }

  onStandaloneMode() {
    this.hot
      .getPlugin('hiddenColumns')
      .hideColumns([8, 9, 10, 11]);
    this.hot.render();

    $('.currency-total-container').hide();
    $('#mbf-company hr').hide();
    $('.mbf-rate-btn').hide();
  }
};
