window.auditShow = {
  init() {
    this.handleDisabledPrevAndNextButton()
    this.handleNextItem()
    this.handlePrevItem()
    this.handleCloneRecord()
    this.handleSendToQuickbooks()
  },

  handleDisabledPrevAndNextButton() {
    var lastId = $("#lastId").val()
    var nextId = $("#nextId").val()

    if (lastId == $('#audit_id')) {
      $('.prevItem').addClass("disabled")
    } else {
      $('.prevItem').removeClass("disabled")
    }

    if ((nextId == $('#audit_id'))) {
      $('.nextItem').addClass("disabled")
    } else {
      $('.nextItem').removeClass("disabled")
    }
  },

  handleNextItem() {
    $('.nextItem').on('click', function (e) {
      $.ajax({
        type: "GET",
        url: "/admin/audits/get_next_item",
        dataType: "json",
        data: { audit_id: $(this).attr('id') },
        success: function (data) {
          window.location.href = `/admin/audits/${data.id}`
        }
      })
    })
  },

  handlePrevItem() {
    $('.prevItem').on('click', function (e) {
      $.ajax({
        type: "GET",
        url: "/admin/audits/get_prev_item",
        dataType: "json",
        data: { audit_id: $(this).attr('id') },
        success: function (data) {
          window.location.href = `/admin/audits/${data.id}`
        }
      })
    })
  },

  handleCloneRecord() {
    $('.clone-record-only').on('click', function (e) {
      $.ajax({
        type: "POST",
        url: "/admin/audits/clone",
        dataType: "json",
        data: { audit_id: $(this).attr('id') },
        success: function (data) {
          if (data.success) {
            success_message('Successfully Duplicated.')
            success_message('Redirecting to Cloned Audit.')

            setTimeout((function () {
              window.location = `/admin/audits/${data.audit.id}`
            }), 4000)
          } else {
            error_message(data.message)
          }
        }
      })
    })
  },

  handleSendToQuickbooks() {
    $('.send-to-quickbooks').on('click', function (e) {
      $.ajax({
        type: "POST",
        url: "/admin/audits/send_to_quickbooks",
        dataType: "json",
        data: { id: $(this).attr('id') },
        success: function (data) {
          success_message('Audit has been sent to QuickBooks.')
          setTimeout((function () { window.location.href = `/admin/audits/${data.id}`}), 4000)
        }
      })
    })
  }
}
